import React from 'react';

import { ICON_PROPERTIES, ICONS, IconSize } from './Icon.constants';
import css from './Icon.module.css';

interface IProps {
  name: ICONS;
  size?: IconSize;
}

const Icon: React.FC<IProps & React.SVGProps<SVGSVGElement>> = ({ name, size, ...rest }) => {
  const icon = ICON_PROPERTIES[name];
  const iconClass = `${css.icon}`;

  if (!icon) {
    return null;
  }

  if (icon.sprite) {
    const xlinkHref =
      (process.env.basePath || '') +
      `/assets/icons/${icon.sprite}.sprite.svg#${icon.sprite}--${name}.svg`;

    return (
      <svg className={iconClass} data-size={size} viewBox={icon.viewBox} {...rest}>
        <use xlinkHref={xlinkHref} />
      </svg>
    );
  }

  if (!icon?.component) {
    return null;
  }

  const IconComponent = icon.component;

  return <IconComponent className={iconClass} data-size={size} {...rest} />;
};

export default Icon;
