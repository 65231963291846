import { EVehicleHookupsCampgrounds } from '@/constants/amenities';
import { vehicleTypes } from '@/constants/vehicleDetails';
import { getIntl } from '@/utility/i18n';

import { TVehicleType } from './rentals/id';

export interface IVehicleCampgrounds {
  type?: TVehicleType;
  vehicle_length?: number;
  hookups?: EVehicleHookupsCampgrounds[];
}

export const prettyPrintVehicleCampgrounds = (vehicle?: IVehicleCampgrounds): string => {
  const intl = getIntl();
  let text = '';
  if (vehicle) {
    const numFilters = (vehicle.hookups || []).length;
    const vehicleType = vehicle.type
      ? intl.formatMessage(vehicleTypes[vehicle.type]?.label || '')
      : '';
    const info = [
      vehicleType,
      vehicle.vehicle_length ? `${vehicle.vehicle_length}ft` : '',
      numFilters ? `${numFilters} filters` : '',
    ].filter(s => !!s.trim());
    text = info.join(' • ');
  }
  return text;
};
