export enum EGiftCardsEventName {
  GIFT_CARDS_SELECTED = 'Gift Cards Selected',
}

export enum EGiftCardsEventSource {
  FOOTER = 'footer',
}

export interface IGiftCardsSelectedEvent {
  source: EGiftCardsEventSource;
}
