import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';

import { isSSR } from '@/utility/isSSR';

const getWindowSize = () => {
  return {
    width: isSSR() ? undefined : window.innerWidth,
    height: isSSR() ? undefined : window.innerHeight,
  };
};

/**
 * If used fully on client side, you can pass initializeWithComputedValue to true.
 * Using true on SSR will cause different initial state between SSR and CSR.
 */
const useWindowSize = (initializeWithComputedValue = false) => {
  const [windowSize, setWindowSize] = useState(() =>
    initializeWithComputedValue
      ? getWindowSize()
      : {
          width: undefined,
          height: undefined,
        },
  );

  useEffect(() => {
    const handleResize = throttle(() => setWindowSize(getWindowSize()), 100, {
      leading: true,
      trailing: true,
    });

    handleResize();

    window.addEventListener('resize', handleResize, { passive: true });
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
