import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import { defaultLocale, ILocale, locales as localesMap, TLocale } from '@/config/locales';

export { defaultLocale };

// Refines arbitrary string as a TLocale
const getValidLocaleString = (maybeLocaleString: string | undefined): TLocale => {
  return maybeLocaleString && isLocale(maybeLocaleString)
    ? maybeLocaleString
    : defaultLocale.locale;
};

export const getSettingsForLocale = (maybeLocaleString: string | undefined): ILocale => {
  return maybeLocaleString && isLocale(maybeLocaleString)
    ? localesMap[maybeLocaleString]
    : defaultLocale;
};

export const isLocale = (maybeLocale: string): maybeLocale is TLocale => {
  return maybeLocale in localesMap;
};

export const getLocale = (locale?: string): TLocale =>
  getValidLocaleString(
    locale ||
      (typeof window !== 'undefined' && window.document?.getElementsByTagName('html')[0]?.lang) ||
      '',
  );

const intlCache = createIntlCache();
let intl: IntlShape = createIntl({
  locale: 'en-us',
  defaultLocale: 'en-us',
});

export const setupIntl = (locale: IntlShape['locale'], messages?: IntlShape['messages']) => {
  intl = createIntl(
    {
      locale,
      defaultLocale: 'en-us',
      messages,
    },
    intlCache,
  );

  return intl;
};

/**
 * @deprecated We should stop using this. Use useIntl instead.
 */
export function getIntl() {
  return intl;
}
