import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FilterCheckbox from '@/components/route/search/FilterCheckbox/FilterCheckbox';
import Text from '@/components/switchback/Text/Text';
import { stayCategories } from '@/constants/vehicleDetails';
import { ILodgingCampgrounds } from '@/services/types/search/lodgingCampgrounds';
import { TStayCategories } from '@/services/types/search/rentals/id';
import { addOrRemoveItem } from '@/utility/addOrRemoveItem';

import { INavbarSearch } from '../../../SearchHeader';

interface LodgingCampgroundsContentProps {
  className?: string;
  open?: boolean;
  onChangeLodging?: INavbarSearch['onChangeLodgingCampgrounds'];
  lodging?: ILodgingCampgrounds;
  onConfirm?: () => void;
}

const LodgingCampgroundsContent: React.FC<
  React.PropsWithChildren<LodgingCampgroundsContentProps>
> = ({ open, onChangeLodging, onConfirm, lodging }) => {
  const intl = useIntl();

  const stayTypeOptions = Object.keys(stayCategories).map(key => {
    const stayDef = stayCategories[key as TStayCategories];
    return {
      label: intl.formatMessage(stayDef.label),
      value: key,
      icon: stayDef.icon,
    };
  });

  const handleChange = (value: string, key: string) => {
    const newData: ILodgingCampgrounds = {};
    if (key === 'type') {
      newData.lodging_group = addOrRemoveItem<TStayCategories>(
        value as TStayCategories,
        lodging?.lodging_group || [],
      );
    }
    onChangeLodging?.({ ...(lodging || {}), ...newData });
  };

  return (
    <div className={`${open ? 'block' : 'hidden'} p-2 md:p-6`}>
      <div className="grid gap-4 md:grid-cols-3">
        <div>
          <Text
            type="paragraph"
            className="mb-5 text-xs font-bold text-gray-500 uppercase semiHighlight">
            <FormattedMessage
              defaultMessage="Lodging type"
              id="A9rYz7"
              description="Guests filter > reset button"
            />
          </Text>
          {stayTypeOptions.map(option => {
            const key = option.value;
            const label = option.label;
            return (
              <FilterCheckbox
                key={key}
                name={key}
                checked={lodging?.lodging_group?.includes(key as TStayCategories)}
                label={label}
                icon={option.icon}
                onChange={() => handleChange(key, 'type')}
              />
            );
          })}
        </div>
      </div>
      <Button
        className="mt-6 md:hidden"
        variant={EButtonColorVariant.Primary}
        label={intl.formatMessage({ defaultMessage: 'Confirm', id: 'N2IrpM' })}
        fullWidth
        onClick={onConfirm}
      />
    </div>
  );
};

export default React.memo(LodgingCampgroundsContent);
