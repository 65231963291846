import { makeVar, useReactiveVar } from '@apollo/client';
import optimizelySdk, {
  Client,
  enums as optimizelyEnums,
  OptimizelyDecision,
  OptimizelyUserContext,
} from '@optimizely/optimizely-sdk';
import { useMemo } from 'react';

import { segmentDecision } from '@/services/analytics/trackEventV2';

export * from './flags';
declare global {
  interface Window {
    optimizelyClientInstance: Client;
  }
}

const OPTIMIZELY_TIMEOUT = 5_000;

// OptimizelyDecision types are refined into ActiveOptimizelyDecision.
export interface ActiveOptimizelyDecision extends OptimizelyDecision {
  enabled: true;
}

const isActiveDecision = (
  decision: Partial<OptimizelyDecision>,
): decision is ActiveOptimizelyDecision => {
  return !!decision.enabled;
};

const isOptimizelyReady = makeVar(false);
const optimizelyUser = makeVar<OptimizelyUserContext | null>(null);

// This function is used to set the user context for Optimizely.
// If Optimizely is not ready, it will set the user context when Optimizely is ready.
// Calling the function again while Optimizely is not ready will cancel the previous
// request, and the new one will take its place.
export const setOptimizelyUser = (() => {
  let unsubscribe: undefined | (() => void) = undefined;

  return (userId: string, attributes: Record<string, unknown> = {}) => {
    const update = () => {
      unsubscribe?.();
      unsubscribe = undefined;

      if (isOptimizelyReady()) {
        optimizelyUser(window.optimizelyClientInstance.createUserContext(userId, attributes));
        return true;
      }

      return false;
    };

    !update() && (unsubscribe = isOptimizelyReady.onNextChange(update));
  };
})();

const useOptimizelyUser = () => useReactiveVar(optimizelyUser);

const setupOptimizely = async (isEnabled: boolean, isLoggerEnabled: boolean) => {
  const sdkKey = process.env.optimizelySDKKey;
  if (!isEnabled || !sdkKey) return;
  const optimizelyClientInstance = optimizelySdk.createInstance({
    logger: isLoggerEnabled
      ? undefined
      : {
          log() {
            // noop
          },
        },
    sdkKey,
  });
  // This registers the optimizely client instance on the window for segment to detect.
  // See: https://segment.com/docs/connections/destinations/catalog/optimizely-web/#optimizely-full-stack-javascript-sdk
  window.optimizelyClientInstance = optimizelyClientInstance;
  const { success } = await optimizelyClientInstance.onReady({ timeout: OPTIMIZELY_TIMEOUT });
  if (success) {
    optimizelyClientInstance.notificationCenter.addNotificationListener(
      optimizelyEnums.NOTIFICATION_TYPES.DECISION,
      segmentDecision,
    );
    isOptimizelyReady(true);
  }
};

/**
 * Use a potentially inactive optimizely decision. decision.enabled may be false here.
 * This will return the decision even if it is not in the active state.
 */
const useRawOptimizelyDecision = (flagKey: string): OptimizelyDecision | null => {
  const user = useOptimizelyUser();
  const flag = useMemo((): OptimizelyDecision | null => {
    const userId = user?.getUserId();
    if (user && userId) {
      const decision = user?.decide(flagKey, []);
      return decision;
    }
    return null;
  }, [user, flagKey]);

  return flag;
};

/**
 * Use an active optimizely decision for a given flagKey.
 * Note that if the decision is not in the "enabled" state, this will return null.
 * "Enabled" not to be confused with the "enabled" variant.
 */
export const useOptimizelyDecision = (flagKey: string): ActiveOptimizelyDecision | null => {
  const decision = useRawOptimizelyDecision(flagKey);
  if (!decision) return null;

  return isActiveDecision(decision) ? decision : null;
};

export const useExperimentIsEnabled = (flagKey: string, enabledVariationKey = 'enabled') => {
  const decision = useOptimizelyDecision(flagKey);
  return decision?.variationKey === enabledVariationKey;
};

export default setupOptimizely;
