import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { useUser } from '@/hooks/useUser';
import { getAuthenticatedUser } from '@/redux/selectors/auth/user';

import css from './Impersonated.module.css';

export const Impersonated = React.memo(function Impersonated() {
  const user = useSelector(getAuthenticatedUser);
  const { logout } = useUser();

  if (!user?.impersonated) return null;

  const getEnv = () => {
    if (typeof window === 'undefined') return '';
    if (location.hostname.includes('staging')) return 'Staging';
    if (location.hostname.includes('outdoorsy')) return 'Production';
    return 'Development';
  };

  const handleSignout = () => {
    logout();
  };

  return (
    <div className={css.impersonatedWrapper}>
      <div>
        <strong>
          <FormattedMessage defaultMessage="Logged in as:" id="sUNxoN" />
        </strong>
        {user.first_name} {user.last_name}
      </div>
      <div>
        <strong>
          <FormattedMessage defaultMessage="Environment:" id="M7yNVd" />
        </strong>
        {getEnv()}
      </div>
      <button onClick={handleSignout} className="text-white cursor-pointer">
        <FormattedMessage defaultMessage="Exit" id="E2sGaF" />
      </button>
    </div>
  );
});
