import { useEffect, useState } from 'react';

import { breakpoint } from '@/constants/breakpoint';

import useWindowSize from './useWindowSize';

export function useBreakpoint() {
  const windowSize = useWindowSize();

  const [isMobile, setIsMobile] = useState(false);
  const [isAboveTablet, setIsAboveTablet] = useState(false);
  const [isAboveDesktop, setIsAboveDesktop] = useState(false);
  const [isAboveXL, setIsAboveXL] = useState(false);
  const [isAboveXXXL, setIsAboveXXXL] = useState(false);

  useEffect(() => {
    if (!windowSize.width) return;
    setIsMobile(windowSize.width < breakpoint.md);
    setIsAboveTablet(windowSize.width >= breakpoint.md);
    setIsAboveDesktop(windowSize.width >= breakpoint.lg);
    setIsAboveXL(windowSize.width >= breakpoint.xl);
    setIsAboveXXXL(windowSize.width >= breakpoint.xxxl);
  }, [windowSize.width]);

  return {
    isMobile,
    isAboveTablet,
    isAboveDesktop,
    isAboveXL,
    isAboveXXXL,
  };
}
