import { TLocale } from '@/config/locales';

import { getCoreApi } from './getCoreApi';

export const buildSocialAuthUrl = (method: string, locale: TLocale) => {
  const state = {
    locale: locale,
  };
  const stateEncoded = btoa(JSON.stringify(state));
  return `${getCoreApi()}/auth/${method}/login?state=${stateEncoded}`;
};
