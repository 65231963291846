import { EProjectSection, trackEventV2 } from '../trackEventV2';
import {
  ESearchEventName,
  IGuidedSearchSelectedInfo,
  IRenterSearchedInfo,
  IRenterSearchFilterUpdatedInfo,
  ISearchCategorySelected,
  ISearchResultInfo,
} from './types';

export const trackSearchEvent = (event: ISearchResultInfo) => {
  trackEventV2(ESearchEventName.SEARCH_RESULT_VIEWED, EProjectSection.MARKETPLACE, {
    ...event,
    locationQuery: event.locationQuery || null,
    locationLatitude: event.locationLatitude || null,
    locationLongitude: event.locationLongitude || null,
    departureDate: event.departureDate || null,
    returnDate: event.returnDate || null,
    flexibleDateRange: event.filters?.flexible_days || null,
    isSEO: event.isSEO,
    isBlended: event.isBlended,
    searchBlendVariant: event.experiments?.searchBlend || null,
    blendLogic: event.experimentsData?.searchBlend?.blendLogic || null,
  });
};

export const searchCategorySelectedEvent = (event: ISearchCategorySelected) => {
  trackEventV2(ESearchEventName.SEARCH_CATEGORY_SELECTED, EProjectSection.MARKETPLACE, {
    ...event,
  });
};

export const trackRenterSearchedEvent = (event: IRenterSearchedInfo) => {
  return trackEventV2(ESearchEventName.RENTER_SEARCHED, EProjectSection.MARKETPLACE, {
    ...event,
  });
};

export const trackRenterSearchFilterUpdatedEvent = (event: IRenterSearchFilterUpdatedInfo) => {
  trackEventV2(ESearchEventName.SEARCH_FILTER_UPDATED, EProjectSection.MARKETPLACE, {
    ...event,
  });
};

export function trackGuidedSearchSelected(event: IGuidedSearchSelectedInfo) {
  trackEventV2(ESearchEventName.GUIDED_SEARCH_SELECTED, EProjectSection.MARKETPLACE, {
    ...event,
  });
}
