import { IAction } from '@/utility/redux/action';

import { IBookingsReviewResponse } from './actions';

export const FETCH_BOOKINGS_REVIEW_PENDING = 'bookingReview/FETCH_BOOKINGS_REVIEW_PENDING';
export const FETCH_BOOKINGS_REVIEW_FAILURE = 'bookingReview/FETCH_BOOKINGS_REVIEW_FAILURE';
export const FETCH_BOOKINGS_REVIEW_SUCCESS = 'bookingReview/FETCH_BOOKINGS_REVIEW_SUCCESS';

interface IBookingsReviewPendingAction extends IAction {
  type: typeof FETCH_BOOKINGS_REVIEW_PENDING;
}

interface IBookingsReviewSuccessAction extends IAction {
  type: typeof FETCH_BOOKINGS_REVIEW_SUCCESS;
  payload: IBookingsReviewResponse;
}

interface IBookingsReviewFailureAction extends IAction {
  type: typeof FETCH_BOOKINGS_REVIEW_FAILURE;
  error: boolean;
}

export type IBookingsReviewAction =
  | IBookingsReviewPendingAction
  | IBookingsReviewSuccessAction
  | IBookingsReviewFailureAction;
