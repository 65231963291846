import { camelToSnakeCase, toCamelCase } from './strings';

// Return true or false as to whether `o` is an Object.
export const isObject = function (o: unknown): o is Record<string, unknown> {
  return o === Object(o) && !Array.isArray(o) && typeof o !== 'function';
};

export const keysToCamel = (o: any): any => {
  if (isObject(o)) {
    const n: Record<string, string | string[] | undefined> = {};

    Object.keys(o).forEach((k: string) => {
      n[toCamelCase(k)] = keysToCamel(o[k]);
    });

    return n;
  } else if (Array.isArray(o)) {
    return o.map(i => {
      return keysToCamel(i);
    });
  }

  return o;
};

export const camelKeysToSnake = <T extends Record<string, any>>(
  object: T,
): Record<string, T[string]> =>
  Object.keys(object).reduce<Record<string, T[string]>>((acc, key) => {
    acc[camelToSnakeCase(key)] = object[key];
    return acc;
  }, {});
