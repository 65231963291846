import { Icon as BonfireIcon, TIconName } from '@outdoorsyco/bonfire';
import React from 'react';

import Checkbox, { CheckboxAlignment } from '@/components/switchback/Checkbox/Checkbox';
import Help from '@/components/switchback/Help/Help';
import Icon from '@/components/switchback/Icon/Icon';
import { ICONS, IconSize } from '@/components/switchback/Icon/Icon.constants';
import IconComponent, { IconType } from '@/components/switchback/Icon/IconComponent';

interface IProps {
  checked?: boolean;
  icon?: ICONS | IconType;
  bonfireIcon?: TIconName;
  label: string;
  name: string;
  onChange: React.ChangeEventHandler;
  testId?: string;
  value?: string;
  helpText?: string;
  helpLabel?: string;
  fontMedium?: boolean;
  description?: string;
}

const FilterCheckbox: React.FC<IProps> = ({
  checked = false,
  icon,
  bonfireIcon,
  label,
  name,
  onChange,
  testId,
  fontMedium,
  value,
  helpText,
  description,
  helpLabel,
}) => (
  <div className="mb-3 last:mb-0">
    <Checkbox
      name={name}
      value={value || name}
      checked={checked}
      testId={testId || name}
      onChange={onChange}
      align={description ? CheckboxAlignment.top : CheckboxAlignment.center}
      contentClassName="flex-auto w-full"
      className="flex-row-reverse items-start justify-start text-xl md:flex-row">
      <div className="flex items-center mr-4 md:mr-0">
        {icon &&
          (typeof icon === 'string' ? (
            <Icon name={icon as ICONS} size={IconSize.normal} />
          ) : (
            <IconComponent name={icon} width={20} height={20} />
          ))}
        {bonfireIcon && <BonfireIcon name={bonfireIcon} width={20} height={20} />}
        <div
          className={`${icon || bonfireIcon ? 'ml-3 mr-2' : ''} ${
            description ? '-mt-1' : ''
          } autoType300`}>
          <span className={`${fontMedium && 'font-medium'} text-base`}>{label}</span>
          {description && (
            <div className="mt-1 mr-4 text-gray-500 md:mr-0 d-block">{description}</div>
          )}
        </div>
        {helpText && helpLabel && (
          <Help label={helpLabel} content={helpText} className="inline-flex" />
        )}
      </div>
    </Checkbox>
  </div>
);

export default FilterCheckbox;
