import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Divider from '@/components/switchback/Divider/Divider';
import { IHeaderSearchFilterDates } from '@/redux/modules/globalHeader';

import FlexibleDateSelectButton from './FlexibleDateSelectButton';

interface IDateContentFooterProps {
  datesValue?: IHeaderSearchFilterDates;
  handleChangeDates: (newDates: IHeaderSearchFilterDates) => void;
  handleClearDates: () => void;
}

const DateContentFooter: React.FC<IDateContentFooterProps> = ({
  datesValue,
  handleChangeDates,
  handleClearDates,
}) => {
  const intl = useIntl();
  return (
    <>
      <Divider className="mt-5" />
      <div className="flex items-center justify-between pt-3">
        <div className="flex">
          <FlexibleDateSelectButton
            selected={datesValue?.flexible_days == undefined}
            onClick={() => handleChangeDates({ flexible_days: undefined })}
            label={intl.formatMessage({ defaultMessage: 'Match exact dates', id: 'uhcpp1' })}
          />
          <FlexibleDateSelectButton
            className="mx-4"
            selected={datesValue?.flexible_days == 1}
            onClick={() => handleChangeDates({ flexible_days: 1 })}
            label={intl.formatMessage({ defaultMessage: '±1 day', id: 'Y9uTsx' })}
          />
          <FlexibleDateSelectButton
            selected={datesValue?.flexible_days == 3}
            onClick={() => handleChangeDates({ flexible_days: 3 })}
            label={intl.formatMessage({ defaultMessage: '±3 days', id: '1sJT3M' })}
          />
        </div>
        <button
          type="button"
          className="flex items-center text-sm text-gray-800 underline border-none underline-offset-2 semiHighlight"
          onClick={handleClearDates}>
          <FormattedMessage
            defaultMessage="Clear dates"
            id="ydk4A5"
            description="Date filter > reset button"
          />
        </button>
      </div>
    </>
  );
};

export default DateContentFooter;
