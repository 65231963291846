import { makeVar } from '@apollo/client';
import Cookies from 'js-cookie';

import { isSSR } from './isSSR';

export const cookieCache = makeVar<Record<string, string | undefined>>(
  isSSR() ? {} : Cookies.get(),
);

export function getCookie(key: string): string | undefined {
  const value = Cookies.get(key);
  const cache = cookieCache();
  if (cache[key] !== value) {
    cookieCache({ ...cache, [key]: value });
  }
  return value;
}

export function setCookie(key: string, value: string, attrs?: Cookies.CookieAttributes): void {
  Cookies.set(key, value, attrs);
  cookieCache({ ...cookieCache(), [key]: value });
}

export function removeCookie(key: string): void {
  Cookies.remove(key);
  const { [key]: _unused, ...restCookie } = cookieCache();
  cookieCache(restCookie);
}
