import { makeVar } from '@apollo/client';
import { AxiosError } from 'axios';
import { ThunkAction } from 'redux-thunk';

import { TCurrency } from '@/config/locales';
import { TRootState } from '@/redux/rootReducer';
import { getLocale } from '@/redux/selectors/locale';
import apiRequest from '@/services/apiRequest';
import { setOptimizelyUser } from '@/services/experiments';
import { getODAnonymousId, trackUserLoginOrUpdate } from '@/services/segment';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';
import { getActorId, getAuthToken } from '@/utility/session';
export const isImpersonated = makeVar(false);

export const FETCH_USER_SERVICE_URL = `${getCoreApi()}/users/mini_me`;

export const FETCH_USER_PENDING = 'auth/FETCH_USER_PENDING';
export const FETCH_USER_SKIPPED = 'auth/FETCH_USER_SKIPPED';
export const FETCH_USER_FAILURE = 'auth/FETCH_USER_FAILURE';
export const FETCH_USER_SUCCESS = 'auth/FETCH_USER_SUCCESS';

//#region interface for Fetch User call (mini_me)
interface IUserPendingAction extends IAction {
  type: typeof FETCH_USER_PENDING;
}

interface IUserSuccessAction extends IAction {
  type: typeof FETCH_USER_SUCCESS;
  payload: IUserResponse;
}

interface IUserFailureAction extends IAction {
  type: typeof FETCH_USER_FAILURE;
  payload: AxiosError;
  error: boolean;
}
//#endregion

export type IUserAction = IUserSuccessAction | IUserFailureAction | IUserPendingAction;

interface Verifications {
  email: boolean;
  has_sent_message_without_phone_verified: boolean;
  phone: boolean;
}
export interface IUserResponse {
  avatar_url: string;
  credits: number;
  currency: TCurrency;
  created?: string;
  email: string;
  first_name: string;
  phone: string;
  id: number;
  last_name: string;
  locale: string;
  owner: boolean;
  unread_conversations: number;
  verifications: Verifications;
  has_installed_apps: boolean;
  accepted_tos_version: number;
  guest?: boolean;
  role?: 'driver' | 'passenger' | null;
  impersonated?: boolean;
  admin?: boolean;
  referral_code?: string;
}

//#region actions for fetch user call (mini_me)
const fetchUserPending = (): IUserPendingAction => ({ type: FETCH_USER_PENDING });

export const fetchUserFailure = (error: AxiosError): IUserFailureAction => ({
  type: FETCH_USER_FAILURE,
  payload: error,
  error: true,
});

export const fetchUserSkipped = () => ({
  type: FETCH_USER_SKIPPED,
});

const fetchUserSuccess = (user: IUserResponse): IUserSuccessAction => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: user,
  };
};
//#endregion

// async FETCH USER THUNK (mini_me)
export const fetchUser = (
  shouldSkipLoading?: boolean,
  shouldIdentifyUser?: boolean,
): ThunkAction<any, TRootState, void, IUserAction> => {
  return async (dispatch, getStore): Promise<IUserResponse | undefined> => {
    const authToken = getAuthToken();
    if (!shouldSkipLoading) {
      dispatch(fetchUserPending());
    }

    if (authToken) {
      try {
        const user = await apiRequest<IUserResponse>(
          {
            url: FETCH_USER_SERVICE_URL,
            method: 'GET',
          },
          true,
        );
        if (user?.id) {
          dispatch(fetchUserSuccess(user));
        } else {
          dispatch(fetchUserFailure({ code: 401 } as unknown as AxiosError));
        }

        const locale = getLocale(getStore());

        // Every time we log in or update the user, send another login/identify call
        // to segment so they can keep a fresh copy of the user's attrs for this device
        // Note -- Run in async anonymous fn to not block redux state in case 3s adblock delay.
        (async () => {
          const anonymousId = await getODAnonymousId();
          const userLogin = {
            email: user.email,
            userId: user.id,
            actorId: getActorId(),
            deviceId: anonymousId || null,
            anonymousId: anonymousId || null,
            isOwner: !!user.owner,
            isGuest: !!user.guest,
            firstName: user.first_name || null,
            lastName: user.last_name || null,
            createdAt: user.created || null,
            referralCode: user.referral_code || null,
            locale: locale?.locale,
            isAdmin: !!user.admin,
          };
          isImpersonated(user.impersonated || false);
          if (shouldIdentifyUser) {
            trackUserLoginOrUpdate(userLogin);
          }
          setOptimizelyUser(anonymousId, userLogin);
        })();

        return user;
      } catch (error) {
        dispatch(fetchUserFailure(error));
      }
    } else {
      dispatch(fetchUserFailure({ code: 401 } as unknown as AxiosError));
    }
  };
};
