import { EImageTags, IImage } from '@/services/types/search/rentals/id';

export const createKuulaImageObject = (url: string, placeholderUrl: string): IImage => ({
  best: false,
  description: '',
  category: { slug: EImageTags.OTHER, name: 'Other' },
  id: 998,
  position: 2, // This position will be adjusted automatically
  primary: false,
  skip_enhance: false,
  tags: undefined,
  url: placeholderUrl,
  video: false,
  alt: '',
  rental_id: 0,
  odnMedia: {
    url: url,
    is360Tour: true,
    isVideo: false,
  },
});

export const createYoutubeVideoObject = (url: string, placeholderUrl: string): IImage => ({
  best: false,
  description: '',
  category: { slug: EImageTags.OTHER, name: 'Other' },
  id: 999,
  position: 3, // This position will be adjusted automatically
  primary: false,
  skip_enhance: false,
  tags: undefined,
  url: placeholderUrl,
  video: true,
  alt: '',
  rental_id: 0,
  odnMedia: {
    url: url,
    is360Tour: false,
    isVideo: true,
  },
});

export const addObjectToGallery = (images: IImage[], customObject: IImage, place: number) => {
  // Check if the custom object already exists in the array
  const exists = images.some(image => image.odnMedia?.url === customObject.odnMedia?.url);
  if (!exists) {
    images.splice(place, 0, customObject);
    // Update the position property of each object accordingly
    for (let i = place; i < images.length; i++) {
      const image = images[i];
      if (image) {
        image.position = i + 1;
      }
    }
  }
};
