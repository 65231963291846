import { makeVar, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';

import { ESerpGuidedSearchVariationKey, OptimizelyFlags } from './flags';
import { useOptimizelyDecision } from './index';

const serpGuidedSearchDecision = makeVar<{ variation?: string; enabled: boolean }>({
  variation: undefined,
  enabled: false,
});

export const useSerpGuidedSearchDecision = () => {
  return useReactiveVar(serpGuidedSearchDecision);
};

// We would like to initialize the decision only for counties, and be able to use the decision also in the header.
// Using the decision directly causes event viewed to be triggered on any page, since we have the decision in the header.
// To avoid this, render this component only if the locality is a county, and the decision will be initialized,
// and via reactive variables we can use the decision in all the other places.
export const InitSerpGuidedSearchDecision = () => {
  const decision = useOptimizelyDecision(OptimizelyFlags.SERP_GUIDED_SEARCH);

  useEffect(() => {
    serpGuidedSearchDecision({
      variation: decision?.variationKey || undefined,
      enabled: Object.values(ESerpGuidedSearchVariationKey).includes(
        decision?.variationKey as ESerpGuidedSearchVariationKey,
      ),
    });
  }, [decision]);

  return null;
};
