import { createSelector } from 'reselect';

import { TLocale } from '@/config/locales';
import { TRootState } from '@/redux/rootReducer';
import { defaultLocale, isLocale } from '@/utility/i18n';

type TAuth = TRootState['auth'];
export type TAuthUser = TRootState['auth']['user'];

export const getAuthenticatedUser = (state: TRootState) => state.auth.user;

export const getIsAuthenticated = createSelector<TRootState, TAuth, boolean>(
  state => state.auth,
  auth => auth.isAuthenticated,
);

export const getUser = createSelector<TRootState, TAuth, TAuthUser>(
  state => state.auth,
  auth => auth.user,
);

export const getUserIsGuest = (state: TRootState): boolean => {
  return !!state.auth?.user?.guest;
};

export const getUserLocale = (state: TRootState): TLocale => {
  const maybeLocale = state.auth?.user?.locale || '';
  return isLocale(maybeLocale) ? maybeLocale : defaultLocale.locale;
};

export const getUnreadConversations = createSelector<TRootState, TAuthUser, number | null>(
  state => state.auth.user,
  user => {
    if (!user) {
      return null;
    }

    return user.unread_conversations;
  },
);

export const getIsFetchingUser = createSelector<TRootState, TAuth, boolean>(
  state => state.auth,
  auth => auth.isFetching,
);

export const getHasAppInstalled = createSelector<TRootState, TAuthUser, boolean>(
  state => state.auth?.user,
  user => user?.has_installed_apps || false,
);
