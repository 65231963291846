export enum ERentalType {
  RV = 'rv',
  STAY = 'stay',
  CAMPGROUND = 'campground',
  LODGING_SITE = 'lodging_site',
  RV_SITE = 'rv_site',
  TENT_SITE = 'tent_site',
  CAMPSITE = 'campsite',
  STATIONARY_CAMPER_SITE = 'stationary_camper_site',
}
