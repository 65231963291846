import { ICampgroundData } from '@/services/types/search/campgrounds/id';
import { isSSR } from '@/utility/isSSR';

declare global {
  interface Window {
    OD_GLOBAL_EVENT_INFO?: {
      isOutdoorsyDestination?: boolean;
      outdoorsyDestinationName?: string;
    };
  }
}

export const getODNEventProperties = (extendedExtraInfo: Record<string, unknown>) => {
  const globalData = window.OD_GLOBAL_EVENT_INFO || {};
  return {
    isOutdoorsyDestination:
      globalData.isOutdoorsyDestination || extendedExtraInfo.isOutdoorsyDestination,
    outdoorsyDestinationName:
      globalData.outdoorsyDestinationName || extendedExtraInfo.outdoorsyDestinationName,
  };
};

export const setODNEventProperties = (campground?: ICampgroundData | null) => {
  if (!isSSR() && campground) {
    const existingInfo = window.OD_GLOBAL_EVENT_INFO || {};
    const isODN = existingInfo.isOutdoorsyDestination || campground?.is_odn || false;
    window.OD_GLOBAL_EVENT_INFO = {
      isOutdoorsyDestination: isODN,
      outdoorsyDestinationName: isODN
        ? existingInfo.outdoorsyDestinationName || campground?.name || ''
        : '',
    };
  }
};
