import { useReactiveVar } from '@apollo/client';
import { CookieAttributes } from 'js-cookie';
import uniq from 'lodash/uniq';
import { useEffect } from 'react';

import { cookieCache, getCookie, removeCookie, setCookie } from '@/utility/cookie';

let pollerId: ReturnType<typeof setInterval> | undefined;
const subscribers: string[] = [];

/**
 * Technically private, but exported so tests can reset any polling
 */
export const teardown = () => {
  if (pollerId) {
    clearInterval(pollerId);
    pollerId = undefined;
  }
};

export function useCookie(
  cookieName: string,
): [
  string | null,
  { update: (value: string | null, attributes?: CookieAttributes) => void; remove: () => void },
] {
  const cache = useReactiveVar(cookieCache);
  const update = (value: string | null, attributes?: CookieAttributes) =>
    setCookie(cookieName, value || '', attributes);
  const remove = () => removeCookie(cookieName);

  useEffect(() => {
    getCookie(cookieName);
    subscribers.push(cookieName);

    if (!pollerId && subscribers.length > 0) {
      pollerId = setInterval(() => {
        uniq(subscribers).forEach(key => {
          getCookie(key);
        });
      }, 2000);
    }

    return () => {
      subscribers.splice(subscribers.indexOf(cookieName), 1);
      if (pollerId && subscribers.length === 0) {
        teardown();
      }
    };
  }, [cookieName]);

  return [cache[cookieName] || null, { update, remove }];
}
