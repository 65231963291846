import { EImageTags } from '@/services/types/search/rentals/id';
import { IAction } from '@/utility/redux/action';

const FILTER_SLIDES = 'filterSlides/FILTER_SLIDES';

interface IFilterSlides extends IAction {
  type: typeof FILTER_SLIDES;
  payload: EImageTags | undefined;
}

type TAction = IFilterSlides;

export const filterSlides = (category: EImageTags | undefined): IFilterSlides => {
  return {
    type: FILTER_SLIDES,
    payload: category,
  };
};

interface IInitialState {
  category: EImageTags | undefined;
}

export const initialState: IInitialState = {
  category: EImageTags.ALL,
};

export default function filterSlidesReducer(state = initialState, action: TAction): IInitialState {
  switch (action.type) {
    case FILTER_SLIDES:
      return { ...state, category: action.payload };
    default:
      return state;
  }
}
