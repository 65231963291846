import React from 'react';

import BaseIcon from './Icon';
import { ICONS } from './Icon.constants';
import css from './Icon.module.css';

export enum IconSize {
  large = 'large',
  normal = 'normal',
  small = 'small',
}

export type IconType = React.FC<React.SVGProps<SVGElement>> | string;

interface IProps extends Omit<React.SVGProps<SVGSVGElement>, 'name'> {
  name: IconType;
  size?: IconSize;
}

const IconComponent: React.FC<IProps> = ({ name: Icon, size, ...rest }) => {
  if (typeof Icon === 'string') {
    return <BaseIcon name={Icon as ICONS} size={size} {...rest} />;
  }

  return <Icon className={css.icon} data-size={size} {...rest} />;
};

export default IconComponent;
