import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { getAuthenticatedUser } from '@/redux/selectors/auth/user';

interface Props {
  siftKey: string;
}

const loadSift = () => {
  const script = document.createElement('script');
  script.src = 'https://cdn.sift.com/s.js';
  document.body.appendChild(script);
};

const SiftTracker = ({ siftKey }: Props) => {
  const authenticatedUser = useSelector(getAuthenticatedUser);
  useEffect(() => {
    if (authenticatedUser && authenticatedUser.impersonated) return;
    if (!(window as any)._sift) loadSift();
    const userId = authenticatedUser ? authenticatedUser.id.toString() : '';
    if (!window.sessionStorage.getItem('session-id')) {
      window.sessionStorage.setItem('session-id', uuidv4());
    }

    const sessionId = window.sessionStorage.getItem('session-id');
    const sift = ((window as any)._sift as any[]) || [];
    sift.push(['_setAccount', siftKey]);
    sift.push(['_setUserId', userId]);
    sift.push(['_setSessionId', sessionId]);
    sift.push(['_trackPageview']);
  }, [authenticatedUser, siftKey]);

  return null;
};

export default SiftTracker;
