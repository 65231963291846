import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { AccountFormTemplate } from '@/components/auth/AccountFormTemplate';
import { IProps as ISignupFormProps, SignUpForm } from '@/components/auth/SignUpForm';
import Modal from '@/components/switchback/Modal';
import { ModalSize } from '@/components/switchback/Modal/Modal';

interface IProps {
  isAuthenticated: boolean;
  isOpen: boolean;
  onLogin: () => void;
  onClose: () => void;
  signupFormProps: ISignupFormProps;
}

const SignUpModal = ({
  isAuthenticated,
  isOpen,
  onLogin,
  onClose,
  signupFormProps,
}: IProps): JSX.Element => {
  const intl = useIntl();
  const [didAcceptTerms, setDidAcceptTerms] = useState(false);

  const messageSignUpHeader = intl.formatMessage(
    { defaultMessage: 'Join {company_name}', id: 'Qp0+MY' },
    { company_name: 'Outdoorsy' },
  );

  useEffect(() => {
    if (isAuthenticated && isOpen) {
      onClose();
    }
  }, [isAuthenticated, isOpen, onClose]);

  return (
    <Modal
      size={ModalSize.medium}
      open={isOpen}
      onClose={onClose}
      title={messageSignUpHeader}
      className="!z-[1401]">
      <AccountFormTemplate onLogin={onLogin} signup didAcceptTerms={didAcceptTerms}>
        <SignUpForm
          {...signupFormProps}
          onDidAcceptTerms={(termsState: boolean) => setDidAcceptTerms(termsState)}
        />
      </AccountFormTemplate>
    </Modal>
  );
};

export default SignUpModal;
