import querystring from 'query-string';
import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { IData } from '@/services/types/search/rentals/id';
import { getSearchApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

import { getCurrency } from '../selectors/currency';

const SET_SIMILAR_RENTALS = 'similarRentals/SET_SIMILAR_RENTALS';
const FETCH_SIMILAR_RENTALS_SUCCESS = 'similarRentals/FETCH_SIMILAR_RENTALS_SUCCESS';
const FETCH_SIMILAR_RENTALS_LOAD = 'similarRentals/FETCH_SIMILAR_RENTALS_LOAD';

interface ISetSimilarRentalsAction extends IAction {
  type: typeof SET_SIMILAR_RENTALS;
  payload: IData[];
}

interface IFetchSimilarRentalsSuccessAction extends IAction {
  type: typeof FETCH_SIMILAR_RENTALS_SUCCESS;
  payload: IData[];
}

interface IFetchSimilarRentalsLoadAction extends IAction {
  type: typeof FETCH_SIMILAR_RENTALS_LOAD;
}

type TAction =
  | ISetSimilarRentalsAction
  | IFetchSimilarRentalsSuccessAction
  | IFetchSimilarRentalsLoadAction;

const fetchSimilarRentalsSuccess = (payload: IData[]): IFetchSimilarRentalsSuccessAction => ({
  type: FETCH_SIMILAR_RENTALS_SUCCESS,
  payload,
});

const fetchSimilarRentalsLoad = (): IFetchSimilarRentalsLoadAction => ({
  type: FETCH_SIMILAR_RENTALS_LOAD,
});

export const getSimilarRentals =
  (
    rentalId: string,
    options: Partial<{ limit: number; from?: string; to?: string }>,
  ): ThunkAction<
    Promise<IFetchSimilarRentalsSuccessAction>,
    TRootState,
    void,
    IFetchSimilarRentalsSuccessAction | IFetchSimilarRentalsLoadAction
  > =>
  async (dispatch, getState) => {
    const currency = getCurrency(getState());
    const query: Record<string, any> = {
      currency,
      'page[limit]': options.limit?.toString(),
      'date[from]': options.from,
      'date[to]': options.to,
      raw_json: true,
      omit_aggregation: true,
    };

    dispatch(fetchSimilarRentalsLoad());
    const requestUrl = `${getSearchApi()}/rentals/${rentalId}/similar?${querystring.stringify(
      query,
    )}`;
    const response = await apiRequest<{ data: IData[] }>({ url: requestUrl });

    return dispatch(fetchSimilarRentalsSuccess(response?.data || []));
  };

interface IState {
  data: IData[];
  isFetched: boolean;
}

export const initialState: IState = {
  data: [],
  isFetched: false,
};

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case SET_SIMILAR_RENTALS:
      return {
        ...state,
        data: action.payload,
      };
    case FETCH_SIMILAR_RENTALS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetched: true,
      };
    case FETCH_SIMILAR_RENTALS_LOAD:
      return {
        ...state,
        isFetched: false,
      };
    default:
      return state;
  }
}
