import React from 'react';

import QuantitySelector, {
  IQuantitySelectorSize,
} from '@/components/switchback/QuantitySelector/QuantitySelector';
import Text from '@/components/switchback/Text/Text';

interface IGuestDetailItemProps {
  label: string;
  disclaimer: string;
  guestsCount: number;
  onChange: (value: number) => void;
}

const GuestDetailItem: React.FC<IGuestDetailItemProps> = ({
  label,
  disclaimer,
  guestsCount,
  onChange,
}) => {
  return (
    <div className="flex justify-between md:w-3/5">
      <div>
        <label className="text-base text-primary-base semiHighlight">{label}</label>
        <Text type="paragraph" className="text-sm text-secondary-base semiHighlight">
          {disclaimer}
        </Text>
      </div>
      <QuantitySelector
        id="guest-detail"
        label={`${label}. ${disclaimer}`}
        name="guest-detail"
        quantity={guestsCount}
        maxQuantity={10}
        size={IQuantitySelectorSize.normal}
        onChange={onChange}
      />
    </div>
  );
};

export default GuestDetailItem;
