import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ESearchFilters } from '@/constants/searchFilters';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { getIsAuthenticated } from '@/redux/selectors/auth/user';
import { saveRecentSearch, useRecentSearches } from '@/services/getRecentSearches';
import { ERentalCategory } from '@/services/types/search/rentals/id';
import { guardedJsonParse } from '@/utility/json';

// we limit recent searches based on usage. By default we limit to the most
// recent MAX_RECENT_SEARCHES but when using auto complete user may see older
// searches exposed
export const MAX_RECENT_SEARCHES = 5;
const MAX_STORED_SEARCHES = 50;

type TRecentSearchOption = {
  label: string;
  value: string;
  url: string;
  timestamp: number;
};

function isRecentSearchFilter(obj: unknown): obj is TRecentSearchOption {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  const { label, url, value } = obj as TRecentSearchOption;
  return typeof label === 'string' && typeof url === 'string' && typeof value === 'string';
}

function parsedObjectIsRecentSearchArray(obj: unknown): obj is TRecentSearchOption[] {
  return Array.isArray(obj) && obj.every(item => isRecentSearchFilter(item));
}

function formatSearchUrl(searchUrl: string): string {
  return `/rv-search?${searchUrl.split('?')[1]}`;
}

function filterRecentSearchByRentalType(
  type: ERentalCategory,
  searchOptions: TRecentSearchOption[],
) {
  // Filter recent search options based on rental type
  return searchOptions.filter(searchOption => {
    const queryParams = searchOption.url.split('?')?.[1];
    const searchParams = new URLSearchParams(queryParams);

    // If the rental type is RV and the rental category is not present,
    // or if the rental type matches the rental category, return true
    if (type === ERentalCategory.RV) {
      return (
        !searchParams.has(ESearchFilters.RENTAL_CATEGORY) ||
        searchParams.get(ESearchFilters.RENTAL_CATEGORY) === type
      );
    } else {
      // For other rental types, return true if the rental category matches the type
      return searchParams.get(ESearchFilters.RENTAL_CATEGORY) === type;
    }
  });
}

export function useRecentSearchesWithFilters(
  searchRentalType = ERentalCategory.RV,
): [TRecentSearchOption[], { addRecentSearch: (search: TRecentSearchOption) => void }] {
  const [recentSearches, { update }] = useLocalStorage('recentSearchesWithFilters');
  const [sessionSearches, { update: updateSessionSearches }] = useLocalStorage(
    'sessionRecentSearchesWithFilters',
  );
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { data: fetchedSearches, mutate: updateFetchedSearches } = useRecentSearches({
    isAuthenticated,
  });

  useEffect(() => {
    if (isAuthenticated && fetchedSearches) {
      const parsedFetchedSearches = fetchedSearches.map(search => ({
        label: search.label,
        value: search.value,
        url: formatSearchUrl(search.search_url),
        timestamp: search.timestamp,
      }));

      const localSearches =
        guardedJsonParse(parsedObjectIsRecentSearchArray, recentSearches ?? '') || [];
      const combinedSearches = [...parsedFetchedSearches, ...localSearches];

      const uniqueSearches = combinedSearches.filter(
        (search, index, self) => index === self.findIndex(s => s.value === search.value),
      );

      updateSessionSearches(JSON.stringify(uniqueSearches.slice(0, MAX_STORED_SEARCHES)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, fetchedSearches]);

  const recentSearchesParsed = useMemo(() => {
    const searches = isAuthenticated ? sessionSearches : recentSearches;
    return (searches && guardedJsonParse(parsedObjectIsRecentSearchArray, searches)) || [];
  }, [isAuthenticated, recentSearches, sessionSearches]);

  const filteredRecentSearches = useMemo(() => {
    return filterRecentSearchByRentalType(searchRentalType, recentSearchesParsed);
  }, [searchRentalType, recentSearchesParsed]);

  const addRecentSearch = useCallback(
    async (recentSearch: TRecentSearchOption) => {
      const updatedRecentSearches = recentSearchesParsed.filter(
        search => search.value !== recentSearch.value,
      );

      if (isAuthenticated) {
        try {
          const savedSearchResults = await saveRecentSearch(recentSearch.url);
          await updateFetchedSearches(savedSearchResults, { revalidate: false });
        } catch {
          return;
        }
      }

      update(
        JSON.stringify([recentSearch, ...updatedRecentSearches].slice(0, MAX_STORED_SEARCHES)),
      );
    },
    [isAuthenticated, recentSearchesParsed, update, updateFetchedSearches],
  );

  return useMemo(
    // Expose all recent searches and limit results when the user starts a search.
    // Auto complete may find older stored searches when filtering but by default we'll
    // show MAX_RECENT_SEARCHES.
    () => [filteredRecentSearches, { addRecentSearch }],
    [filteredRecentSearches, addRecentSearch],
  );
}
