import { useMemo } from 'react';

import { useRouter } from '@/hooks/useRouter';
import { getParams } from '@/utility/queryParams';

export default function useQueryParams(includeRouteParams?: boolean) {
  const router = useRouter();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => getParams(router, includeRouteParams), [router.asPath, includeRouteParams]);
}
