import React from 'react';
import { useIntl } from 'react-intl';

import { trackNavClickedEvent } from '@/services/analytics/header';

const GlobalHeaderNavTravelJournal: React.FC = () => {
  const intl = useIntl();

  const handleOnClick = (link: string) => {
    trackNavClickedEvent({
      clickedLink: link,
      isInDropdown: true,
      dropdownHeader: 'Resources & guides',
    });
  };

  const MenuSection = (props: {
    label: string;
    menuItems: Array<{ label: string; link: string }>;
    index: number;
    totalSections: number;
  }) => (
    <div
      className={`flex flex-col pb-4 mb-4 lg:mb-0 lg:pb-0 gap-y-4 lg:w-48 gap-x-4 ${
        props.index < props.totalSections - 1 ? 'lg:border-r lg:border-gray-310' : ''
      }`}>
      <span className="font-bold text-gray-800 autoType300">{props.label}</span>
      <ul role="menu" className="grid gap-y-4 gap-x-4 lg:gap-x-6">
        {props.menuItems.map(item => (
          <li key={item.label}>
            <a
              href={item.link}
              onClick={() => handleOnClick(item.label)}
              className="py-1 text-gray-800 autoType300 before-focus-style">
              <span aria-hidden>{item.label}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );

  const menuData = [
    {
      label: intl.formatMessage({ defaultMessage: 'Resources and guides', id: '5o+PKS' }),
      menuItems: [
        {
          label: intl.formatMessage({ defaultMessage: 'How-to guides', id: 'VuwzZ7' }),
          link: '/blog/category/how-to-guides',
        },
        {
          label: intl.formatMessage({ defaultMessage: '2024 Fall guide.', id: 'O0PV2U' }),
          link: '/guide/seasonal/fall',
        },
        {
          label: intl.formatMessage({ defaultMessage: 'RV lifestyle', id: '6Cp12g' }),
          link: '/blog/category/rv-lifestyle',
        },
        {
          label: intl.formatMessage({ defaultMessage: 'News and events', id: '++ayNL' }),
          link: '/blog/category/news-events',
        },
      ],
    },
    {
      label: intl.formatMessage({ defaultMessage: 'RV Owners', id: 'afvdaR' }),
      menuItems: [
        {
          label: intl.formatMessage({ defaultMessage: 'RV maintenance', id: 'HkrlCB' }),
          link: '/blog/category/rv-maintenance',
        },
        {
          label: intl.formatMessage({ defaultMessage: 'Remodeling and DIY', id: 'hjWT+I' }),
          link: '/blog/category/rv-remodeling-diy',
        },
        {
          label: intl.formatMessage({ defaultMessage: 'RV business tips', id: '5eQp51' }),
          link: '/blog/category/rv-business-tips',
        },
        {
          label: intl.formatMessage({ defaultMessage: 'Host stories', id: 'qkwgbL' }),
          link: '/blog/category/owner-stories',
        },
      ],
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Travel inspiration', id: '0MMMr8' }),
      menuItems: [
        {
          label: intl.formatMessage({ defaultMessage: 'RV beginner tips', id: 'e9GSUz' }),
          link: '/blog/category/rv-beginner-tips',
        },
        {
          label: intl.formatMessage({ defaultMessage: 'Trip planning', id: 'ZXgbGE' }),
          link: '/blog/category/trip-planning',
        },
        {
          label: intl.formatMessage({ defaultMessage: 'RV travel hacks', id: 'lMJcx3' }),
          link: '/blog/category/rv-travel-hacks',
        },
        {
          label: intl.formatMessage({ defaultMessage: 'RV parks and campgrounds', id: '62ClZl' }),
          link: '/blog/category/rv-parks-campgrounds',
        },
        {
          label: intl.formatMessage({ defaultMessage: 'Guest stories', id: 'Sz27hD' }),
          link: '/blog/category/outdoorsy-renters',
        },
      ],
    },
  ];

  return (
    <div className="flex flex-col w-full lg:flex-row gap-4" data-testid="travel-journal-dropdown">
      {menuData.map((section, index) => (
        <MenuSection
          key={index}
          label={section.label}
          menuItems={section.menuItems}
          index={index}
          totalSections={menuData.length}
        />
      ))}
    </div>
  );
};

export default GlobalHeaderNavTravelJournal;
