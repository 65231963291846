import { makeVar } from '@apollo/client';

export const localStorageCache = makeVar<Record<string, string | null>>({});

export function getItemFromLocalStorage(key: string): string | null {
  if (canUseLocalStorage()) {
    try {
      const localStorageValue = window.localStorage.getItem(key);
      const cache = localStorageCache();
      if (cache[key] !== localStorageValue) {
        localStorageCache({ ...localStorageCache(), [key]: localStorageValue });
      }

      return localStorageValue;
    } catch {
      return localStorageCache()[key] || null;
    }
  }

  return localStorageCache()[key] || null;
}

export function setItemInLocalStorage(key: string, value: string): void {
  try {
    window.localStorage.setItem(key, value);
  } catch {
    return;
  } finally {
    localStorageCache({ ...localStorageCache(), [key]: value });
  }
}

export function removeItemFromLocalStorage(key: string): void {
  try {
    window.localStorage.removeItem(key);
  } catch {
    return;
  } finally {
    localStorageCache({ ...localStorageCache(), [key]: null });
  }
}

export function canUseLocalStorage(): boolean {
  try {
    if (!window?.localStorage) {
      return false;
    }
    window.localStorage.setItem('test', 'test');
    window.localStorage.removeItem('test');
    return true;
  } catch {
    return false;
  }
}
