import { useLocaleSettings } from '@/hooks/useLocaleSettings';
import { trackLoggedOutEvent } from '@/services/analytics/accounts';
import { setOptimizelyUser } from '@/services/experiments';
import { resetSegmentUser } from '@/services/segment';
import { logger } from '@/utility/logger';
import { EVENT_AUTHENTICATE_NAME, EVENT_NAME_LOGOUT, resetSessionCookie } from '@/utility/session';

export function useUser() {
  const { locale } = useLocaleSettings();

  const logoutUser = async () => {
    if (typeof window === 'undefined') {
      return;
    }

    try {
      const event = new CustomEvent(EVENT_AUTHENTICATE_NAME, {
        cancelable: true,
        bubbles: true,
        detail: {
          name: EVENT_NAME_LOGOUT,
        },
      });

      // the user calling event.preventDefault will cause this dispatch to return false
      const header = document.getElementById('global-header');
      if (header) header.dispatchEvent(event);

      resetSessionCookie();
      trackLoggedOutEvent();

      // Run in anonymous async fn to not block callers awaiting on this function.
      (async () => {
        const anonId = await resetSegmentUser();
        if (anonId) {
          setOptimizelyUser(anonId, { locale });
        }
      })();
    } catch (exception) {
      logger.error(exception);
    }
  };

  return { logout: logoutUser };
}
