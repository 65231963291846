import omit from 'lodash/omit';
import pick from 'lodash/pick';
import type { ParsedUrlQueryInput } from 'querystring';

import { ROUTE_PARAMS as ListingRouteParams } from '@/constants/listing';
import router from '@/hooks/useRouter';
import { getParamAsString, getParams } from '@/utility/queryParams';

type TQueryValue = ParsedUrlQueryInput[string];

// TODO: add unit tests
export function parseRentalIdFromSlug(slug: string | string[]): string {
  const slugString = getParamAsString(slug) ?? '';
  const rentalId = /(\d+)(-listing)/gi.exec(slugString);

  if (!rentalId?.length) return '0';

  // @ts-expect-error fixable: unchecked index access
  return rentalId[1];
}

/*
 * Uses the given path creator function or the current pathname
 */
function getPathOrDefault(getPath?: (...args: TQueryValue[]) => string) {
  return (...args: TQueryValue[]) =>
    typeof getPath === 'function' ? getPath(...args) : router.asPath.split('?')[0];
}

interface IUrl {
  pathname: string;
  query: ParsedUrlQueryInput;
}

/*
 * Create a URL object without duplicated route params
 */
export function createUrlObj(
  queryParams: ParsedUrlQueryInput,
  routeParams: string[],
  getPath: (...routeParams: TQueryValue[]) => string,
): IUrl {
  const query = Object.keys(queryParams).reduce<ParsedUrlQueryInput>((acc, param) => {
    if (!routeParams.includes(param)) {
      acc[param] = queryParams[param];
    }
    return acc;
  }, {});
  const route = routeParams.map(param => queryParams[param]);
  const pathname = getPath(...route);

  return { pathname, query };
}

/*
 * Create a listing URL object without duplicated route params.
 * By default it uses the current pathname.
 */
export function createListingUrlObj(
  queryParams: ParsedUrlQueryInput,
  getPath?: (location: TQueryValue, slug: TQueryValue) => string,
): IUrl {
  // @ts-expect-error fixable: unchecked index access
  return createUrlObj(queryParams, ListingRouteParams, getPathOrDefault(getPath));
}

export function createOwnerUrlObj(
  queryParams: ParsedUrlQueryInput,
  getPath?: (slug: TQueryValue) => string,
): IUrl {
  // @ts-expect-error fixable: unchecked index access
  return createUrlObj(queryParams, ['slug'], getPathOrDefault(getPath));
}

export function updateUrl(
  action: 'replace' | 'push',
  urlObjBuilder: (queryParams: ParsedUrlQueryInput) => IUrl,
  {
    paramsToOmit,
    paramsToOverride,
    paramsToKeep,
    scroll,
  }: {
    paramsToOmit?: string[];
    paramsToOverride?: Record<string, string | number | undefined>;
    paramsToKeep?: string[];
    scroll?: boolean;
  },
) {
  const url = urlObjBuilder({
    ...omit(getParams(router), paramsToOmit || []),
    ...paramsToOverride,
  });

  return router[action](
    { ...url, query: paramsToKeep ? pick(url.query, paramsToKeep) : url.query },
    undefined,
    { shallow: true, scroll },
  );
}
