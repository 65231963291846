import { SAVED_RENTALS } from '@/constants/urls';
import apiRequest from '@/services/apiRequest';
import { getCoreApi } from '@/utility/getCoreApi';

import { IData } from './types/search/rentals/id';

export const savedRentalsRequest = async () => {
  const rentalsData = await apiRequest<IData[]>(
    {
      url: `${getCoreApi()}${SAVED_RENTALS}`,
    },
    true,
    true,
  );

  return rentalsData?.data;
};
