import * as OwnerActions from '@/redux/modules/owner/type';
import { IReview } from '@/services/types/core/reviews/id';
import { IData, IMeta } from '@/services/types/search/rentals/id';
import { IUserResponseAttributes } from '@/services/types/search/users/id';

interface IOwnerState {
  owner: {
    id: string | null;
    data: Partial<IUserResponseAttributes> | null;
    isFetching: boolean;
    error?: boolean;
  };
  rentals: {
    data: Array<IData>;
    meta: IMeta | null;
    isFetching: boolean;
    error?: boolean;
  };
  stays: {
    data: Array<IData>;
    meta: IMeta | null;
    isFetching: boolean;
    error?: boolean;
  };
  reviews: {
    data: Array<IReview>;
    isFetching: boolean;
    error?: boolean;
  };
}

export const initialState: IOwnerState = {
  owner: {
    id: null,
    data: null,
    isFetching: false,
    error: false,
  },
  rentals: {
    data: [],
    meta: null,
    isFetching: false,
    error: false,
  },
  stays: {
    data: [],
    meta: null,
    isFetching: false,
    error: false,
  },
  reviews: {
    data: [],
    isFetching: false,
    error: false,
  },
};

export default function reducer(
  state: IOwnerState = initialState,
  action:
    | OwnerActions.IOwnerAction
    | OwnerActions.IOwnerRentalsAction
    | OwnerActions.IOwnerReviewsAction,
): IOwnerState {
  switch (action.type) {
    case OwnerActions.FETCH_OWNER_PENDING:
      return {
        ...state,
        owner: {
          ...state.owner,
          isFetching: true,
          error: false,
        },
      };
    case OwnerActions.FETCH_OWNER_SUCCESS:
      return {
        ...state,
        owner: {
          id: action.payload.data.id,
          data: action.payload.data.attributes || null,
          isFetching: false,
          error: false,
        },
      };
    case OwnerActions.FETCH_OWNER_FAILURE:
      return {
        ...state,
        owner: {
          ...state.owner,
          isFetching: false,
          error: true,
        },
      };
    case OwnerActions.FETCH_OWNER_RENTALS_PENDING:
      return {
        ...state,
        rentals: {
          ...state.rentals,
          data: [],
          isFetching: true,
          error: false,
        },
      };
    case OwnerActions.FETCH_OWNER_RENTALS_SUCCESS:
      return {
        ...state,
        rentals: {
          data: action.payload.data || [],
          meta: action.payload.meta || null,
          isFetching: false,
          error: false,
        },
      };
    case OwnerActions.FETCH_OWNER_RENTALS_FAILURE:
      return {
        ...state,
        rentals: {
          ...state.rentals,
          isFetching: false,
          error: true,
        },
      };
    case OwnerActions.FETCH_OWNER_STAYS_PENDING:
      return {
        ...state,
        stays: {
          ...state.stays,
          data: [],
          isFetching: true,
          error: false,
        },
      };
    case OwnerActions.FETCH_OWNER_STAYS_SUCCESS:
      return {
        ...state,
        stays: {
          data: action.payload.data || [],
          meta: action.payload.meta || null,
          isFetching: false,
          error: false,
        },
      };
    case OwnerActions.FETCH_OWNER_STAYS_FAILURE:
      return {
        ...state,
        stays: {
          ...state.stays,
          isFetching: false,
          error: true,
        },
      };
    case OwnerActions.FETCH_OWNER_REVIEWS_PENDING:
      return {
        ...state,
        reviews: {
          data: [],
          isFetching: true,
          error: false,
        },
      };
    case OwnerActions.FETCH_OWNER_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: {
          data: action.payload || [],
          isFetching: false,
          error: false,
        },
      };
    case OwnerActions.FETCH_OWNER_REVIEWS_FAILURE:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          isFetching: false,
          error: true,
        },
      };
    case OwnerActions.FETCH_OWNER_RESET:
      return {
        ...state,
        rentals: {
          data: [],
          meta: null,
          isFetching: false,
          error: false,
        },
        reviews: {
          data: [],
          isFetching: false,
          error: false,
        },
      };
    default:
      return state;
  }
}
