import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { INearbyCampground } from '@/services/types/nearbyCampgrounds/nearbyCampgrounds';
import { getSearchApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

const SET_NEARBY_CAMPGROUNDS = 'NearbyCampgrounds/SET_NEARBY_CAMPGROUNDS';
const FETCH_NEARBY_CAMPGROUNDS_SUCCESS = 'NearbyCampgrounds/FETCH_NEARBY_CAMPGROUNDS_SUCCESS';
const FETCH_NEARBY_CAMPGROUNDS_LOAD = 'NearbyCampgrounds/FETCH_NEARBY_CAMPGROUNDS_LOAD';

interface ISetNearbyCampgroundsAction extends IAction {
  type: typeof SET_NEARBY_CAMPGROUNDS;
  payload: INearbyCampground[];
}

interface IFetchNearbyCampgroundsSuccessAction extends IAction {
  type: typeof FETCH_NEARBY_CAMPGROUNDS_SUCCESS;
  payload: INearbyCampground[];
}

interface IFetchNearbyCampgroundsLoadAction extends IAction {
  type: typeof FETCH_NEARBY_CAMPGROUNDS_LOAD;
}

type TAction =
  | ISetNearbyCampgroundsAction
  | IFetchNearbyCampgroundsSuccessAction
  | IFetchNearbyCampgroundsLoadAction;

const fetchNearbyCampgroundsSuccess = (
  payload: INearbyCampground[],
): IFetchNearbyCampgroundsSuccessAction => ({
  type: FETCH_NEARBY_CAMPGROUNDS_SUCCESS,
  payload,
});

const fetchNearbyCampgroundsLoad = (): IFetchNearbyCampgroundsLoadAction => ({
  type: FETCH_NEARBY_CAMPGROUNDS_LOAD,
});

export const getNearbyCampgrounds =
  (
    rentalId: string,
  ): ThunkAction<
    Promise<IFetchNearbyCampgroundsSuccessAction>,
    TRootState,
    void,
    IFetchNearbyCampgroundsSuccessAction | IFetchNearbyCampgroundsLoadAction
  > =>
  async dispatch => {
    dispatch(fetchNearbyCampgroundsLoad());
    const requestUrl = `${getSearchApi()}/external-campgrounds/nearby-for-rental/${rentalId}`;
    let response: INearbyCampground[] = [];
    try {
      response =
        (await apiRequest<INearbyCampground[] | undefined | null>({ url: requestUrl })) || [];
      return dispatch(fetchNearbyCampgroundsSuccess(response));
    } catch {
      // fine for now
      return dispatch(fetchNearbyCampgroundsSuccess(response));
    }
  };

interface IState {
  data: INearbyCampground[];
  isFetched: boolean;
}

export const initialState: IState = {
  data: [],
  isFetched: false,
};

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case SET_NEARBY_CAMPGROUNDS:
      return {
        ...state,
        data: action.payload,
      };
    case FETCH_NEARBY_CAMPGROUNDS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetched: true,
      };
    case FETCH_NEARBY_CAMPGROUNDS_LOAD:
      return {
        ...state,
        isFetched: false,
      };
    default:
      return state;
  }
}
