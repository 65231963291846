import React from 'react';

interface IFlexibleDateSelectButtonProps {
  onClick: () => void;
  selected?: boolean;
  label: string;
  className?: string;
}

const FlexibleDateSelectButton: React.FC<
  React.PropsWithChildren<IFlexibleDateSelectButtonProps>
> = ({ onClick, selected, label, className }) => {
  return (
    <button
      type="button"
      className={`text-sm semiHighlight border-gray-900 ${className} ${
        selected ? 'border-b text-gray-900' : 'text-gray-500'
      }`}
      onClick={onClick}>
      {label}
    </button>
  );
};

export default FlexibleDateSelectButton;
