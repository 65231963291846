import moonbeams from 'moonbeams';

const getSeason = (date: Date, northernHemisphere = true) => {
  const currentDate = date || new Date();

  // Calculate given date in Julian days
  const julianDayFraction: number = moonbeams.hmsToDay(
    currentDate.getHours(),
    currentDate.getMinutes(),
    currentDate.getSeconds(),
  );

  const jd: number = moonbeams.calendarToJd(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDay() + julianDayFraction,
  );

  // TODO: Could LRU cache Julian equinoxes for each year
  // Calculate Julian days of equinoxes for given year
  let isMissingEq = false;
  const equinoxes = [0, 1, 2, 3].map(i => {
    const season = moonbeams.season(i, currentDate.getFullYear());
    if (!season) isMissingEq = true;
    return season;
  });

  if (isMissingEq) return;

  if (jd >= equinoxes[0] && jd < equinoxes[1]) {
    return northernHemisphere ? 'spring' : 'fall';
  }
  if (jd >= equinoxes[1] && jd < equinoxes[2]) {
    return northernHemisphere ? 'summer' : 'winter';
  }
  if (jd >= equinoxes[2] && jd < equinoxes[3]) {
    return northernHemisphere ? 'fall' : 'spring';
  }
  if (jd >= equinoxes[3] || jd < equinoxes[0]) {
    return northernHemisphere ? 'winter' : 'summer';
  }
};

export default getSeason;
