import { defaultLocale } from '@/config/locales';
import { IMenuItem, LOCALE_DESTINATIONS } from '@/constants/menu';

import { useLocaleSettings } from './useLocaleSettings';

export function useLocaleDestinations(): IMenuItem[] {
  const { locale } = useLocaleSettings();

  return LOCALE_DESTINATIONS[locale] || LOCALE_DESTINATIONS[defaultLocale.locale] || [];
}
