import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { getIntl } from '@/utility/i18n';

dayjs.extend(localizedFormat);
const intl = getIntl();

const formatDate = (date: dayjs.ConfigType, format = 'll') => {
  return dayjs(date).format(format);
};

export const formatDateRange = (from?: string, to?: string, short?: boolean, year?: boolean) => {
  if (!from && !to) {
    return null;
  }

  // If we have one date, but not the other e.g. "Sep 24"
  if ((from && !to) || (to && !from)) {
    const date = from || to;
    if (!date) {
      return null;
    }

    return intl.formatDate(date, {
      month: 'short',
      day: 'numeric',
      year: year ? 'numeric' : undefined,
    });
  }

  if (!from || !to) {
    return null;
  }

  if (year && dayjs(from).year() !== dayjs(to).year()) {
    // If years are different e.g. "Dec 24, 2024 - Jan 5, 2025"
    if (dayjs(from).month() === dayjs(to).month() && !short) {
      return `${intl.formatDate(from, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })} - ${intl.formatDate(to, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })}`;
    }

    // If years are different e.g. "9/24/2024 - 10/3/2025"
    return `${intl.formatDate(from, {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    })} - ${intl.formatDate(to, {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    })}`;
  }

  // If both dates are the same month e.g. "Sep 24-29"
  // If contains year, e.g. "Sep 24-29, 2024"
  if (dayjs(from).month() === dayjs(to).month() && !short) {
    return `${intl.formatDate(from, {
      month: 'short',
      day: 'numeric',
    })}-${intl.formatDate(to, {
      day: 'numeric',
    })}${
      year
        ? `, ${intl.formatDate(to, {
            year: 'numeric',
          })}`
        : ''
    }`;
  }

  // If months are different e.g. "9/24-10/3"
  // If contains year, e.g. "9/24-10/3, 2024"
  return `${intl.formatDate(from, {
    month: 'numeric',
    day: 'numeric',
  })}-${intl.formatDate(to, {
    month: 'numeric',
    day: 'numeric',
  })}${
    year
      ? `, ${intl.formatDate(to, {
          year: 'numeric',
        })}`
      : ''
  }`;
};

export const formatDateFullRange = (from?: string, to?: string): string | undefined => {
  if (!from || !to || !from.trim() || !to.trim()) {
    return;
  }
  const rawFromDate = new Date(from);
  const rawToDate = new Date(to);

  if (rawFromDate > rawToDate) {
    return formatDateFullRange(to, from);
  }
  const fromYear = rawFromDate.getFullYear();
  const toYear = rawToDate.getFullYear();

  const fromMonth = rawFromDate.getMonth() + 1;
  const toMonth = rawToDate.getMonth() + 1;

  //Dates occur in different years: Dec 24, 2024 - Jan 5, 2025
  if (fromYear !== toYear) {
    const formatFromDate = dayjs(from).format('MMM D, YYYY');
    const formatToDate = dayjs(to).format('MMM D, YYYY');
    return `${formatFromDate} - ${formatToDate}`;
  }

  //Dates occur in different months but the same year: Feb 29 - Mar 9
  else if (fromYear === toYear && fromMonth !== toMonth) {
    const formatFromDate = dayjs(from).format('MMM D');
    const formatToDate = dayjs(to).format('MMM D');
    return `${formatFromDate} - ${formatToDate}`;
  }

  //Dates occur in the same month and year: Jan 1 - 13
  else {
    const formatFromDate = dayjs(from).format('MMM D');
    const formatToDate = dayjs(to).format('D');
    return `${formatFromDate}-${formatToDate}`;
  }
};

export default formatDate;
