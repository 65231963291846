import { isSSR } from '@/utility/isSSR';
import { getParamAsString } from '@/utility/queryParams';

const ITEMS_PER_PAGE = 24;
const MAX_ITEMS_PER_PAGE = 150;
// Takes pageLimit query param, if any, and determines the number of items to render.
export const getItemsPerPage = (pageLimit?: string | string[]): number => {
  if (isSSR()) return ITEMS_PER_PAGE;
  // We cap client queries at 150 as this is a requirement of odc-search
  // Not introducing this cap leads to UI issues with pagination tabs
  // See https://github.com/outdoorsy/odc-search/blob/9ec4d577d65e592ad7eb9f461192ed977e57864a/types/request/rentalquery.go#L862
  if (pageLimit)
    return Math.min(MAX_ITEMS_PER_PAGE, parseInt(getParamAsString(pageLimit) || '', 10));
  return ITEMS_PER_PAGE;
};
