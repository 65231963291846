import odcMetrics from '@outdoorsyco/odc-metrics';

const appId = 'content-app';

export const setupOdcMetrics = (enabled, debug) => {
  if (enabled) {
    window.Outdoorsy = window.Outdoorsy || {};
    window.Outdoorsy.appId = appId;
    window.Outdoorsy.debug = debug;
    window.Outdoorsy.metrics = odcMetrics;
  }
};
