import * as React from 'react';

import ModalAnimation from './ModalAnimation';

interface IBackdrop {
  children?: React.ReactNode;
  open: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  transitionDuration?:
    | number
    | Partial<{
        appear: number;
        enter: number;
        exit: number;
      }>;
}

const Backdrop: React.FC<IBackdrop> = ({
  children,
  open,
  transitionDuration,
  onClick,
  ...other
}) => (
  <ModalAnimation in={open} timeout={transitionDuration} {...other}>
    <div
      data-mui-test="Backdrop"
      data-testid="modal-backdrop"
      aria-hidden
      onClick={onClick}
      className="fixed inset-0 flex items-center justify-center"
      style={{
        zIndex: -1,
        backgroundColor: 'hsla(200, 5%, 12%, 0.85)',
        WebkitTapHighlightColor: 'transparent',
      }}>
      {children}
    </div>
  </ModalAnimation>
);

export default Backdrop;
