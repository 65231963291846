import { EBookingsFilters } from '@/constants/booking';
import { IAction } from '@/utility/redux/action';

import { IBookingsResponse } from './actions';

export const FETCH_BOOKINGS_PENDING = 'booking/FETCH_BOOKINGS_PENDING';
export const FETCH_BOOKINGS_FAILURE = 'booking/FETCH_BOOKINGS_FAILURE';
export const FETCH_BOOKINGS_SUCCESS = 'booking/FETCH_BOOKINGS_SUCCESS';

interface IBookingsPendingAction extends IAction {
  type: typeof FETCH_BOOKINGS_PENDING;
  payload: { filter: EBookingsFilters };
}

interface IBookingsSuccessAction extends IAction {
  type: typeof FETCH_BOOKINGS_SUCCESS;
  payload: IBookingsResponse;
}

interface IBookingsFailureAction extends IAction {
  type: typeof FETCH_BOOKINGS_FAILURE;
  error: boolean;
  payload: { filter: EBookingsFilters };
}

export type IBookingsAction =
  | IBookingsPendingAction
  | IBookingsSuccessAction
  | IBookingsFailureAction;
