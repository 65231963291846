import Link from 'next/link';
import React from 'react';

import Loading from '../Loading/Loading';
import css from './Button.module.css';

export enum ButtonSize {
  xxsmall = 'xxsmall',
  xsmall = 'xsmall',
  small = 'small',
  normal = 'normal',
  medium = 'medium',
  large = 'large',
}

export enum ButtonShape {
  circle = 'circle',
  rectangle = 'rectangle',
  square = 'square',
  pill = 'pill',
  wide = 'wide',
}

export enum ButtonState {
  disabled = 'disabled',
}

export enum ButtonVariants {
  primaryContained = 'primary-contained',
  primaryOutlined = 'primary-outlined',
  lightPrimaryContained = 'light-primary-contained',
  lightPrimaryOutlined = 'light-primary-outlined',
  darkPrimaryContained = 'dark-primary-contained',
  grayOutlined = 'gray-outlined',
  darkGrayOutlined = 'dark-gray-outlined',
  whiteContained = 'white-contained',
  whiteOutlined = 'white-outlined',
  redContained = 'red-contained',
  redOutlined = 'red-outlined',
  redText = 'red-text',
  jungleContained = 'jungle-contained',
  jungleOutlined = 'jungle-outlined',
  borderless = 'borderless',
}

type TButtonElement = React.ButtonHTMLAttributes<HTMLButtonElement>;
type TAnchorElement = React.HTMLAttributes<HTMLAnchorElement>;

export type TButtonProps = (TButtonElement | TAnchorElement) & {
  href?: string;
  prefetch?: boolean;
  shallow?: boolean;
  size?: ButtonSize;
  className?: string;
  position?: string;
  disabled?: boolean;
  shape?: ButtonShape;
  variant?: ButtonVariants;
  state?: ButtonState;
  target?: '_blank' | '_self';
  testid?: string;
  loading?: boolean;
  omitUnderline?: boolean;
  forceClientsideRouting?: boolean;
};

const Button: React.FC<TButtonProps> = ({
  className = '',
  children,
  href,
  shallow,
  target,
  size = ButtonSize.normal,
  variant = ButtonVariants.primaryContained,
  shape = ButtonShape.pill,
  position = 'relative',
  state,
  disabled = false,
  loading,
  testid = 'button',
  forceClientsideRouting = false,
  ...props
}) => {
  const doClientsideRouting = forceClientsideRouting;
  // prev external had no default value
  //!external && href && (href.startsWith('/') || href.startsWith('#'));

  const content = () => {
    return (
      <>
        {children}
        {loading && (
          <Loading
            data-testid="loading"
            className={`${css.loading} absolute inset-0 flex justify-center items-center`}
            iconFill={variant === ButtonVariants.darkPrimaryContained ? 'white' : undefined}
          />
        )}
      </>
    );
  };

  if (!href || disabled) {
    const buttonProps = props as TButtonElement;
    return (
      <button
        data-testid={testid}
        data-size={size}
        data-shape={shape}
        data-variant={variant}
        data-loading={loading}
        disabled={disabled}
        data-state={state}
        className={`${css.button} before-focus-style rounded ${position} highlight transition-all duration-500 ${className}`}
        {...buttonProps}>
        <span className={css.content}>{content()}</span>
      </button>
    );
  }

  const anchorProps = props as TAnchorElement;

  if (doClientsideRouting) {
    return (
      <Link href={href} shallow={shallow} scroll={true} passHref legacyBehavior>
        <a
          data-testid={testid}
          data-size={size}
          data-shape={shape}
          data-variant={variant}
          data-loading={loading}
          data-state={state}
          target={target ? target : '_self'}
          rel={target === '_blank' ? 'noopener' : ''}
          className={`${css.button} flex items-center before-focus-style rounded relative text-center highlight transition-all duration-500 ${className}`}
          {...anchorProps}>
          <span className={css.content}>{content()}</span>
        </a>
      </Link>
    );
  }

  return (
    <a
      data-testid={testid}
      className={`${css.button} flex items-center before-focus-style rounded relative text-center highlight transition-all duration-500 ${className}`}
      data-size={size}
      data-shape={shape}
      data-variant={variant}
      data-loading={loading}
      href={href}
      target={target}
      rel={target === '_blank' ? 'noopener' : ''}
      {...anchorProps}>
      <span className={css.content}>{content()}</span>
    </a>
  );
};

export default Button;
