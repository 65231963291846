import { datadogRum } from '@datadog/browser-rum-slim';
import { AxiosError } from 'axios';

const isAxiosError = (error: any): error is AxiosError => {
  return !!error?.isAxiosError;
};

export default function setupDatadogRum() {
  if (!process.env.ddRumEnabled) return;

  datadogRum.init({
    applicationId: `${process.env.ddRumApplicationId}`,
    clientToken: `${process.env.ddRumClientToken}`,
    service: process.env.ddClientService,
    site: 'datadoghq.com',
    env: process.env.deployTarget,
    version: process.env.releaseVersion,
    sessionSampleRate: 80,
    sessionReplaySampleRate: 1,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    beforeSend: (event, context) => {
      // remove email from view url
      event.view.url = event.view.url.replace(/email=[^&]*/, 'email=REDACTED');

      // @ts-expect-error missing type
      if (event.error && context.error) {
        // @ts-expect-error missing type
        const error = context.error;

        if (error && error instanceof Error) {
          // Safari will throw this error when the user cancels a window.navigator.share
          if (error.name === 'AbortError') {
            return false;
          }
          // Their browser is blocking media from playing.
          if (error.name === 'NotAllowedError') {
            return false;
          }
          // Manually handle security errors we wanna ignore.
          if (error.name === 'SecurityError') {
            if (/^the operation is insecure/i.test(error.message)) {
              return false;
            }
            if (/^blocked a frame with origin/i.test(error.message)) {
              return false;
            }
            if (/^permission denied to access property/i.test(error.message)) {
              return false;
            }
          }
        }

        if (isAxiosError(error)) {
          const url = error.config.url;
          const status = error.response?.status;
          if (url && status) {
            if (status === 400 && url.indexOf('/v0/quotes') > -1) {
              return false;
            }
            if (status === 401 && url?.indexOf('/creds/login') > -1) {
              return false;
            }
          }
        }
      } else if (event.error) {
        // @ts-expect-error missing type
        if (typeof event.error.message == 'string') {
          // @ts-expect-error missing type
          if (/optimizely/i.test(event.error.message)) {
            return false;
          }
        }
      }
      return true;
    },
  });
}
