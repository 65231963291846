import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import OptionsInput from '@/components/switchback/OptionsInput/OptionsInput';
import Text from '@/components/switchback/Text/Text';
import {
  GET_VEHICLE_INFO_RESPONSE,
  GET_VEHICLES_MAKE_RESPONSE,
  GET_VEHICLES_MODEL_RESPONSE,
  getVehiclesMake,
  getVehiclesModel,
  getVehiclesYear,
} from '@/redux/modules/search';
import {
  getMakeVehicles,
  getModelVehicles,
  getYearVehicles,
} from '@/redux/selectors/search/vehicle';
import { IVehicle } from '@/services/types/search/vehicle';

import { INavbarSearch } from '../../../SearchHeader';
import classes from '../DesktopUniversalSearch.module.css';

interface VehicleContentProps {
  className?: string;
  open?: boolean;
  onChangeVehicle?: INavbarSearch['onChangeVehicle'];
  vehicle?: IVehicle;
  onConfirm?: () => void;
}

interface IVehicleState {
  year?: number;
  make?: string;
  id?: number;
}

const defaultVehicle: IVehicleState = {
  year: undefined,
  make: undefined,
  id: undefined,
};

const VehicleContent: React.FC<VehicleContentProps> = ({ open, onChangeVehicle, onConfirm }) => {
  const intl = useIntl();
  const [vehicle, setVehicle] = useState<IVehicleState>(defaultVehicle);
  const dispatch = useDispatch();

  const yearVehicles = useSelector(getYearVehicles);
  const makeVehicles = useSelector(getMakeVehicles);
  const modelVehicles = useSelector(getModelVehicles);

  const yearOptions = yearVehicles.map(v => ({
    label: v.year.toString(),
    value: v.year.toString(),
  }));
  const makeOptions = makeVehicles.map(v => ({
    label: v.make,
    value: v.make,
  }));
  const modelOptions = modelVehicles.map(v => ({
    label: v.model,
    value: v.id.toString(),
  }));

  const handleChange = (value: string, key: string) => {
    if (key === 'year' && value === '') {
      dispatch({
        type: GET_VEHICLES_MODEL_RESPONSE,
        payload: [],
      });
      dispatch({
        type: GET_VEHICLES_MAKE_RESPONSE,
        payload: [],
      });
      return;
    }
    if (key === 'model') {
      if (value === '') {
        dispatch({
          type: GET_VEHICLES_MAKE_RESPONSE,
          payload: [],
        });
        return;
      }
      const selectedModelVehicle = modelVehicles.find(v => v.id === Number(value));
      setVehicle({
        year: selectedModelVehicle?.year,
        make: selectedModelVehicle?.make,
        id: selectedModelVehicle?.id,
      });
      dispatch({
        type: GET_VEHICLE_INFO_RESPONSE,
        payload: selectedModelVehicle,
      });
      onChangeVehicle?.(selectedModelVehicle);
    } else {
      setVehicle(prevValue => ({ ...prevValue, [key]: value }));
    }
  };

  useEffect(() => {
    dispatch(getVehiclesYear());
  }, [dispatch]);

  useEffect(() => {
    if (vehicle?.year) {
      dispatch(getVehiclesMake(vehicle.year));
    }
  }, [dispatch, vehicle?.year]);

  useEffect(() => {
    if (vehicle?.year && vehicle?.make) {
      dispatch(getVehiclesModel(vehicle.year, vehicle.make));
    }
  }, [dispatch, vehicle?.year, vehicle?.make]);

  return (
    <div className={`${open ? 'block' : 'hidden'} ${classes.homeDateWrapper}`}>
      <Text
        type="paragraph"
        className="mb-8 text-base font-regular md:mb-5 semiHighlight text-gray-850">
        <FormattedMessage
          defaultMessage="Tell us about your towing vehicle to see what you can tow."
          id="XgirlV"
        />
      </Text>

      <div className="grid gap-4 md:grid-cols-3">
        <OptionsInput
          name="year"
          placeholder={intl.formatMessage({ defaultMessage: 'Year', id: 'IFo1oo' })}
          value={vehicle?.year}
          options={yearOptions}
          selectedOption={yearOptions.find(option => option.value === vehicle?.year?.toString())}
          onChange={e => handleChange(e.target.value, 'year')}
        />
        <OptionsInput
          name="make"
          placeholder={intl.formatMessage({ defaultMessage: 'Make', id: '6AAM0P' })}
          value={vehicle?.make}
          disabled={makeOptions.length === 0}
          options={makeOptions}
          selectedOption={makeOptions.find(option => option.value === vehicle?.make)}
          onChange={e => handleChange(e.target.value, 'make')}
        />
        <OptionsInput
          name="model"
          placeholder={intl.formatMessage({ defaultMessage: 'Model', id: 'rhSI1/' })}
          value={vehicle?.id}
          disabled={modelOptions.length === 0}
          options={modelOptions}
          selectedOption={modelOptions.find(option => option.value === vehicle?.id?.toString())}
          onChange={e => handleChange(e.target.value, 'model')}
        />
      </div>
      <Button
        className="mt-6 md:!hidden"
        variant={EButtonColorVariant.Primary}
        label={intl.formatMessage({ defaultMessage: 'Confirm', id: 'N2IrpM' })}
        fullWidth
        onClick={onConfirm}
      />
    </div>
  );
};

export default React.memo(VehicleContent);
