import { IReview } from '@/services/types/core/reviews/id';
import { IAction } from '@/utility/redux/action';

export const FETCH_REVIEWS_PENDING = 'review/FETCH_REVIEWS_PENDING';
export const FETCH_REVIEWS_SUCCESS = 'review/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_FAILURE = 'review/FETCH_REVIEWS_FAILURE';

interface IReviewPendingAction extends IAction {
  type: typeof FETCH_REVIEWS_PENDING;
}

interface IReviewSuccessAction extends IAction {
  type: typeof FETCH_REVIEWS_SUCCESS;
  payload: IReview[];
}

interface IReviewFailureAction extends IAction {
  type: typeof FETCH_REVIEWS_FAILURE;
  error: boolean;
}

export const SUBMIT_REVIEW_PENDING = 'review/SUBMIT_REVIEW_PENDING';
export const SUBMIT_REVIEW_SUCCESS = 'review/SUBMIT_REVIEW_SUCCESS';
export const SUBMIT_REVIEW_FAILURE = 'review/SUBMIT_REVIEW_FAILURE';

interface ISubmitPendingAction extends IAction {
  type: typeof SUBMIT_REVIEW_PENDING;
}

interface ISubmitSuccessAction extends IAction {
  type: typeof SUBMIT_REVIEW_SUCCESS;
  payload: IReview;
}

interface ISubmitFailureAction extends IAction {
  type: typeof SUBMIT_REVIEW_FAILURE;
  error: boolean;
}

export const FETCH_REVIEW_PENDING = 'review/FETCH_REVIEW_PENDING';
export const FETCH_REVIEW_SUCCESS = 'review/FETCH_REVIEW_SUCCESS';
export const FETCH_REVIEW_FAILURE = 'review/FETCH_REVIEW_FAILURE';

interface IFetchReviewPending extends IAction {
  type: typeof FETCH_REVIEW_PENDING;
}

interface IFetchReviewSuccess extends IAction {
  type: typeof FETCH_REVIEW_SUCCESS;
  payload: IReview;
}

interface IFetchReviewFailure extends IAction {
  type: typeof FETCH_REVIEW_FAILURE;
  error: boolean;
}

export const UPDATE_REVIEW_PENDING = 'review/UPDATE_REVIEW_PENDING';
export const UPDATE_REVIEW_SUCCESS = 'review/UPDATE_REVIEW_SUCCESS';
export const UPDATE_REVIEW_FAILURE = 'review/UPDATE_REVIEW_FAILURE';

interface IUpdateReviewPendingAction extends IAction {
  type: typeof UPDATE_REVIEW_PENDING;
}

interface IUpdateReviewSuccessAction extends IAction {
  type: typeof UPDATE_REVIEW_SUCCESS;
  payload: IReview;
}

interface IUpdateReviewFailureAction extends IAction {
  type: typeof UPDATE_REVIEW_FAILURE;
  error: boolean;
}

export const RESET_REVIEWS_DATA = 'review/RESET_REVIEWS_DATA';
interface IResetReviewsDataAction extends IAction {
  type: typeof RESET_REVIEWS_DATA;
}

export type IReviewAction =
  | IReviewPendingAction
  | IReviewSuccessAction
  | IReviewFailureAction
  | ISubmitPendingAction
  | ISubmitSuccessAction
  | ISubmitFailureAction
  | IFetchReviewPending
  | IFetchReviewSuccess
  | IFetchReviewFailure
  | IUpdateReviewPendingAction
  | IUpdateReviewSuccessAction
  | IUpdateReviewFailureAction
  | IResetReviewsDataAction;
