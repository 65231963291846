const filterEmptyValues = (values: Record<string, unknown>) => {
  const filteredValues: Record<string, unknown> = {};
  Object.keys(values).forEach(key => {
    const value = values[key];
    if (!(value === '' || value == null || value === 0)) {
      filteredValues[key] = value;
    }
  });
  return filteredValues;
};

export default filterEmptyValues;
