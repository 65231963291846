declare global {
  interface Window {
    openChat?: unknown;
  }
}

export const triggerChat = () => {
  if (typeof window.openChat === 'function') {
    window.openChat();
  }
};
