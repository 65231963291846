import { createSelector } from 'reselect';

import { ESearchFilters } from '@/constants/searchFilters';
import { ISearchFormFilters, TState as ISearchFormState } from '@/redux/modules/searchForm';
import { TRootState } from '@/redux/rootReducer';

type TSearchFormType = Partial<ISearchFormFilters>[ESearchFilters.FILTER_TYPE];
type TSearchFormMake = Partial<ISearchFormFilters>[ESearchFilters.FILTER_VEHICLE_MAKE];
type TSearchFormMakeModels = Partial<ISearchFormFilters>[ESearchFilters.FILTER_VEHICLE_MAKE_MODELS];
type TSearchFormMinMakeYear = Partial<ISearchFormFilters>[ESearchFilters.YEAR_GREATER];
type TSearchFormMaxMakeYear = Partial<ISearchFormFilters>[ESearchFilters.YEAR_LESSER];
type TSearchFormAmenities = Partial<ISearchFormFilters>[ESearchFilters.FILTER_FEATURE];
interface ISearchFormPrice {
  [ESearchFilters.PRICE_MIN]: Partial<ISearchFormFilters>[ESearchFilters.PRICE_MIN];
  [ESearchFilters.PRICE_MAX]: Partial<ISearchFormFilters>[ESearchFilters.PRICE_MAX];
}
interface ISearchFormAccommodations {
  [ESearchFilters.SEATBELTS]: Partial<ISearchFormFilters>[ESearchFilters.SEATBELTS];
  [ESearchFilters.SLEEPS]: Partial<ISearchFormFilters>[ESearchFilters.SLEEPS];
  [ESearchFilters.BATHROOMS]: Partial<ISearchFormFilters>[ESearchFilters.BATHROOMS];
  [ESearchFilters.BEDROOMS]: Partial<ISearchFormFilters>[ESearchFilters.BEDROOMS];
  [ESearchFilters.BEDS]: Partial<ISearchFormFilters>[ESearchFilters.BEDS];
  [ESearchFilters.CAMPSITE_BATHROOMS]: Partial<ISearchFormFilters>[ESearchFilters.CAMPSITE_BATHROOMS];
  [ESearchFilters.CAMPSITE_BEDROOMS]: Partial<ISearchFormFilters>[ESearchFilters.CAMPSITE_BEDROOMS];
  [ESearchFilters.CAMPSITE_BEDS]: Partial<ISearchFormFilters>[ESearchFilters.CAMPSITE_BEDS];
}

interface ISearchDelivery {
  [ESearchFilters.DELIVERY]: Partial<ISearchFormFilters>[ESearchFilters.DELIVERY];
  [ESearchFilters.DELIVERY_ADDRESS]: Partial<ISearchFormFilters>[ESearchFilters.DELIVERY_ADDRESS];
  [ESearchFilters.DELIVERY_RADIUS]: Partial<ISearchFormFilters>[ESearchFilters.DELIVERY_RADIUS];
  [ESearchFilters.DELIVERY_STATIONARY]: Partial<ISearchFormFilters>[ESearchFilters.DELIVERY_STATIONARY];
  [ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID]: Partial<ISearchFormFilters>[ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID];
}

type TSearchFormInstantBook = Partial<ISearchFormFilters>[ESearchFilters.INSTANT_BOOK];

export const getSearchFormTypeFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormType
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.FILTER_TYPE],
);

export const getSearchFormMakeFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormMake
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.FILTER_VEHICLE_MAKE],
);

export const getSearchFormMakeModelsFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormMakeModels
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.FILTER_VEHICLE_MAKE_MODELS],
);

export const getSearchFormMinMakeYear = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormMinMakeYear
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.YEAR_GREATER],
);

export const getSearchFormMaxMakeYear = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormMaxMakeYear
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.YEAR_LESSER],
);

export const getSearchFormPriceFilter = createSelector<
  TRootState,
  ISearchFormState,
  ISearchFormPrice
>(
  state => state.searchForm,
  searchForm => ({
    [ESearchFilters.PRICE_MIN]: searchForm.filters[ESearchFilters.PRICE_MIN],
    [ESearchFilters.PRICE_MAX]: searchForm.filters[ESearchFilters.PRICE_MAX],
  }),
);

export const getSearchFormAccommodationsFilter = createSelector<
  TRootState,
  ISearchFormState,
  ISearchFormAccommodations
>(
  state => state.searchForm,
  searchForm => ({
    [ESearchFilters.SEATBELTS]: searchForm.filters[ESearchFilters.SEATBELTS],
    [ESearchFilters.SLEEPS]: searchForm.filters[ESearchFilters.SLEEPS],
    [ESearchFilters.BATHROOMS]: searchForm.filters[ESearchFilters.BATHROOMS],
    [ESearchFilters.BEDROOMS]: searchForm.filters[ESearchFilters.BEDROOMS],
    [ESearchFilters.BEDS]: searchForm.filters[ESearchFilters.BEDS],
    [ESearchFilters.CAMPSITE_BATHROOMS]: searchForm.filters[ESearchFilters.CAMPSITE_BATHROOMS],
    [ESearchFilters.CAMPSITE_BEDROOMS]: searchForm.filters[ESearchFilters.CAMPSITE_BEDROOMS],
    [ESearchFilters.CAMPSITE_BEDS]: searchForm.filters[ESearchFilters.CAMPSITE_BEDS],
  }),
);

export const getSearchFormAmenitiesFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormAmenities
>(
  state => state.searchForm,
  searchForm => {
    return searchForm.filters[ESearchFilters.FILTER_FEATURE];
  },
);

export const getSearchFormCampgroundsAmenitiesFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormAmenities
>(
  state => state.searchForm,
  searchForm => {
    return searchForm.filters[ESearchFilters.CAMPGROUND_FEATURES];
  },
);

export const getSearchFormDeliveryFilter = createSelector<
  TRootState,
  ISearchFormState,
  ISearchDelivery
>(
  state => state.searchForm,
  searchForm => ({
    [ESearchFilters.DELIVERY]: searchForm.filters[ESearchFilters.DELIVERY],
    [ESearchFilters.DELIVERY_ADDRESS]: searchForm.filters[ESearchFilters.DELIVERY_ADDRESS],
    [ESearchFilters.DELIVERY_STATIONARY]: searchForm.filters[ESearchFilters.DELIVERY_STATIONARY],
    [ESearchFilters.DELIVERY_RADIUS]: searchForm.filters[ESearchFilters.DELIVERY_RADIUS],
    [ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID]:
      searchForm.filters[ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID],
  }),
);

export const getSearchFormInstantBookFilter = createSelector<
  TRootState,
  ISearchFormState,
  TSearchFormInstantBook
>(
  state => state.searchForm,
  searchForm => searchForm.filters[ESearchFilters.INSTANT_BOOK],
);
