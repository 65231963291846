import React from 'react';

import { calculateOptionIndex } from '@/utility/autoCompleteGroupedOptions';

import LocationNotice from '../../components/LocationNotice';
import { IGroupOption, IOption } from '../LocationSearchInput';

interface ILocationSearchContentProps {
  open: boolean;
  groupOptions: IGroupOption[];
  allGroupOptions: IGroupOption[];
  getListboxProps: () => Record<string, unknown>;
  getOptionProps: ({
    option,
    index,
  }: {
    option: IOption;
    index: number;
  }) => Record<string, unknown>;
  isDelivery: boolean;
}

const LocationSearchContent: React.FC<ILocationSearchContentProps> = ({
  open,
  groupOptions,
  allGroupOptions,
  getListboxProps,
  getOptionProps,
  isDelivery,
}) => {
  return (
    <>
      {isDelivery && <LocationNotice />}
      <div
        className={`pb-6 bg-white md:grid md:grid-flow-col ${open ? 'block' : 'hidden'}`}
        {...getListboxProps()}>
        {groupOptions.map((groupOption, groupIndex) => (
          <div key={groupIndex}>
            <div className="px-5 pt-6 pb-4 text-xs font-bold text-gray-500 uppercase semiHighlight">
              {groupOption.group}
            </div>
            <ul>
              {groupOption.options.map((option, optionIndex) => (
                <li
                  key={option.label}
                  className="px-5 text-base font-normal cursor-pointer py-1.5 hover:bg-gray-100 text-primary-base semiHighlight"
                  {...getOptionProps({
                    option,
                    index: calculateOptionIndex(option.groupName, optionIndex, allGroupOptions),
                  })}>
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};

export default LocationSearchContent;
