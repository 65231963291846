import React, { useState } from 'react';

import Dropdown from '@/components/switchback/Dropdown/Dropdown';

export interface IOption {
  label: string;
  value: unknown;
}

interface IFooterDropDownProps {
  id: string;
  onSelect: (selectedOption: IOption) => void;
  selectedValue: IOption['value'];
  options: IOption[];
  testingId?: string;
}

const FooterDropDown: React.FC<IFooterDropDownProps> = ({
  id,
  selectedValue,
  options,
  onSelect,
  testingId,
}) => {
  // anchors the dropdown menu to the trigger button, otherwise dropdown won't show
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  // if there are no choices to display, display nothing
  if (!options.length) return null;

  const selectedOption = options.find(option => option.value === selectedValue);

  return (
    <>
      <div className="relative inline-block py-1 pr-1 text-left">
        <button
          id={id}
          type="button"
          onClick={event => setAnchorEl(event.currentTarget)}
          data-testid={testingId}
          className="trigger">
          <span className="font-medium text-white">{selectedOption?.label}</span>
          <svg
            className={`w-5 h-5 text-white transition duration-150 ease-in-out transform group-hover:text-gray-500 group-focus:text-gray-500 inline ${
              anchorEl ? 'rotate-180' : ''
            }`}
            fill="currentColor"
            viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        <Dropdown
          className={`p-0 py-1 bg-white rounded-md ring-1 ring-black ring-opacity-5 absolute-left`}
          show={Boolean(anchorEl)}
          relativeTo={anchorEl}
          onDismiss={() => setAnchorEl(null)}>
          {options.map((option, i) => (
            <button
              key={i}
              data-testid={`${option.value}-dropdown-option`}
              onClick={() => onSelect(option)}
              className={`${
                selectedValue === option.value ? 'text-white font-medium' : ''
              } selection block w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900`}>
              {option.label}
            </button>
          ))}
        </Dropdown>
      </div>
    </>
  );
};

export default FooterDropDown;
