import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getAuthenticatedUser } from '@/redux/selectors/auth/user';
import apiRequest from '@/services/apiRequest';
import { getCoreApi } from '@/utility/getCoreApi';
import { logger } from '@/utility/logger';

import TermsAndConditionsModal from './TermsAndConditionsModal';

interface IWordpressTOSContent {
  content: string;
}

interface IWordpressTOSVersion {
  version: number;
}

const TermsAndConditionsModalContainer = () => {
  const [tosContent, setTOSContent] = useState<string>();
  const [tosVersion, setTOSVersion] = useState<number>();
  const authenticatedUser = useSelector(getAuthenticatedUser);

  useEffect(() => {
    (async () => {
      if (!authenticatedUser || authenticatedUser.impersonated || (tosVersion && tosContent)) {
        return null;
      }

      try {
        const resVersion = await apiRequest<IWordpressTOSVersion>(
          {
            url: `${getCoreApi()}/tos/version`,
          },
          true,
        );
        const version = resVersion && resVersion.version;

        if (
          version &&
          (!authenticatedUser.accepted_tos_version ||
            version > authenticatedUser.accepted_tos_version)
        ) {
          // show the modal if the user has not yet accepted the terms and conditions
          // or the version they accepted is lower than the the last major tos version

          const data = await apiRequest<IWordpressTOSContent>(
            {
              url: `${getCoreApi()}/tos/content`,
            },
            true,
          );
          setTOSContent(data.content);
          setTOSVersion(version);
        }
      } catch {
        logger.log('Something went wrong with fetching the content or version data.');
      }
    })();
  }, [authenticatedUser]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!authenticatedUser || authenticatedUser.impersonated) {
    return null;
  }

  return <TermsAndConditionsModal version={tosVersion} htmlContent={tosContent} />;
};

export default TermsAndConditionsModalContainer;
