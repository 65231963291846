import querystring from 'query-string';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { fetchBooking } from '@/redux/modules/booking/actions';
import * as ReviewActions from '@/redux/modules/reviews/type';
import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { IReview } from '@/services/types/core/reviews/id';
import { getCoreApi } from '@/utility/getCoreApi';
import { getAuthToken } from '@/utility/session';

const REVIEWS_URL = `${getCoreApi()}/reviews`;

const fetchReviewsSuccessAction = (payload: IReview[]): ReviewActions.IReviewAction => ({
  type: ReviewActions.FETCH_REVIEWS_SUCCESS,
  payload,
});

const fetchReviewsFailureAction = (): ReviewActions.IReviewAction => ({
  type: ReviewActions.FETCH_REVIEWS_FAILURE,
  error: true,
});

const fetchReviewsPendingAction = (): ReviewActions.IReviewAction => ({
  type: ReviewActions.FETCH_REVIEWS_PENDING,
});

const submitReviewSuccessAction = (payload: IReview): ReviewActions.IReviewAction => ({
  type: ReviewActions.SUBMIT_REVIEW_SUCCESS,
  payload,
});

const submitReviewFailureAction = (): ReviewActions.IReviewAction => ({
  type: ReviewActions.SUBMIT_REVIEW_FAILURE,
  error: true,
});

const submitReviewPendingAction = (): ReviewActions.IReviewAction => ({
  type: ReviewActions.SUBMIT_REVIEW_PENDING,
});

const fetchReviewSuccessAction = (payload: IReview): ReviewActions.IReviewAction => ({
  type: ReviewActions.FETCH_REVIEW_SUCCESS,
  payload,
});

const fetchReviewFailureAction = (): ReviewActions.IReviewAction => ({
  type: ReviewActions.FETCH_REVIEWS_FAILURE,
  error: true,
});

const fetchReviewPendingAction = (): ReviewActions.IReviewAction => ({
  type: ReviewActions.FETCH_REVIEW_PENDING,
});

const updateReviewPendingAction = (): ReviewActions.IReviewAction => ({
  type: ReviewActions.UPDATE_REVIEW_PENDING,
});

const updateReviewSuccessAction = (payload: IReview): ReviewActions.IReviewAction => ({
  type: ReviewActions.UPDATE_REVIEW_SUCCESS,
  payload,
});

const updateReviewFailureAction = (): ReviewActions.IReviewAction => ({
  type: ReviewActions.UPDATE_REVIEW_FAILURE,
  error: true,
});

export const resetReviewsData = (): ReviewActions.IReviewAction => ({
  type: ReviewActions.RESET_REVIEWS_DATA,
});

export const getReviews =
  (
    // rentalId can be a campgroundId
    rentalId: string,
    isCampground?: boolean,
    options?: Partial<{ limit: number }>,
  ): ThunkAction<Promise<void>, TRootState, void, ReviewActions.IReviewAction> =>
  async dispatch => {
    const query = querystring.stringify({
      ...(isCampground ? { campground_id: rentalId } : { rental_id: rentalId }),
      ...options,
    });
    const url = `${REVIEWS_URL}?${query}`;
    dispatch(fetchReviewsPendingAction());
    try {
      const data = await apiRequest<IReview[]>({ url });
      dispatch(fetchReviewsSuccessAction(data || []));
    } catch {
      dispatch(fetchReviewsFailureAction);
    }
  };

export const submitReview =
  (
    data: Partial<IReview>,
    siblingData?: Partial<IReview>,
  ): ThunkAction<Promise<boolean>, TRootState, void, ReviewActions.IReviewAction> =>
  async dispatch => {
    const authToken = getAuthToken();
    dispatch(submitReviewPendingAction());
    if (!authToken) {
      dispatch(submitReviewPendingAction());
      return false;
    }
    try {
      const reviewPayload = { ...data };

      const [review] = await Promise.all([
        apiRequest<IReview>(
          {
            url: REVIEWS_URL,
            method: 'POST',
            data: reviewPayload,
          },
          true,
        ),
        // update sibling review as well if it exists
        siblingData &&
          apiRequest<void>({
            url: REVIEWS_URL,
            method: 'POST',
            data: siblingData,
          }),
      ]);

      dispatch(submitReviewSuccessAction(review));
      dispatch(fetchBooking(String(data.booking_id), true) as unknown as AnyAction);
      return true;
    } catch {
      dispatch(submitReviewFailureAction());
      return false;
    }
  };

export const fetchReviewByBookingId =
  (
    bookingId?: number,
    reviewerId?: number,
  ): ThunkAction<Promise<void>, TRootState, void, ReviewActions.IReviewAction> =>
  async dispatch => {
    try {
      dispatch(fetchReviewPendingAction());
      const reviewerParam = reviewerId ? `&reviewer_id=${reviewerId}` : '';
      const review = await apiRequest<IReview[]>(
        {
          url: `${REVIEWS_URL}?booking_id=${bookingId}${reviewerParam}`,
          method: 'GET',
        },
        true,
      );
      // @ts-expect-error fixable: unchecked index access
      dispatch(fetchReviewSuccessAction(review[0] || {}));
    } catch {
      dispatch(fetchReviewsFailureAction());
    }
  };

export const fetchReview =
  (
    reviewId: number | string,
  ): ThunkAction<Promise<void>, TRootState, void, ReviewActions.IReviewAction> =>
  async dispatch => {
    try {
      dispatch(fetchReviewPendingAction);
      const review = await apiRequest<IReview>(
        {
          url: `${REVIEWS_URL}/${reviewId}`,
          method: 'GET',
        },
        true,
      );
      dispatch(fetchReviewSuccessAction(review || {}));
    } catch {
      dispatch(fetchReviewFailureAction());
    }
  };

export const updateReview =
  (
    reviewId: string,
    reviewData: Partial<IReview>,
  ): ThunkAction<Promise<boolean>, TRootState, void, ReviewActions.IReviewAction> =>
  async dispatch => {
    try {
      dispatch(updateReviewPendingAction());

      const requestUrl = `${getCoreApi()}/reviews/${reviewId}`;

      const updatedReview = await apiRequest<IReview>(
        { url: requestUrl, data: reviewData, method: 'PATCH' },
        true,
        false,
      );

      dispatch(updateReviewSuccessAction(updatedReview));
      return true;
    } catch {
      dispatch(updateReviewFailureAction());
      return false;
    }
  };

interface IReviewState {
  all: {
    data: Array<IReview> | null;
    isFetching: boolean;
    error?: boolean;
  };
  current: {
    data: IReview | null;
    isFetching: boolean;
    error?: boolean;
  };
}

export const initialState: IReviewState = {
  all: {
    data: null,
    isFetching: false,
  },
  current: {
    data: null,
    isFetching: false,
  },
};

export default function reducer(
  state: IReviewState = initialState,
  action: ReviewActions.IReviewAction,
) {
  switch (action.type) {
    case ReviewActions.FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        all: {
          ...state.all,
          data: action.payload,
          isFetching: false,
          error: false,
        },
      };
    case ReviewActions.FETCH_REVIEWS_PENDING:
      return {
        ...state,
        all: {
          ...state.all,
          data: null,
          isFetching: true,
          error: false,
        },
      };
    case ReviewActions.FETCH_REVIEWS_FAILURE:
      return {
        ...state,
        all: {
          ...state.all,
          isFetching: false,
          error: true,
        },
      };
    case ReviewActions.SUBMIT_REVIEW_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          data: action.payload,
          isFetching: false,
          error: false,
        },
      };
    case ReviewActions.SUBMIT_REVIEW_PENDING:
      return {
        ...state,
        current: {
          ...state.current,
          data: null,
          isFetching: true,
          error: false,
        },
      };
    case ReviewActions.SUBMIT_REVIEW_FAILURE:
      return {
        ...state,
        current: {
          ...state.current,
          isFetching: false,
          error: true,
        },
      };
    case ReviewActions.FETCH_REVIEW_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          data: action.payload,
          isFetching: false,
          error: false,
        },
      };
    case ReviewActions.FETCH_REVIEW_PENDING:
      return {
        ...state,
        current: {
          ...state.current,
          data: null,
          isFetching: true,
          error: false,
        },
      };
    case ReviewActions.FETCH_REVIEW_FAILURE:
      return {
        ...state,
        current: {
          ...state.current,
          isFetching: false,
          error: true,
        },
      };
    case ReviewActions.UPDATE_REVIEW_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          data: { ...(state.current.data || {}), ...action.payload },
          isFetching: false,
          error: false,
        },
      };
    case ReviewActions.UPDATE_REVIEW_PENDING:
      return {
        ...state,
        current: {
          ...state.current,
          isFetching: true,
          error: false,
        },
      };
    case ReviewActions.UPDATE_REVIEW_FAILURE:
      return {
        ...state,
        current: {
          ...state.current,
          isFetching: false,
          error: true,
        },
      };
    case ReviewActions.RESET_REVIEWS_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
