import { useEffect, useState } from 'react';

const DEFAULT_DELAY = 500;

function useDebounce<T>(value: T, delay?: number): T {
  const ms = delay || DEFAULT_DELAY;
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), ms);
    return () => {
      clearTimeout(timer);
    };
  }, [value, ms]);
  return debouncedValue;
}

export default useDebounce;
