import { IGeocodeFeature } from '@/services/types/mapbox/mapboxApi';
import { IQuoteDeliveryLocation } from '@/services/types/quote/IDeliveryLocation';

export const itemizeAddress = (address: IQuoteDeliveryLocation & IGeocodeFeature) => {
  let latLong = null;

  if (address === undefined) return {};

  if (address?.center) {
    latLong = {
      lng: address.center[0],
      lat: address.center[1],
    };
  } else if (address?.lat && address?.lng) {
    latLong = {
      lng: address?.lng,
      lat: address?.lat,
    };
  }

  const splitContext = address.context
    ? address.context.reduce<Record<string, string>>((acc, cur) => {
        // @ts-expect-error fixable: unchecked index access
        acc[cur.id.split('.')[0]] = cur.text;
        return acc;
      }, {})
    : null;

  const itemizedAddress: IQuoteDeliveryLocation = {
    ...latLong,
    formattedAddress: address.place_name,
    street: address?.properties?.address || address?.place_name?.split(',')[0],
    city: splitContext?.place,
    state: splitContext?.region,
    country: splitContext?.country,
    zip: splitContext?.postcode,
    location: address,
  };
  return itemizedAddress;
};
