import isEqual from 'lodash/isEqual';
import { ParsedUrlQuery } from 'querystring';
import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import { IAction } from '@/utility/redux/action';

export const UPDATE = 'queryParams/UPDATE';

type Query = ParsedUrlQuery;

interface IUpdateAction extends IAction {
  type: typeof UPDATE;
  payload: Query;
}

type TAction = IUpdateAction;

const updateQueryParams = (payload: Query): IUpdateAction => ({
  type: UPDATE,
  payload,
});

export const initialState: Query = {};

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case UPDATE:
      return action.payload;
    default:
      return state;
  }
}

export const setQueryParam =
  (query: Query): ThunkAction<IUpdateAction | null, TRootState, void, IUpdateAction> =>
  (dispatch, getState) => {
    const existingParams = getState().queryParams;

    if (isEqual(existingParams, query)) {
      return null;
    }
    return dispatch(updateQueryParams(query));
  };
