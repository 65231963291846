import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React, { useMemo } from 'react';

import { getHeaders } from '@/utility/getHeaders';
import { getAuthToken } from '@/utility/session';

const httpLink = new HttpLink({ uri: process.env.graphqlApi });

let apolloClient: ApolloClient<NormalizedCacheObject>; // singelton

const headersMiddleware = (token: string | null = null) =>
  setContext(async () => {
    const authToken = token || getAuthToken(); // getAuthToken is from the client
    const headers = await getHeaders();

    return {
      headers: {
        ...(authToken ? { Authorization: authToken } : {}),
        'riskified-session-id': window.sessionStorage.getItem('riskified-session-id'),
        ...headers,
      },
    };
  });

const graphqlClient = (token: string | null = null) =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: from([headersMiddleware(token), httpLink]),
    ssrMode: typeof window === 'undefined',
  });

const initializeApollo = ({
  initialState = {},
  token = null,
}: {
  initialState?: Record<string, any>;
  token?: string | null;
}) => {
  const _apolloClient = apolloClient ?? graphqlClient(token); // pass the token if come from SSR

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
};
interface IProps {
  children?: React.ReactNode;
  pageProps?: Record<string, any>;
}

const useApollo = (initialState: Record<string, any>) => {
  const store = useMemo(() => initializeApollo({ initialState }), [initialState]);
  return store;
};

const Apollo: React.FC<IProps> = ({ children, pageProps }) => {
  const client = useApollo(pageProps?.initialApolloState || {});
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Apollo;
