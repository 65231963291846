import cloneDeep from 'lodash/cloneDeep';

export interface IOption {
  label: string;
  groupName: string;
  value?: any;
  url?: string;
}

export interface IGroupOption {
  options: IOption[];
  key: number;
  index: number;
  group: string;
}

/**
 * Limit recent searches options in the grouped options.
 *
 * @param groupedOptions
 * @param recentSearchesGroupName
 * @param maxRecentSearches
 * @returns {IGroupOption} the grouped options limited for recent searches
 */
export const limitRecentSearches = (
  groupedOptions: IGroupOption[],
  recentSearchesGroupName: string,
  maxRecentSearches: number,
): IGroupOption[] => {
  const result = cloneDeep(groupedOptions);

  if (result?.length > 0) {
    const recentSearchesGroup = result.find(
      groupOption => groupOption.group === recentSearchesGroupName,
    );
    if (recentSearchesGroup) {
      recentSearchesGroup.options = recentSearchesGroup.options.slice(0, maxRecentSearches);
    }
  }

  return result;
};

/**
 * We may hide some recent searches, so we have to calculate the index into the results for getting
 * option props (onClick handler) as the autocomplete results may have more results than we display
 * in the UI.
 *
 * We need to calculate the overall index into the full list of grouped options. For example the 2nd option
 * in the 2nd group where the first group has 5 options would be at index 6 for the full list of grouped options.
 *
 * @param groupName
 * @param optionIndex
 * @param allGroupOptions
 * @returns {number} The index into the full list of grouped options
 */
export const calculateOptionIndex = (
  groupName: string,
  optionIndex: number,
  allGroupOptions: IGroupOption[],
): number => {
  let index = 0;
  let found = false;

  for (const group of allGroupOptions) {
    for (let currentIndex = 0; currentIndex < group.options.length; currentIndex++) {
      const option = group.options[currentIndex];
      if (option?.groupName === groupName && currentIndex === optionIndex) {
        found = true;
        break;
      }

      index++;
    }

    if (found) break;
  }

  return index;
};
