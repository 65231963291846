import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { IAvailability } from '@/services/types/search/availability';
import formatDate from '@/utility/format-date';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

const SET_AVAILABILITY = 'availability/SET_AVAILABILITY';
const FETCH_AVAILABILITY_SUCCESS = 'availability/FETCH_AVAILABILITY_SUCCESS';

interface ISetAvailabilityAction extends IAction {
  type: typeof SET_AVAILABILITY;
  payload: IAvailability[];
}

interface IFetchAvailabilitySuccessAction extends IAction {
  type: typeof FETCH_AVAILABILITY_SUCCESS;
  payload: IAvailability[];
}

type TAction = ISetAvailabilityAction | IFetchAvailabilitySuccessAction;

const fetchAvailabilitySuccess = (payload: IAvailability[]): IFetchAvailabilitySuccessAction => ({
  type: FETCH_AVAILABILITY_SUCCESS,
  payload,
});

export const getAvailability =
  (
    rentalId: string,
    bookingId?: string,
  ): ThunkAction<
    Promise<IFetchAvailabilitySuccessAction>,
    TRootState,
    void,
    IFetchAvailabilitySuccessAction
  > =>
  dispatch => {
    const today = new Date();
    const yearFromNow = new Date().setMonth(today.getMonth() + 12);
    const fromDate = formatDate(today, 'YYYY-MM-DD');
    const toDate = formatDate(yearFromNow, 'YYYY-MM-DD');
    const bookingIdParam = bookingId ? `&booking_id=${bookingId}` : '';
    const requestUrl = `${getCoreApi()}/availability?from=${fromDate}&to=${toDate}&rental_id=${rentalId}${bookingIdParam}&`;

    return new Promise((resolve, reject) =>
      apiRequest<IAvailability[]>({ url: requestUrl })
        .then(data => {
          return resolve(dispatch(fetchAvailabilitySuccess(data || [])));
        })
        .catch(error => {
          return reject(error);
        }),
    );
  };

interface IState {
  data: IAvailability[];
  isFetched: boolean;
}

export const initialState: IState = {
  data: [],
  isFetched: false,
};

// TODO: Add async actions (REQUEST/FAIL/SUCCESS)
export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case SET_AVAILABILITY:
      return {
        ...state,
        data: action.payload,
      };
    case FETCH_AVAILABILITY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetched: true,
      };
    default:
      return { ...state };
  }
}
