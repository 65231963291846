import useScript from '@/hooks/useScript';
import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';

export const useAttentiveScript = (): boolean | undefined => {
  const attentiveScriptIsEnabled = useExperimentIsEnabled(OptimizelyFlags.ATTENTIVE_ENABLED);

  const isAttentiveScriptLoaded = useScript(
    attentiveScriptIsEnabled ? 'https://cdn.attn.tv/outdoorsy/dtag.js' : undefined,
  );

  return isAttentiveScriptLoaded;
};
