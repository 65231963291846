import {
  ActiveOptimizelyDecision,
  OptimizelyFlags,
  useOptimizelyDecision,
} from '@/services/experiments';

export enum SystemDowntimeState {
  Upcoming = 'upcoming',
  Active = 'active',
  Off = 'off',
}

interface SystemDowntime {
  state: SystemDowntimeState;
  title?: string;
  description?: string;
}

export const DEFAULT_SYSTEM_DOWNTIME: SystemDowntime = { state: SystemDowntimeState.Off };

export const systemDowntimeFromDecision = (decision: ActiveOptimizelyDecision | null) => {
  if (!decision || !decision.enabled) return null;

  const state = decision.variables?.state;

  if (state === SystemDowntimeState.Upcoming || state === SystemDowntimeState.Active) {
    return {
      state,
      title: typeof decision.variables?.title === 'string' ? decision.variables.title : undefined,
      description:
        typeof decision.variables?.description === 'string'
          ? decision.variables.description
          : undefined,
    };
  }

  return DEFAULT_SYSTEM_DOWNTIME;
};

export const useSystemDowntime = (): null | SystemDowntime => {
  const decision = useOptimizelyDecision(OptimizelyFlags.SYSTEM_DOWNTIME);

  return systemDowntimeFromDecision(decision);
};
