import { datadogRum } from '@datadog/browser-rum-slim';
/* eslint-disable no-console */

class Logger {
  info(...args: any[]): void {
    console.info(...args);
  }
  log(...args: any[]): void {
    console.log(...args);
  }
  warn(...args: any[]): void {
    console.warn(...args);
  }
  error(...args: any[]): void {
    console.error(...args);
  }
  captureExceptionWithDatadog(error: unknown, context?: Record<string, unknown>) {
    datadogRum.addError(error, context);
  }
}

export const logger = new Logger();
