import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';

export const getHeaderSearchFilters = (state: TRootState) => state.globalHeader;

export const getHeaderSearchAddressFilter = createSelector<
  TRootState,
  TRootState['globalHeader'],
  TRootState['globalHeader']['address']
>(getHeaderSearchFilters, globalHeader => globalHeader.address);

export const getHeaderSearchDatesFilter = createSelector<
  TRootState,
  TRootState['globalHeader'],
  TRootState['globalHeader']['dates']
>(getHeaderSearchFilters, globalHeader => globalHeader.dates);

export const getHeaderSearchGuestsFilter = createSelector<
  TRootState,
  TRootState['globalHeader'],
  TRootState['globalHeader']['guests']
>(getHeaderSearchFilters, globalHeader => globalHeader.guests);

export const getHeaderSearchVehicleTypeFilter = createSelector<
  TRootState,
  TRootState['globalHeader'],
  TRootState['globalHeader']['vehicleType']
>(getHeaderSearchFilters, globalHeader => globalHeader.vehicleType);

export const getHeaderSearchVehicleFilter = createSelector<
  TRootState,
  TRootState['globalHeader'],
  TRootState['globalHeader']['vehicle']
>(getHeaderSearchFilters, globalHeader => globalHeader.vehicle);
