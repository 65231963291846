import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { ILocation } from '@/services/types/core/locations/id';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

const GET_DEALER_LOCATION_REQUEST = 'dealerLocation/GET_DEALER_LOCATION_REQUEST';
const GET_DEALER_LOCATION_RESPONSE = 'dealerLocation/GET_DEALER_LOCATION_RESPONSE';
const GET_DEALER_LOCATION_FAILURE = 'dealerLocation/GET_DEALER_LOCATION_FAILURE';

interface IGetDealerLocationAction extends IAction {
  type: typeof GET_DEALER_LOCATION_REQUEST;
}
interface IGetDealerLocationResponseAction extends IAction {
  type: typeof GET_DEALER_LOCATION_RESPONSE;
  payload: ILocation;
}
interface IGetDealerLocationFailAction extends IAction {
  type: typeof GET_DEALER_LOCATION_FAILURE;
  payload: string;
  error: true;
}

type TAction =
  | IGetDealerLocationAction
  | IGetDealerLocationResponseAction
  | IGetDealerLocationFailAction;

export const getDealerLocation =
  (): ThunkAction<void, TRootState, void, TAction> => (dispatch, getState) => {
    dispatch<IGetDealerLocationAction>({
      type: GET_DEALER_LOCATION_REQUEST,
    });

    const state = getState();
    const locationId = state.listing.data?.current_location_id;
    const url = `${getCoreApi()}/locations/${locationId}`;

    if (!locationId) {
      dispatch<IGetDealerLocationFailAction>({
        type: GET_DEALER_LOCATION_FAILURE,
        payload: "Could not find listing's location id",
        error: true,
      });
      return;
    }

    apiRequest<ILocation>({ url }, true)
      .then(response => {
        if (response.error) {
          throw new Error(response.error);
        }

        dispatch<IGetDealerLocationResponseAction>({
          type: GET_DEALER_LOCATION_RESPONSE,
          payload: response,
        });
      })
      .catch(error =>
        dispatch<IGetDealerLocationFailAction>({
          type: GET_DEALER_LOCATION_FAILURE,
          payload: error?.error || error,
          error: true,
        }),
      );
  };

interface IState {
  data?: ILocation;
  isLoading?: boolean;
  error?: string;
}

export const initialState: IState = {};

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case GET_DEALER_LOCATION_REQUEST:
      return {
        ...state,
        data: undefined,
        isLoading: true,
        error: undefined,
      };
    case GET_DEALER_LOCATION_RESPONSE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: undefined,
      };
    case GET_DEALER_LOCATION_FAILURE:
      return {
        ...state,
        data: undefined,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
