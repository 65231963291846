import React from 'react';

import css from './Toggle.module.css';

interface IToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
  'data-testid'?: string;
  position?: 'right' | 'left' | 'below-end';
  className?: string;
  toggleSize?: 'auto' | 'small' | 'large';
}

const Toggle = React.forwardRef<HTMLInputElement, IToggleProps>(
  ({ children, name, position = 'right', className = '', toggleSize = 'auto', ...props }, ref) => {
    const itemsAlign = position === 'below-end' ? 'items-end' : 'items-center';
    const mapPositionToFlex = {
      'below-end': 'flex-col',
      right: 'flex-row',
      left: 'flex-row-reverse',
    };
    const direction = mapPositionToFlex[position];
    return (
      <label
        className={`inline-flex autoType200 font-medium text-gray-900 select-none ${itemsAlign} ${direction} ${className}`}
        htmlFor={name}>
        <input
          className={`absolute opacity-0 pointer-events-none ${css.input}`}
          id={name}
          name={name}
          ref={ref}
          type="checkbox"
          {...props}
        />
        <span
          aria-hidden
          data-size={toggleSize}
          className={`cursor-pointer relative flex items-center bg-gray-500 mb-1 rounded-full ${
            toggleSize === 'auto' ? 'md:h-4 md:w-7 ' : toggleSize === 'small' ? 'h-4 w-7' : ''
          } ${css.control}`}
        />
        {children}
      </label>
    );
  },
);

Toggle.displayName = 'Toggle';

export default Toggle;
