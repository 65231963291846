import querystring from 'querystring';
import React from 'react';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { getSettingsForLocale } from '@/utility/i18n';
import { decodeJWT, getSessionCookie, isJWTValid, isSessionValid } from '@/utility/session';

// import { initialAuthState } from './modules/auth';
import rootReducer from './rootReducer';

function mergeAuthWithInitialState(initialState, authState) {
  // Initial reducer state will get overridden by ssr state after store is created
  // wiping out all initial synchronous state like initialization from cookies
  let state = initialState && {
    ...initialState,
    auth: {
      ...initialState.auth,
      isAuthenticated: authState,
    },
  };

  return state;
}

const store = initialState => {
  return createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunk)));
};

// Additional setup for ServerSideRendering
const isServer = typeof window === 'undefined';
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

function getOrCreateStore(initialState) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return store(initialState);
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = store(initialState);
  }
  return window[__NEXT_REDUX_STORE__];
}

export const withReduxStore = App =>
  class AppWithRedux extends React.Component {
    static async getInitialProps(appContext) {
      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const routerLocale = getSettingsForLocale(appContext.ctx.locale);
      const reduxStore = getOrCreateStore({ routerLocale });

      // Provide the store to getInitialProps of pages
      appContext.ctx.reduxStore = reduxStore;

      let appProps = {};
      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(appContext);
      }

      // to be able to render the correct authenticated layout, the store need to be
      // initialized in a authenticated state if the user is authenticated
      // this will also put the client store in the correct state
      // - i want to know if the user already has a valid session cookie with token
      // - if not authenticated, login based on jwt token from url Ex: email links
      // - if authenticated from query set the session cookie of the
      let parsedQuery = appContext.ctx.query;
      try {
        parsedQuery = querystring.parse(appContext.ctx.asPath.split('?')[1]);
      } catch (err) {
        // defaults to appContext.ctx.query
      }
      const sessionValid =
        isJWTValid(decodeJWT(parsedQuery?.token)) ||
        isSessionValid(getSessionCookie(appContext.ctx.req?.headers.cookie));

      return {
        ...appProps,
        initialReduxState: mergeAuthWithInitialState(reduxStore.getState(), sessionValid),
      };
    }

    constructor(props) {
      super(props);
      this.reduxStore = getOrCreateStore(props.initialReduxState);
    }

    render() {
      return <App {...this.props} reduxStore={this.reduxStore} />;
    }
  };

export default store;
