import React from 'react';

import Text from '@/components/switchback/Text/Text';
import Toggle from '@/components/switchback/Toggle/Toggle';

interface IRequirementItemProps {
  value: boolean;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  icon: React.ReactNode;
}

const RequirementItem: React.FC<IRequirementItemProps> = ({ value, onChange, label, icon }) => {
  return (
    <span className="flex items-center justify-between mb-4 text">
      <Toggle
        position="left"
        toggleSize="small"
        checked={value}
        onChange={onChange}
        className="w-full">
        <span className="flex items-center mr-auto">
          {icon}
          <Text type="inline" className="text-base font-normal semiHighlight text-primary-base">
            {label}
          </Text>
        </span>
      </Toggle>
    </span>
  );
};

export default RequirementItem;
