// TODO: consider using the /\S+@\S+\.\S{2,}/ pattern instead
export const emailValidator = {
  required: true,
  pattern: /^\S+@\S+\.\S+$/,
};

export const passwordValidator = {
  required: true,
};

export const termsValidator = {
  required: true,
};

export const setPasswordValidator = {
  minLength: 8,
  maxLength: 50,
  required: true,
  pattern: /\d/,
};

export const nameValidator = {
  pattern: /.+\s.+/,
  required: true,
  minLength: 2,
  maxLength: 100,
};

export const strictNameValidator = {
  pattern: /^ *[a-zA-ZÀ-ÖØ-öø-ɏ]+((\.|-|'| )[a-zA-ZÀ-ÖØ-öø-ɏ]*)* *$/,
  required: true,
  minLength: 2,
  maxLength: 40,
};

export const phoneValidator = {
  /**
   * (\+?\d{1,3}( |-)*)?          # Country code, it may start with +, but not making it mandatory
   * (\(\d{1,3}\)( |-)*)?         # May have prefix wrapped in parentheses
   * ((\d+( |-)+)*\d+)            # The remaining of the number may be split by spaces
   * ( *x(\d+))?                  # It may contain an extension
   *                              # It might start/end with some spaces
   */
  pattern: /^ *(\+?\d{1,3}( |-)*)?(\(\d{1,3}\)( |-)*)?((\d+( |-)+)*\d+)( *x(\d+))? *$/,
  required: true,
  minLength: 7,
  maxLength: 30,
};

export const addressValidator = {
  /**
   * This pattern is very permissive, and could be used for city, address.
   * It must be a combination of words delimited by space, dot, comma or dash, and
   * might also use apostrophe.
   * It might start/end with some spaces.
   */
  pattern: /^ *[a-zA-ZÀ-ÖØ-öø-ɏ0-9]+((\.|,|-|'| )[a-zA-ZÀ-ÖØ-öø-ɏ0-9]*)* *$/,
  required: true,
  minLength: 2,
  maxLength: 100,
};

export const zipCodeValidator = {
  /**
   * There isn't really a way to validate all zip codes with a regex, but we are good
   * to inherit the address and allow the same special characters.
   */
  pattern: addressValidator.pattern,
  required: true,
  minLength: 2,
  maxLength: 30,
};
