import { CookieAttributes } from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TCurrency } from '@/config/locales';
import { updateUser } from '@/redux/modules/auth';
import { getQuote } from '@/redux/modules/quote';
import { getAuthenticatedUser, getIsAuthenticated } from '@/redux/selectors/auth/user';
import { getQueryParams } from '@/redux/selectors/queryParams';
import { parseCurrencyCookie } from '@/utility/currency';

import { useCookie } from './useCookie';
import { useLocaleSettings } from './useLocaleSettings';

export function useSessionCurrency(): [TCurrency, (currency: TCurrency) => void] {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { quote: quoteQueryId } = useSelector(getQueryParams);
  const user = useSelector(getAuthenticatedUser);
  const locale = useLocaleSettings();
  const [cookieCurrency, updateCookieCurrency] = useCurrencyCookie();
  // initialize activeCurrency with locale.base_currency
  const [activeCurrency, setActiveCurrency] = useState<TCurrency>(locale.base_currency);

  useEffect(() => {
    // update activeCurrency after component mounts
    const currency = user?.currency || cookieCurrency || locale.base_currency;
    setActiveCurrency(currency);
  }, [user?.currency, cookieCurrency, locale.base_currency]);

  const update = useCallback(
    async (currency: TCurrency, cookieAttributes?: CookieAttributes) => {
      if (isAuthenticated) {
        await dispatch(updateUser({ locale: { base_currency: currency } }));
      }
      if (quoteQueryId) {
        await dispatch(getQuote());
      }
      updateCookieCurrency(currency, cookieAttributes);

      // TODO: remove once the app can react to this change
      window.location.reload();
    },
    [dispatch, isAuthenticated, quoteQueryId, updateCookieCurrency],
  );

  return [activeCurrency, update];
}

function useCurrencyCookie(): [
  TCurrency | undefined,
  (value: TCurrency, attributes?: CookieAttributes) => void,
] {
  const [value, { update: updateCookie }] = useCookie('userLocaleCurrency');

  const update = useCallback(
    (currency: TCurrency, attributes: CookieAttributes = { expires: 365 * 24 * 60 * 60 }) => {
      updateCookie(encodeURIComponent(JSON.stringify({ currency })), attributes);
    },
    [updateCookie],
  );

  return [parseCurrencyCookie(value) || undefined, update];
}
