import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import { IDay, IDays } from '@/services/types/core/locations/id';

const TIME_INTERVAL = 15 * 60;
const MAX_TIME = 86400;

const mapDateToWeekDay = (date: string) => {
  const weekDayNumber = dayjs(date, 'YYYY-MM-DD').day();
  const weekDays: (keyof IDays)[] = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];
  return weekDays[weekDayNumber];
};

export const mapTimeToIntervals = (rawStartTime: number, rawEndTime: number): number[] => {
  let startTime = rawStartTime;
  let endTime = rawEndTime;
  // Start options at midnight of the current day
  if (!startTime || startTime < 0) startTime = 0;
  // Treat as ending/starting options at midnight of the next day
  if (!endTime || endTime > MAX_TIME) endTime = MAX_TIME;
  if (startTime > MAX_TIME) startTime = MAX_TIME;
  // Impossible, no options returned
  if (endTime < 0 || startTime >= endTime) return [];

  const period = endTime - startTime;
  const intervals = endTime === MAX_TIME ? period / TIME_INTERVAL : period / TIME_INTERVAL + 1;
  return [...Array(intervals)].map((_, index) => startTime + TIME_INTERVAL * index);
};

export const getWeekdayInfo = createSelector<
  TRootState,
  TRootState['dealerLocation'],
  TRootState['checkout']['booking'],
  IDay | undefined
>(
  state => state.dealerLocation,
  state => state.checkout.booking,
  (dealerLocation, booking) => {
    if (!dealerLocation || !booking) {
      return undefined;
    }

    const weekDay = mapDateToWeekDay(booking.from);

    // @ts-expect-error fixable: unchecked index access
    return dealerLocation.data?.availability?.days?.[weekDay];
  },
);

export const getDealerLocationPickupOptions = createSelector<
  TRootState,
  IDay | undefined,
  number[]
>(getWeekdayInfo, weekDayInfo => {
  const pickup = weekDayInfo?.pickup;

  if (!pickup || pickup.unavailable) {
    return mapTimeToIntervals(0, MAX_TIME);
  }

  return mapTimeToIntervals(pickup.start_time, pickup.end_time);
});

export const getDealerLocationDropoffOptions = createSelector<
  TRootState,
  IDay | undefined,
  number[]
>(getWeekdayInfo, weekDayInfo => {
  const dropoff = weekDayInfo?.dropoff;

  if (!dropoff || dropoff.unavailable) {
    return mapTimeToIntervals(0, MAX_TIME);
  }

  return mapTimeToIntervals(dropoff.start_time, dropoff.end_time);
});
