import { IAutocompleteOption } from '@/components/switchback/Autocomplete/AutocompleteOptions';
import { TPark } from '@/graphql/components/home/DiscoverTheBest/parks';
import { useSearchPopularParksQuery } from '@/graphql/types-and-hooks';
import getSeason from '@/utility/getSeason';

interface IBreadcrumbsEntity {
  id: string;
  title: string;
  url?: string;
}

interface ImagesEntity {
  season: string;
  url: string;
}

const getImageUrlBySeason = (images: ImagesEntity[]) => {
  const date = new Date();
  const seasonByDate = getSeason(date);
  const img = images.find(({ season }) => season === seasonByDate);
  return img ? img.url : images[0]?.url;
};

export const usePopularParks = <T = TPark[] | IAutocompleteOption[]>(defaultLocations: T) => {
  const { data, error } = useSearchPopularParksQuery({});

  const popularParksRes = data?.searchPopularParks?.parks || [];

  const shouldShowDefaultLocations = error || !popularParksRes.length;

  if (shouldShowDefaultLocations) {
    return defaultLocations;
  }

  const parsedResponse = popularParksRes.map(res => {
    if (!res) return null;
    const id = res.id;
    const { title, images, breadcrumbs, address } = res.metadata;

    const link = breadcrumbs.find(
      (breadcrumb: IBreadcrumbsEntity) => breadcrumb.id === String(id),
    )?.url;

    const image = getImageUrlBySeason(images);

    const hasLabelWithState = address.state_code && title;

    return {
      id,
      name: title,
      image,
      link,
      label: hasLabelWithState && `${title}, ${address.state_code}`,
    };
  });

  return parsedResponse;
};
