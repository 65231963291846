import striptags from 'striptags';

export const stripHTML = (text: string) => {
  if (!text && typeof text !== 'string') {
    return '';
  }
  try {
    return striptags(text);
  } catch {
    return '';
  }
};

export const decodeString = (text: string): string => {
  if (!text || typeof text !== 'string') {
    return '';
  }
  if (typeof window !== 'undefined') {
    const DOMParser = window.DOMParser;
    const parser = new DOMParser();
    return parser.parseFromString(text, 'text/html').body.textContent || text;
  }

  return text;
};
