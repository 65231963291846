export const addOrRemoveItem: <K = string>(item: K, list: K[]) => K[] = (item, list) => {
  const newList = [];
  let didHaveElement = false;
  list.forEach(element => {
    if (item !== element) {
      newList.push(element);
    } else {
      didHaveElement = true;
    }
  });
  if (!didHaveElement) {
    newList.push(item);
  }
  return newList;
};
