import { TCurrency } from '@/config/locales';

interface ICurrency {
  domain?: string;
  code: TCurrency;
  name: TCurrency;
  label: TCurrency;
}

export const currencies: ICurrency[] = [
  {
    domain: '',
    code: 'AUD',
    name: 'AUD',
    label: 'AUD',
  },
  {
    code: 'CAD',
    name: 'CAD',
    label: 'CAD',
  },
  {
    domain: '',
    code: 'EUR',
    name: 'EUR',
    label: 'EUR',
  },
  {
    domain: '',
    code: 'GBP',
    name: 'GBP',
    label: 'GBP',
  },
  {
    domain: '',
    code: 'NZD',
    name: 'NZD',
    label: 'NZD',
  },
  {
    code: 'USD',
    name: 'USD',
    label: 'USD',
  },
];
