import { isSSR } from '@/utility/isSSR';
import { logger } from '@/utility/logger';

export const triggerCustomEvent = (type: string, name: string, element?: any) => {
  try {
    const event = new CustomEvent(type, {
      cancelable: true,
      bubbles: true,
      detail: { name },
    });
    if (element) {
      element.dispatchEvent(event);
    }

    if (!element && !isSSR()) {
      document.dispatchEvent(event);
    }
  } catch (error) {
    logger.captureExceptionWithDatadog(error, { url: error?.request?._currentUrl });
  }
};
