import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useRouter } from '@/hooks/useRouter';
import { getAuthenticatedUser } from '@/redux/selectors/auth/user';
import { identify } from '@/services/track-event';

// _app does not have reactive access to redux, so this is a dummy node
// that tracks legacy alias calls when the page (or user) is changed.
const TrackUserAlias: React.FC = () => {
  const user = useSelector(getAuthenticatedUser);
  const router = useRouter();
  const pathWithoutQuery = router.asPath.split('?')[0];
  const userId = user?.id;
  const isAdmin = !!user?.impersonated;

  useEffect(() => {
    if (userId) {
      identify({ userId, isAdmin });
    }
  }, [pathWithoutQuery, userId, isAdmin]);

  return null;
};

export default TrackUserAlias;
