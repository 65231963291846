import React, { createContext, useContext, useEffect, useState } from 'react';

import { useRouter } from '@/hooks/useRouter';

interface IAppRouteContextProps {
  prev: string;
  current: string;
}

const AppRouteContext = createContext<IAppRouteContextProps>({ prev: '', current: '' });

export const AppRouteProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { asPath } = useRouter();
  const [state, setState] = useState<IAppRouteContextProps>({ prev: '', current: '' });

  useEffect(() => {
    setState(currentState => ({
      prev: currentState.current,
      current: asPath,
    }));
  }, [asPath]);

  return <AppRouteContext.Provider value={state}>{children}</AppRouteContext.Provider>;
};

export const useAppRoute = (): IAppRouteContextProps => {
  return useContext(AppRouteContext);
};
