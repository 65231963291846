import { triggerCustomEvent } from '@/utility/triggerCustomEvent';

import { EVENT_NAME_CLOSE_SIGN_UP, EVENT_NAME_LOGIN } from './session';
/**
 * TODO: Add redux  action for this
 */
export const triggerLoginModal = (waitTime?: number) => {
  setTimeout(() => {
    triggerCustomEvent('authenticate', EVENT_NAME_CLOSE_SIGN_UP);
    triggerCustomEvent('authenticate', EVENT_NAME_LOGIN);
  }, waitTime ?? 1000);
};
