import { format as formatUrl, UrlObject } from 'url';

import { domains } from '@/config/locales';

export const isExternalLink = (href: UrlObject | string | undefined): boolean => {
  if (!href) {
    return false;
  }

  const hostApp = new URL(process.env.hostApp || 'https://www.staging.outdoorsy.com');

  try {
    const outdoorsyDomains = [hostApp.hostname, ...domains];
    let linkUrl: URL;

    if (typeof href === 'string') {
      linkUrl = new URL(href, process.env.hostApp);
    } else {
      const formattedUrl = formatUrl(href);
      linkUrl = new URL(formattedUrl, process.env.hostApp);
    }

    return !outdoorsyDomains.includes(linkUrl.hostname);
  } catch {
    return false;
  }
};
