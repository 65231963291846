import useAutocomplete from '@material-ui/lab/useAutocomplete';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { IAutocompleteOption } from '@/components/switchback/Autocomplete/AutocompleteOptions';
import useComponentVisible from '@/hooks/useComponentVisible';
import useMapboxQuery from '@/hooks/useMapboxQuery';
import { IHeaderSearchFilterDates, IHeaderSearchFilterGuests } from '@/redux/modules/globalHeader';
import { ILodgingCampgrounds } from '@/services/types/search/lodgingCampgrounds';
import { IVehicle } from '@/services/types/search/vehicle';
import { IVehicleCampgrounds } from '@/services/types/search/vehicleCampgrounds';
import { limitRecentSearches } from '@/utility/autoCompleteGroupedOptions';
import { formatDateRange } from '@/utility/format-date';
import { stripDiacritics } from '@/utility/stripDiacritics';
import {
  LocationGroupType,
  mapLocationLabelToGroupName,
  setSurfacedLocation,
} from '@/utility/surfacedLocation';

import { EHeaderTab, IHeaderMenu } from '../UniversalSearchContainer/menu';
import DateContent from './DateContent/DateContent';
import classes from './DesktopUniversalSearch.module.css';
import DetailsContent from './DetailsContent/DetailsContent';
import LocationSearchContent from './LocationContent/LocationSearchContent';
import LocationSearchInput from './LocationSearchInput';
import LodgingCampgroundsContent from './LodgingCampgroundsContent/LodgingCampgroundsContent';
import {
  DateTab,
  GuestsTab,
  SearchButton,
  VehicleDetailCampgroundsTab,
  VehicleDetailTab,
} from './SearchTabs';
import VehicleCampgroundsContent from './VehicleCampgroundsContent/VehicleCampgroundsContent';
import VehicleContent from './VehicleContent/VehicleContent';

interface IOption {
  label: string;
  groupName: string;
  value?: any;
  url?: string;
}

interface IGroupOption {
  options: IOption[];
  key: number;
  index: number;
  group: string;
}

export enum SEARCH_TAB {
  DATES = 'DATES',
  DETAILS = 'DETAILS',
  LOCATION = 'LOCATION',
  VEHICLE = 'VEHICLE',
  VEHICLE_CAMPGROUNDS = 'VEHICLE_CAMPGROUNDS',
  LODGING_CAMPGROUNDS = 'LODGING_CAMPGROUNDS',
}

export const getClassName = (
  selectedTab: SEARCH_TAB | null,
  currentTab: SEARCH_TAB,
  isStayFilterEnabled?: boolean,
) => {
  const className = () => {
    if (selectedTab === currentTab) {
      return 'bg-white';
    }

    if (selectedTab !== null) {
      return 'border-b border-grey-200 bg-gray-100 rounded-bl-none';
    }

    return isStayFilterEnabled ? 'bg-white' : 'bg-gray-100';
  };

  if (
    [
      SEARCH_TAB.DATES,
      SEARCH_TAB.DETAILS,
      SEARCH_TAB.VEHICLE,
      SEARCH_TAB.LODGING_CAMPGROUNDS,
      SEARCH_TAB.VEHICLE_CAMPGROUNDS,
    ].includes(currentTab)
  ) {
    return `${className()} ${classes.date_tab}`;
  }

  if (currentTab === SEARCH_TAB.LOCATION && selectedTab && selectedTab !== SEARCH_TAB.LOCATION) {
    return `${className()} ${classes.active_location_tab}`;
  }

  return className();
};

interface DesktopUniversalSearchProps {
  onSubmit?: () => void;
  handleRecentSearchNavigation?: (url: string) => void;
  location?: { lat: number; lng: number };
  address?: string;
  guests?: IHeaderSearchFilterGuests;
  dates?: IHeaderSearchFilterDates;
  vehicle?: IVehicle;
  vehicleCampgrounds?: IVehicleCampgrounds;
  lodgingCampgrounds?: ILodgingCampgrounds;
  recentSearches?: IAutocompleteOption[];
  maxRecentSearches: number;
  loading?: boolean;
  onChangeAddress?: (address?: string) => void;
  onChangeDates?: (address?: IHeaderSearchFilterDates) => void;
  onChangeGuests?: (guests?: IHeaderSearchFilterGuests) => void;
  onConfirmAddress?: (address?: string) => void;
  onConfirmDates?: (dates?: IHeaderSearchFilterDates) => void;
  onConfirmGuests?: (guests: IHeaderSearchFilterGuests) => void;
  onChangeVehicle?: (vehicle?: IVehicle) => void;
  onChangeVehicleCampgrounds?: (vehicle?: IVehicleCampgrounds) => void;
  onChangeLodgingCampgrounds?: (vehicle?: ILodgingCampgrounds) => void;
  openedTab?: IHeaderMenu;
  popularDestinations?: { label: string }[];
  isCategoryEnabled?: boolean;
  isStayFilterEnabled?: boolean;
  isGuestOccupancyAvailable?: boolean;
}

const DesktopUniversalSearch: React.FC<DesktopUniversalSearchProps> = ({
  onSubmit,
  location,
  address,
  dates,
  guests,
  vehicle,
  vehicleCampgrounds,
  lodgingCampgrounds,
  recentSearches = [],
  maxRecentSearches,
  loading = false,
  onChangeAddress,
  onChangeDates,
  onChangeGuests,
  onConfirmAddress,
  onConfirmDates,
  onConfirmGuests,
  onChangeVehicle,
  onChangeVehicleCampgrounds,
  onChangeLodgingCampgrounds,
  openedTab,
  popularDestinations = [],
  isCategoryEnabled,
  isStayFilterEnabled,
  handleRecentSearchNavigation,
  isGuestOccupancyAvailable,
}) => {
  const intl = useIntl();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);
  const [selectedTab, setSelectedTab] = useState<SEARCH_TAB | null>(null);
  const [addressValue, setAddressValue] = useState(address || '');
  const placesOption = {
    text: addressValue,
    ...(location ? { location: [location.lng, location.lat] } : {}),
    types: 'country,region,postcode,district,place,locality,neighborhood,address',
  };
  const places = useMapboxQuery(addressValue.length < 3 ? null : placesOption);
  const suggestions = places.map(p => ({ label: p.place_name, value: p }));

  const formattedDates = useMemo<string | null>(() => {
    if (!dates?.from || !dates?.to) {
      return null;
    }

    return formatDateRange(dates.from.toString(), dates.to.toString(), false);
  }, [dates]);

  useEffect(() => {
    if (address !== addressValue) {
      setAddressValue(address || '');
    }
  }, [address, addressValue]);

  const suggestionsGroupName = intl.formatMessage({
    defaultMessage: 'Matches',
    id: 'VLIHLz',
    description: 'Global Header > Search',
  });

  const options: IOption[] = [
    ...suggestions.map(option => ({
      ...option,
      groupName: suggestionsGroupName,
    })),
    ...recentSearches.map(option => ({
      ...option,
      groupName: intl.formatMessage({
        defaultMessage: 'Recent Searches',
        id: 'yLl55w',
        description: 'Global Header > Search',
      }),
    })),
    ...popularDestinations.map(option => ({
      ...option,
      groupName: intl.formatMessage({
        defaultMessage: 'Popular Destinations',
        id: 'zWHSAh',
        description: 'Global Header > Search',
      }),
    })),
  ];

  const handleAddressChange = (_e: React.ChangeEvent<Record<string, string>>, value: string) => {
    onChangeAddress?.(value);
    setAddressValue(value);
    setSurfacedLocation(LocationGroupType.OTHER);
  };

  const handleAddressSelect = (_e: React.ChangeEvent<Record<string, string>>, value: any) => {
    if (value?.url) {
      handleRecentSearchNavigation?.(value.url);
      return;
    }
    const groupName = mapLocationLabelToGroupName(value?.groupName);
    setSurfacedLocation(groupName);

    if (openedTab?.tab === EHeaderTab.TOWABLE) {
      if (!vehicle && value?.label) {
        setSelectedTab(SEARCH_TAB.VEHICLE);
      }
    } else {
      if (!dates && value?.label) {
        setSelectedTab(SEARCH_TAB.DATES);
      }
    }

    if (dates && value?.label) {
      onConfirmAddress?.(value.label);
      setSelectedTab(null);
    }
  };

  const handleChangeDate = (value?: IHeaderSearchFilterDates) => {
    if ((value?.from && value?.to) || isEmpty(value)) {
      onChangeDates?.(value);
    }
  };

  const handleChangeVehicle = (v?: IVehicle) => {
    if (v?.year && v?.model && v?.make) {
      setSelectedTab(SEARCH_TAB.DATES);
    }
    onChangeVehicle?.(v);
  };

  const handleChangeVehicleCampgrounds = (v?: IVehicleCampgrounds) => {
    onChangeVehicleCampgrounds?.(v);
  };

  const handleChangeLodgingCampgrounds = (lodging?: ILodgingCampgrounds) => {
    onChangeLodgingCampgrounds?.(lodging);
  };

  const handleOpen = () => {
    setSelectedTab(SEARCH_TAB.LOCATION);
  };

  const { getInputProps, getListboxProps, getOptionProps, groupedOptions, focused } =
    useAutocomplete({
      inputValue: addressValue,
      options,
      filterOptions: (autocompleteOptions, autocompleteState) => {
        const input = stripDiacritics(autocompleteState.inputValue.trim().toLowerCase());

        return !input
          ? autocompleteOptions
          : autocompleteOptions.filter(autocompleteOption => {
              // Do not filter suggestions, those are already filtered by the API
              if (autocompleteOption.groupName === suggestionsGroupName) {
                return autocompleteOption;
              }

              const candidate = stripDiacritics(
                autocompleteState.getOptionLabel(autocompleteOption).trim().toLowerCase(),
              );

              return candidate.indexOf(input) > -1;
            });
      },
      getOptionLabel: option => option.label,
      groupBy: option => option.groupName,
      onInputChange: handleAddressChange,
      onChange: handleAddressSelect,
      onOpen: handleOpen,
      freeSolo: true,
      open: selectedTab === SEARCH_TAB.LOCATION,
    });

  const handleClickTab = (tab: SEARCH_TAB) => {
    if (tab !== SEARCH_TAB.LOCATION && tab === selectedTab) {
      setSelectedTab(null);
    } else {
      setSelectedTab(tab);
    }
    setIsComponentVisible(true);
  };

  useEffect(() => {
    if (focused) {
      handleClickTab(SEARCH_TAB.LOCATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused]);

  // action when closing dropdown
  useEffect(() => {
    if (!isComponentVisible) {
      if (selectedTab === SEARCH_TAB.DETAILS && guests) {
        onConfirmGuests?.(guests);
      }
      if (selectedTab === SEARCH_TAB.DATES && dates) {
        onConfirmDates?.(dates);
      }
      setSelectedTab(null);
    }
  }, [isComponentVisible, guests, onConfirmGuests, onConfirmDates, dates, selectedTab]);

  // we may hide some recent searches but require all options to calculate the index into the
  // results for getting option props (onClick handler)
  const allGroupOptions = groupedOptions as unknown as IGroupOption[];
  const groupOptions = limitRecentSearches(
    groupedOptions as unknown as IGroupOption[],
    intl.formatMessage({
      defaultMessage: 'Recent Searches',
      id: 'yLl55w',
      description: 'Global Header > Search',
    }),
    maxRecentSearches,
  );

  const handleSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    onSubmit?.();
    setSelectedTab(null);
  };

  const dateTabText = formattedDates
    ? `${formattedDates} ${dates?.flexible_days ? ` (±${dates?.flexible_days})` : ''}`
    : '';

  const isTowable = openedTab?.tab === EHeaderTab.TOWABLE;
  const isRVSite = openedTab?.tab === EHeaderTab.RV_SITE;
  const isLodgingSite = openedTab?.tab === EHeaderTab.LODGING_SITE;
  const needsExtraRow = isTowable || isRVSite;
  const isDelivery = openedTab?.tab === EHeaderTab.DELIVERABLES;

  return (
    <>
      <form
        className={`
        ${classes.search_homepage}
        ${selectedTab ? 'bg-white shadow-xl' : 'bg-gray-100'}
        ${isStayFilterEnabled ? classes.hasStayFilter : ''}
      `}
        onSubmit={handleSubmit}
        ref={ref}>
        <div className={`${classes.search_wrapper} ${needsExtraRow ? classes.isTowable : ''}`}>
          <LocationSearchInput
            isTowables={needsExtraRow}
            selectedTab={selectedTab}
            className={`${getClassName(selectedTab, SEARCH_TAB.LOCATION, isStayFilterEnabled)} ${
              classes.search_location
            } ${needsExtraRow ? classes.isTowable : ''}`}
            getInputProps={getInputProps}
            isCategoryEnabled={isCategoryEnabled}
            isStayFilterEnabled={isStayFilterEnabled}
          />
          {isTowable && (
            <VehicleDetailTab
              onClick={() => handleClickTab(SEARCH_TAB.VEHICLE)}
              selectedTab={selectedTab}
              vehicle={vehicle}
              isStayFilterEnabled={isStayFilterEnabled}
            />
          )}
          {isRVSite && (
            <VehicleDetailCampgroundsTab
              onClick={() => handleClickTab(SEARCH_TAB.VEHICLE_CAMPGROUNDS)}
              selectedTab={selectedTab}
              vehicle={vehicleCampgrounds}
              isStayFilterEnabled={isStayFilterEnabled}
            />
          )}
          {/* TODO: readd lodging campground if we end up supporting lodging type */}
          <DateTab
            isTowables={needsExtraRow}
            onClick={() => handleClickTab(SEARCH_TAB.DATES)}
            selectedTab={selectedTab}
            selectedDates={dateTabText}
            isStayFilterEnabled={isStayFilterEnabled}
          />
          <GuestsTab
            onClick={() => handleClickTab(SEARCH_TAB.DETAILS)}
            selectedTab={selectedTab}
            guests={guests}
            isStayFilterEnabled={isStayFilterEnabled}
            isGuestOccupancyAvailable={isGuestOccupancyAvailable}
          />
          <SearchButton
            selectedTab={selectedTab}
            loading={loading}
            isTabActive={selectedTab === SEARCH_TAB.DETAILS}
            isCategoryEnabled={isCategoryEnabled}
            isStayFilterEnabled={isStayFilterEnabled}
          />
        </div>
        {selectedTab && (
          <div
            data-tab={selectedTab}
            data-istowable={isTowable}
            className={`bg-white ${classes.searchDropdown}`}>
            {groupOptions.length > 0 && (
              <LocationSearchContent
                open={selectedTab === SEARCH_TAB.LOCATION}
                groupOptions={groupOptions}
                allGroupOptions={allGroupOptions}
                getListboxProps={getListboxProps}
                getOptionProps={getOptionProps}
                isDelivery={isDelivery}
              />
            )}
            <DateContent
              dates={dates}
              onChangeDates={handleChangeDate}
              open={selectedTab === SEARCH_TAB.DATES}
            />
            <DetailsContent
              guests={guests}
              onChangeGuests={onChangeGuests}
              onConfirmGuests={onConfirmGuests}
              onSubmit={onSubmit}
              open={selectedTab === SEARCH_TAB.DETAILS}
              hidePetFriendly={openedTab?.tab === EHeaderTab.PET_FRIENDLY}
              isGuestOccupancyAvailable={isGuestOccupancyAvailable}
            />
            {isTowable ? (
              <VehicleContent
                onChangeVehicle={handleChangeVehicle}
                open={selectedTab === SEARCH_TAB.VEHICLE}
                vehicle={vehicle}
              />
            ) : isRVSite ? (
              <VehicleCampgroundsContent
                onChangeVehicle={handleChangeVehicleCampgrounds}
                open={selectedTab === SEARCH_TAB.VEHICLE_CAMPGROUNDS}
                vehicle={vehicleCampgrounds}
              />
            ) : isLodgingSite ? (
              <LodgingCampgroundsContent
                onChangeLodging={handleChangeLodgingCampgrounds}
                open={selectedTab === SEARCH_TAB.LODGING_CAMPGROUNDS}
                lodging={lodgingCampgrounds}
              />
            ) : null}
          </div>
        )}
      </form>
    </>
  );
};

export default DesktopUniversalSearch;
