import uniq from 'lodash/uniq';
import { useCallback, useMemo } from 'react';

import { useLocalStorage } from '@/hooks/useLocalStorage';
import { guardedJsonParse } from '@/utility/json';

export const MAX_RECENT_SEARCHES = 5;

function parsedObjectIsRecentSearchArray(obj: unknown): obj is string[] {
  return Array.isArray(obj) && obj.every(item => typeof item === 'string');
}

export function useRecentSearches(): [string[], { addRecentSearch: (search: string) => void }] {
  const [recentSearchesString, { update }] = useLocalStorage('recentSearches');

  const recentSearchesParsed = useMemo(() => {
    return recentSearchesString
      ? guardedJsonParse(parsedObjectIsRecentSearchArray, recentSearchesString) || []
      : [];
  }, [recentSearchesString]);

  const addRecentSearch = useCallback(
    (recentSearch: string) => {
      update(
        JSON.stringify(uniq([recentSearch, ...recentSearchesParsed]).slice(0, MAX_RECENT_SEARCHES)),
      );
    },
    [recentSearchesParsed, update],
  );

  return useMemo(
    () => [recentSearchesParsed, { addRecentSearch }],
    [recentSearchesParsed, addRecentSearch],
  );
}
