import { ESearchFilters } from '@/constants/searchFilters';
import { TQueryParams } from '@/services/searchApiRequest';

import { camelToSnakeCase } from './strings';

// Safelist(object) of only search related filters that should get sent to the api.
export const filterSafeQuery = (query: TQueryParams, toCamelCase = true) =>
  Object.keys(query)
    .filter(key => Object.values(ESearchFilters).includes(key as ESearchFilters))
    .reduce(
      (acc, key) => ({ ...acc, [toCamelCase ? camelToSnakeCase(key) : key]: query[key] }),
      {},
    );
