import { TCurrency } from '@/config/locales';
import { TFlexibleDateRange } from '@/services/analytics/search/types';
import { EShareMethodType } from '@/services/types/referral/referral';

import { ERentalType } from '../types';

export interface IListingEvent {
  rentalID: number;
  rentalType: ERentalType;
  isCampground: boolean;
  source?: string;
  reviewsCount?: number;
  reviewsAverage?: number;
  isInstabook?: boolean;
  pricePerNight?: number;
  basePricePerNight?: number;
  discountPercentOff?: number;
  listingPlacement?: number;
  currentPageViewed?: number;
  imagePlacement?: number;
  totalImagesAvailable?: number;
  isGalleryView?: boolean;
  shareMethod?: EShareMethodType;
  totalNights?: number;
  departureDate?: number;
  returnDate?: number;
  handoffMethod?: 'pickup' | 'delivery';
  totalCost?: number;
  subcategoryReviews?: Record<string, number>;
  ownerReviews?: Record<string, number>;
  insuranceCoverage?: string;
  insuranceCost?: number;
  addOnsCost?: number | null;
  addOnsSelected?: string[] | null;
  mileageFeesCost?: number;
  currencyType?: TCurrency;
  previousCost?: number;
  isOwnerShare?: boolean;
  flexibleDateRange?: TFlexibleDateRange;
  listingUrl?: string;
  rvImageUrl?: string;
  rvName?: string;
  totalNearbyCampgrounds?: number;
  displaySiteCategoryType?: string;
  siteCategoryType?: ERentalType;
  isOutdoorsyStay?: boolean;
}

export enum EListingEventName {
  LISTING_VIEWED = 'Listing Viewed',
  LISTING_SELECTED = 'Listing Selected',
  LISTING_GALLERY_VIEWED = 'Listing Gallery Viewed',
  LISTING_IMAGE_VIEWED = 'Listing Images Viewed',
  LISTING_SHARED = 'Listing Shared',
  LISTING_QUOTE_UPDATED = 'Listing Quote Updated',
  LISTING_FAVORITED = 'Listing Favorited',
  LISTING_UNFAVORITED = 'Listing Unfavorited',
  LISTING_AVAILABILITY_CHECKED = 'Listing Availability Checked',
  SEE_ALL_REVIEWS_CHECKED = 'See All Reviews Selected',
  CAROUSEL_RENTAL_INTERACTED = 'Carousel Rental Interacted',
  LISTING_MESSAGE_HOST_CLICKED = 'Listing Message Host Clicked',
  MESSAGE_SEND = 'Message Sent',
  DISCOUNT_PROMOTION_ACCEPTED = 'Discount Promotion Accepted',
}

export enum ESearchSort {
  RECOMMENDED = 'recommended',
  PRICE_HIGH = 'price_high_to_low',
  PRICE_LOW = 'price_low_to_high',
}

export enum EListingSource {
  SEARCH_LISTING = 'search_listing',
  EXPLORE_THIS_VEHICLE = 'explore_this_vehicle',
  GRID_LAYOUT = 'grid_layout',
  MAP_PREVIEW = 'map_preview',
  LOCALITY = 'locality',
  TRIPS = 'trips',
  HOME_CAROUSEL = 'home_carousel',
  CAMPGROUND_CAROUSEL = 'campground_carousel',
  CAMPGROUND = 'campgrounds',
  PROMO = 'promo',
  HOMEPAGE_DESTINATIONS = 'homepage_destinations',
  HOMEPAGE_RECOMMENDATIONS = 'homepage_recommendations',
  WP_DESTINATIONS = 'wp_destinations',
  LISTING = 'listing',
  LISTING_PAGE = 'listing_page',
}

export enum EProgression {
  FORWARD = 'forward',
  BACKWARD = 'backward',
}

export enum ENavigationType {
  SWIPE = 'swipe',
  CLICK = 'click',
}
