import { EIconButtonSize, EIconButtonVariant, IconButton } from '@outdoorsyco/bonfire';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';

import DatePicker, { IDatePickerProps } from '../DatePicker/DatePicker';

interface IDatePickerModalContent extends IDatePickerProps {
  initialFrom?: Date;
}

export const DatePickerModalContent = ({
  onChange,
  disabledDays,
  disableBefore,
  dateFrom,
  dateTo,
  minimumDays,
  disableFrom,
  numberOfMonths,
  isStacked,
  initialFrom,
  ...rest
}: IDatePickerModalContent) => {
  const [initialMonth, setInitialMonth] = useState<Date | undefined>(initialFrom);

  const mobileCanGoToPrevMonth = useMemo(
    () => !dayjs().isSame(initialMonth, 'month'),
    [initialMonth],
  );

  const mobileCanGoToNextMonth = useMemo(
    () => !dayjs().add(22, 'month').isSame(initialMonth, 'month'),
    [initialMonth],
  );

  return (
    <>
      {isStacked && mobileCanGoToPrevMonth && (
        <div className="flex justify-center">
          <IconButton
            icon="General.Caret.Up"
            variant={EIconButtonVariant.Ghost}
            size={EIconButtonSize.Medium}
            onClick={() => {
              setInitialMonth?.(dayjs(initialMonth).subtract(1, 'month').startOf('month').toDate());
            }}
          />
        </div>
      )}

      <DatePicker
        range
        numberOfMonths={numberOfMonths}
        isStacked={isStacked}
        onChange={onChange}
        disabledDays={disabledDays}
        disableBefore={disableBefore}
        dateTo={dateTo}
        dateFrom={dateFrom}
        toMonth={dayjs().add(2, 'year').toDate()}
        minimumDays={minimumDays}
        month={initialMonth}
        onMonthChange={setInitialMonth}
        disableFrom={disableFrom}
        {...rest}
      />

      {isStacked && mobileCanGoToNextMonth && (
        <div className="flex justify-center">
          <IconButton
            icon="General.Caret.Down"
            variant={EIconButtonVariant.Ghost}
            size={EIconButtonSize.Medium}
            onClick={() => {
              setInitialMonth?.(dayjs(initialMonth).add(1, 'month').startOf('month').toDate());
            }}
          />
        </div>
      )}
    </>
  );
};
