import pick from 'lodash/pick';
import querystring, { ParsedUrlQuery } from 'querystring';
import url, { UrlObject } from 'url';

import { domains } from '@/config/locales';
import { NextRouter } from '@/hooks/useRouter';

export const getParamAsString = (value: string | string[] | undefined): string | undefined =>
  value ? ([] as string[]).concat(value)[0] : undefined;

// Due to a cloudflare query param stripping issue, we need to use
// getParams(router) instead of router.query, as this will extract
// the query params from router.asPath
export const getParams = (router: NextRouter, includeRouteParams?: boolean): ParsedUrlQuery => {
  let parsed = includeRouteParams ? { ...router.query } : {};
  try {
    parsed = { ...parsed, ...getUrlParams(router.asPath) };
  } catch {
    // do nothing
  }
  return parsed;
};

export const getUrlParams = (urlStr: string): ParsedUrlQuery => {
  const firstQuestionMarkIndex = urlStr.indexOf('?');
  if (firstQuestionMarkIndex == -1) return {};
  return querystring.parse(urlStr.slice(firstQuestionMarkIndex + 1));
};

const UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'cam', 'subcam', 'gclid', 'source'];
const AFFILIATE_PARAMS = ['hotid', 'aff_sub', 'odc_partner', 'offer_id'];
const PARAMS_TO_PERSIST = [...AFFILIATE_PARAMS, ...UTM_PARAMS];
const ALLOWED_HOSTS = [...domains, 'staging.outdoorsy.com', 'preview.outdoorsy.com'];

export const addUTMParams = (
  urlStrOrObj: string | UrlObject,
  router: NextRouter,
): string | UrlObject => {
  const inputIsStr = typeof urlStrOrObj === 'string';
  const urlStr = inputIsStr ? urlStrOrObj : url.format(urlStrOrObj);
  const urlObj = inputIsStr ? url.parse(urlStrOrObj) : urlStrOrObj;

  // Do not add UTM params to non http protocols (e.g. tel:)
  if (urlObj.protocol) {
    if (!urlObj.protocol.startsWith('http')) {
      return urlStrOrObj;
    }
  }
  // Do not add UTM params to disallowed hosts (e.g. some-unsupported-external-site.com)
  if (urlObj.host) {
    const incomingHost = urlObj.host;
    if (!ALLOWED_HOSTS.some(host => host.endsWith(incomingHost))) {
      return urlStrOrObj;
    }
  }

  const utmParams = pick(getUrlParams(router.asPath), PARAMS_TO_PERSIST);
  const urlParams = getUrlParams(urlStr);
  const finalParams = querystring.stringify({ ...utmParams, ...urlParams });
  if (!finalParams) return urlStrOrObj;
  const finalURLStr = `${urlStr.split('?')[0]}?${finalParams}`;
  return inputIsStr ? finalURLStr : url.parse(finalURLStr);
};
