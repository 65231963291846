/**
 * Removes all falsy elements from an array and returns with correct type
 */
export const filterEmpty = <T = unknown>(list: Array<T | 0 | null | undefined>) =>
  list.filter(it => it !== null && it !== undefined) as T[];

/**
 * Move item from old index to new index
 */
export const moveListItemByIndex = <T = unknown>(
  list: Array<T>,
  prevIndex: number,
  newIndex: number,
) => {
  if (list.length === 0) {
    return list;
  }
  const listCopy = [...list];

  // @ts-expect-error fixable: unchecked index access
  listCopy.splice(newIndex, 0, listCopy.splice(prevIndex, 1)[0]);
  return listCopy;
};

export const replaceListItemOrAdd = <T = unknown>(
  list: Array<T>,
  item: T,
  predicate: (value: T) => boolean,
) => {
  const patchedItemIndex = list.findIndex(predicate);

  if (patchedItemIndex > -1) {
    const listCopy = [...list];
    listCopy[patchedItemIndex] = item;
    return listCopy;
  }

  return [...list, item];
};
