import dayjs from 'dayjs';
import Cookies from 'js-cookie';

import { getCookie } from '@/utility/cookie';
import { capitalize, compact } from '@/utility/emberUtils';
import { isSSR } from '@/utility/isSSR';
import { getSessionCookie } from '@/utility/session';

// Continue sending legacy experiments to legacy events.
// Note that this app can no longer create/setup legacy experiments.
// These are being sent for historical reasons.
const getLegacyExperimentsCookieObject = () => {
  const experimentsCookie = getCookie('ember-experiments');
  if (!experimentsCookie) return {};
  try {
    return JSON.parse(decodeURI(experimentsCookie));
  } catch {
    return {};
  }
};

export const trackEvent = options => {
  if (isSSR()) return;
  const experimentsObject = getLegacyExperimentsCookieObject();
  // Cloudflare workers are controlling this cookie
  // We want to combine it with existing flagr experiments
  // for proper tracking in amplitude.
  const ribbitValue = Cookies.get('ribbitR1Cookie');
  if (typeof ribbitValue === 'string') {
    experimentsObject['ribbitR1'] = ribbitValue;
  }

  if (!options.uid) {
    const session = getSessionCookie();
    if (session?.authenticated?.user_id) {
      options.uid = session.authenticated.user_id;
    }
  }

  const trackingOptions = { ...options, experiments: experimentsObject };

  _trackODCMetricsEvent(trackingOptions);
  _trackGTMEvent(trackingOptions);
};

export const trackPage = (options = {}) => {
  if (isSSR()) return;
  _trackODCMetricsPage(options);
  _trackGTMPage(options);
};

export const identify = options => {
  const { userId, isAdmin } = options;
  if (userId == null || isAdmin == null) return;
  window.Outdoorsy.metrics.push({
    type: 'alias',
    uid: userId,
    is_admin: isAdmin,
  });
};

const _trackODCMetricsPage = _options => {
  window.Outdoorsy.metrics.push({
    source: 'ribbit',
    type: 'page',
  });
};

const _trackGTMPage = options => {
  const compactedOptions = compact(options);
  const sendEvent = {
    event: compactedOptions['event'] || 'pageview',
  };

  const pageEvent = { ...sendEvent, ...compactedOptions };

  window.dataLayer?.push(pageEvent);

  return pageEvent;
};

const _trackODCMetricsEvent = (options = {}) => {
  const trackingOptions = { source: 'ribbit', ...options, type: 'event' };
  if (!trackingOptions.rental_category) {
    trackingOptions.rental_category = 'rv';
  }

  window.Outdoorsy.metrics.push(trackingOptions);
};
const _trackGTMEvent = (options = {}) => {
  const compactedOptions = compact(options);
  const gtmEvent = { event: compactedOptions['event'] };

  delete compactedOptions['event'];

  for (let key in compactedOptions) {
    const capitalizedKey = capitalize(key);
    gtmEvent[`event${capitalizedKey}`] = compactedOptions[key];
  }

  window.dataLayer?.push(gtmEvent);

  return gtmEvent;
};

export const trackBookingEvent = (event, booking, currentUser) => {
  if (!booking) return;
  let bookingId = booking.id;
  const userId = currentUser?.id;
  const isStay = !!booking.rental_summary?.stay_summary;
  let from = dayjs(booking.from).format('YYYY-MM-DD');
  let to = dayjs(booking.to).format('YYYY-MM-DD');
  let role =
    booking.renter_id === userId ? 'renter' : booking.owner_id === userId ? 'owner' : 'guest';

  trackEvent({
    event,
    booking_id: bookingId,
    from,
    to,
    role,
    rental_category: isStay ? 'stay' : 'rv',
  });
};
