import { useRouter } from '@/hooks/useRouter';
import { ERentalType } from '@/services/analytics/types';

export const useHostingType = () => {
  const router = useRouter();
  const isRentalRV = router.pathname === '/become-a-host/rv';
  const isRentalStay = router.pathname === '/become-a-host/stay';
  const isRentalCampground = router.pathname === '/become-a-host/campground';

  let rentalType: ERentalType;
  if (isRentalRV) {
    rentalType = ERentalType.RV;
  } else if (isRentalStay) {
    rentalType = ERentalType.STAY;
  } else {
    rentalType = ERentalType.CAMPGROUND;
  }
  return { isRentalRV, rentalType, isRentalStay, isRentalCampground };
};
