import { IAddonProposalItem } from '@/components/trips/Addons/AddonsContainer';
import { IBooking, IItem } from '@/services/types/booking/details';
import {
  IAddonProposal,
  IDeliveryProposal,
  IProposal,
  ProposalTypes,
} from '@/services/types/proposals/proposals';

import { IUserResponse } from '../auth/userMini';

interface IRenterDeliveryProposalsAndPermissions {
  canRequestDelivery: boolean;
  canCancelRequestedDelivery?: boolean;
  deliveryProposal?: IDeliveryProposal;
  ownerDeliveryProposal?: IDeliveryProposal;
  canReviewOwnerDeliveryProposal?: boolean;
}

interface IRenterAddonProposalsAndPermissions {
  canRequestAddons: boolean;
  canCancelRequestedAddons?: boolean;
  canReviewOwnerAddonProposals?: boolean;
  renterProposals?: IProposal[] | null;
  ownerProposals?: IProposal[] | null;
}

export const extractDateChangeData = (
  booking: Partial<IBooking> | null,
  currentUser: IUserResponse | null,
  campgroundDateChangeIsAvailable: boolean,
) => {
  if (!booking || !currentUser) return { canUpdateDates: false, dateProposal: null };

  if (booking.rental_summary?.campsite_summary && !campgroundDateChangeIsAvailable) {
    // Support date change for campground bookings only if experiment in on
    return { canUpdateDates: false, dateProposal: null };
  }

  const bookingStatuses = booking.rental_summary?.campsite_summary
    ? ['approved', 'imminent']
    : ['approved', 'imminent', 'handed_off'];

  const canUpdateDates =
    booking.renter_id === currentUser.id && bookingStatuses.includes(booking.status || '');

  const dateProposal = booking.proposals?.find(
    proposal =>
      proposal.owner_id === currentUser.id &&
      proposal.action === ProposalTypes.ChangeDates &&
      proposal.status === 'open',
  );

  // TODO figure out why .find() above is not refining the type to IChangeDateProposal
  if (dateProposal?.action === ProposalTypes.ChangeDates) {
    return { canUpdateDates, dateProposal };
  }
  return { canUpdateDates, dateProposal: null };
};

export const getRenterAddonProposalsAndPermissions = (
  booking: Partial<IBooking> | null,
  currentUser: IUserResponse | null,
): IRenterAddonProposalsAndPermissions => {
  if (!booking || !currentUser)
    return {
      canRequestAddons: false,
      canCancelRequestedAddons: false,
      canReviewOwnerAddonProposals: false,
      renterProposals: null,
      ownerProposals: null,
    };
  const renterProposals = booking.proposals?.filter(
    proposal =>
      proposal.owner_id === currentUser.id &&
      proposal.status === 'open' &&
      [ProposalTypes.AddItem, ProposalTypes.RemoveItem].includes(proposal.action || ''),
  );

  const ownerProposals = booking.proposals?.filter(
    proposal =>
      proposal.owner_id === booking.owner_id &&
      !proposal.renter_acknowledged &&
      [ProposalTypes.AddItem, ProposalTypes.RemoveItem].includes(proposal.action || ''),
  );

  const matchStatus = Boolean(
    ['approved', 'imminent', 'negotiating'].includes(booking.status || ''),
  );

  const canReviewOwnerAddonProposals =
    booking.renter_id === currentUser.id && ownerProposals && ownerProposals.length > 0;

  const canRequestAddons =
    !canReviewOwnerAddonProposals && booking.renter_id === currentUser.id && matchStatus;

  const canCancelRequestedAddons =
    booking.renter_id === currentUser.id &&
    renterProposals &&
    renterProposals.length > 0 &&
    matchStatus;

  return {
    canRequestAddons,
    canCancelRequestedAddons,
    canReviewOwnerAddonProposals,
    renterProposals,
    ownerProposals,
  };
};

export const getPayloadFromBookingAddons = (bookingAddons: IItem[]) => {
  const bookingAddonsProposals: IAddonProposalItem[] = [];

  bookingAddons.forEach(a => {
    const item = {
      object_id: a.id,
      object_type: 'item',
      action: ProposalTypes.RemoveItem,
      details: {
        id: a.id,
        name: a.name,
        price: a.price,
        daily: a.daily,
        description: a.description,
        quantity: a.count,
      },
    };

    bookingAddonsProposals.push(item);
  });

  return bookingAddonsProposals;
};

export const getPayloadFromRemovedAddons = (removedAddonProposals: IAddonProposal[]) => {
  return removedAddonProposals.map(i => {
    // we can't directly use the removed addons as for the backend those items are closed
    // to get around this create a new add_item proposal and negate the total
    return {
      object_id: i.details?.id,
      object_type: i.object_type,
      action: ProposalTypes.AddItem,
      details: {
        id: i.details?.id,
        name: i.details?.name,
        description: i.details?.description,
        price: i.details?.price,
        quantity: i.details?.quantity,
      },
    };
  });
};

export const getRenterDeliveryProposalAndPermissions = (
  booking: Partial<IBooking> | null,
  currentUser: IUserResponse | null,
): IRenterDeliveryProposalsAndPermissions => {
  if (!booking || !currentUser)
    return {
      canRequestDelivery: false,
      canCancelRequestedDelivery: false,
      canReviewOwnerDeliveryProposal: false,
    };
  const proposal = booking.proposals?.find(
    p =>
      p.owner_id === currentUser.id &&
      p.status === 'open' &&
      [ProposalTypes.AddDelivery, ProposalTypes.RemoveDelivery].includes(p.action || ''),
  );
  const openOwnerProposal = booking.proposals?.find(
    p =>
      p.owner_id === booking.owner_id &&
      p.status === 'open' &&
      !p.renter_acknowledged &&
      [ProposalTypes.AddDelivery, ProposalTypes.RemoveDelivery].includes(p.action || ''),
  );
  const ownerDeliveryProposal = openOwnerProposal && (openOwnerProposal as IDeliveryProposal);
  const deliveryProposal = proposal && (proposal as IDeliveryProposal);
  const matchStatus = Boolean(
    ['approved', 'imminent', 'negotiating'].includes(booking.status || ''),
  );

  const canRequestDelivery = booking.renter_id === currentUser.id && matchStatus;
  const canCancelRequestedDelivery =
    booking.renter_id === currentUser.id && deliveryProposal && matchStatus;
  const canReviewOwnerDeliveryProposal =
    ownerDeliveryProposal && booking.renter_id === currentUser.id;

  return {
    canRequestDelivery,
    canCancelRequestedDelivery,
    deliveryProposal,
    ownerDeliveryProposal,
    canReviewOwnerDeliveryProposal,
  };
};
