import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { TCurrency } from '@/config/locales';
import { useLocaleSettings } from '@/hooks/useLocaleSettings';
import { useRouter } from '@/hooks/useRouter';
import { trackNavClickedEvent } from '@/services/analytics/header';
import { formatCurrency } from '@/utility/currency';
import { openSessionModal } from '@/utility/session';

import GlobalHeaderNavDestinations from './GlobalHeaderNavDestinations';
import GlobalHeaderNavMenuItem from './GlobalHeaderNavMenuItem/GlobalHeaderNavMenuItem';
import GlobalHeaderNavMyAccount from './GlobalHeaderNavMyAccount';
import GlobalHeaderNavMyAccountAvatar from './GlobalHeaderNavMyAccountAvatar';
import GlobalHeaderNavTravelJournal from './GlobalHeaderNavTravelJournal';

enum ESubmenus {
  DESTINATIONS = 'destinations',
  TRAVEL_JOURNAL = 'travel-journal',
  MY_ACCOUNT = 'my-account',
}

interface IProps {
  isNewHomePage?: boolean;
  noSearchNavbar?: boolean;
  isTransparent?: boolean;
  authenticated?: boolean;
  hasUnreadMessages?: boolean;
  hasPendingReviews?: boolean;
  isOwner?: boolean;
  userFirstName?: string;
  userLastName?: string;
  userAvatarUrl?: string;
  userCredits?: number;
  userCurrency?: TCurrency;
  isHeaderWhite?: boolean;
  onClickSignout: () => void;
  onClickSubItem?: () => void;
}

const GlobalHeaderNav: React.FC<IProps> = ({
  isNewHomePage = false,
  noSearchNavbar = false,
  isTransparent = false,
  authenticated = false,
  hasUnreadMessages = false,
  hasPendingReviews = false,
  isOwner = false,
  userFirstName,
  userLastName,
  userAvatarUrl,
  userCredits,
  userCurrency,
  isHeaderWhite,
  onClickSignout,
  onClickSubItem,
}) => {
  const intl = useIntl();
  const { locale } = useLocaleSettings();
  const { pathname } = useRouter();

  const [openSubmenu, setOpenSubmenu] = useState<ESubmenus | null>(null);
  const newBecomeAHostPage = pathname.includes('/become-a-host/');
  const isBAHLandingPage = pathname === '/become-a-host';

  const handleOpenSubmenu = (submenu: ESubmenus) => () => {
    setOpenSubmenu(submenu);
  };

  const handleCloseSubmenu = () => {
    setOpenSubmenu(null);
  };

  const handleClickLogin = () => {
    openSessionModal('login');
  };

  const handleClickSignup = () => {
    openSessionModal('signup');
  };

  // This effect depends on isAuthenticated because when the user logout and then login again, it misses the ref.
  // The effect prevents reopening the submenu in the wrong place.
  useEffect(() => {
    setOpenSubmenu(null);
  }, [setOpenSubmenu, authenticated]);

  const becomeAHostText = () => {
    if (newBecomeAHostPage) {
      return intl.formatMessage({
        defaultMessage: 'Try hosting today',
        id: '0Q7HS7',
        description: 'Global Header Nav: Hosting flow link',
      });
    } else {
      return intl.formatMessage({
        defaultMessage: 'Become a host',
        id: '+ViN7X',
        description: 'Global Header Nav: List my RV link',
      });
    }
  };

  const referAFriendText = () => {
    if (userCredits) {
      return intl.formatMessage(
        {
          defaultMessage: 'Credits: {amount}',
          id: 'kQI740',
          description: 'Global Header Nav: RAF link',
        },
        {
          amount: formatCurrency({
            priceInCents: userCredits,
            digits: 2,
            currency: userCurrency || 'USD',
          }),
        },
      );
    } else {
      return intl.formatMessage({
        defaultMessage: 'Get $75',
        id: 'UnhYZm',
        description: 'Global Header Nav: RAF link',
      });
    }
  };

  return (
    <nav>
      <ul className="flex items-center" role="menubar">
        {['en-us', 'en-ca', 'fr-ca'].includes(locale) && !isBAHLandingPage && (
          <>
            {noSearchNavbar && (
              <GlobalHeaderNavMenuItem
                data-testid="travel-journal-btn"
                label={intl.formatMessage({
                  defaultMessage: 'Travel Journal',
                  id: 'G/wCJJ',
                  description: 'Global Header Nav: Travel Journal link',
                })}
                submenu={<GlobalHeaderNavTravelJournal />}
                onClick={handleOpenSubmenu(ESubmenus.TRAVEL_JOURNAL)}
                onCloseSubmenu={handleCloseSubmenu}
                submenuOpen={openSubmenu === ESubmenus.TRAVEL_JOURNAL}
                onTransparentHeader={isTransparent}
                newNavMenuItem
              />
            )}

            <GlobalHeaderNavMenuItem
              data-testid="destinations-btn"
              label={intl.formatMessage({
                defaultMessage: 'Destinations',
                id: 'nssesN',
                description: 'Global Header Nav: Destinations link',
              })}
              submenu={<GlobalHeaderNavDestinations />}
              onClick={handleOpenSubmenu(ESubmenus.DESTINATIONS)}
              onCloseSubmenu={handleCloseSubmenu}
              submenuOpen={openSubmenu === ESubmenus.DESTINATIONS}
              onTransparentHeader={isTransparent}
              newNavMenuItem
            />
          </>
        )}

        {authenticated ? (
          <>
            {userCredits !== undefined && (
              <GlobalHeaderNavMenuItem
                data-testid="refer-a-friend-btn"
                href="/referral/renter"
                onClick={() => {
                  trackNavClickedEvent({
                    clickedLink: 'referral credits',
                    isInDropdown: false,
                    dropdownHeader: 'My Account',
                  });
                }}
                label={referAFriendText()}
                onTransparentHeader={isTransparent}
                secondaryButtonVariant={isNewHomePage}
                asDesktopButton
                newNavMenuItem
                rafLink
              />
            )}

            <GlobalHeaderNavMenuItem
              data-testid="my-account-btn"
              label={intl.formatMessage({
                defaultMessage: 'My account',
                id: 'cw3vOs',
                description: 'Global Header Nav: My account link',
              })}
              content={
                <GlobalHeaderNavMyAccountAvatar
                  firstName={userFirstName}
                  lastName={userLastName}
                  avatarUrl={userAvatarUrl}
                />
              }
              submenu={
                <GlobalHeaderNavMyAccount
                  isOwner={isOwner}
                  userName={userFirstName}
                  hasUnreadMessages={hasUnreadMessages}
                  hasPendingReviews={hasPendingReviews}
                  onClickSignout={onClickSignout}
                  onClickItem={onClickSubItem}
                />
              }
              onClick={handleOpenSubmenu(ESubmenus.MY_ACCOUNT)}
              onCloseSubmenu={handleCloseSubmenu}
              submenuOpen={openSubmenu === ESubmenus.MY_ACCOUNT}
              onTransparentHeader={isTransparent}
              newNavMenuItem
            />
          </>
        ) : (
          <>
            <GlobalHeaderNavMenuItem
              data-testid="login-btn"
              onClick={handleClickLogin}
              label={intl.formatMessage({
                defaultMessage: 'Log in',
                id: '5+5g80',
                description: 'Global Header Nav: Log in link',
              })}
              onTransparentHeader={isTransparent}
              newNavMenuItem
            />

            {!isBAHLandingPage && (
              <GlobalHeaderNavMenuItem
                data-testid="signup-btn"
                onClick={handleClickSignup}
                label={intl.formatMessage({
                  defaultMessage: 'Sign up',
                  id: 'kZjbOt',
                  description: 'Global Header Nav: Sign up link',
                })}
                onTransparentHeader={isTransparent}
                newNavMenuItem
              />
            )}
          </>
        )}

        {!authenticated && !isBAHLandingPage && (
          <GlobalHeaderNavMenuItem
            data-testid="list-my-rv-btn"
            href="/become-a-host"
            label={becomeAHostText()}
            onTransparentHeader={isTransparent}
            secondaryButtonVariant={isNewHomePage}
            asDesktopButton
            newNavMenuItem
            newBecomeAHostPage={newBecomeAHostPage}
            isHeaderWhite={isHeaderWhite}
          />
        )}
      </ul>
    </nav>
  );
};

export default GlobalHeaderNav;
