import React from 'react';

import { CARET_LARGE } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import Text from '@/components/switchback/Text/Text';

import css from './OptionsInput.module.css';

export interface IOptionsInputItem {
  label: string;
  value: string;
}

export interface TProps
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'name' | 'placeholder'> {
  error?: string;
  'data-testid'?: string;
  name: string;
  placeholder: string;
  selectedOption?: IOptionsInputItem;
  children?: React.ReactNode;
  options?: IOptionsInputItem[];
  valueClassName?: string;
}

const OptionsInput = React.forwardRef<HTMLSelectElement, TProps>(
  (
    {
      children,
      error,
      name,
      options,
      placeholder,
      disabled,
      selectedOption,
      valueClassName = '',
      ...props
    },
    ref,
  ) => {
    const id = `${name}-options-input`;

    return (
      <div className="relative">
        <div className="relative flex items-center">
          <select
            aria-label={props['aria-label'] || placeholder}
            data-has-error={String(!!error)}
            className={css.select}
            id={id}
            ref={ref}
            name={name}
            disabled={disabled}
            {...props}>
            {options ? (
              <>
                <option value="">{placeholder}</option>
                {options.map((option, optionIndex) => (
                  <option key={`${name}-option-${optionIndex}`} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </>
            ) : (
              (children ?? null)
            )}
          </select>
          <label
            aria-hidden
            className={`${css.label} ${disabled ? 'bg-gray-100' : ''}`}
            htmlFor={id}>
            <span
              className={`autoType300 ${
                disabled ? 'text-gray-700' : 'text-gray-500'
              } ${valueClassName}`}>
              {selectedOption?.label || placeholder}
            </span>
            <Icon className="text-gray-900 transform -rotate-90" name={CARET_LARGE} width={12} />
          </label>
        </div>
        {error && (
          <Text
            className={`${css.error} top-full left-0 mt-1`}
            data-testid={`${id}-error`}
            type="paragraph">
            {error}
          </Text>
        )}
      </div>
    );
  },
);

OptionsInput.displayName = 'OptionsInput';

export default OptionsInput;
