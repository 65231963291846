import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Divider from '@/components/switchback/Divider/Divider';
import { ACCESSIBILITY, PET_FRIENDLY } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import Text from '@/components/switchback/Text/Text';

import RequirementItem from './RequirementItem';

interface IRequirementDetailProps {
  petFriendlyValue: boolean;
  accessibleValue: boolean;
  onChangePetFriendly: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeAccessible: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isGuestOccupancyAvailable?: boolean;
}

const RequirementDetail: React.FC<IRequirementDetailProps> = ({
  petFriendlyValue,
  accessibleValue,
  onChangeAccessible,
  onChangePetFriendly,
  isGuestOccupancyAvailable,
}) => {
  const intl = useIntl();
  const labels = {
    accessibility: intl.formatMessage({
      description: 'Guests filter > Accessibility checkbox',
      defaultMessage: 'ADA accessible',
      id: 'hpFUsT',
    }),
    pet: intl.formatMessage({
      description: 'Guests filter > Pet-friendly checkbox',
      defaultMessage: 'Pet-friendly',
      id: 'ZqscfO',
    }),
  };

  return (
    <div className="grow">
      {!isGuestOccupancyAvailable && (
        <Text
          type="paragraph"
          className="mb-5 text-xs font-bold text-gray-500 uppercase semiHighlight">
          <FormattedMessage
            defaultMessage="Requirements"
            id="KFXSg9"
            description="Guests filter > reset button"
          />
        </Text>
      )}

      <RequirementItem
        value={petFriendlyValue}
        onChange={onChangePetFriendly}
        label={labels.pet}
        icon={
          <Icon
            className="mr-2 text-gray-700"
            style={{ width: '18px', height: '18px' }}
            name={PET_FRIENDLY}
          />
        }
      />
      {isGuestOccupancyAvailable && <Divider className="my-4 md:hidden" />}
      <RequirementItem
        value={accessibleValue}
        onChange={onChangeAccessible}
        label={labels.accessibility}
        icon={
          <Icon
            className="mr-2 text-gray-700"
            style={{ width: '18px', height: '18px' }}
            name={ACCESSIBILITY}
          />
        }
      />
    </div>
  );
};

export default RequirementDetail;
