import { useEffect, useState } from 'react';

export enum ScrollDirection {
  up = 'up',
  down = 'down',
}

export const useScrollDirection = ({
  skip = false,
  threshold = 50,
}: {
  skip?: boolean;
  threshold?: number;
}) => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>();

  useEffect(() => {
    if (skip) return;

    let previousScrollYPosition = window.scrollY;

    const scrolledMoreThanThreshold = (currentScrollYPosition: number) =>
      Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;

    const onScroll = () =>
      window.requestAnimationFrame(() => {
        const currentScrollYPosition = window.scrollY;

        if (scrolledMoreThanThreshold(currentScrollYPosition)) {
          setScrollDirection(
            currentScrollYPosition > previousScrollYPosition
              ? ScrollDirection.down
              : ScrollDirection.up,
          );

          previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0;
        }
      });

    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', onScroll);
  }, [skip, threshold]);

  return scrollDirection;
};
