import { IBooking } from '@/services/types/booking/details';

export const CUSTOMER_SUPPORT_PHONE_NUMBER = '+1-877-723-7232';
export const CUSTOMER_SUPPORT_EMAIL = 'support@outdoorsy.co';
export const ROADSIDE_SUPPORT_NUMBER = '+1-877-723-7232';
export const FILE_CLAIM_LINK = 'https://outdoorsy.typeform.com/to/NnBLVa';

export enum EBookingsFilters {
  ABANDONED = 'abandoned',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  PENDING = 'pending',
  UPCOMING = 'upcoming',
}

export const bookingUpdateAttributes: (keyof IBooking)[] = [
  'rental_id',
  'renter_id',
  // 'read',
  'security_deposit',
  'from',
  'from_time',
  'to',
  'to_time',
  'pickup',
  'dropoff',
  'mileage',
  'delivery',
  'generator',
  'cancel_policy',
  // 'stripe_token',
  // 'waive_service_fee',
  'deposit_percentage',
  'minimum_deposit',
  'insurance_coverage',
  'insurance_plan_id',
  'final_payment_due_days',
  'security_deposit_due_days',
  'include_coachnet',
  'checkout_questions',
  'details',
  // 'recalculate',
  'travelers',
  'destination',
  'rental_charge_card_id',
  'security_deposit_card_id',
  // 'decline_reason',
  'require_insurance',
  'discount_code',
  'auto_assign_group',
  'destination_lat',
  'destination_lng',
  // 'message',
  'location_id',
  // 'update_location_address',
  'delivery_usage_item_id',
  'mileage_usage_item_id',
  'generator_usage_item_id',
  'addons',
  'bundle_id',
  'trip_credits_disabled',
];
