// eslint-disable-next-line no-restricted-imports
import router, { NextRouter, useRouter as useNextRouter } from 'next/router';
import { useMemo } from 'react';

import { addUTMParams } from '@/utility/queryParams';

export type { NextRouter };

// Proxy router replace/push so that we can inject UTM params on all transitions.
export function useRouter(): NextRouter {
  const nextRouter = useNextRouter();

  const odRouter = useMemo(() => {
    const nextRouterReplace = nextRouter.replace.bind(nextRouter);
    const nextRouterPush = nextRouter.push.bind(nextRouter);
    const replace: NextRouter['replace'] = function replace(url, ...args) {
      const urlWithUTMParams = addUTMParams(url, nextRouter);
      return nextRouterReplace(urlWithUTMParams, ...args);
    };
    const push: NextRouter['push'] = function push(url, ...args) {
      const urlWithUTMParams = addUTMParams(url, nextRouter);
      return nextRouterPush(urlWithUTMParams, ...args);
    };

    return {
      ...nextRouter,
      replace,
      push,
    };
  }, [nextRouter]);

  return odRouter;
}

const nextRouterReplace = router.replace.bind(router);
const nextRouterPush = router.push.bind(router);
router.replace = function replace(url, ...args) {
  const urlWithUTMParams = addUTMParams(url, router);
  return nextRouterReplace(urlWithUTMParams, ...args);
};
router.push = function push(url, ...args) {
  const urlWithUTMParams = addUTMParams(url, router);
  return nextRouterPush(urlWithUTMParams, ...args);
};

export default router;
