import { defaultLocale, locales, TCurrency, TLocale } from '@/config/locales';
import { TCookieValue } from '@/redux/modules/cookies';

import { getIntl } from './i18n';
const nonUSDollarCurrencies = ['CAD', 'AUD', 'NZD'];

export const formatCurrency = ({
  priceInCents,
  currency,
  digits = 0,
  maxDigits,
  ceil = false,
}: {
  priceInCents?: number | null;
  currency?: string;
  digits?: number;
  maxDigits?: number;
  ceil?: boolean;
}): string => {
  const intl = getIntl();
  let rawPriceInCents = 0;

  if (!currency) {
    return '';
  }

  if (priceInCents) {
    rawPriceInCents = priceInCents;
  }

  const price = ceil ? Math.ceil(rawPriceInCents / 100) : rawPriceInCents / 100;

  if (nonUSDollarCurrencies.includes(currency)) {
    const newFormattedCurrency = intl
      .formatNumber(price, {
        style: 'currency',
        minimumFractionDigits: digits,
        maximumFractionDigits: maxDigits,
        currency,
        currencyDisplay: 'code',
      })
      .replace(
        currency,
        currency === 'AUD' ? currency.slice(0, -2) + '$' : currency.slice(0, -1) + '$',
      )
      .replace(/\xA0/g, '');

    return newFormattedCurrency;
  }

  return intl.formatNumber(price, {
    style: 'currency',
    minimumFractionDigits: digits,
    maximumFractionDigits: maxDigits,
    currency,
  });
};

export const getCurrencyCode = (locale?: string) => {
  const localeCode = locale?.toLocaleLowerCase() as TLocale;
  return localeCode in locales ? locales[localeCode].base_currency : defaultLocale.base_currency;
};

const CURRENCIES = ['USD', 'CAD', 'AUD', 'NZD', 'GBP', 'EUR', 'PLN', 'NOK'];
export const isCurrency = (anyString?: string): anyString is TCurrency => {
  if (!anyString) return false;
  return CURRENCIES.includes(anyString);
};
export const parseCurrencyCookie = (cookieValue?: TCookieValue | null): TCurrency | undefined => {
  if (!cookieValue) return;
  try {
    if (typeof cookieValue === 'string') {
      const cookie = JSON.parse(decodeURIComponent(cookieValue));
      if (isCurrency(cookie?.currency)) {
        return cookie.currency;
      }
    } else {
      if (isCurrency(cookieValue.currency)) {
        return cookieValue.currency;
      }
    }
  } catch {
    // parsing error
  }
};
