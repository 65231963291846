import React from 'react';

import CustomLink from '@/components/switchback/Link/Link';
import { DISCLAIMER_LINKS } from '@/constants/checkout';

const TermsLink: React.FC<React.PropsWithChildren> = ({ children }) => (
  <CustomLink target="_blank" href={DISCLAIMER_LINKS.TERMS}>
    {children}
  </CustomLink>
);

export default TermsLink;
