import React from 'react';

import { EFilterContent } from '../../NavbarSearch/MobileNavbarSearch/MobileSearchModal';

interface IFilterTriggerButtonProps {
  onClick: () => void;
  content: string;
  label: string;
  filterKey: EFilterContent;
  ariaLabel: string;
  ariaExpanded: boolean;
  showLabel?: boolean;
  btnClassName?: string;
}

const FilterTriggerButton: React.FC<IFilterTriggerButtonProps> = ({
  onClick,
  content,
  label,
  ariaLabel,
  ariaExpanded,
  filterKey,
  btnClassName = '',
  showLabel = true,
}) => {
  return (
    <div className="flex flex-col justify-center gap-y-1">
      {showLabel && (
        <label
          htmlFor={`header-${filterKey}-btn`}
          className="block text-xs font-bold text-left text-gray-500 uppercase semiHighlight">
          {label}
        </label>
      )}
      <button
        className={`text-left border-2 border-transparent highlight leading-5 md:h-full md:px-4 md:bg-gray-100 md:font-normal truncate ${btnClassName}`}
        id={`header-${filterKey}-btn`}
        data-testid={`add-${filterKey}-btn`}
        onClick={onClick}
        type="button"
        aria-expanded={ariaExpanded}
        aria-controls={`header-${filterKey}-dialog`}
        aria-label={ariaLabel}>
        {content}
      </button>
    </div>
  );
};

export default FilterTriggerButton;
