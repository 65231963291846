import * as BookingActions from '@/redux/modules/booking/type';
import { ICancellationDetail } from '@/services/types/booking/cancellationDetail';
import { ICard } from '@/services/types/booking/card';
import type { IBooking, IBookingGuest } from '@/services/types/booking/details';
import { IFile } from '@/services/types/booking/file';
import { IBookingHandoff } from '@/services/types/booking/handoff';
import { IInsuranceBundle } from '@/services/types/booking/insuranceBundle';
import { IBookingPayment } from '@/services/types/booking/payment';
import { IBookingService } from '@/services/types/booking/services';
import { IWeather } from '@/services/types/booking/weather';
import { IWithdrawalReason } from '@/services/types/booking/withdrawalReason';
import { IOwner } from '@/services/types/owner';

interface IBookingState {
  details: {
    data: IBooking | null;
    // Payment info from the headers
    meta?: IBookingPayment;
    isFetching: boolean;
    error?: boolean;
  };
  services: {
    data: Array<IBookingService>;
    isFetching: boolean;
    error?: boolean;
  };
  owner: {
    data: Partial<IOwner>;
    isFetching: boolean;
    error: boolean;
  };
  passenger: {
    data?: IBookingGuest[];
    isFetching: boolean;
    error: boolean;
  };
  insuranceBundles: {
    data: IInsuranceBundle[];
    isFetching: boolean;
    error: boolean;
  };
  file: {
    data: Partial<IFile> | undefined;
    isFetching: boolean;
    error: boolean;
  };
  bookingBundle: {
    data: Partial<IInsuranceBundle>;
    isUpdating: boolean;
    isUpdated: boolean;
    error: boolean;
  };
  cards: {
    data?: ICard[];
    isFetching: boolean;
    error: boolean;
  };
  withdrawalReasons: {
    data?: IWithdrawalReason[];
    isFetching: boolean;
    error: boolean;
  };
  addService: {
    isFetching: boolean;
    error: boolean;
  };
  removeService: {
    isFetching: boolean;
    error: boolean;
  };
  addPassenger: 'initial' | 'loading' | 'success' | 'error' | 'already_exists';
  removePassenger: 'initial' | 'loading' | 'success' | 'error';
  updateStatus: {
    isFetching: boolean;
    error: boolean;
  };
  updateDriverVerification: {
    isFetching: boolean;
    error: boolean;
  };
  cancellationDetail: {
    data: ICancellationDetail | null;
    isFetching: boolean;
    error: boolean;
  };
  handoff: {
    data: IBookingHandoff | null;
    isFetching: boolean;
    error: boolean;
  };
  weather: {
    data: IWeather | null;
    isFetching: boolean;
    error: boolean;
  };
}

export const initialState: IBookingState = {
  details: {
    data: null,
    isFetching: false,
  },
  services: {
    data: [],
    isFetching: false,
  },
  owner: {
    data: {},
    isFetching: false,
    error: false,
  },
  passenger: {
    data: [],
    isFetching: false,
    error: false,
  },
  insuranceBundles: {
    data: [],
    isFetching: false,
    error: false,
  },
  file: {
    data: undefined,
    isFetching: false,
    error: false,
  },
  bookingBundle: {
    data: {},
    isUpdated: false,
    isUpdating: false,
    error: false,
  },
  cards: {
    data: [],
    isFetching: false,
    error: false,
  },
  withdrawalReasons: {
    data: [],
    isFetching: false,
    error: false,
  },
  addService: {
    isFetching: false,
    error: false,
  },
  removeService: {
    isFetching: false,
    error: false,
  },
  addPassenger: 'initial',
  removePassenger: 'initial',
  updateStatus: {
    isFetching: false,
    error: false,
  },
  updateDriverVerification: {
    isFetching: false,
    error: false,
  },
  cancellationDetail: {
    data: null,
    isFetching: false,
    error: false,
  },
  handoff: {
    data: null,
    isFetching: false,
    error: false,
  },
  weather: {
    data: null,
    isFetching: false,
    error: false,
  },
};

export default function reducer(
  state: IBookingState = initialState,
  action: BookingActions.IBookingAction,
): IBookingState {
  switch (action.type) {
    case BookingActions.FETCH_BOOKING_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          error: true,
          isFetching: false,
        },
      };

    case BookingActions.FETCH_BOOKING_PENDING:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
          error: undefined,
        },
      };

    case BookingActions.FETCH_BOOKING_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
          error: undefined,
          data: action.payload.booking,
          meta: action.payload.payment,
        },
      };

    case BookingActions.FETCH_BOOKING_SERVICES_PENDING:
      return {
        ...state,
        services: {
          ...state.services,
          isFetching: true,
          error: false,
        },
      };

    case BookingActions.FETCH_BOOKING_SERVICES_FAILURE:
      return {
        ...state,
        services: {
          ...state.services,
          isFetching: false,
          error: true,
        },
      };

    case BookingActions.FETCH_BOOKING_SERVICES_SUCCESS:
      return {
        ...state,
        services: {
          ...state.services,
          isFetching: false,
          data: action.payload,
          error: false,
        },
      };

    case BookingActions.FETCH_BOOKING_OWNER_PENDING:
      return {
        ...state,
        owner: {
          ...state.owner,
          isFetching: true,
          error: false,
        },
      };

    case BookingActions.FETCH_BOOKING_OWNER_FAILURE:
      return {
        ...state,
        owner: {
          ...state.owner,
          isFetching: false,
          error: true,
        },
      };
    case BookingActions.FETCH_BOOKING_OWNER_SUCCESS:
      return {
        ...state,
        owner: {
          isFetching: false,
          data: action.payload,
          error: false,
        },
      };
    case BookingActions.FETCH_BOOKING_PASSENGER_PENDING:
      return {
        ...state,
        passenger: {
          ...state.passenger,
          isFetching: true,
          error: false,
        },
      };
    case BookingActions.FETCH_BOOKING_FILES_PENDING:
      return {
        ...state,
        file: {
          ...state.file,
          isFetching: true,
          error: false,
        },
      };
    case BookingActions.FETCH_BOOKING_PASSENGER_FAILURE:
      return {
        ...state,
        passenger: {
          ...state.passenger,
          isFetching: false,
          error: true,
        },
      };
    case BookingActions.FETCH_BOOKING_PASSENGER_SUCCESS:
      return {
        ...state,
        passenger: {
          isFetching: false,
          data: action.payload,
          error: false,
        },
      };
    case BookingActions.FETCH_BOOKING_FILES_FAILURE:
      return {
        ...state,
        file: {
          data: undefined,
          isFetching: false,
          error: true,
        },
      };
    case BookingActions.FETCH_BOOKING_FILES_SUCCESS:
      return {
        ...state,
        file: {
          isFetching: false,
          data: action.payload,
          error: false,
        },
      };
    case BookingActions.FETCH_INSURANCE_BUNDLES_PENDING:
      return {
        ...state,
        insuranceBundles: {
          ...state.insuranceBundles,
          isFetching: true,
          error: false,
        },
      };
    case BookingActions.FETCH_INSURANCE_BUNDLES_SUCCESS:
      return {
        ...state,
        insuranceBundles: {
          isFetching: false,
          data: action.payload,
          error: false,
        },
      };
    case BookingActions.FETCH_INSURANCE_BUNDLES_FAILURE:
      return {
        ...state,
        insuranceBundles: {
          ...state.insuranceBundles,
          isFetching: false,
          error: true,
        },
      };

    case BookingActions.UPDATE_BOOKING_INSURANCE_BUNDLE_PENDING:
      return {
        ...state,
        bookingBundle: {
          ...state.bookingBundle,
          isUpdated: false,
          isUpdating: true,
          error: false,
        },
      };

    case BookingActions.UPDATE_BOOKING_INSURANCE_BUNDLE_FAILURE:
      return {
        ...state,
        bookingBundle: {
          ...state.bookingBundle,
          isUpdating: false,
          isUpdated: false,
          error: true,
        },
      };
    case BookingActions.UPDATE_BOOKING_INSURANCE_BUNDLE_SUCCESS:
      if (!state.details.data) {
        return state;
      }
      return {
        ...state,
        details: {
          ...state.details,
          data: {
            ...state.details.data,
            bundle_id: action.payload.id,
            insurance_bundle: action.payload,
          },
        },
        bookingBundle: {
          isUpdating: false,
          isUpdated: true,
          data: action.payload,
          error: false,
        },
      };

    case BookingActions.FETCH_USER_CARD_PENDING:
      return {
        ...state,
        cards: {
          ...state.cards,
          isFetching: true,
          error: false,
        },
      };

    case BookingActions.FETCH_USER_CARD_FAILURE:
      return {
        ...state,
        cards: {
          ...state.cards,
          isFetching: false,
          error: true,
        },
      };
    case BookingActions.FETCH_USER_CARD_SUCCESS:
      return {
        ...state,
        cards: {
          isFetching: false,
          data: action.payload,
          error: false,
        },
      };
    case BookingActions.ADD_BOOKING_SERVICES_FAILURE:
      return {
        ...state,
        addService: {
          isFetching: false,
          error: true,
        },
      };
    case BookingActions.ADD_BOOKING_SERVICES_PENDING: {
      return {
        ...state,
        addService: {
          isFetching: true,
          error: false,
        },
      };
    }
    case BookingActions.ADD_BOOKING_SERVICES_SUCCESS: {
      if (!state.details.data) {
        return state;
      }
      return {
        ...state,
        addService: {
          isFetching: false,
          error: false,
        },
        details: {
          ...state.details,
          data: {
            ...(state.details.data || {}),
            services: [...(state.details.data?.services || []), { ...action.payload }],
          },
        },
      };
    }
    case BookingActions.REMOVE_BOOKING_SERVICES_PENDING: {
      return {
        ...state,
        removeService: {
          isFetching: true,
          error: false,
        },
      };
    }
    case BookingActions.REMOVE_BOOKING_SERVICES_FAILURE: {
      return {
        ...state,
        removeService: {
          isFetching: false,
          error: true,
        },
      };
    }
    case BookingActions.REMOVE_BOOKING_SERVICES_SUCCESS: {
      if (!state.details.data) {
        return state;
      }
      const removedService = (state.services?.data || []).find(
        service => service.id == action.payload,
      );
      return {
        ...state,
        removeService: {
          isFetching: false,
          error: false,
        },
        details: {
          ...state.details,
          data: {
            ...(state.details.data || {}),
            services: [
              ...(state.details.data?.services || []).filter(
                service => service.category !== removedService?.category,
              ),
            ],
          },
        },
      };
    }
    case BookingActions.ADD_BOOKING_PASSENGERS_PENDING: {
      return {
        ...state,
        addPassenger: 'loading',
      };
    }
    case BookingActions.ADD_BOOKING_PASSENGERS_FAILURE: {
      return {
        ...state,
        addPassenger: 'error',
      };
    }
    case BookingActions.ADD_BOOKING_PASSENGERS_SUCCESS: {
      const existingPassenger = (state.passenger.data || []).find(p => p.id === action.payload.id);
      if (existingPassenger) {
        return {
          ...state,
          addPassenger: 'already_exists',
        };
      }
      return {
        ...state,
        addPassenger: 'success',
        passenger: {
          ...state.passenger,
          data: [...(state.passenger.data || []), { ...action.payload }],
          isFetching: false,
        },
      };
    }
    case BookingActions.RESET_BOOKING_PASSENGERS_STATUS: {
      return {
        ...state,
        addPassenger: 'initial',
        removePassenger: 'initial',
      };
    }
    case BookingActions.REMOVE_BOOKING_PASSENGERS_PENDING: {
      return {
        ...state,
        removePassenger: 'loading',
      };
    }
    case BookingActions.REMOVE_BOOKING_PASSENGERS_FAILURE: {
      return {
        ...state,
        removePassenger: 'error',
      };
    }
    case BookingActions.REMOVE_BOOKING_PASSENGERS_SUCCESS: {
      return {
        ...state,
        removePassenger: 'success',
        passenger: {
          ...state.passenger,
          data: [
            ...(state.passenger.data || []).filter(passenger => passenger.id !== action.payload),
          ],
        },
      };
    }

    case BookingActions.FETCH_WITHDRAWAL_REASONS_PENDING:
      return {
        ...state,
        withdrawalReasons: {
          ...state.withdrawalReasons,
          isFetching: true,
          error: false,
        },
      };
    case BookingActions.FETCH_WITHDRAWAL_REASONS_FAILURE:
      return {
        ...state,
        withdrawalReasons: {
          ...state.withdrawalReasons,
          isFetching: false,
          error: true,
        },
      };
    case BookingActions.FETCH_WITHDRAWAL_REASONS_SUCCESS:
      return {
        ...state,
        withdrawalReasons: {
          isFetching: false,
          data: action.payload,
          error: false,
        },
      };

    case BookingActions.UPDATE_BOOKING_STATUS_PENDING:
      return {
        ...state,
        updateStatus: {
          isFetching: true,
          error: false,
        },
      };
    case BookingActions.UPDATE_BOOKING_STATUS_FAILURE:
      return {
        ...state,
        updateStatus: {
          isFetching: false,
          error: true,
        },
      };
    case BookingActions.UPDATE_BOOKING_STATUS_SUCCESS:
      return {
        ...state,
        updateStatus: {
          isFetching: false,
          error: false,
        },
      };

    case BookingActions.UPDATE_DRIVER_VERIFICATION_PENDING:
      return {
        ...state,
        updateDriverVerification: {
          isFetching: false,
          error: false,
        },
      };
    case BookingActions.UPDATE_DRIVER_VERIFICATION_FAILURE:
      return {
        ...state,
        updateDriverVerification: {
          isFetching: true,
          error: true,
        },
      };
    case BookingActions.UPDATE_DRIVER_VERIFICATION_SUCCESS:
      return {
        ...state,
        updateDriverVerification: {
          isFetching: false,
          error: false,
        },
      };

    case BookingActions.FETCH_CANCELLATION_DETAIL_PENDING:
      return {
        ...state,
        cancellationDetail: {
          data: null,
          isFetching: true,
          error: false,
        },
      };
    case BookingActions.FETCH_CANCELLATION_DETAIL_SUCCESS:
      return {
        ...state,
        cancellationDetail: {
          data: action.payload,
          isFetching: false,
          error: false,
        },
      };
    case BookingActions.FETCH_CANCELLATION_DETAIL_FAILURE:
      return {
        ...state,
        cancellationDetail: {
          data: null,
          isFetching: false,
          error: true,
        },
      };
    case BookingActions.RESET_BOOKING_DATA:
      return {
        ...initialState,
      };
    case BookingActions.FETCH_BOOKING_HANDOFF_PENDING:
      return {
        ...state,
        handoff: {
          data: null,
          isFetching: true,
          error: false,
        },
      };
    case BookingActions.FETCH_BOOKING_HANDOFF_SUCCESS:
      return {
        ...state,
        handoff: {
          data: action.payload,
          isFetching: false,
          error: false,
        },
      };
    case BookingActions.FETCH_BOOKING_HANDOFF_FAILURE:
      return {
        ...state,
        handoff: {
          data: null,
          isFetching: false,
          error: true,
        },
      };
    case BookingActions.FETCH_BOOKING_WEATHER_PENDING:
      return {
        ...state,
        weather: {
          data: null,
          isFetching: true,
          error: false,
        },
      };
    case BookingActions.FETCH_BOOKING_WEATHER_SUCCESS:
      return {
        ...state,
        weather: {
          data: action.payload,
          isFetching: false,
          error: false,
        },
      };
    case BookingActions.FETCH_BOOKING_WEATHER_FAILURE:
      return {
        ...state,
        weather: {
          data: null,
          isFetching: false,
          error: true,
        },
      };
    default:
      return state;
  }
}
