const getPlatform = (): string | undefined => {
  if (typeof navigator !== 'undefined') {
    return navigator.platform;
  }
};

const getUserAgent = (): string | undefined => {
  if (typeof navigator !== 'undefined') {
    return navigator.userAgent;
  }
};

export const getIsIOSDevice = () => {
  const platform = getPlatform();
  const userAgent = getUserAgent();
  if (platform && userAgent && typeof document !== 'undefined') {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        platform,
      ) ||
      (userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  return false;
};

export const getIsAndroidDevice = () => {
  const userAgent = getUserAgent();
  if (userAgent) {
    const androidUserAgent = /android/i;
    return androidUserAgent.test(userAgent);
  }

  return false;
};

export const getIsMobileSafari = () => {
  const userAgent = getUserAgent();
  if (userAgent) {
    return /Mobi/.test(userAgent) && /^((?!chrome|android).)*safari/i.test(userAgent);
  }

  return false;
};
