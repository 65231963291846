import queryString from 'query-string';

import { TCurrency } from '@/config/locales';
import { DEFAULT_EXCLUSIONS, ESearchFilters } from '@/constants/searchFilters';
import { SEARCH_API_QUERY_PARAMS } from '@/constants/urls';
import apiRequest from '@/services/apiRequest';
import { getSearchApi } from '@/utility/getCoreApi';
import { getLocale } from '@/utility/i18n';

export type TQueryParams = Record<
  string | ESearchFilters,
  string | string[] | number | boolean | undefined
>;

export const getSearchUrl = (
  endpoint: string,
  queryParams?: TQueryParams,
  promoQueryParams?: TQueryParams | null,
) => {
  const decodedParams = queryParams && decodeURI(queryString.stringify(queryParams));

  if (promoQueryParams) {
    // do not send dates from promo query if regular query contains dates
    if (
      promoQueryParams[ESearchFilters.DATE_FROM] &&
      queryParams &&
      queryParams[ESearchFilters.DATE_FROM]
    ) {
      promoQueryParams[ESearchFilters.DATE_FROM] = '';
    }

    if (
      promoQueryParams[ESearchFilters.DATE_TO] &&
      queryParams &&
      queryParams[ESearchFilters.DATE_TO]
    ) {
      promoQueryParams[ESearchFilters.DATE_TO] = '';
    }

    const decodedPromoParams = decodeURI(queryString.stringify(promoQueryParams));

    // let promo params comes first
    // in case of duplicated params backend should consider the last one only
    // and in some cases consider the first one as a 'promo' intersection case
    const unifiedParams = [SEARCH_API_QUERY_PARAMS, decodedPromoParams, decodedParams]
      .filter(Boolean)
      .join('&');

    return `${getSearchApi()}/${endpoint}${unifiedParams}`;
  }

  const params = [SEARCH_API_QUERY_PARAMS, decodedParams].filter(Boolean).join('&');
  return `${getSearchApi()}/${endpoint}${params}`;
};

export const excludeUnusedTypesIfNotSpecified = (
  queryParams: TQueryParams,
  exclusions: string[] = DEFAULT_EXCLUSIONS,
) => {
  const isStay = queryParams[ESearchFilters.RENTAL_CATEGORY] === 'stay';
  if (isStay) return { ...queryParams };
  let exclusionsList = '';
  if (queryParams['filter[type]'] && typeof queryParams['filter[type]'] === 'string') {
    const filterTypes = queryParams['filter[type]'].split(',');
    exclusionsList = exclusions
      .filter((exclusion: string) => filterTypes.indexOf(exclusion) === -1)
      .map((exclusion: string) => exclusion)
      .join();
  } else {
    exclusionsList = exclusions.join(',');
  }
  return {
    ...queryParams,
    [ESearchFilters.EXCLUDE_TYPE]: exclusionsList,
  };
};

export default function searchApiRequest<T>(
  endpoint: string,
  options?: {
    currency?: TCurrency;
    queryParams?: TQueryParams;
    rejectOnError?: boolean;
    locale?: string;
    promoQueryParams?: TQueryParams | null;
  },
) {
  const locale = getLocale(options?.locale);
  let queryParams = options?.queryParams ? options?.queryParams : {};
  queryParams['locale'] = locale;

  if (queryParams && !queryParams.owner_id) {
    queryParams = excludeUnusedTypesIfNotSpecified(queryParams);
  }

  if (queryParams && queryParams['date[from]'] && queryParams['date[to]']) {
    const from = new Date(queryParams['date[from]'] as string);
    const to = new Date(queryParams['date[to]'] as string);
    if (from > to) {
      [queryParams['date[from]'], queryParams['date[to]']] = [
        queryParams['date[to]'],
        queryParams['date[from]'],
      ];
    }
  }

  if (queryParams && queryParams['delivery'] === 'false' && queryParams['delivery_address']) {
    delete queryParams['delivery_address'];
  }

  return apiRequest<T>(
    {
      url: getSearchUrl(
        endpoint,
        options && { ...queryParams, currency: options.currency },
        options?.promoQueryParams,
      ),
    },
    options?.rejectOnError,
  );
}
