import { ICancellationDetail } from '@/services/types/booking/cancellationDetail';
import { ICard } from '@/services/types/booking/card';
import type { IBooking, IBookingGuest } from '@/services/types/booking/details';
import { IFile } from '@/services/types/booking/file';
import { IBookingHandoff } from '@/services/types/booking/handoff';
import { IInsuranceBundle } from '@/services/types/booking/insuranceBundle';
import { IBookingPayment } from '@/services/types/booking/payment';
import { IBookingService } from '@/services/types/booking/services';
import { IWeather } from '@/services/types/booking/weather';
import { IWithdrawalReason } from '@/services/types/booking/withdrawalReason';
import { IOwner } from '@/services/types/owner';
import { IAction } from '@/utility/redux/action';

export const FETCH_BOOKING_PENDING = 'booking/FETCH_BOOKING_PENDING';
export const FETCH_BOOKING_FAILURE = 'booking/FETCH_BOOKING_FAILURE';
export const FETCH_BOOKING_SUCCESS = 'booking/FETCH_BOOKING_SUCCESS';

interface IBookingPendingAction extends IAction {
  type: typeof FETCH_BOOKING_PENDING;
}

interface IBookingSuccessAction extends IAction {
  type: typeof FETCH_BOOKING_SUCCESS;
  payload: { booking: IBooking; payment?: IBookingPayment };
}

interface IBookingFailureAction extends IAction {
  type: typeof FETCH_BOOKING_FAILURE;
  error: boolean;
}

export const FETCH_BOOKING_SERVICES_PENDING = 'booking/FETCH_BOOKING_SERVICES_PENDING';
export const FETCH_BOOKING_SERVICES_FAILURE = 'booking/FETCH_BOOKING_SERVICES_FAILURE';
export const FETCH_BOOKING_SERVICES_SUCCESS = 'booking/FETCH_BOOKING_SERVICES_SUCCESS';

interface IBookingServicesPendingAction extends IAction {
  type: typeof FETCH_BOOKING_SERVICES_PENDING;
}

interface IBookingServicesSuccessAction extends IAction {
  type: typeof FETCH_BOOKING_SERVICES_SUCCESS;
  payload: Array<IBookingService>;
}

interface IBookingServicesFailureAction extends IAction {
  type: typeof FETCH_BOOKING_SERVICES_FAILURE;
  error: boolean;
}

export const FETCH_BOOKING_OWNER_PENDING = 'booking/FETCH_BOOKING_OWNER_PENDING';
export const FETCH_BOOKING_OWNER_FAILURE = 'booking/FETCH_BOOKING_OWNER_FAILURE';
export const FETCH_BOOKING_OWNER_SUCCESS = 'booking/FETCH_BOOKING_OWNER_SUCCESS';

interface IBookingOwnerPendingAction extends IAction {
  type: typeof FETCH_BOOKING_OWNER_PENDING;
}

interface IBookingOwnerSuccessAction extends IAction {
  type: typeof FETCH_BOOKING_OWNER_SUCCESS;
  payload: IOwner;
}

interface IBookingOwnerFailureAction extends IAction {
  type: typeof FETCH_BOOKING_OWNER_FAILURE;
  error: boolean;
}

export const FETCH_BOOKING_PASSENGER_PENDING = 'booking/FETCH_BOOKING_PASSENGER_PENDING';
export const FETCH_BOOKING_PASSENGER_FAILURE = 'booking/FETCH_BOOKING_PASSENGER_FAILURE';
export const FETCH_BOOKING_PASSENGER_SUCCESS = 'booking/FETCH_BOOKING_PASSENGER_SUCCESS';

interface IBookingPassengerPendingAction extends IAction {
  type: typeof FETCH_BOOKING_PASSENGER_PENDING;
}

interface IBookingPassengerSuccessAction extends IAction {
  type: typeof FETCH_BOOKING_PASSENGER_SUCCESS;
  payload: IBookingGuest[];
}

interface IBookingPassengerFailureAction extends IAction {
  type: typeof FETCH_BOOKING_PASSENGER_FAILURE;
  error: boolean;
}

export const FETCH_INSURANCE_BUNDLES_PENDING = 'booking/FETCH_INSURANCE_BUNDLES_PENDING';
export const FETCH_INSURANCE_BUNDLES_SUCCESS = 'booking/FETCH_INSURANCE_BUNDLES_SUCCESS';
export const FETCH_INSURANCE_BUNDLES_FAILURE = 'booking/FETCH_INSURANCE_BUNDLES_FAILURE';

interface IInsuranceBundlesPendingAction extends IAction {
  type: typeof FETCH_INSURANCE_BUNDLES_PENDING;
}

interface IInsuranceBundlesSuccessAction extends IAction {
  type: typeof FETCH_INSURANCE_BUNDLES_SUCCESS;
  payload: IInsuranceBundle[];
}

interface IInsuranceBundlesFailureAction extends IAction {
  type: typeof FETCH_INSURANCE_BUNDLES_FAILURE;
  error: boolean;
}

export const FETCH_BOOKING_FILES_PENDING = 'booking/FETCH_BOOKING_FILES_PENDING';
export const FETCH_BOOKING_FILES_FAILURE = 'booking/FETCH_BOOKING_FILES_FAILURE';
export const FETCH_BOOKING_FILES_SUCCESS = 'booking/FETCH_BOOKING_FILES_SUCCESS';

interface IBookingFilesPendingAction extends IAction {
  type: typeof FETCH_BOOKING_FILES_PENDING;
}

interface IBookingFilesSuccessAction extends IAction {
  type: typeof FETCH_BOOKING_FILES_SUCCESS;
  payload: IFile | undefined;
}

interface IBookingFilesFailureAction extends IAction {
  type: typeof FETCH_BOOKING_FILES_FAILURE;
  error: boolean;
}

export const UPDATE_BOOKING_INSURANCE_BUNDLE_PENDING =
  'booking/UPDATE_BOOKING_INSURANCE_BUNDLE_PENDING';
export const UPDATE_BOOKING_INSURANCE_BUNDLE_SUCCESS =
  'booking/UPDATE_BOOKING_INSURANCE_BUNDLE_SUCCESS';
export const UPDATE_BOOKING_INSURANCE_BUNDLE_FAILURE =
  'booking/UPDATE_BOOKING_INSURANCE_BUNDLE_FAILURE';

interface IUpdateBookingBundlePendingAction extends IAction {
  type: typeof UPDATE_BOOKING_INSURANCE_BUNDLE_PENDING;
}

interface IUpdateBookingBundleSuccessAction extends IAction {
  type: typeof UPDATE_BOOKING_INSURANCE_BUNDLE_SUCCESS;
  payload: IInsuranceBundle;
}

interface IUpdateBookingBundleFailureAction extends IAction {
  type: typeof UPDATE_BOOKING_INSURANCE_BUNDLE_FAILURE;
  error: boolean;
}

export const FETCH_USER_CARD_PENDING = 'booking/FETCH_USER_CARD_PENDING';
export const FETCH_USER_CARD_FAILURE = 'booking/FETCH_USER_CARD_FAILURE';
export const FETCH_USER_CARD_SUCCESS = 'booking/FETCH_USER_CARD_SUCCESS';

interface IFetchUserCardPendingAction extends IAction {
  type: typeof FETCH_USER_CARD_PENDING;
}

interface IFetchUserCardSuccessAction extends IAction {
  type: typeof FETCH_USER_CARD_SUCCESS;
  payload: ICard[];
}

interface IFetchUserCardFailureAction extends IAction {
  type: typeof FETCH_USER_CARD_FAILURE;
  error: boolean;
}
export const ADD_BOOKING_SERVICES_PENDING = 'booking/ADD_BOOKING_SERVICES_PENDING';
export const ADD_BOOKING_SERVICES_FAILURE = 'booking/ADD_BOOKING_SERVICES_FAILURE';
export const ADD_BOOKING_SERVICES_SUCCESS = 'booking/ADD_BOOKING_SERVICES_SUCCESS';

interface IAddBookingServicesPendingAction extends IAction {
  type: typeof ADD_BOOKING_SERVICES_PENDING;
}

interface IAddBookingServicesSuccessAction extends IAction {
  type: typeof ADD_BOOKING_SERVICES_SUCCESS;
  payload: IBookingService;
}

interface IAddBookingServicesFailureAction extends IAction {
  type: typeof ADD_BOOKING_SERVICES_FAILURE;
  error: boolean;
}

export const REMOVE_BOOKING_SERVICES_PENDING = 'booking/REMOVE_BOOKING_SERVICES_PENDING';
export const REMOVE_BOOKING_SERVICES_FAILURE = 'booking/REMOVE_BOOKING_SERVICES_FAILURE';
export const REMOVE_BOOKING_SERVICES_SUCCESS = 'booking/REMOVE_BOOKING_SERVICES_SUCCESS';

interface IRemoveBookingServicesPendingAction extends IAction {
  type: typeof REMOVE_BOOKING_SERVICES_PENDING;
}

interface IRemoveBookingServicesSuccessAction extends IAction {
  type: typeof REMOVE_BOOKING_SERVICES_SUCCESS;
  payload: number;
}

interface IRemoveBookingServicesFailureAction extends IAction {
  type: typeof REMOVE_BOOKING_SERVICES_FAILURE;
  error: boolean;
}

export const ADD_BOOKING_PASSENGERS_PENDING = 'booking/ADD_BOOKING_PASSENGERS_PENDING';
export const ADD_BOOKING_PASSENGERS_FAILURE = 'booking/ADD_BOOKING_PASSENGERS_FAILURE';
export const ADD_BOOKING_PASSENGERS_SUCCESS = 'booking/ADD_BOOKING_PASSENGERS_SUCCESS';

interface IAddBookingPassengersPendingAction extends IAction {
  type: typeof ADD_BOOKING_PASSENGERS_PENDING;
}

interface IAddBOokingPassengersSuccessAction extends IAction {
  type: typeof ADD_BOOKING_PASSENGERS_SUCCESS;
  payload: IBookingGuest;
}

interface IAddBOokingPassengersFailureAction extends IAction {
  type: typeof ADD_BOOKING_PASSENGERS_FAILURE;
  error: boolean;
}

export const REMOVE_BOOKING_PASSENGERS_PENDING = 'booking/REMOVE_BOOKING_PASSENGERS_PENDING';
export const REMOVE_BOOKING_PASSENGERS_FAILURE = 'booking/REMOVE_BOOKING_PASSENGERS_FAILURE';
export const REMOVE_BOOKING_PASSENGERS_SUCCESS = 'booking/REMOVE_BOOKING_PASSENGERS_SUCCESS';

interface IRemoveBookingPassengersPendingAction extends IAction {
  type: typeof REMOVE_BOOKING_PASSENGERS_PENDING;
}

interface IRemoveBOokingPassengersSuccessAction extends IAction {
  type: typeof REMOVE_BOOKING_PASSENGERS_SUCCESS;
  payload: number;
}

interface IRemoveBOokingPassengersFailureAction extends IAction {
  type: typeof REMOVE_BOOKING_PASSENGERS_FAILURE;
  error: boolean;
}

export const RESET_BOOKING_PASSENGERS_STATUS = 'booking/RESET_BOOKING_PASSENGERS_STATUS';
interface IResetBookingPassengersStatusAction extends IAction {
  type: typeof RESET_BOOKING_PASSENGERS_STATUS;
}

export const FETCH_WITHDRAWAL_REASONS_PENDING = 'booking/FETCH_WITHDRAWAL_REASONS_PENDING';
export const FETCH_WITHDRAWAL_REASONS_FAILURE = 'booking/FETCH_WITHDRAWAL_REASONS_FAILURE';
export const FETCH_WITHDRAWAL_REASONS_SUCCESS = 'booking/FETCH_WITHDRAWAL_REASONS_SUCCESS';

interface IFetchWithdrawalReasonsPendingAction extends IAction {
  type: typeof FETCH_WITHDRAWAL_REASONS_PENDING;
}

interface IFetchWithdrawalReasonsSuccessAction extends IAction {
  type: typeof FETCH_WITHDRAWAL_REASONS_SUCCESS;
  payload: IWithdrawalReason[];
}

interface IFetchWithdrawalReasonsFailureAction extends IAction {
  type: typeof FETCH_WITHDRAWAL_REASONS_FAILURE;
  error: boolean;
}

/**
 * update booking status
 */
export const UPDATE_BOOKING_STATUS_PENDING = 'booking/UPDATE_BOOKING_STATUS_PENDING';
export const UPDATE_BOOKING_STATUS_FAILURE = 'booking/UPDATE_BOOKING_STATUS_FAILURE';
export const UPDATE_BOOKING_STATUS_SUCCESS = 'booking/UPDATE_BOOKING_STATUS_SUCCESS';

interface IUpdateBookingStatusPendingAction extends IAction {
  type: typeof UPDATE_BOOKING_STATUS_PENDING;
}

interface IUpdateBookingStatusSuccessAction extends IAction {
  type: typeof UPDATE_BOOKING_STATUS_SUCCESS;
}

interface IUpdateBookingStatusFailureAction extends IAction {
  type: typeof UPDATE_BOOKING_STATUS_FAILURE;
  error: boolean;
}

/**
 * update driver verification
 */
export const UPDATE_DRIVER_VERIFICATION_PENDING = 'booking/UPDATE_DRIVER_VERIFICATION_PENDING';
export const UPDATE_DRIVER_VERIFICATION_FAILURE = 'booking/UPDATE_DRIVER_VERIFICATION_FAILURE';
export const UPDATE_DRIVER_VERIFICATION_SUCCESS = 'booking/UPDATE_DRIVER_VERIFICATION_SUCCESS';

interface IUpdateDriverVerificationPendingAction extends IAction {
  type: typeof UPDATE_DRIVER_VERIFICATION_PENDING;
}

interface IUpdateDriverVerificationSuccessAction extends IAction {
  type: typeof UPDATE_DRIVER_VERIFICATION_SUCCESS;
  payload: IBooking;
}

interface IUpdateDriverVerificationFailureAction extends IAction {
  type: typeof UPDATE_DRIVER_VERIFICATION_FAILURE;
  error: boolean;
}

/**
 * fetch cancellation details
 */
export const FETCH_CANCELLATION_DETAIL_PENDING = 'booking/FETCH_CANCELLATION_DETAIL_PENDING';
export const FETCH_CANCELLATION_DETAIL_FAILURE = 'booking/FETCH_CANCELLATION_DETAIL_FAILURE';
export const FETCH_CANCELLATION_DETAIL_SUCCESS = 'booking/FETCH_CANCELLATION_DETAIL_SUCCESS';

interface IFetchCancellationDetailPendingAction extends IAction {
  type: typeof FETCH_CANCELLATION_DETAIL_PENDING;
}

interface IFetchCancellationDetailSuccessAction extends IAction {
  type: typeof FETCH_CANCELLATION_DETAIL_SUCCESS;
  payload: ICancellationDetail;
}

interface IFetchCancellationDetailFailureAction extends IAction {
  type: typeof FETCH_CANCELLATION_DETAIL_FAILURE;
  error: boolean;
}

export const RESET_BOOKING_DATA = 'booking/RESET_BOOKING_DATA';
interface IResetBookingData {
  type: typeof RESET_BOOKING_DATA;
}

export const FETCH_BOOKING_HANDOFF_PENDING = 'booking/FETCH_BOOKING_HANDOFF_PENDING';
export const FETCH_BOOKING_HANDOFF_FAILURE = 'booking/FETCH_BOOKING_HANDOFF_FAILURE';
export const FETCH_BOOKING_HANDOFF_SUCCESS = 'booking/FETCH_BOOKING_HANDOFF_SUCCESS';

interface IBookingHandoffPendingAction extends IAction {
  type: typeof FETCH_BOOKING_HANDOFF_PENDING;
}

interface IBookingHandoffSuccessAction extends IAction {
  type: typeof FETCH_BOOKING_HANDOFF_SUCCESS;
  payload: IBookingHandoff;
}

interface IBookingHandoffFailureAction extends IAction {
  type: typeof FETCH_BOOKING_HANDOFF_FAILURE;
  error: boolean;
}

export const FETCH_BOOKING_WEATHER_SUCCESS = 'booking/FETCH_BOOKING_WEATHER_SUCCESS';
export const FETCH_BOOKING_WEATHER_PENDING = 'booking/FETCH_BOOKING_WEATHER_PENDING';
export const FETCH_BOOKING_WEATHER_FAILURE = 'booking/FETCH_BOOKING_WEATHER_FAILURE';

interface IBookingWeatherSuccessAction extends IAction {
  type: typeof FETCH_BOOKING_WEATHER_SUCCESS;
  payload: IWeather;
}

interface IBookingWeatherPendingAction extends IAction {
  type: typeof FETCH_BOOKING_WEATHER_PENDING;
}

interface IBookingWeatherFailureAction extends IAction {
  type: typeof FETCH_BOOKING_WEATHER_FAILURE;
  error: boolean;
}

export type IBookingAction =
  | IBookingPendingAction
  | IBookingSuccessAction
  | IBookingFailureAction
  | IBookingServicesPendingAction
  | IBookingServicesSuccessAction
  | IBookingServicesFailureAction
  | IBookingOwnerPendingAction
  | IBookingOwnerSuccessAction
  | IBookingOwnerFailureAction
  | IBookingPassengerPendingAction
  | IBookingPassengerSuccessAction
  | IBookingPassengerFailureAction
  | IInsuranceBundlesPendingAction
  | IInsuranceBundlesSuccessAction
  | IInsuranceBundlesFailureAction
  | IBookingFilesPendingAction
  | IBookingFilesSuccessAction
  | IBookingFilesFailureAction
  | IUpdateBookingBundlePendingAction
  | IUpdateBookingBundleSuccessAction
  | IUpdateBookingBundleFailureAction
  | IFetchUserCardPendingAction
  | IFetchUserCardSuccessAction
  | IFetchUserCardFailureAction
  | IFetchWithdrawalReasonsPendingAction
  | IFetchWithdrawalReasonsSuccessAction
  | IFetchWithdrawalReasonsFailureAction
  | IFetchUserCardFailureAction
  | IAddBookingServicesPendingAction
  | IAddBookingServicesSuccessAction
  | IAddBookingServicesFailureAction
  | IRemoveBookingServicesPendingAction
  | IRemoveBookingServicesSuccessAction
  | IRemoveBookingServicesFailureAction
  | IAddBookingPassengersPendingAction
  | IAddBOokingPassengersSuccessAction
  | IAddBOokingPassengersFailureAction
  | IRemoveBookingPassengersPendingAction
  | IRemoveBOokingPassengersSuccessAction
  | IRemoveBOokingPassengersFailureAction
  | IResetBookingPassengersStatusAction
  | IUpdateBookingStatusPendingAction
  | IUpdateBookingStatusSuccessAction
  | IUpdateBookingStatusFailureAction
  | IUpdateDriverVerificationPendingAction
  | IUpdateDriverVerificationSuccessAction
  | IUpdateDriverVerificationFailureAction
  | IFetchCancellationDetailPendingAction
  | IFetchCancellationDetailSuccessAction
  | IFetchCancellationDetailFailureAction
  | IResetBookingData
  | IBookingHandoffPendingAction
  | IBookingHandoffSuccessAction
  | IBookingHandoffFailureAction
  | IBookingWeatherSuccessAction
  | IBookingWeatherPendingAction
  | IBookingWeatherFailureAction;
