import { ThunkAction } from 'redux-thunk';

import * as BookingsReviewActions from '@/redux/modules/bookingsReview/type';
import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import type { IBooking } from '@/services/types/booking/details';
import { getCoreApi } from '@/utility/getCoreApi';
import { getAuthToken } from '@/utility/session';

const FETCH_BOOKINGS_URL = `${getCoreApi()}/bookings/`;

export interface IBookingsReviewResponse {
  bookings: IBooking[];
}

//fetch Bookings actions
const fetchBookingsReviewPendingAction = (): BookingsReviewActions.IBookingsReviewAction => {
  return {
    type: BookingsReviewActions.FETCH_BOOKINGS_REVIEW_PENDING,
  };
};

const fetchBookingsReviewFailureAction = (
  error: boolean,
): BookingsReviewActions.IBookingsReviewAction => {
  return {
    type: BookingsReviewActions.FETCH_BOOKINGS_REVIEW_FAILURE,
    error,
  };
};

const fetchBookingsReviewSuccessAction = (
  bookings: IBooking[],
): BookingsReviewActions.IBookingsReviewAction => {
  return {
    type: BookingsReviewActions.FETCH_BOOKINGS_REVIEW_SUCCESS,
    payload: { bookings },
  };
};

export const fetchBookingsReview =
  (): ThunkAction<void, TRootState, void, BookingsReviewActions.IBookingsReviewAction> =>
  async dispatch => {
    dispatch(fetchBookingsReviewPendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(fetchBookingsReviewFailureAction(true));
      return;
    }

    const params = {
      needs_review: true,
      include_shared: true,
      role: 'renter',
    } as Record<string, string | boolean>;

    try {
      const bookings = await apiRequest<IBooking[]>(
        {
          url: FETCH_BOOKINGS_URL,
          method: 'GET',
          params,
        },
        true,
      );
      if (bookings) {
        dispatch(fetchBookingsReviewSuccessAction(bookings));
      } else {
        dispatch(fetchBookingsReviewFailureAction(true));
      }
    } catch {
      dispatch(fetchBookingsReviewFailureAction(true));
    }
  };
