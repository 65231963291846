import dayjs from 'dayjs';
import React, { useState } from 'react';

import DatePicker from '@/components/switchback/DatePicker/DatePicker';
import { IHeaderSearchFilterDates } from '@/redux/modules/globalHeader';

import { INavbarSearch } from '../../../SearchHeader';
import classes from '../DesktopUniversalSearch.module.css';
import DateContentFooter from './DateContentFooter';

interface DateContentProps {
  className?: string;
  open?: boolean;
  dates?: IHeaderSearchFilterDates;
  onChangeDates?: INavbarSearch['onChangeDates'];
}

const DateContent: React.FC<DateContentProps> = ({
  className = '',
  open,
  dates,
  onChangeDates,
}) => {
  const [datesValue, setDatesValue] = useState<IHeaderSearchFilterDates | undefined>(dates);

  const handleChangeDates = (newDates: IHeaderSearchFilterDates) => {
    setDatesValue({ ...datesValue, ...newDates });
    onChangeDates?.({ ...datesValue, ...newDates });
  };

  const handleClearDates = () => {
    setDatesValue({});
    onChangeDates?.({});
  };

  return (
    <div className={`p-5 ${open ? 'block' : 'hidden'} ${classes.homeDateWrapper}`}>
      <DatePicker
        className={`${classes.date_picker} ${className}`}
        range
        numberOfMonths={2}
        isStacked={false}
        onChange={handleChangeDates}
        disabledDays={[]}
        dateTo={datesValue?.to}
        dateFrom={datesValue?.from}
        toMonth={dayjs().add(2, 'year').toDate()}
      />
      <DateContentFooter
        datesValue={datesValue}
        handleChangeDates={handleChangeDates}
        handleClearDates={handleClearDates}
      />
    </div>
  );
};

export default DateContent;
