import React from 'react';

export type THeadingLevels = 1 | 2 | 3 | 4 | 5 | 6;
type TTitleTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type IHeadingElement = React.HTMLAttributes<HTMLHeadingElement>;

interface IHeadingProps {
  level?: THeadingLevels;
}

const Heading: React.FC<IHeadingProps & IHeadingElement> = ({ level = 2, ...props }) => {
  const HeadingTag = `h${level}` as TTitleTag;
  return <HeadingTag {...props} />;
};

export default Heading;
