import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

const SET_RENTAL_NOTES = 'rentalNote/SET_RENTAL_NOTES';

type TRentalNote = {
  id: number;
  name: string;
  description: string;
};

type TRentalNoteResponse = {
  notes?: TRentalNote[];
};

interface ISetRentalNotesAction extends IAction {
  type: typeof SET_RENTAL_NOTES;
  payload: TRentalNote[];
}

type TAction = ISetRentalNotesAction;

const setRentalNotes = (payload: TRentalNote[]): ISetRentalNotesAction => ({
  type: SET_RENTAL_NOTES,
  payload,
});

export const getRentalNotes =
  (
    rentalId: string | number,
  ): ThunkAction<Promise<void>, TRootState, void, ISetRentalNotesAction> =>
  async dispatch => {
    const params = {
      available_for_rental_id: rentalId,
    };
    const url = `${getCoreApi()}/notes-to-renters`;
    const data = await apiRequest<TRentalNoteResponse>({ url, params, method: 'GET' });
    dispatch(setRentalNotes(data?.notes ?? []));
  };

interface IState {
  data: TRentalNote[];
}

export const initialState: IState = {
  data: [],
};

// TODO: Add async actions (REQUEST/FAIL/SUCCESS)
export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case SET_RENTAL_NOTES:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return { ...state };
  }
}
