import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useUpdateEffect = (effectFn: EffectCallback, deps: DependencyList) => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    return effectFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
