import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { countryCodes } from '@/constants/countryCodes';
import { setMapboxPlace } from '@/redux/modules/mapboxPlace';
import apiRequest from '@/services/apiRequest';
import { IMapboxPlacesResponse } from '@/services/types/mapbox/mapboxApi';

import useDebounce from './useDebounce';
import { useGeoDataMyIp } from './useGeoDataMyIp';
import usePrevious from './usePrevious';

const BASE_URL = 'https://api.mapbox.com';
const MAPBOX_TOKEN = process.env.mapboxApiToken || 'xxx';

type TMapboxQuery = {
  text: string;
  location?: Array<number>;
  types?: string;
  limit?: number;
  // whether to perform a reverse geocoding lookup (from lng/lat to place name)
  isReverseLookup?: boolean;
};

export const genMapboxUrl = (query: TMapboxQuery): string => {
  const { location, isReverseLookup } = query;
  // we either perform reverse geocoding lookup by lng/lat or a geocoding lookup by place name
  const searchText =
    isReverseLookup && location ? encodeURI(location.join(',')) : encodeURI(query.text);
  const url = new URL(`${BASE_URL}/geocoding/v5/mapbox.places/${searchText}.json`);
  const params = {
    access_token: MAPBOX_TOKEN,
    country: countryCodes.join(','),
    ...(location ? { proximity: location.join(',') } : {}),
    types: query.types || 'region,place,district,locality,poi',
    limit: String(query.limit || 5),
  };
  url.search = new URLSearchParams(params).toString();
  return url.toString();
};

function useMapboxQuery(query?: TMapboxQuery | null, delay?: number) {
  const { data: geoDataMyIp } = useGeoDataMyIp(!!query?.location);
  const location = geoDataMyIp ? [geoDataMyIp.lng, geoDataMyIp.lat] : undefined;

  const url = query ? genMapboxUrl({ ...query, location: query?.location ?? location }) : '';
  const debouncedUrl = useDebounce<typeof url>(url, delay);
  const previousUrl = usePrevious(debouncedUrl);
  const [results, setResults] = useState<IMapboxPlacesResponse['features']>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (debouncedUrl === previousUrl) return;
    if (!debouncedUrl) {
      setResults([]);
      dispatch(setMapboxPlace(null));
      return;
    }
    let isCanceled = false;
    const fetchPlaces = async () => {
      const response = await apiRequest<IMapboxPlacesResponse>({ url: debouncedUrl });
      if (response && !isCanceled) {
        setResults(response.features);
        dispatch(setMapboxPlace(response.features?.[0] ?? null));
      }
    };
    fetchPlaces();
    return () => {
      isCanceled = true;
    };
  }, [debouncedUrl, dispatch, previousUrl]);

  return results;
}

export default useMapboxQuery;
