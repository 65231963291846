import { ThunkAction } from 'redux-thunk';

import { getQuoteSuccess } from '@/redux/modules/quote';
import { TRootState } from '@/redux/rootReducer';
import { getAddons } from '@/redux/selectors/listing/addons';
import { getQuoteFromAndTo, getQuoteInput } from '@/redux/selectors/listing/bill';
import { trackListingQuoteUpdatedEvent } from '@/services/analytics/listings';
import apiRequest from '@/services/apiRequest';
import { IItem, IQuote } from '@/services/types/core/quotes';
import { IAddOnItem } from '@/services/types/quote/IAddons';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

import { getFromAndTo } from '../selectors/queryParams';

const SET_ADDONS = 'addons/SET_ADDONS';
const SET_TOTAL_ADDONS_REQUEST = 'addons/SET_TOTAL_ADDONS_REQUEST';
export const SET_TOTAL_ADDONS_RESPONSE = 'addons/SET_TOTAL_ADDONS_RESPONSE';
const SET_TOTAL_ADDONS_FAILURE = 'addons/SET_TOTAL_ADDONS_FAILURE';
const CLEAR_ADDONS = 'addons/CLEAR_ADDONS';

export interface ISetAddons extends IAction {
  type: typeof SET_ADDONS;
}
interface IGetAddonsTotalAction extends IAction {
  type: typeof SET_TOTAL_ADDONS_REQUEST;
}
export interface IGetAddonsTotalResponseAction extends IAction {
  type: typeof SET_TOTAL_ADDONS_RESPONSE;
}
interface IGetAddonsTotalFailAction extends IAction {
  type: typeof SET_TOTAL_ADDONS_FAILURE;
  payload: string;
  error: true;
}
interface IClearAddonsTotalAction extends IAction {
  type: typeof CLEAR_ADDONS;
}

type TAction =
  | ISetAddons
  | IGetAddonsTotalAction
  | IGetAddonsTotalResponseAction
  | IGetAddonsTotalFailAction
  | IClearAddonsTotalAction;

export const setAddons = (payload: IAddOnItem[] | undefined): ISetAddons => ({
  type: SET_ADDONS,
  payload,
});

export const setTotalAddons =
  (): ThunkAction<
    void,
    TRootState,
    void,
    IGetAddonsTotalAction | IGetAddonsTotalResponseAction | IGetAddonsTotalFailAction
  > =>
  (dispatch, getState) => {
    const state = getState();
    const quoteFromAndTo = getQuoteFromAndTo(state);
    const queryFromAndTo = getFromAndTo(state);
    const from = quoteFromAndTo?.from || queryFromAndTo?.from;
    const to = quoteFromAndTo?.to || queryFromAndTo?.to;
    const prevQuote = state.quote.data;

    if (!from || !to) {
      return;
    }

    dispatch<IGetAddonsTotalAction>({
      type: SET_TOTAL_ADDONS_REQUEST,
    });

    const { currency, rentalId } = getQuoteInput(state);
    const addons = getAddons(state);
    const url = `${getCoreApi()}/quotes`;
    const bundleId = state.quote.data?.bundle_id;
    const quoteDelivery = state.quote.delivery;
    const id = state.quote.data?.id;
    const serviceIds = state.quote.data?.services.map(({ service_id }) => service_id) || [];
    let delivery;

    if (quoteDelivery) {
      const { location, stationary } = quoteDelivery;
      if (location)
        delivery = {
          stationary,
          location,
        };
    }

    const data = {
      presentment_currency: currency,
      rental_id: rentalId,
      from,
      to,
      items: addons,
      delivery,
      reserve: true,
      bundle_id: bundleId,
      id,
      services: Array.from(new Set(serviceIds)),
    };

    apiRequest<IQuote>({ url, data, method: 'POST' }, true)
      .then(response => {
        dispatch(getQuoteSuccess(response));
        if (response && prevQuote) {
          trackListingQuoteUpdatedEvent(prevQuote, response);
        }
        const totalValue = response?.addons?.reduce(
          (acc: number, curr: IItem) => acc + curr.total,
          0,
        );
        return totalValue;
      })
      .then(addonsValue =>
        dispatch<IGetAddonsTotalResponseAction>({
          type: SET_TOTAL_ADDONS_RESPONSE,
          payload: addonsValue,
        }),
      )
      .catch(error =>
        dispatch<IGetAddonsTotalFailAction>({
          type: SET_TOTAL_ADDONS_FAILURE,
          payload: error?.error || error,
          error: true,
        }),
      );
  };

export interface IAddonState {
  // Items selected by the user.
  addons?: IAddOnItem[] | undefined;
  // Total cost of the selected items for the current quote.
  totalValue?: number;
  // True if fetching a new quote with updated addons.
  isLoading?: boolean;
  // Stores the error message if updating the quote fails.
  error?: string;
}

export const initialState: IAddonState = {};

export default function reducer(state = initialState, action: TAction): IAddonState {
  switch (action.type) {
    case SET_ADDONS:
      return {
        ...state,
        addons: action.payload,
        isLoading: false,
        error: undefined,
      };
    case SET_TOTAL_ADDONS_REQUEST:
      return {
        ...state,
        totalValue: undefined,
        isLoading: true,
        error: undefined,
      };
    case SET_TOTAL_ADDONS_RESPONSE:
      return {
        ...state,
        totalValue: action.payload,
        isLoading: false,
        error: undefined,
      };
    case SET_TOTAL_ADDONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CLEAR_ADDONS:
      return {
        ...state,
        totalValue: undefined,
        addons: undefined,
        isLoading: false,
        error: undefined,
      };

    default:
      return state;
  }
}
