import { EToastVariant, Toast, useBonfire } from '@outdoorsyco/bonfire';
import React, { useCallback } from 'react';

export { EToastVariant } from '@outdoorsyco/bonfire';

export const useToast = () => {
  const { showToast, closeToast } = useBonfire();

  return {
    showToast: useCallback(
      (variant: EToastVariant, title: string, content: string) => {
        return showToast(<Toast variant={variant} title={title} content={content} />, {
          position: 'bottom-right',
          bodyClassName: 'w-[calc(100vw-2rem)] sm:w-[450px]',
          autoClose: 8000,
        });
      },
      [showToast],
    ),
    closeToast,
  };
};
