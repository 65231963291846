import sortBy from 'lodash/sortBy';

import { vehicleStyleToTypeMap } from '@/constants/vehicleDetails';
import { ERentalType } from '@/services/analytics/types';
import { ICampgroundImage } from '@/services/types/search/campgrounds/id';
import {
  EImageTags,
  IData as IRentalData,
  IImage,
  IImage as IRentalImage,
  TVehicleType,
} from '@/services/types/search/rentals/id';
import { getIntl } from '@/utility/i18n';

import { addObjectToGallery, createKuulaImageObject, createYoutubeVideoObject } from './odn';

type TImageCategory = { id: EImageTags; title: string };
export type TImageCategories = TImageCategory[];

const odnMedia: Record<string, IImage[]> = {
  outdoorsy_hill_country: [
    createKuulaImageObject(
      'https://kuula.co/share/collection/7c3df',
      'https://res.cloudinary.com/outdoorsy/image/upload/v1718137071/d/campgrounds/10/inventory/942/images/pyd8xzui5cvxwgydxjy9.jpg',
    ),
    createYoutubeVideoObject(
      'https://youtu.be/3J_BJ1zFcq4?si=EBywdjiBCTe4w73O',
      'https://res.cloudinary.com/outdoorsy/image/upload/v1718991595/Campground_Portal_Video_Support_m3m3m0.jpg',
    ),
  ],
  outdoorsy_bayfield: [
    createYoutubeVideoObject(
      'https://youtu.be/EmTVXXC79ZQ?si=Y53ICRXTN_dlrcjg',
      'https://res.cloudinary.com/outdoorsy/image/upload/v1718991659/Campground_Portal_Video_a6li9m.jpg',
    ),
  ],
  outdoorsy_yosemite: [
    createYoutubeVideoObject(
      'https://youtu.be/edupCmji4CY?si=ML3_Yz60p5NZxw6P',
      'https://res.cloudinary.com/outdoorsy/image/upload/v1718991675/Campground_Portal_Video_xt5ih0.png',
    ),
  ],
};

export const transformCampgroundImagesData = (
  images: ICampgroundImage[],
  odnId?: string,
): IImage[] => {
  const formattedImages = images.map(image => {
    return {
      best: image.best,
      description: image.description,
      category: image.category,
      id: image.id,
      position: image.position,
      primary: image.primary,
      rental_id: image.campground_id,
      skip_enhance: image.skip_enhance,
      tags: image?.tags,
      url: image.url,
      video: image.video,
      alt: image.description || '',
    };
  });

  const odnMediaItems = odnMedia[odnId || ''] || [];
  odnMediaItems.forEach((odnMediaItem, index) => {
    addObjectToGallery(formattedImages, odnMediaItem, 1 + index);
  });

  return formattedImages;
};

export const parseRentalStringAsRentalType = (category: string): ERentalType => {
  if (category === 'campground') return ERentalType.CAMPGROUND;
  if (category === 'stay') return ERentalType.STAY;
  return ERentalType.RV;
};
export function getFormattedImages(rentalData: IRentalData | null | undefined): IRentalImage[] {
  if (!rentalData) {
    return [];
  }

  const { vehicle_make, vehicle_model, vehicle_year, images } = rentalData;
  const vehicleText = [vehicle_make, vehicle_model, vehicle_year].filter(Boolean).join(' ');

  return images.map(image => ({
    ...image,
    alt: [image.description, vehicleText].filter(Boolean).join('. '),
  }));
}

function getImageCategoriesWithCountDisplayed(
  images: IImage[] | null | undefined,
): TImageCategories {
  if (!images) {
    return [];
  }

  const categorySlugs: EImageTags[] = [];

  let categoriesWithCountDisplayed = images.reduce<TImageCategory[]>((categories, item) => {
    if (item.category && !categorySlugs.includes(item.category.slug)) {
      categories.push({
        id: item.category.slug,
        title: `${item.category.name || item.category.slug} (${
          images.filter(
            image =>
              image.category &&
              item.category &&
              !image.video &&
              image.category.name === item.category.name,
          ).length
        })`,
      });

      categorySlugs.push(item.category.slug);
    }

    if (item.video && !categorySlugs.includes(EImageTags.VIDEO)) {
      categories.push({
        id: EImageTags.VIDEO,
        title: `Video (${images.filter(image => image.video).length})`,
      });

      categorySlugs.push(EImageTags.VIDEO);
    }

    return categories;
  }, []);
  const otherCategory = categoriesWithCountDisplayed.find(c => c.id === EImageTags.OTHER);

  if (otherCategory) {
    categoriesWithCountDisplayed = categoriesWithCountDisplayed.filter(
      category => category.id !== EImageTags.OTHER,
    );
    categoriesWithCountDisplayed.push(otherCategory);
  }

  return categoriesWithCountDisplayed;
}

export function getImageCategoryTabs(images: IImage[] | null | undefined): TImageCategories {
  const intl = getIntl();
  const imageCategories = getImageCategoriesWithCountDisplayed(images);
  const hasOnlyOtherCategory = imageCategories[0]?.id === EImageTags.OTHER;

  return !imageCategories.length || hasOnlyOtherCategory
    ? []
    : [
        {
          id: EImageTags.ALL,
          title: intl.formatMessage(
            {
              defaultMessage: 'All ({total})',
              id: 'NUGx/h',
              description: 'Gallery filter "All"',
            },
            { total: images?.length ?? 0 },
          ),
        },
        ...getImageCategoriesWithCountDisplayed(images),
      ];
}

export function sortImagesByPrimaryAndPosition(images: IRentalImage[]): IRentalImage[] {
  return images
    .filter(img => img.primary)
    .concat(
      sortBy(
        images.filter(o => !o.primary),
        'position',
      ),
    );
}

export function sortImagesByPosition(images: IRentalImage[]): IRentalImage[] {
  return sortBy(images, 'position');
}

export const getStayTypeDisplay = (displayValue?: string) => {
  if (displayValue) {
    return displayValue;
  }
  const intl = getIntl();
  return intl.formatMessage({ defaultMessage: 'Unique Stay', id: 'jr93Ek' });
};

export const getVehicleTypeDisplay = (displayValue?: string): string => {
  if (displayValue) {
    return displayValue;
  }
  const intl = getIntl();
  return intl.formatMessage({ defaultMessage: 'Motor Home', id: '7uU0Dt' });
};

export const getCampsiteTypeDisplay = (displayValue?: string) => {
  if (displayValue) {
    return displayValue;
  }

  const intl = getIntl();
  return intl.formatMessage({ defaultMessage: 'Campsite', id: 'KW/Gg9' });
};

export function isVehicleDrivable(type: TVehicleType): boolean {
  return vehicleStyleToTypeMap['drivable'].includes(type);
}
