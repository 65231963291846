export const breakpoint = {
  xs: 372,
  sm: 576,
  md: 767,
  lg: 992,
  xl: 1170,
  xxl: 1280,
  xxxl: 1439,
  xxxxl: 1920,
};
