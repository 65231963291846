let simpleIdCounter = 1;
export const generateSimpleId = () => (simpleIdCounter++ / 100000).toString(36).substr(5, 9);

export const lineIsClamped = element => element.scrollHeight > element.clientHeight;
export const contentOverflows = element =>
  !!element && element.scrollHeight !== element.clientHeight;

export const getClientLocale = () => {
  if (typeof window === 'undefined') return null;

  // do not use navigator.language or navigator.languages since we override the user's ability to
  // select their locale, instead its set by the server per domain and sent down via the HTML tag
  const locale = (document.documentElement.getAttribute('lang') || '')
    .trim()
    .toUpperCase()
    .split('-');

  return {
    country: locale.length === 2 ? locale[1] || 'US' : 'US',
    language: locale[0] || 'EN',
  };
};
