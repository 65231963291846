import { TLocale } from '@/config/locales';

export const getTranslations = (locale: TLocale = 'en-us') => {
  switch (locale) {
    case 'fr-fr':
      return import('@/translations/fr-fr.json');
    case 'de-de':
      return import('@/translations/de-de.json');
    case 'en-au':
      return import('@/translations/en-au.json');
    case 'en-ca':
      return import('@/translations/en-ca.json');
    case 'en-gb':
      return import('@/translations/en-gb.json');
    case 'en-nz':
      return import('@/translations/en-nz.json');
    case 'es-es':
      return import('@/translations/es-es.json');
    case 'fr-ca':
      return import('@/translations/fr-ca.json');
    case 'it-it':
      return import('@/translations/it-it.json');
    default:
      // if no match, assume en-us, in which case we can use embedded fallback messages
      return;
  }
};
