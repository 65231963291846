import React from 'react';

import css from './Toast.module.css';

interface IToastProps {
  title?: string;
  description?: string;
  className?: string;
  type: TToastType;
}

export interface IToast {
  title?: string;
  description?: string;
  type: TToastType;
}

type TToastType = 'TOAST_SUCCESS' | 'TOAST_ERROR' | 'TOAST_WARNING';

const Toast: React.FC<IToastProps> = ({
  className = '',
  title,
  description = '',
  type = 'TOAST_SUCCESS',
}) => {
  if (type === 'TOAST_SUCCESS') {
    return (
      <div
        className={`relative z-50 flex p-3 mt-3 mx-2 md:mx-0 md:mt-4 mb-4 md:mb-0 bg-white rounded-lg shadow-2xl md:w-full w-auto ${css.toastWidth} ${className}`}>
        <div className="w-1 mr-4 bg-green-800 rounded-lg" />
        <div>
          {title && <p className="text-sm text-gray-800 highlight">{title}</p>}
          {description && <p className="text-sm text-gray-800 semiHighlight">{description}</p>}
        </div>
      </div>
    );
  } else if (type === 'TOAST_ERROR') {
    return (
      <div
        className={`relative z-50 flex p-3 mt-3 mx-2 md:mx-0 md:mt-4 mb-4 md:mb-0 bg-white rounded-lg shadow-2xl md:w-full w-auto ${css.toastWidth} ${className}`}>
        <div className="w-1 mr-4 bg-red-600 rounded-lg" />
        <div>
          {title && <p className="text-sm text-red-600 highlight">{title}</p>}
          {description && <p className="text-sm text-gray-800 semiHighlight">{description}</p>}
        </div>
      </div>
    );
  }
  return null;
};

export default Toast;
