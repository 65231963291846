import { TRootState } from '@/redux/rootReducer';

export const hasMessagedPhoneUnverified = (state: TRootState) => {
  return state.auth?.user?.verifications?.has_sent_message_without_phone_verified === true;
};

export const getIsPhoneVerified = (state: TRootState) => {
  return state.auth?.user?.verifications?.phone === true;
};

export const authUserSelector = (state: TRootState) => state.auth?.user;
