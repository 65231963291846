import { ThunkAction } from 'redux-thunk';

import { fetchBooking } from '@/redux/modules/booking/actions';
import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { IProposal } from '@/services/types/proposals/proposals';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';
import { getAuthToken } from '@/utility/session';

const getProposalUrl = (bookingId: number, proposalId?: number): string =>
  `${getCoreApi()}/bookings/${bookingId}/proposals${proposalId ? `/${proposalId}` : ''}`;

const PATCH_PROPOSAL_LOADING = 'proposal/PATCH_PROPOSAL_LOADING';
const PATCH_PROPOSAL_ERROR = 'proposal/PATCH_PROPOSAL_ERROR';
const PATCH_PROPOSAL_SUCCESS = 'proposal/PATCH_PROPOSAL_SUCCESS';

interface IPatchProposalLoading extends IAction {
  type: typeof PATCH_PROPOSAL_LOADING;
}
interface IPatchProposalError extends IAction {
  type: typeof PATCH_PROPOSAL_ERROR;
}
interface IPatchProposalSuccess extends IAction {
  type: typeof PATCH_PROPOSAL_SUCCESS;
}

type TPatchAction = IPatchProposalError | IPatchProposalLoading | IPatchProposalSuccess;

const patchProposalLoading = (): IPatchProposalLoading => ({
  type: PATCH_PROPOSAL_LOADING,
});
const patchProposalError = (): IPatchProposalError => ({
  type: PATCH_PROPOSAL_ERROR,
});
const patchProposalSuccess = (): IPatchProposalSuccess => ({
  type: PATCH_PROPOSAL_SUCCESS,
});

interface IPatchProposalState {
  data: IProposal | null;
  error: boolean;
  isLoading: boolean;
}
export const initialPatchProposalState: IPatchProposalState = {
  data: null,
  error: false,
  isLoading: false,
};
function patchProposalReducer(state = initialPatchProposalState, action: TPatchAction) {
  switch (action.type) {
    case PATCH_PROPOSAL_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case PATCH_PROPOSAL_ERROR:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    case PATCH_PROPOSAL_SUCCESS:
      return {
        error: false,
        isLoading: false,
        data: action.payload,
      };
    default:
      return { ...state };
  }
}

export default {
  patchProposalReducer,
};

export const patchProposal =
  (
    proposal: IProposal,
  ): ThunkAction<
    Promise<{ isSuccess: boolean; errorMessage?: string | null }>,
    TRootState,
    void,
    TPatchAction
  > =>
  async dispatch => {
    const bookingId = proposal.booking_id;
    const url = getProposalUrl(bookingId, proposal.id);
    dispatch(patchProposalLoading());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(patchProposalError());
      return { isSuccess: false };
    }

    try {
      const data = { ...proposal };
      await apiRequest<IProposal>({ url, method: 'PATCH', data }, true);
      await dispatch(fetchBooking(String(bookingId), true));
      dispatch(patchProposalSuccess());
      return { isSuccess: true };
    } catch (error) {
      dispatch(patchProposalError());
      let errorMessage = '';
      if (error.code === 'DeliveryOutOfRange') {
        errorMessage = error.error;
      }
      return { isSuccess: false, errorMessage };
    }
  };
