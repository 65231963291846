import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

const SET_STAY_STATES = 'stayStates/SET_STAY_STATES';

interface IStayState {
  code: string;
  full_name: string;
}

interface ISetStayStatesAction extends IAction {
  type: typeof SET_STAY_STATES;
  payload: IStayState[];
}

type TAction = ISetStayStatesAction;

const setStayStates = (payload: IStayState[]): ISetStayStatesAction => ({
  type: SET_STAY_STATES,
  payload,
});

export const fetchAllowedStatesForStay =
  (): ThunkAction<Promise<void>, TRootState, void, ISetStayStatesAction> => async dispatch => {
    const params = {
      is_owner: false,
    };
    const url = `${getCoreApi()}/static/stay-states`;
    try {
      const data = await apiRequest<IStayState[]>({ url, params, method: 'GET' });
      dispatch(setStayStates(data ?? []));
    } catch {
      dispatch(setStayStates([]));
    }
  };

interface IState {
  data: IStayState[];
}

export const initialState: IState = {
  data: [],
};

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case SET_STAY_STATES:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return { ...state };
  }
}
