import classNames from 'classnames';
import Head from 'next/head';
import { StaticImageData } from 'next/image';
import React, { ImgHTMLAttributes, useEffect, useRef } from 'react';

import formatImageURL, { getSourceSet, ImageSize, TImageCropMode } from '@/utility/formatImageURL';

import css from './ResponsiveImage.module.css';

interface ExtendedImgHTMLAttributes extends ImgHTMLAttributes<HTMLImageElement> {
  fetchpriority?: 'high' | 'low' | 'auto';
}

export type ImageSizes = ImageSize[];

export interface IImageProps {
  /**
   * Original image source
   */
  src: string | StaticImageData;
  /**
   * Comma-separated list of rendered sizes
   * See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Img#attr-sizes
   */
  sizes?: string;
  /**
   * Single or list of predefined source sizes from `formatImageURL`
   */
  sourceSizes?: ImageSizes;
  /**
   * Crop mode
   */
  cropMode?: TImageCropMode;

  priority?: boolean;
  progressive?: boolean;
  withFetchPriority?: 'high' | 'low' | undefined;
}

const loadImage = (image: HTMLImageElement, src: string) => {
  image.setAttribute('src', src);
  image.onload = () => {
    image.removeAttribute('data-src');
  };
};

// Progressive loading implemented using intersection observer and data-src attribute with immediate CSS blur
// See https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Loading for more info.

const ResponsiveImage: React.FC<ExtendedImgHTMLAttributes & IImageProps> = ({
  alt = '',
  src,
  sizes,
  sourceSizes: sourceSizesProp,
  cropMode = 'fill',
  priority = false,
  progressive = false,
  withFetchPriority,
  ...props
}) => {
  const sourceSizes: ImageSizes =
    sourceSizesProp && sourceSizesProp.length ? sourceSizesProp : ['default'];
  const srcSet = sourceSizes.length > 1 ? getSourceSet(src, sourceSizes, cropMode) : undefined;
  const transformedSrc = formatImageURL(src, sourceSizes[0] || 'default', cropMode);
  const loadingImage = formatImageURL(src, 'square20', cropMode);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (progressive) {
      if (imgRef?.current) {
        if ('IntersectionObserver' in window) {
          const observer = new IntersectionObserver((items, obs) => {
            items.forEach(item => {
              if (item.isIntersecting && item.target === imgRef.current) {
                loadImage(imgRef.current, transformedSrc);
                obs.unobserve(imgRef.current);
              }
            });
          });
          observer.observe(imgRef.current);
        } else {
          loadImage(imgRef.current, transformedSrc);
        }
      }
    }
  }, [progressive, transformedSrc]);

  return (
    <>
      {priority && (
        <Head>
          <link
            data-testid="preload"
            rel="preload"
            as="image"
            href={srcSet ? undefined : progressive ? loadingImage : transformedSrc}
            imageSrcSet={srcSet}
            imageSizes={sizes}
          />
        </Head>
      )}
      <img
        {...props}
        className={classNames(css.blur, props.className)}
        ref={imgRef}
        loading={!priority ? 'lazy' : undefined}
        data-testid="img"
        alt={alt}
        src={srcSet ? undefined : progressive ? loadingImage : transformedSrc}
        data-src={progressive ? 'true' : undefined}
        srcSet={srcSet}
        sizes={sizes}
        {...(withFetchPriority && { fetchpriority: withFetchPriority })}
      />
    </>
  );
};

export default ResponsiveImage;
