import Cookies, { CookieAttributes } from 'js-cookie';

import { IAction } from '@/utility/redux/action';

export const UPDATE_JAR = 'session/UPDATE_JAR';
export const UPDATE_COOKIE = 'session/UPDATE_COOKIE';
const REMOVE_COOKIE = 'session/REMOVE_COOKIE';

export type TCookieValue = string | Record<string, any>;

interface ICookie {
  name: string;
  value: TCookieValue;
}

export interface ICookieInput extends ICookie {
  options?: CookieAttributes;
}

interface IUpdateJarAction extends IAction {
  type: typeof UPDATE_JAR;
  payload: TCookieJar;
}

interface IUpdateCookieAction extends IAction {
  type: typeof UPDATE_COOKIE;
  payload: ICookie;
}

interface IRemoveCookieAction extends IAction {
  type: typeof REMOVE_COOKIE;
  payload: string;
}

type TAction = IUpdateJarAction | IUpdateCookieAction | IRemoveCookieAction;

type TCookieJar = { [key: string]: TCookieValue | null };

export const DEFAULT_OPTIONS: CookieAttributes = {
  expires: 365, // one year
  sameSite: 'strict',
  secure: process.env.NODE_ENV === 'production',
};

export const initialState: Partial<TCookieJar> = {};

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case UPDATE_JAR:
      return action.payload;
    case UPDATE_COOKIE:
      return { ...state, [action.payload.name]: action.payload.value };
    case REMOVE_COOKIE:
      return { ...state, [action.payload]: null };
    default:
      return state;
  }
}

const updateJar = (payload: TCookieJar): IUpdateJarAction => ({
  type: UPDATE_JAR,
  payload,
});

const updateCookie = (payload: ICookie): IUpdateCookieAction => ({
  type: UPDATE_COOKIE,
  payload,
});

export const setCookie = (payload: ICookieInput) => {
  const { name, value, options } = payload;
  Cookies.set(name, value, { ...DEFAULT_OPTIONS, ...options });

  return updateCookie(payload);
};

export const removeCookie = (name: string): IRemoveCookieAction => {
  Cookies.remove(name);

  return {
    type: REMOVE_COOKIE,
    payload: name,
  };
};

export const loadCookies = () => updateJar(Cookies.getJSON() ?? {});
