import Head from 'next/head';
import React, { useEffect } from 'react';

import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';
import { trackPage } from '@/services/track-event';

const GTM_DELAY = 4000;
const gtmTag = process.env.gtmTag || '';

// Temporary to avoid button click delay for get-outta-here campaign
const PAGES_EXEMPTED_FROM_GTM_DELAY = ['/get-outta-here'];

const googleTagManagerSetup = (win, doc, scr, layer, key, isWithoutDelay) => {
  win[layer] = win[layer] || [];
  win[layer].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });

  return setTimeout(
    () => {
      let firstScriptTag = doc.getElementsByTagName(scr)[0];
      let injectedScript = doc.createElement(scr);
      let layerParam = layer !== 'dataLayer' ? `&l=${layer}` : '';

      injectedScript.async = true;
      injectedScript.src = `https://www.googletagmanager.com/gtm.js?id=${key}${layerParam}`;

      firstScriptTag.parentNode.insertBefore(injectedScript, firstScriptTag);
    },
    isWithoutDelay ? 0 : GTM_DELAY,
  );
};

const GTMScript = args => {
  const gtmScriptIsEnabled = useExperimentIsEnabled(OptimizelyFlags.GTM_ENABLED);

  useEffect(() => {
    if (!gtmScriptIsEnabled) return;

    const isWithoutDelay = PAGES_EXEMPTED_FROM_GTM_DELAY.includes(args.pathWithoutQuery);

    const timeout = googleTagManagerSetup(
      window,
      document,
      'script',
      'dataLayer',
      gtmTag,
      isWithoutDelay,
    );
    trackPage();
    return () => clearTimeout(timeout);
  }, [gtmScriptIsEnabled, args.pathWithoutQuery]);

  if (!gtmScriptIsEnabled) return null;

  return (
    <Head>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmTag}" height="0" width="0" style="display:none;visibility:hidden;"></iframe>`,
        }}
      />
    </Head>
  );
};

export default GTMScript;
