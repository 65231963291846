import { parse } from 'query-string';
import { ThunkAction } from 'redux-thunk';

import { SIGNUP_SOURCE_RIBBIT } from '@/constants/services';
import { TRootState } from '@/redux/rootReducer';
import {
  trackLoggedInEvent,
  trackLoginCredentialsEntered,
  trackSignedUpEvent,
} from '@/services/analytics/accounts';
import apiRequest from '@/services/apiRequest';
import { ILoginForm, TAccountType } from '@/services/types/auth/ILoginForm';
import { ILoginResponse } from '@/services/types/auth/ILoginResponse';
import { ISignupRequestBody } from '@/services/types/auth/ISignupRequestBody';
import { getCoreApi } from '@/utility/getCoreApi';
import { getItemFromLocalStorage, removeItemFromLocalStorage } from '@/utility/localstorage';
import { IAction } from '@/utility/redux/action';
import { reset2FASessionCookie, resetSessionCookie, set2FASessionCookie } from '@/utility/session';
import { setSessionCookie } from '@/utility/session.login';
import { triggerCustomEvent } from '@/utility/triggerCustomEvent';

import { TAuthError } from './index';

const LOGIN_SERVICE_URL = `${getCoreApi()}/auth/creds/login`;
const SIGNUP_SERVICE_URL = `${getCoreApi()}/auth/creds/create`;

export const SIGNIN_USER_PENDING = 'auth/SIGNIN_USER_PENDING';
export const SIGNIN_USER_FAILURE = 'auth/SIGNIN_USER_FAILURE';
export const SIGNIN_USER_SUCCESS = 'auth/SIGNIN_USER_SUCCESS';

export const SIGNUP_USER_PENDING = 'auth/SIGNUP_USER_PENDING';
export const SIGNUP_USER_FAILURE = 'auth/SIGNUP_USER_FAILURE';
export const SIGNUP_USER_SUCCESS = 'auth/SIGNUP_USER_SUCCESS';

export const RESET_USER = 'auth/RESET_USER';

export type ILoginAction =
  | ILoginSuccessAction
  | ILoginFailureAction
  | ILoginPendingAction
  | IResetUserAction;

type ISignupAction =
  | ISignupSuccessAction
  | ISignupFailureAction
  | ISignupPendingAction
  | IResetUserAction;

//#region interface for Login Call
interface ILoginSuccessAction extends IAction {
  type: typeof SIGNIN_USER_SUCCESS;
  payload: ILoginResponse;
}

interface ILoginPendingAction extends IAction {
  type: typeof SIGNIN_USER_PENDING;
}

interface ILoginFailureAction extends IAction {
  type: typeof SIGNIN_USER_FAILURE;
  payload: TAuthError;
  error: boolean;
}
//#endregion

//#region interface for Signup Call
interface ISignupSuccessAction extends IAction {
  type: typeof SIGNUP_USER_SUCCESS;
  payload: any;
}

interface ISignupPendingAction extends IAction {
  type: typeof SIGNUP_USER_PENDING;
}

interface ISignupFailureAction extends IAction {
  type: typeof SIGNUP_USER_FAILURE;
  payload: TAuthError;
  error: boolean;
}
//#endregion

interface IResetUserAction extends IAction {
  type: typeof RESET_USER;
}

//#region actions for login call
const signinUserPending = (): ILoginPendingAction => ({ type: SIGNIN_USER_PENDING });

const signinUserFailure = (error: TAuthError): ILoginFailureAction => ({
  type: SIGNIN_USER_FAILURE,
  payload: error,
  error: true,
});

const signinUserSuccess = (loginResponse: ILoginResponse): ILoginSuccessAction => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: loginResponse,
  };
};
//#endregion

//#region actions for signup call
const signupUserPending = (): ISignupPendingAction => ({ type: SIGNUP_USER_PENDING });

const signupUserFailure = (error: TAuthError): ISignupFailureAction => ({
  type: SIGNUP_USER_FAILURE,
  payload: error,
  error: true,
});

const signupUserSuccess = (signupResponse: ILoginResponse): ISignupSuccessAction => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: signupResponse,
  };
};
//#endregion

// track logout in application layout and dispatch this
export const resetUser = (): IResetUserAction => {
  resetSessionCookie();
  return { type: RESET_USER };
};

// async SEND CODE THUNK (mini_me)

//async Login USER THUNK
export const loginUser =
  (creds: ILoginForm): ThunkAction<void, TRootState, void, ILoginAction> =>
  async dispatch => {
    dispatch(signinUserPending());
    try {
      const loginResponse = await apiRequest<ILoginResponse>(
        { url: LOGIN_SERVICE_URL, method: 'POST', data: creds },
        true,
      );

      if (loginResponse.show_2fa_screen) {
        let next = parse(window.location.search)['next'] as string;
        // Send the user back to the URL they were on before they got redirected to /security
        // when the 'next' query param is undefined and pathname is not '/users/new' .
        if (!next && window.location.pathname !== '/users/new') {
          next = btoa(window.location.pathname + (window.location.search || ''));
        }

        trackLoginCredentialsEntered({ accountMethod: 'email' });
        set2FASessionCookie({ ...loginResponse, next: next });
        window.location.href = '/security';
        return;
      }

      if (loginResponse?.token && loginResponse?.user_id) {
        await dispatch(completeLogin(loginResponse));
        trackLoggedInEvent({ accountMethod: 'email' });

        // if trace of previous social login exists, remove it
        removeItemFromLocalStorage('social_auth_method');
      } else {
        dispatch(signinUserFailure({ code: 401 }));
      }
    } catch (error) {
      dispatch(signinUserFailure(error));
    }
  };

export const completeLogin =
  (loginResponse: ILoginResponse): ThunkAction<void, TRootState, void, ILoginAction> =>
  async dispatch => {
    dispatch(signinUserSuccess(loginResponse));
    await setSessionCookie(loginResponse, dispatch);
    triggerCustomEvent('session', 'load-user');

    // delete 2fa session cookie after login is completed
    reset2FASessionCookie();
  };

export const signupUser =
  (signupParams: {
    name: string;
    email: string;
    phone: string;
    password: string;
    country?: string;
    token?: string;
    enableSms: boolean;
  }): ThunkAction<void, TRootState, void, ISignupAction> =>
  async dispatch => {
    const nameParts = signupParams.name.split(' ');
    const firstName = nameParts.shift() || '';
    const lastName = nameParts.pop() || '';

    const postParams: ISignupRequestBody = {
      first_name: firstName,
      last_name: lastName,
      phone: signupParams.phone,
      email: (signupParams.email || '').toLowerCase(),
      password: signupParams.password,
      recaptcha_response: signupParams.token,
      country: signupParams.country,
      skipGuest: true,
      dealer_user: false,
      signup_source: SIGNUP_SOURCE_RIBBIT,
      enable_sms: signupParams.enableSms,
    };
    dispatch(signupUserPending());
    try {
      const signupResponse = await apiRequest<ILoginResponse>(
        { url: SIGNUP_SERVICE_URL, method: 'POST', data: postParams },
        true,
      );
      if (signupResponse?.user_id) {
        dispatch(signupUserSuccess(signupResponse));
        await setSessionCookie(signupResponse, dispatch);
        triggerCustomEvent('session', 'load-user');
        trackSignedUpEvent({ accountMethod: 'email' });
      } else {
        dispatch(signupUserFailure({ code: 401 }));
      }
    } catch (error) {
      dispatch(signupUserFailure(error));
    }
  };

export const trackSocialLogin = (requires2FA: boolean) => {
  const isSocialLogin = getItemFromLocalStorage('social_auth_success');
  const socialAuthMethod = getItemFromLocalStorage('social_auth_method');

  if (
    isSocialLogin &&
    socialAuthMethod &&
    ['apple', 'facebook', 'google'].includes(socialAuthMethod)
  ) {
    const method = socialAuthMethod as TAccountType;
    if (requires2FA) {
      trackLoginCredentialsEntered({ accountMethod: method });
    } else {
      trackLoggedInEvent({ accountMethod: method });
      removeItemFromLocalStorage('social_auth_method');
    }

    removeItemFromLocalStorage('social_auth_success');
  }
};
