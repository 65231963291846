// Converts snake_case and kebab-case to camelCase
export const toCamelCase = (text: string) => {
  return text.replace(/([-_][a-z])/gi, s => {
    return s.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const camelToSnakeCase = (text: string) => {
  return text.replace(/([A-Z])/g, '_$1').toLowerCase();
};
