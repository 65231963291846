import React from 'react';

import { ALERT_CIRCLE } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import Text from '@/components/switchback/Text/Text';

const InlineErrorMessage: React.FC<
  React.PropsWithChildren<{ className?: string; showIcon?: boolean }>
> = ({ children, className, showIcon = true }) => {
  return (
    <Text aria-live="polite" type="block" className={`text-red-800 text-200 ${className || ''}`}>
      {showIcon && (
        <Icon
          size={IconSize.small}
          name={ALERT_CIRCLE}
          className="relative inline w-4 mr-1 -top-1"
        />
      )}

      {children}
    </Text>
  );
};

export default InlineErrorMessage;
