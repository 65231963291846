import { AxiosError } from 'axios';
import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';
import { getAuthToken, getSessionCookie } from '@/utility/session';

import { fetchUser } from './userMini';

const UPDATE_USER_SERVICE_URL = `${getCoreApi()}/users/`;

const UPDATE_USER_PENDING = 'auth/UPDATE_USER_PENDING';
export const UPDATE_USER_FAILURE = 'auth/UPDATE_USER_FAILURE';
export const UPDATE_USER_SUCCESS = 'auth/UPDATE_USER_SUCCESS';

interface IUpdateUserPendingAction extends IAction {
  type: typeof UPDATE_USER_PENDING;
}

interface IUpdateUserSuccessAction extends IAction {
  type: typeof UPDATE_USER_SUCCESS;
}

interface IUpdateUserFailureAction extends IAction {
  type: typeof UPDATE_USER_FAILURE;
  payload: AxiosError | undefined;
  error: boolean;
}

//#region actions for fetch user call (mini_me)
const updateUserPending = (): IUpdateUserPendingAction => ({ type: UPDATE_USER_PENDING });

const updateUserFailure = (error: AxiosError): IUpdateUserFailureAction => ({
  type: UPDATE_USER_FAILURE,
  payload: error,
  error: true,
});

// TODO: generate types from JSON response (payload not needed right now)
const updateUserSuccess = (): IUpdateUserSuccessAction => {
  return {
    type: UPDATE_USER_SUCCESS,
  };
};
//#endregion

export type IUpdateUserAction =
  | IUpdateUserPendingAction
  | IUpdateUserSuccessAction
  | IUpdateUserFailureAction;

// async FETCH USER THUNK (mini_me)
export const updateUser =
  (
    data: any,
    shouldSkipLoading?: boolean,
  ): ThunkAction<Promise<boolean>, TRootState, void, IUpdateUserAction> =>
  async dispatch => {
    if (!shouldSkipLoading) {
      await dispatch(updateUserPending());
    }
    // not using cookie values on redux here because it cannot be trusted as SSOT
    const authToken = getAuthToken();
    const session = getSessionCookie();

    const userId = session?.authenticated?.user_id;

    if (!authToken || !userId) {
      await dispatch(updateUserFailure({ code: 401 } as unknown as AxiosError));
      return false;
    }

    try {
      const user: any = await apiRequest(
        {
          url: UPDATE_USER_SERVICE_URL + userId,
          method: 'PATCH',
          data,
        },
        true,
      );

      if (user?.id) {
        await dispatch(fetchUser(shouldSkipLoading, true));
        dispatch(updateUserSuccess());
        return true;
      } else {
        dispatch(updateUserFailure({ code: 401 } as unknown as AxiosError));
        return false;
      }
    } catch (error) {
      dispatch(
        updateUserFailure({ code: error?.code, message: error?.error } as unknown as AxiosError),
      );
      return false;
    }
  };
