import { triggerCustomEvent } from '@/utility/triggerCustomEvent';

import { EVENT_NAME_CLOSE_LOGIN, EVENT_NAME_SIGN_UP } from './session';
/**
 * TODO: Add redux  action for this
 */
export const triggerSignupModal = () => {
  triggerCustomEvent('authenticate', EVENT_NAME_CLOSE_LOGIN);
  triggerCustomEvent('authenticate', EVENT_NAME_SIGN_UP);
};
