import React, { AnchorHTMLAttributes, forwardRef } from 'react';

import { isExternalLink } from '@/utility/isExternalLink';

const Anchor = forwardRef<HTMLAnchorElement, AnchorHTMLAttributes<HTMLAnchorElement>>(
  function Anchor({ href, children, target, rel, ...props }, ref) {
    const finalTarget = target || (isExternalLink(href) ? '_blank' : undefined);
    const finalRef = rel || (finalTarget === '_blank' ? 'noreferrer' : undefined);

    return (
      <a ref={ref} href={href} target={finalTarget} rel={finalRef} {...props}>
        {children}
      </a>
    );
  },
);

export default Anchor;
