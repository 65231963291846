import Popover, { PopoverOrigin, PopoverProps } from '@material-ui/core/Popover';
import { TransitionHandlerProps } from '@material-ui/core/transitions/transition';
import React from 'react';

export interface IPopupProps extends Omit<PopoverProps, 'open'> {
  /**
   * Whether or not the popup is currently visible
   */
  show?: boolean;
  /**
   * Fired when the popup open transition is done
   */
  onEntered?: TransitionHandlerProps['onEntered'];
  /**
   * Fired when the popup is closed
   */
  onDismiss?: (reason: 'backdropClick' | 'escapeKeyDown') => void;
  /**
   * Fired when the popup is exited
   */
  onExited?: TransitionHandlerProps['onExited'];
  /**
   * The element to which the popup is anchored
   */
  relativeTo: Element | null;
  /**
   * Optionally override the default popup styles
   */
  className?: string;
  /**
   * The origin for where on the anchor the popup will display
   */
  anchorOrigin?: PopoverOrigin;
  /**
   * The origin to align the popup itself
   */
  transformOrigin?: PopoverOrigin;
  /**
   * Enables pop up to render children when pop up is not visible, for seo purposes.
   */
  renderChildrenBeforePopupOpen?: boolean;
}

const Popup: React.FC<IPopupProps> = ({
  show = false,
  onEntered,
  onDismiss,
  onExited,
  relativeTo,
  children,
  className,
  renderChildrenBeforePopupOpen,
  anchorOrigin = {
    horizontal: 'center',
    vertical: 'bottom',
  },
  transformOrigin = {
    horizontal: 'center',
    vertical: 'top',
  },
  ...rest
}) => {
  /**
   * Renders children before pop up is triggered, for seo purposes
   */

  if (renderChildrenBeforePopupOpen && !show) {
    return <div className="hidden">{children}</div>;
  }

  return (
    <Popover
      data-testid="popup"
      elevation={0}
      open={show}
      anchorEl={relativeTo}
      onClose={onDismiss}
      TransitionProps={{
        onExited,
        onEntered,
      }}
      PaperProps={{
        className: className || 'p-8 shadow-100 rounded-box',
        variant: 'outlined',
        square: true,
        style: { border: 0 },
      }}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      {...rest}>
      {children}
    </Popover>
  );
};

export default Popup;
