import dayjs from 'dayjs';
import React, { useState } from 'react';

import DatePicker from '@/components/switchback/DatePicker/DatePicker';
import { IHeaderSearchFilterDates } from '@/redux/modules/globalHeader';

import { INavbarSearch } from '../../../SearchHeader';
import DateContentFooter from './DateContentFooter';

interface IDateContentProps {
  className?: string;
  open?: boolean;
  dates?: IHeaderSearchFilterDates;
  onChangeDates: INavbarSearch['onChangeDates'];
}

const DateContent: React.FC<IDateContentProps> = ({
  className = '',
  open,
  dates,
  onChangeDates,
}) => {
  const [datesValue, setDatesValue] = useState<IHeaderSearchFilterDates | undefined>(dates);

  const handleChangeDates = (newDates: IHeaderSearchFilterDates) => {
    setDatesValue({ ...datesValue, ...newDates });
    onChangeDates({ ...datesValue, ...newDates });
  };

  const handleClearDates = () => {
    setDatesValue({});
    onChangeDates({});
  };

  return (
    <div className={`p-4 ${open ? 'block' : 'hidden'}`}>
      <DatePicker
        className={className}
        range
        numberOfMonths={2}
        isStacked={false}
        onChange={handleChangeDates}
        disabledDays={[]}
        dateTo={datesValue?.to}
        dateFrom={datesValue?.from}
        toMonth={dayjs().add(2, 'year').toDate()}
        size="small"
      />
      <DateContentFooter
        datesValue={datesValue}
        handleChangeDates={handleChangeDates}
        handleClearDates={handleClearDates}
      />
    </div>
  );
};

export default DateContent;
