import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  BOOKER_DRIVABLE,
  BOOKER_TOWABLE,
  CABIN,
  CAMPER_VAN,
  CAMPING,
  CANVAS_TENT,
  MAP_PIN,
  SEARCH,
  STAYS,
} from '@/components/switchback/Icon/assets';
import { IconType } from '@/components/switchback/Icon/IconComponent';
import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';
import { isProduction } from '@/utility/isSSR';

export enum EHeaderTab {
  ALL = 'all',
  DRIVABLE = 'drivable',
  TOWABLE = 'towable',
  DELIVERABLES = 'deliverables',
  PET_FRIENDLY = 'pet_friendly',
  FESTIVAL_FRIENDLY = 'festival_friendly',
  RV_SITE = 'rv_site',
  TENT_SITE = 'tent_site',
  LODGING_SITE = 'lodging_site',
  RVS = 'rv',
  STAYS = 'stay',
  CAMPGROUNDS = 'campgrounds',
}

export interface IHeaderMenu {
  tab: EHeaderTab;
  title: React.ReactNode;
  icon: IconType;
}

export const TOP_MENU_RVS = {
  tab: EHeaderTab.RVS,
  title: <FormattedMessage defaultMessage="RVs" id="VkfQIb" />,
  icon: CAMPER_VAN,
};

const TOP_MENU_STAYS = {
  tab: EHeaderTab.STAYS,
  title: <FormattedMessage defaultMessage="Stays" id="qnXkIR" />,
  icon: STAYS,
};

const TOP_MENU_CAMPGROUNDS = {
  tab: EHeaderTab.CAMPGROUNDS,
  title: <FormattedMessage defaultMessage="Campgrounds" id="124tCD" />,
  icon: CAMPING,
};

export const useSearchModuleTabs = () => {
  const isCampgroundsWebEnabled = useExperimentIsEnabled(
    OptimizelyFlags.CAMPGROUNDS_SEARCH_TAB_AND_HOME_TAB,
  );
  const showCampgroundsTab = !isProduction() && isCampgroundsWebEnabled;

  return showCampgroundsTab
    ? [TOP_MENU_RVS, TOP_MENU_STAYS, TOP_MENU_CAMPGROUNDS]
    : [TOP_MENU_RVS, TOP_MENU_STAYS];
};

export const HEADER_TOP_MENU_ALL: IHeaderMenu = {
  tab: EHeaderTab.ALL,
  title: <FormattedMessage defaultMessage="All" id="zQvVDJ" />,
  icon: SEARCH,
};

export const useSearchModuleHeaderTabs = () => {
  const HEADER_TOP_MENUS: IHeaderMenu[] = [
    HEADER_TOP_MENU_ALL,
    {
      tab: EHeaderTab.DRIVABLE,
      title: <FormattedMessage defaultMessage="Drivable" id="AZvTFz" />,
      icon: BOOKER_DRIVABLE,
    },
    {
      tab: EHeaderTab.TOWABLE,
      title: <FormattedMessage defaultMessage="Towable" id="nUrQjk" />,
      icon: BOOKER_TOWABLE,
    },
    {
      tab: EHeaderTab.DELIVERABLES,
      title: <FormattedMessage defaultMessage="Delivery" id="drqP2L" />,
      icon: MAP_PIN,
    },
  ];
  return HEADER_TOP_MENUS;
};

export const Header_TOP_MENUS_CAMPGROUNDS: IHeaderMenu[] = [
  {
    tab: EHeaderTab.ALL,
    title: <FormattedMessage defaultMessage="All" id="zQvVDJ" />,
    icon: SEARCH,
  },
  {
    tab: EHeaderTab.RV_SITE,
    title: <FormattedMessage defaultMessage="RV Sites" id="C7ZisL" />,
    icon: BOOKER_DRIVABLE,
  },
  {
    tab: EHeaderTab.TENT_SITE,
    title: <FormattedMessage defaultMessage="Tent Sites" id="VxqFE6" />,
    icon: CANVAS_TENT,
  },
  {
    tab: EHeaderTab.LODGING_SITE,
    title: <FormattedMessage defaultMessage="Lodging" id="60BVSI" />,
    icon: CABIN,
  },
];
