import React from 'react';

import Anchor from '@/components/switchback/Anchor/Anchor';

interface IFooterListItem {
  dataTestId?: string;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const FooterListItem: React.FC<React.PropsWithChildren<IFooterListItem>> = ({
  href,
  children,
  dataTestId,
  onClick,
}) => {
  return (
    <li className="block mt-4 text-white truncate border-black">
      <Anchor
        data-testid={dataTestId}
        href={href}
        className="text-base text-white cursor-pointer leading-6 hover:underline underline-offset-2"
        onClick={onClick}>
        {children}
      </Anchor>
    </li>
  );
};

export default FooterListItem;
