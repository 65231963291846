import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCacheEviction } from '@/hooks/useCacheEviction';
import { useUpdateEffect } from '@/hooks/useUpdateEffect';
import { fetchAllowedStatesForStay } from '@/redux/modules/stayStates';
import { getIsAuthenticated } from '@/redux/selectors/auth/user';

export const Global: React.FC = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { evict } = useCacheEviction(['me']);
  const dispatch = useDispatch();

  // evict the cache when the user is logged out
  useUpdateEffect(() => {
    if (!isAuthenticated) {
      evict();
    }
  }, [isAuthenticated, evict]);

  useEffect(() => {
    dispatch(fetchAllowedStatesForStay());
  }, [dispatch]);

  return null;
};
