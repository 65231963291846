import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { ESearchFilters } from '@/constants/searchFilters';
import { stayCategories } from '@/constants/vehicleDetails';
import { getSearchResultsMeta } from '@/redux/selectors/search/meta';
import { getSearchQuery } from '@/redux/selectors/search/searchResults';
import { TStayType } from '@/services/types/search/rentals/id';
import { formatDateFullRange } from '@/utility/format-date';

import { useRouter } from './useRouter';

// filters excluded from the filter count
const EXCLUDED_FILTERS = new Set([
  ESearchFilters.ADDRESS,
  ESearchFilters.SLEEPS_ADULTS,
  ESearchFilters.SLEEPS_CHILDREN,
  ESearchFilters.PAGE_OFFSET,
  ESearchFilters.PAGE_LIMIT,
  ESearchFilters.DATE_FROM,
  ESearchFilters.DATE_TO,
  ESearchFilters.GEOIP_ADDRESS,
  ESearchFilters.BOUNDS_NE,
  ESearchFilters.BOUNDS_SW,
  ESearchFilters.RENTAL_CATEGORY,
  // delivery params on request with delivery=false should not trigger filter count
  ESearchFilters.DELIVERY_QUERY,
  ESearchFilters.DELIVERY_CENTER,
  ESearchFilters.DELIVERY_ADDRESS,
  ESearchFilters.DELIVERY_STATIONARY,
  ESearchFilters.DELIVERY_LOCATION_ID,
]);
// some filters can appear with a value of 'false' and should not be counted
const BOOLEAN_FILTERS = new Set([
  ESearchFilters.DELIVERY,
  ESearchFilters.INSTANT_BOOK,
  ESearchFilters.UNLIMITED_MILES,
  ESearchFilters.UNLIMITED_GENERATOR,
  ESearchFilters.STAY_PARKING_ONSITE,
]);
// these are used to create a set for each run of the filter count function
enum COMPOUND_FILTER {
  PRICE = 'price',
  TOWING = 'towing',
  TRAILER_WEIGHT = 'trailer-weight',
}
// filters that may come in the form of one or more parameters, not all of which need be present
const COMPOUND_FILTERS: { [key: string]: COMPOUND_FILTER } = {
  [ESearchFilters.PRICE_MIN]: COMPOUND_FILTER.PRICE,
  [ESearchFilters.PRICE_MAX]: COMPOUND_FILTER.PRICE,
  [ESearchFilters.TOWING_ID]: COMPOUND_FILTER.TOWING,
  [ESearchFilters.SUGGESTED_TOWING_CAPACITY]: COMPOUND_FILTER.TOWING,
  [ESearchFilters.WEIGHT_GREATER]: COMPOUND_FILTER.TRAILER_WEIGHT,
  [ESearchFilters.WEIGHT_LESSER]: COMPOUND_FILTER.TRAILER_WEIGHT,
};
// filters that contain comma separated lists
const LIST_FILTERS = new Set([
  ESearchFilters.FILTER_FEATURE,
  ESearchFilters.FILTER_TYPE,
  ESearchFilters.FILTER_PROPERTY,
  ESearchFilters.LENGTH_BETWEEN,
]);
// stay types (multiple values given for a single type filter)
const CABIN_TYPES = stayCategories.cabins.types;
const TENT_TYPES = stayCategories.tents.types;
const CAMPER_TYPES = stayCategories.campers.types;
const UNIQUE_STAY_TYPES = stayCategories['unique-stays'].types;
// filters contained in comma separated lists that may have multiple values for a single filter
const COMPOUND_LIST_FILTERS = [CABIN_TYPES, TENT_TYPES, CAMPER_TYPES, UNIQUE_STAY_TYPES];

export function useRecentSearchOption() {
  const { country, state, city } = useSelector(getSearchResultsMeta);
  const searchQuery = useSelector(getSearchQuery);

  const router = useRouter();
  const intl = useIntl();
  let addressValue = '';
  let addressLabel = '';
  const { query } = router;
  let searchOptionValue = '';
  let searchOptionLabel = '';
  if (query.address && typeof query.address === 'string') {
    if (!city) {
      addressValue = query.address;
      addressLabel = query.address;
    } else {
      addressValue = [city, state || '', country || ''].filter(Boolean).join(', ');
      addressLabel = [city, state || ''].filter(Boolean).join(', ');
    }

    const getFiltersCount = () => {
      let count = 0;
      if (Object.keys(query).length >= 1) {
        // for compound filters we'll track whether we have already seen a related param
        const compoundFilters = new Set<COMPOUND_FILTER>();

        for (const rawParam in query) {
          const param = rawParam as ESearchFilters;
          const value = query[param];

          // filter out empty keys, excluded filters, boolean params not set to 'true',
          // and repeat compound filters
          const isParamNotEmpty = rawParam !== '' && typeof value === 'string';
          const isFilterNotExcluded = !EXCLUDED_FILTERS.has(param);
          const isNotBooleanFilterOrIsTrue = !BOOLEAN_FILTERS.has(param) || value === 'true';
          let isFirstCompoundFilter = true;
          const compoundFilter = COMPOUND_FILTERS[param];
          if (compoundFilter) {
            if (compoundFilters.has(compoundFilter)) {
              isFirstCompoundFilter = false;
            } else {
              compoundFilters.add(compoundFilter);
            }
          }

          if (
            isParamNotEmpty &&
            isFilterNotExcluded &&
            isNotBooleanFilterOrIsTrue &&
            isFirstCompoundFilter
          ) {
            if (LIST_FILTERS.has(param)) {
              let values = value.split(',');

              COMPOUND_LIST_FILTERS.forEach(filterValues => {
                // if any of the filter values are found we will count them as 1 filter
                const remaining = values.filter(val => !filterValues.includes(val as TStayType));
                if (remaining.length !== values.length) {
                  count++;
                  values = remaining;
                }
              });

              // count what is left
              count += values.length;
            } else {
              count++;
            }
          }
        }
      }

      return count;
    };
    const filtersCount = getFiltersCount();
    const { 'sleeps[adults]': adults = 0, 'sleeps[children]': children = 0 } = query;
    const guestsCount = Number(adults) + Number(children);
    const filtersCountLabel = intl.formatMessage(
      { defaultMessage: '{count, plural, =1 {1 filter} other {# filters}}', id: 'a3ufaD' },
      { count: filtersCount },
    );

    const guestsCountLabel = intl.formatMessage(
      { defaultMessage: '{count, plural, =1 {1 guest} other {# guests}}', id: 'TzaBjH' },
      { count: guestsCount },
    );

    const date = formatDateFullRange(
      query?.['date[from]'] as string,
      query?.['date[to]'] as string,
    );

    searchOptionValue = date ? `${addressValue} • ${date}` : addressValue;
    searchOptionLabel = date ? `${addressLabel} • ${date}` : addressLabel;

    if (guestsCount) {
      searchOptionLabel += ` • ${guestsCountLabel}`;
    }

    if (filtersCount) {
      searchOptionLabel += ` • ${filtersCountLabel}`;
    }
  }

  return {
    searchOption: {
      value: searchOptionValue.toLowerCase(),
      label: searchOptionLabel,
      url: `/rv-search?${searchQuery.split('?')[1]}`,
      timestamp: Math.floor(Date.now() / 1000),
    },
  };
}
