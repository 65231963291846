import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';

type TFavoritesData = TRootState['favorites'];

export const getUserFavorites = createSelector<TRootState, TFavoritesData, TFavoritesData>(
  state => state.favorites,
  data => data,
);
