import React from 'react';

export enum DividerTone {
  normal = 'normal',
  dark = 'dark',
  darker = 'darker',
  darkest = 'darkest',
}

interface IProps extends React.HTMLAttributes<HTMLHRElement> {
  tone?: DividerTone;
}

const mapToneToClassName: Record<DividerTone, string> = {
  normal: 'border-gray-500/10',
  dark: 'border-gray-500/15',
  darker: 'border-gray-500/20',
  darkest: 'border-gray-500',
};

const Divider: React.FC<IProps> = ({ className = '', tone = DividerTone.normal, ...props }) => {
  return (
    <hr
      className={`border-b-0 border-t ${mapToneToClassName[tone]} ${className}`}
      data-testid="divider"
      {...props}
    />
  );
};

export default Divider;
