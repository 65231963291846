interface CountryCode {
  code: string;
  name: string;
}

/**
 * Countries available in Outdoorsy
 */
const countries: Array<CountryCode> = [
  {
    code: 'AU',
    name: 'Australia',
  },
  {
    code: 'AT',
    name: 'Austria',
  },
  {
    code: 'BE',
    name: 'Belgium',
  },
  {
    code: 'CA',
    name: 'Canada',
  },
  {
    code: 'HR',
    name: 'Croatia',
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
  },
  {
    code: 'FR',
    name: 'France',
  },
  {
    code: 'DE',
    name: 'Germany',
  },
  {
    code: 'GR',
    name: 'Greece',
  },
  {
    code: 'HU',
    name: 'Hungary',
  },
  {
    code: 'IS',
    name: 'Iceland',
  },
  {
    code: 'IE',
    name: 'Ireland',
  },
  {
    code: 'IM',
    name: 'Isle of Man',
  },
  {
    code: 'IT',
    name: 'Italy',
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
  },
  {
    code: 'LU',
    name: 'Luxembourg',
  },
  {
    code: 'MT',
    name: 'Malta',
  },
  {
    code: 'MX',
    name: 'Mexico',
  },
  {
    code: 'ME',
    name: 'Montenegro',
  },
  {
    code: 'NZ',
    name: 'New Zealand',
  },
  {
    code: 'SM',
    name: 'San Marino',
  },
  {
    code: 'SI',
    name: 'Slovenia',
  },
  {
    code: 'ES',
    name: 'Spain',
  },
  {
    code: 'CH',
    name: 'Switzerland',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
  },
  {
    code: 'US',
    name: 'United States',
  },
];

export const countryCodes = countries.map(country => country.code);
