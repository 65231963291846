import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { ILoginResponse } from '@/services/types/auth/ILoginResponse';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

import { TAuthError } from './index';

export const RESET_PASSWORD_PENDING = 'auth/RESET_PASSWORD_PENDING';
export const RESET_PASSWORD_FAILURE = 'auth/RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS';

export type ResetPasswordAction =
  | ResetPasswordSuccessAction
  | ResetPasswordFailureAction
  | ResetPasswordPendingAction;

//#region interface for Login Call
interface ResetPasswordSuccessAction extends IAction {
  type: string;
  payload: null;
}

interface ResetPasswordPendingAction extends IAction {
  type: typeof RESET_PASSWORD_PENDING;
}

interface ResetPasswordFailureAction extends IAction {
  type: typeof RESET_PASSWORD_FAILURE;
  payload: TAuthError;
  error: boolean;
}
//#endregion

//#region actions for login call
const resetPasswordPending = (): ResetPasswordPendingAction => ({ type: RESET_PASSWORD_PENDING });

const resetPasswordFailure = (error: TAuthError): ResetPasswordFailureAction => ({
  type: RESET_PASSWORD_FAILURE,
  payload: error,
  error: true,
});

const resetPasswordSuccess = (): ResetPasswordSuccessAction => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: null,
  };
};
//#endregion

//async Login USER THUNK
export const resetPassword =
  (params: { email: string }): ThunkAction<void, TRootState, void, ResetPasswordAction> =>
  async dispatch => {
    dispatch(resetPasswordPending());
    try {
      const resp = await apiRequest<ILoginResponse>(
        {
          url: `${getCoreApi()}/auth/creds/forgot`,
          method: 'POST',
          data: { email: params.email },
        },
        true,
      );

      dispatch(resetPasswordSuccess());

      return resp;
    } catch (error) {
      dispatch(resetPasswordFailure(error));
    }
  };
