import { isDev, isProduction, isSSR } from './isSSR';

const isCI = Boolean(process.env.CI);

export const getCoreApi = () => {
  return isSSR() && !isDev() && !isCI
    ? `${process.env.internalCoreApi}`
    : `${process.env.coreApiHost}`;
};

export const getSearchApi = () => {
  if (!isSSR() && !isProduction()) {
    const searchHost = window.localStorage.getItem('search_host');
    if (searchHost) {
      return searchHost;
    }
  }

  return isSSR() && !isDev() && !isCI
    ? `${process.env.internalSearchApi}`
    : `${process.env.searchApi}`;
};
