import React, { useMemo, useState } from 'react';

import { LOCK } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import Text from '@/components/switchback/Text/Text';
import { generateSimpleId } from '@/utility/helpers';

import css from './TextInput.module.css';

export interface ITextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Name of the input
   */
  name: string;
  /**
   * Label of the input
   */
  label?: string;
  /**
   * Validation error to be displayed
   */
  error?: string;
  /**
   * Classname to apply to the error message element
   */
  errorClassName?: string;
  inputClassName?: string;
}

const TextInput = React.forwardRef<HTMLInputElement, ITextInputProps>(
  (
    {
      id,
      className = '',
      errorClassName = '',
      type = 'text',
      label,
      value,
      inputClassName,
      defaultValue = '',
      error,
      readOnly = false,
      onFocus,
      onBlur,
      onChange,
      ...props
    },
    ref,
  ) => {
    const [internalValue, setInternalValue] = useState(defaultValue);
    const inputId = useMemo(() => id || `input_${generateSimpleId()}`, [id]);
    const [hasFocus, setHasFocus] = useState(false);
    const isControlled = typeof value !== 'undefined';
    const hasValue = !!internalValue || !!value;

    return (
      <div className={`${className} relative`}>
        <div
          data-testid={`${props.name}-input-container`}
          className={css.inputContainer}
          data-focused={String(hasFocus)}
          data-empty={String(!hasValue)}
          data-has-error={String(!!error)}
          data-read-only={readOnly}>
          <input
            id={inputId}
            className={`${css.input} ${inputClassName || ''}`}
            readOnly={readOnly}
            ref={ref}
            type={type}
            value={isControlled ? value : internalValue}
            onFocus={e => {
              setHasFocus(true);
              onFocus?.(e);
            }}
            onBlur={e => {
              setHasFocus(false);
              onBlur?.(e);
            }}
            onChange={e => {
              onChange?.(e);
              setInternalValue(e.currentTarget.value);
            }}
            {...props}
          />
          {label && (
            <label className={css.label} htmlFor={inputId}>
              {label}
            </label>
          )}
          {readOnly && (
            <Icon className={css.lock} name={LOCK} size={IconSize.small} height={16} width={16} />
          )}
        </div>
        {error && (
          <Text
            data-testid={`${props.name}-input-error`}
            type="inline"
            className={`${css.error} ${errorClassName} top-full left-0 mt-1`}>
            {error}
          </Text>
        )}
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';

export default TextInput;
