import { ToggleModalAction, UI_TOGGLE_MODAL } from './actions';

interface ModalState {
  id: string;
  props?: { [key: string]: any };
}

export const initialModalState: ModalState = {
  id: '',
  props: undefined,
};

export default function reducer(state: ModalState = initialModalState, action: ToggleModalAction) {
  switch (action.type) {
    case UI_TOGGLE_MODAL:
      return {
        ...state,
        id: action.payload.id,
        props: action.payload.props,
      };
    default:
      return state;
  }
}
