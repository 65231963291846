import Script from 'next/script';
import React, { useEffect, useRef } from 'react';

import { useRouter } from '@/hooks/useRouter';
import { isSSR } from '@/utility/isSSR';

declare global {
  interface Window {
    RISKX?: {
      go?: (arg: string | undefined) => void;
    };
  }
}

const getPage = () => {
  const path = window.location.pathname.split('/');
  return path.length > 1 ? path[1] : path[0];
};
export const RiskifiedScript: React.FC = React.memo(() => {
  const router = useRouter();
  const pathRef = useRef<string | undefined>('');

  const handleRouteChange = () => {
    if (!isSSR()) {
      if (getPage() !== pathRef.current && getPage() !== '/') {
        pathRef.current = getPage();
        if (typeof window?.RISKX?.go === 'function') {
          window.RISKX.go(pathRef.current);
        }
      }
    }
  };
  useEffect(() => {
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isSSR()) {
    return null;
  }

  const sid = window.sessionStorage.getItem('riskified-session-id');
  const url =
    ('https:' == document.location.protocol ? 'https://' : 'http://') +
    'beacon.riskified.com/?shop=' +
    process.env.riskifiedStoreDomain +
    '&sid=' +
    sid;

  const handleOnLoad = () => {
    if (!isSSR()) {
      if (getPage() !== pathRef.current && getPage() !== '/') {
        pathRef.current = getPage();
        if (typeof window?.RISKX?.go === 'function') {
          window.RISKX.go(pathRef.current);
        }
      }
      router.events.on('routeChangeComplete', handleRouteChange);
    }
  };

  return (
    <>
      <Script src={url} onLoad={handleOnLoad} />
    </>
  );
});
RiskifiedScript.displayName = 'RiskifiedScript';
