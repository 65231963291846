import { TRootState } from '@/redux/rootReducer';

export const selectAuth = (state: TRootState) => state.auth;
export const selectAuthenticated = (state: TRootState) => {
  return selectAuth(state).isAuthenticated;
};

export const selectAuthenticating = (state: TRootState) => {
  const auth = selectAuth(state);
  return auth.isFetching || auth.isLoggingIn;
};

export const selectHasTriedFetch = (state: TRootState) => {
  return selectAuth(state).hasTriedFetch;
};
