import kebabCase from 'lodash/kebabCase';
import { createSelector } from 'reselect';

import { getItemsPerPage } from '@/constants/pagination';
import { ESearchFilters } from '@/constants/searchFilters';
import { TRootState } from '@/redux/rootReducer';
import { getLocale } from '@/redux/selectors/locale';
import { ICampgroundData } from '@/services/types/search/campgrounds/id';
import { IData } from '@/services/types/search/rentals/id';
import { filterEmpty } from '@/utility/array';
import { getIntl } from '@/utility/i18n';
import { getStateName, TCountry } from '@/utility/location';

import { getFromAndTo, getQueryParams, TQueryParams } from '../queryParams';
import { getSearchResultsMeta, IMetaData } from './meta';

type TSearchData = TRootState['search']['data'];

export const getSearchCanonicalSlug = createSelector<TRootState, IMetaData, string>(
  getSearchResultsMeta,
  ({ city, county, country, state }) => {
    if (!city && !county && !state) return '/';

    const stateName = getStateName(state, country.toUpperCase() as TCountry) ?? state;
    const location = filterEmpty([stateName, city || county])
      .map(kebabCase)
      .join('/');

    return `/rv-rental/${location}`;
  },
);

export const getSearchCanonicalURL = createSelector<
  TRootState,
  ReturnType<typeof getSearchCanonicalSlug>,
  ReturnType<typeof getLocale>,
  string
>(getSearchCanonicalSlug, getLocale, (slug, locale) => `https://${locale.domain}${slug}`);

export const getSearchTitle = createSelector<TRootState, IMetaData, string>(
  getSearchResultsMeta,
  ({ city, country, county, state }) => {
    const intl = getIntl();

    if (!city && !county && !state)
      return intl.formatMessage({
        defaultMessage: 'Campers, Motorhomes & RVs for Rent',
        id: 'ErVfeH',
        description: 'Search page default title',
      });

    let location = getStateName(state, country as TCountry);

    if ((state && city) || county) {
      location = [city || county, state].join(', ');
    }

    return intl.formatMessage(
      {
        defaultMessage: '{location} Campers, Motorhomes & RVs for Rent',
        id: 'I4DNKU',
        description: 'Search page location title',
      },
      { location },
    );
  },
);

export interface IPageViewEvent {
  queryParams: TQueryParams;
  rentalIds: string[];
  perPage: number;
  lat: number;
  lng: number;
  from?: string;
  to?: string;
}

const getPrice = (result: IData | ICampgroundData): number => {
  let price: number;
  if ('price_per_day' in result) {
    price = result.price_per_day;
  } else {
    price = result.lowest_price;
  }
  return price / 100;
};

export const getPageViewEventData = createSelector<
  TRootState,
  IMetaData,
  TQueryParams,
  ReturnType<typeof getFromAndTo>,
  TSearchData,
  ICampgroundData[] | undefined,
  boolean,
  IPageViewEvent | null
>(
  getSearchResultsMeta,
  getQueryParams,
  getFromAndTo,
  state => state.search.data,
  state => state.search.campgrounds?.data,
  state => !!state.search.isLoading || !!state.search.isMetaLoading,
  (
    { lat, lng, total, totalUnavailable },
    queryParams,
    { from, to },
    rentalsData,
    campgroundsData,
    isLoading,
  ) => {
    const data = rentalsData?.length ? rentalsData : campgroundsData?.length ? campgroundsData : [];
    if (data && !isLoading) {
      const { [ESearchFilters.PAGE_LIMIT]: pageLimit } = queryParams;
      return {
        queryParams,
        rentalIds: data.map(item => String(item.id)),
        rentalPrices: data.map(item => ({
          id: item.id,
          price_per_day: getPrice(item),
        })),
        perPage: getItemsPerPage(pageLimit),
        lat,
        lng,
        from,
        to,
        distance_in_miles: data.map(item => ({
          id: item.id,
          distance: item.distance,
        })),
        total_rentals: total,
        total_unavailable: totalUnavailable,
      };
    }
    return null;
  },
);

export const getSearchStayStates = createSelector<TRootState, TSearchData, string[]>(
  state => state.search.data,
  data => {
    const stateSet: Set<string> = new Set();
    data?.forEach(result => {
      if (result.rental_category === 'stay') stateSet.add(result.location.state);
    });
    return Array.from(stateSet);
  },
);
