import pick from 'lodash/pick';

import { TCurrency } from '@/config/locales';
import { bookingUpdateAttributes } from '@/constants/booking';
import { EBookingStatus } from '@/constants/checkout';
import { IUserResponse } from '@/redux/modules/auth/userMini';
import { IBooking, IBookingGuest } from '@/services/types/booking/details';
import { IFile } from '@/services/types/booking/file';
import { IBookingPayment } from '@/services/types/booking/payment';
import { IOwner as IRentalOwner } from '@/services/types/search/rentals/id';
import { getIntl } from '@/utility/i18n';

import { formatCurrency } from './currency';

export function bookingFriendlyType(type: string, display_type: string) {
  if (display_type) {
    return display_type;
  }
  const intl = getIntl();
  switch (type) {
    case 'reservation_charge':
      return intl.formatMessage({
        defaultMessage: 'Reservation deposit',
        id: 'v31Mo+',
      });
    case 'booking_charge':
      return intl.formatMessage({
        defaultMessage: 'Full reservation charge',
        id: '8Y81bk',
      });
    case 'remainder_charge':
      return intl.formatMessage({
        defaultMessage: 'Reservation remainder',
        id: 'xskTwR',
      });
    case 'security_deposit_charge':
      return intl.formatMessage({
        defaultMessage: 'Security deposit hold',
        id: 'fHaDBQ',
      });
    case 'payout':
      return intl.formatMessage({
        defaultMessage: 'Payout',
        id: 'xdVU3q',
      });
    case 'refund':
      return intl.formatMessage({
        defaultMessage: 'Refund',
        id: 'IeUH3/',
      });
    case 'transfer':
      return intl.formatMessage({
        defaultMessage: 'Transfer',
        id: 'DtYelJ',
      });
    case 'reversal':
      return intl.formatMessage({
        defaultMessage: 'Reversal',
        id: 'Ri4dTc',
      });
    case 'coachnet_charge':
      return intl.formatMessage({
        defaultMessage: 'Coach-net fee',
        id: 'z0wGmx',
      });
    case 'coachnet_fee_refund':
      return intl.formatMessage({
        defaultMessage: 'Coach-net fee refund',
        id: '+nyMTu',
      });
    case 'application_fee_refund':
      return intl.formatMessage({
        defaultMessage: 'Service fee refund',
        id: 'FNy5py',
      });
    case 'security_deposit_refund':
      return intl.formatMessage({
        defaultMessage: 'Security deposit returned',
        id: 'XTcVMp',
      });
    case 'security_deposit_transfer':
      return intl.formatMessage({
        defaultMessage: 'Security deposit claimed',
        id: 'aoaa5c',
      });
    case 'security_deposit_transfer_reversed':
      return intl.formatMessage({
        defaultMessage: 'Security deposit reversed',
        id: 'wWRqKl',
      });
    case 'security_deposit_payout':
      return intl.formatMessage({
        defaultMessage: 'Security deposit payout',
        id: 'ILL6PX',
      });
    case 'manual':
      return intl.formatMessage({
        defaultMessage: 'Manual charge',
        id: 'K0jmWT',
      });
    case 'cash':
      return intl.formatMessage({
        defaultMessage: 'Cash payment',
        id: '4XTAWX',
      });
    case 'cash_refund':
      return intl.formatMessage({
        defaultMessage: 'Cash refund',
        id: 'KtFbeL',
      });
    case 'cash_fee_charge':
      return intl.formatMessage({
        defaultMessage: 'Wheelbase fee payment',
        id: 'lz9QY/',
      });
    case 'renter_service_fee':
      return intl.formatMessage({
        defaultMessage: 'Outdoorsy service fee',
        id: '1/6a2s',
      });
    case 'renter_service_fee_refund':
      return intl.formatMessage({
        defaultMessage: 'Outdoorsy service fee refund',
        id: 'v9N2tp',
      });
    case 'fx_fee_charge':
      return intl.formatMessage({
        defaultMessage: 'International transaction fee',
        id: 'BID1XK',
      });
    case 'fx_fee_refund':
      return intl.formatMessage({
        defaultMessage: 'International transaction fee refund',
        id: 'iRiT0J',
      });
    case 'referral_credit':
      return intl.formatMessage({
        defaultMessage: 'Referral credit',
        id: 'PirTBh',
      });
    case 'referral_credit_refund':
      return intl.formatMessage({
        defaultMessage: 'Referral credit refund',
        id: 'xxgJ8K',
      });
    case 'credit_conversion':
      return intl.formatMessage({
        defaultMessage: 'Converted into credits',
        id: 'pE8czs',
      });
    case 'trip_insurance_charge':
      return intl.formatMessage({
        defaultMessage: 'Trip insurance charge',
        id: 'Zsk8ky',
      });
    case 'trip_insurance_refund':
      return intl.formatMessage({
        defaultMessage: 'Trip insurance refund',
        id: 'N1BEyH',
      });
    default:
      return type;
  }
}

export const bookingDriversSelector = (passengers: IBookingGuest[]) =>
  (passengers || []).filter(passenger => passenger.role === 'driver');

export const bookingStatusAllowModifyBundleSelector = (booking: Partial<IBooking>) =>
  ['available', 'conversation', 'negotiating', 'draft', 'approved', 'imminent'].includes(
    booking.status || '',
  );

export const getCurrentBookingGuest = (
  user: IUserResponse | null,
  passengers: IBookingGuest[] | undefined,
) => {
  const foundPassenger = passengers?.find(passenger => passenger.id === user?.id);
  return foundPassenger;
};

export const userPassengerVerificationStatus = (passenger: IBookingGuest | undefined) => {
  return passenger?.checks?.find(c => c.method === 'persona')?.status;
};

export const userPassengerPersonaInquiryID = (passenger: IBookingGuest | undefined) => {
  return passenger?.checks?.find(c => c.method === 'persona' && c.status === 'pending')
    ?.external_id;
};

export const canModifyGuestsAndDriversSelector = (isRenter: boolean, booking: IBooking) =>
  isRenter && ['negotiating', 'approved', 'imminent', 'handed_off'].includes(booking.status || '');

export const hasAnyVerifiedDriverSelector = (guests: IBookingGuest[]) => {
  return guests.some(d => d.role === 'driver' && d.invite_status !== 'pending' && d.verified);
};

export const signatureCompletedSelector = (
  currentUserEmail: string | undefined,
  signersData: Partial<IFile> | undefined,
): boolean => {
  if (!signersData) {
    return false;
  }
  const signer = signersData.signers?.find(
    signer => signer.signer_email_address === currentUserEmail,
  );
  return signer?.status_code === 'signed';
};

/**
 *
 * @param bookingData original booking response
 * @param data things to update to booking
 * @returns Record<string, any>
 */
export const buildPatchBookingData = (
  bookingData: Partial<IBooking> | null,
  data: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> => {
  const bookingDataPartial = pick(bookingData, ...bookingUpdateAttributes);
  return {
    ...bookingDataPartial,
    insurance_plan_id: bookingDataPartial.insurance_plan_id?.id,
    ...data,
  };
};

export const securityDepositFormatter = (
  securityDeposit: number | undefined,
  presentmentCurrency: TCurrency | undefined,
) => {
  return formatCurrency({
    priceInCents: securityDeposit || 0,
    currency: presentmentCurrency || 'USD',
    digits: 2,
  });
};

// To be used for getting payment info from headers, this may be missing.
export const getRawBookingPayment = (headers: Record<string, string>): IBookingPayment => ({
  token: headers['x-ppp-token'],
  country: headers['x-ppp-country'],
});

// Since payment info may be missing from headers when requesting booking,
// we should fallback to rental country (owner bank country/country).
// If we have payment info from headers, we use those directly.
export const getProcessedBookingPayment = (
  rawPayment?: IBookingPayment,
  rentalOwner?: IRentalOwner,
): IBookingPayment => {
  if (rawPayment?.token && rawPayment?.country) return rawPayment;

  if (!rawPayment || !rentalOwner) return {};

  const country = rawPayment.country || rentalOwner.bank_country || rentalOwner.country || 'US';

  return {
    token:
      rawPayment.token || process.env.stripeCountries[country] || process.env.stripePublishableKey,
    country,
  };
};

export const isBookingNegotiatedOrStarted = (booking: IBooking) => {
  return [EBookingStatus.APPROVED, EBookingStatus.IMMINENT, EBookingStatus.HANDED_OFF].includes(
    booking.status as EBookingStatus,
  );
};
