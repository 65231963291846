import * as BookingsReviewActions from '@/redux/modules/bookingsReview/type';
import type { IBooking } from '@/services/types/booking/details';

interface IBookingsReviewState {
  data: IBooking[] | null;
  isFetching: boolean;
  error: boolean;
}

export const initialState: IBookingsReviewState = {
  data: [],
  isFetching: false,
  error: false,
};

export default function reducer(
  state: IBookingsReviewState = initialState,
  action: BookingsReviewActions.IBookingsReviewAction,
): IBookingsReviewState {
  switch (action.type) {
    case BookingsReviewActions.FETCH_BOOKINGS_REVIEW_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
      };

    case BookingsReviewActions.FETCH_BOOKINGS_REVIEW_PENDING:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case BookingsReviewActions.FETCH_BOOKINGS_REVIEW_SUCCESS:
      return {
        ...state,
        data: action.payload.bookings,
        isFetching: false,
        error: false,
      };
    default:
      return state;
  }
}
