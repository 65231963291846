import { createSelector } from 'reselect';

import { TCurrency } from '@/config/locales';
import { TRootState } from '@/redux/rootReducer';
import { ICheckoutQuestion } from '@/services/types/booking/details';

import { getDealerLocationDropoffOptions, getDealerLocationPickupOptions } from '../dealerLocation';

export const getBookingId = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  number | undefined
>(
  state => state.checkout.booking,
  data => data?.id,
);

export const getBookingRentalId = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  number | undefined
>(
  state => state.checkout.booking,
  data => data?.rental_id,
);

export const getBookingCurrency = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  TCurrency | undefined
>(
  state => state.checkout.booking,
  data => data?.presentment_currency,
);

export const getBookingCheckoutQuestions = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  ICheckoutQuestion[] | null | undefined
>(
  state => state.checkout.booking,
  data => data?.checkout_questions,
);

export const getBookingCheckoutTimes = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  ReturnType<typeof getDealerLocationPickupOptions>,
  ReturnType<typeof getDealerLocationDropoffOptions>,
  {
    from_time: number | undefined;
    to_time: number | undefined;
  }
>(
  state => state.checkout.booking,
  getDealerLocationPickupOptions,
  getDealerLocationDropoffOptions,
  (bookingData, pickupOptions, dropoffOptions) => {
    /**
     * In case pickup or dropoff times are not available in the location
     * select the most middle available option.
     */
    const currentFromTime = bookingData?.from_time;
    const fromTime =
      currentFromTime && pickupOptions.includes(currentFromTime)
        ? currentFromTime
        : pickupOptions[Math.floor(pickupOptions.length / 2)];

    const currentToTime = bookingData?.to_time;
    const toTime =
      currentToTime && dropoffOptions.includes(currentToTime)
        ? currentToTime
        : dropoffOptions[Math.floor(dropoffOptions.length / 2)];

    return {
      from_time: fromTime,
      to_time: toTime,
    };
  },
);

export const getBookingDiscountCode = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  string | undefined
>(
  state => state.checkout.booking,
  data => data?.discount_code,
);

export const getBookingReferralCode = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  string | undefined
>(
  state => state.checkout.booking,
  data => data?.raf_waiver_summary?.referral_code,
);

export const getBookingExternalGiftCardNumber = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  string | undefined
>(
  state => state.checkout.booking,
  data => data?.external_gift_card_details?.card_number,
);

export const getBookingExternalGiftCardAmount = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  number | undefined
>(
  state => state.checkout.booking,
  data => data?.external_gift_card_details?.converted_amount,
);

export const getBookingData = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  TRootState['checkout']['booking']
>(
  state => state.checkout.booking,
  booking => booking,
);
