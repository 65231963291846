import { TVehicleType } from '@/services/types/search/rentals/id';
import { IAction } from '@/utility/redux/action';

const SET_LISTING_VEHICLE_TYPE = 'listingVehicleType/SET_LISTING_VEHICLE_TYPE';

interface ISetListingVehicleTypeAction extends IAction {
  type: typeof SET_LISTING_VEHICLE_TYPE;
  payload: TVehicleType;
}

type TAction = ISetListingVehicleTypeAction;

export const setListingVehicleType = (payload: TVehicleType): TAction => ({
  type: SET_LISTING_VEHICLE_TYPE,
  payload,
});

interface IState {
  data: TVehicleType | null;
}

export const initialState: IState = {
  data: null,
};

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case SET_LISTING_VEHICLE_TYPE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return { ...state };
  }
}
