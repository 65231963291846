import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectAuth, signupUser } from '@/redux/modules/auth';
import { ISignupForm } from '@/services/types/auth/ISignupForm';
import { getReCaptchaToken } from '@/utility/recaptcha';
import {
  EVENT_NAME_CLOSE_SIGN_UP,
  EVENT_NAME_SIGN_UP,
  registerAuthenticateEvent,
} from '@/utility/session';
import { triggerLoginModal } from '@/utility/triggerLoginModal';

import SignUpModal from './SignUpModal';

const SignUpModalContainer: React.FC = () => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const [isOpen, setIsOpen] = useState(false);

  const onSignup = useCallback((data: ISignupForm) => dispatch(signupUser(data)), [dispatch]);

  const onClose = useCallback<() => void>(() => setIsOpen(false), []);

  useEffect(() => {
    registerAuthenticateEvent(EVENT_NAME_CLOSE_SIGN_UP, onClose); // TODO: Do this via redux post R1
    registerAuthenticateEvent(EVENT_NAME_SIGN_UP, () => {
      if (!auth.isAuthenticated) setIsOpen(true);
    });
  }, [auth.isAuthenticated, onClose]);

  return (
    <SignUpModal
      signupFormProps={{
        onSignup,
        error: auth.error,
        reCaptchaToken: getReCaptchaToken(),
      }}
      isAuthenticated={auth.isAuthenticated}
      isOpen={isOpen}
      onLogin={triggerLoginModal}
      onClose={onClose}
    />
  );
};

export default SignUpModalContainer;
