export const DISCLAIMER_LINKS = {
  INSURANCE: '/insurance',
  PRIVACY: '/help/privacy-policy',
  TERMS: '/help/outdoorsy-terms-and-conditions',
  RAF_TERMS: '/help/refer-friend-terms-conditions',
};

/**
 * The status of the booking. Possible values are:
 *
 * conversation     shell booking to allow for a conversation with no dates
 * draft            Host is creating a booking on behalf of a renter to send to them
 * negotiating      booking made, parties negotiating
 * approved         booking approved by both parties
 * dealer_complete  sent when a dealer wants to complete a booking on behalf of a renter - NOT STORED IN DB
 * renter_withdrew  booking has been withdrawn by the renter (cancelled before approved)
 * owner_declined   Host declined booking
 * imminent         the rental period will begin in two days
 * handed_off       renter has picked up rental
 * returned         renter has returned rental
 * renter_cancelled renter cancelled booking
 * owner_cancelled  Host cancelled booking
 * unavailable      blacked out by host (no quote associated)
 * available        used to remove a blackout
 * expired          the booking went past its start date without being accepted
 * card_update      payment method is changed
 */
export enum EBookingStatus {
  CONVERSATION = 'conversation',
  DRAFT = 'draft',
  NEGOTIATING = 'negotiating',
  APPROVED = 'approved',
  DEALER_COMPLETE = 'dealer_complete',
  RENTER_WITHDREW = 'renter_withdrew',
  OWNER_DECLINED = 'owner_declined',
  IMMINENT = 'imminent',
  HANDED_OFF = 'handed_off',
  RETURNED = 'returned',
  RENTER_CANCELLED = 'renter_cancelled',
  OWNER_CANCELLED = 'owner_cancelled',
  UNAVAILABLE = 'unavailable',
  AVAILABLE = 'available',
  EXPIRED = 'expired',
  CARD_UPDATE = 'card_update',
}

export enum EGuestUserHeader {
  USER_ID = 'anon-user-id',
  TOKEN = 'anon-token',
}

export enum EBookingError {
  /**
   * Error code: UserCreateGenericError
   *
   * When creating a booking, use this error code from the api to trigger a login modal.
   * Where bookingInfo:renterInfo:email is equal to an email address associated with an existing account.
   */
  USER_CREATE_GENERIC_ERROR = 'UserCreateGenericError',
}

/**
 * Form input elements for react-hook-form require their name attribute not be a number or start with a number.
 * see: https://react-hook-form.com/api#register
 */
export const OWNER_QUESTIONS_PREFIX = 'owner-question-';

export const GQL_CHECKOUT = 'gql_checkout';
