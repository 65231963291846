import Script from 'next/script';
import React from 'react';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    googletag: any;
  }
}

const GoogleAds = () => {
  return (
    <>
      <div id="div-gpt-ad-1614955491295-0" />
      <Script
        async
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        onLoad={() => {
          window.googletag = window.googletag || { cmd: [] };
          window.googletag.cmd.push(function () {
            window.googletag
              .defineSlot(
                '/18190176,23062940659/MCM_Validation',
                [1, 1],
                'div-gpt-ad-1614955491295-0',
              )
              .addService(window.googletag.pubads());
            window.googletag.pubads().enableSingleRequest();
            window.googletag.enableServices();
            window.googletag.cmd.push(function () {
              if (window.googletag.pubads().isInitialLoadDisabled()) {
                window.googletag.display('div-gpt-ad-1614955491295-0');
                window.googletag.refresh('div-gpt-ad-1614955491295-0');
              } else {
                window.googletag.display('div-gpt-ad-1614955491295-0');
              }
            });
          });
        }}
      />
    </>
  );
};

export default GoogleAds;
