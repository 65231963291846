import React from 'react';

type TTypes = 'inline' | 'paragraph' | 'block';
type TTextTag = 'span' | 'p' | 'div';

type ITextElement = React.HTMLAttributes<HTMLElement>;

interface ITextProps {
  type: TTypes;
}

const tags = {
  inline: 'span',
  paragraph: 'p',
  block: 'div',
};

const Text: React.FC<ITextProps & ITextElement> = ({ type, ...props }) => {
  const TextTag = tags[type] as TTextTag;
  return <TextTag {...props} />;
};

export default Text;
