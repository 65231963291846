import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  loginUser,
  resetPassword,
  resetUser,
  selectAuthResetPasswordError,
  selectAuthResetPasswordLoading,
} from '@/redux/modules/auth';
import { selectAuthenticated } from '@/redux/modules/auth/selectors';
import { selectModalState } from '@/redux/modules/modal/selectors';
import { TRootState } from '@/redux/rootReducer';
import { ILoginForm } from '@/services/types/auth/ILoginForm';
import { getReCaptchaToken } from '@/utility/recaptcha';
import {
  EVENT_NAME_CLOSE_LOGIN,
  EVENT_NAME_LOGIN,
  EVENT_NAME_LOGOUT,
  registerAuthenticateEvent,
} from '@/utility/session';
import { triggerSignupModal } from '@/utility/triggerSignupModal';

import { ForgotPasswordFormFields } from './ForgotPasswordForm';
import LoginModal from './LoginModal';

type TAuth = TRootState['auth'];

const LoginModalContainer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthenticated);
  const modalState = useSelector(selectModalState);
  const authState = useSelector<TRootState, TAuth>(state => state.auth);
  const resetPasswordLoading = useSelector(selectAuthResetPasswordLoading);
  const resetPasswordError = useSelector(selectAuthResetPasswordError);

  const onLogin = useCallback((data: ILoginForm) => dispatch(loginUser(data)), [dispatch]);
  const onLogout = useCallback(() => dispatch(resetUser()), [dispatch]);
  const onResetPassword = useCallback(
    (formData: ForgotPasswordFormFields) => dispatch(resetPassword({ email: formData.email })),
    [dispatch],
  );

  let email;

  if (modalState.id === 'login') {
    email = modalState.props?.email;
  }

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    registerAuthenticateEvent(EVENT_NAME_CLOSE_LOGIN, onClose); // TODO: Do this via redux post R1
    registerAuthenticateEvent(EVENT_NAME_LOGOUT, onLogout, true);
    registerAuthenticateEvent(EVENT_NAME_LOGIN, () => {
      // if the user logs out in a different tab, update the redux store to indicate they are logged out
      if (!isAuthenticated) {
        onLogout();
        setIsOpen(true);
      }
    });
  }, [onClose, onLogout, isAuthenticated]);

  useEffect(() => {
    if (!isOpen && isResettingPassword) {
      setTimeout(() => {
        setIsResettingPassword(false);
      }, 1000);
    }
  }, [isOpen, isResettingPassword]);
  return (
    <LoginModal
      isAuthenticated={isAuthenticated}
      isOpen={isOpen}
      isResettingPassword={isResettingPassword}
      onForgotPassword={() => setIsResettingPassword(!isResettingPassword)}
      onSignup={triggerSignupModal}
      onClose={onClose}
      loginFormProps={{
        onLogin,
        email,
        isLoading: authState.isLoggingIn,
        error: authState.error,
        reCaptchaToken: getReCaptchaToken(),
      }}
      resetPasswordFormProps={{
        loading: resetPasswordLoading,
        error: resetPasswordError,
        onSubmit: onResetPassword,
      }}
    />
  );
};

export default LoginModalContainer;
