import React, { useCallback, useState } from 'react';

import {
  MINUS_CIRCLE,
  MINUS_CIRCLE_BIG,
  PLUS_CIRCLE,
  PLUS_CIRCLE_BIG,
} from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';

import css from './QuantitySelector.module.css';

export enum IQuantitySelectorSize {
  small = 'small',
  normal = 'normal',
  large = 'large',
}

export interface IQuantitySelectorProps {
  className?: string;
  'data-testid'?: string;
  id: string;
  label?: string;
  maxQuantity: number;
  name: string;
  quantity: number;
  size?: IQuantitySelectorSize;
  minQuantity?: number;
  onChange: (value: number) => void;
}

const QuantitySelector: React.FC<IQuantitySelectorProps> = ({
  className = '',
  'data-testid': testId = 'quantity-selector',
  id,
  label,
  maxQuantity,
  name,
  quantity,
  size = IQuantitySelectorSize.normal,
  minQuantity = 0,
  onChange,
  ...props
}) => {
  const [prevValue, setPrevValue] = useState(quantity);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(e.target.value, 10);

    if (newQuantity < 0 || newQuantity > maxQuantity) {
      onChange?.(prevValue);
      return;
    }

    setPrevValue(newQuantity);
    onChange?.(newQuantity);
  };

  const handleIncrease = useCallback(() => {
    const newQuantity = quantity + 1;
    if (newQuantity > maxQuantity) return;
    onChange?.(newQuantity);
  }, [onChange, quantity, maxQuantity]);

  const handleDecrease = useCallback(() => {
    const newQuantity = quantity - 1;
    if (newQuantity < minQuantity) return;
    onChange?.(newQuantity);
  }, [onChange, quantity, minQuantity]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      if (/^[0-9]$/i.test(e.key)) setPrevValue(parseInt(target.value, 10));
    },
    [setPrevValue],
  );

  const buttonTemplate = useCallback(
    (type: 'increase' | 'decrease') => {
      return (
        // Skipping buttons for screen readers
        <span
          aria-disabled={quantity === (type === 'increase' ? maxQuantity : minQuantity)}
          aria-hidden="true"
          className={`${css.button} before-focus-style`}
          data-size={size}
          data-testid={type === 'increase' ? `${testId}-add-btn` : `${testId}-remove-btn`}
          onClick={type === 'increase' ? handleIncrease : handleDecrease}>
          {size === IQuantitySelectorSize.small && (
            <Icon size={IconSize.normal} name={type === 'increase' ? PLUS_CIRCLE : MINUS_CIRCLE} />
          )}
          {size === IQuantitySelectorSize.normal && (
            <Icon name={type === 'increase' ? PLUS_CIRCLE : MINUS_CIRCLE} />
          )}
          {size === IQuantitySelectorSize.large && (
            <Icon name={type === 'increase' ? PLUS_CIRCLE_BIG : MINUS_CIRCLE_BIG} />
          )}
        </span>
      );
    },
    [handleDecrease, handleIncrease, maxQuantity, minQuantity, quantity, size, testId],
  );

  return (
    <div className={`flex items-center ${className}`}>
      {buttonTemplate('decrease')}
      <label className="sr-only" htmlFor={id}>
        {label}
      </label>
      <input
        className={css.input}
        id={id}
        name={name}
        max={maxQuantity}
        min={minQuantity}
        type="number"
        data-testid={testId}
        value={quantity}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        {...props}
      />
      {buttonTemplate('increase')}
    </div>
  );
};

export default QuantitySelector;
