import React, { useMemo, useState } from 'react';

import { generateSimpleId } from '@/utility/helpers';

enum TTooltipPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

/** Multiplier * 1rem (4px), this assumption is made possible by the tooltip's fixed width */
const TOOLTIP_WIDTH = 52 * 4;

interface ITooltipProps {
  /**
   * An element in which the tooltip will be applied to
   */
  children: React.ReactElement;
  /**
   * The content displayed within the tooltip
   */
  content: string | React.ReactNode;
  /**
   * An accessible label to describe the purpose of the tooltip
   */
  label: string;
  /**
   * Custom classnames
   */
  className?: string;
  /**
   * Custom classnames for the tooltip box
   */
  tooltipClassName?: string;
}

const Tooltip: React.FC<ITooltipProps> = ({
  children,
  content,
  label,
  className,
  tooltipClassName,
}) => {
  const [contentPosition, setContentPosition] = useState<TTooltipPosition>(TTooltipPosition.RIGHT);
  const tooltipId = useMemo(() => `${generateSimpleId()}-desc`, []);

  const handleFocus = (
    e: React.FocusEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();

    const { x: triggerX } = e.currentTarget.getBoundingClientRect();
    const spaceToTheLeft = triggerX - TOOLTIP_WIDTH;
    const spaceToTheRight = window.innerWidth - (triggerX + TOOLTIP_WIDTH); // 208 = rem * tooltipWidth (52)
    setContentPosition(
      spaceToTheLeft > spaceToTheRight ? TTooltipPosition.RIGHT : TTooltipPosition.LEFT,
    );
  };

  const tooltipPositionClass = contentPosition === TTooltipPosition.RIGHT ? 'right-0' : 'left-0';
  return (
    <button
      aria-describedby={tooltipId}
      className={`relative flex group ${className}`}
      data-testid="tooltip"
      onFocus={handleFocus}
      onMouseEnter={handleFocus}
      type="button">
      {children}
      <span className="sr-only" data-testid="tooltip-label">
        {label}
      </span>
      <div
        className={`text-left w-52 mt-2 hidden group-hover:block group-focus:block pointer-events-none text autoType200 bg-gray-900/95 text-white px-3 py-2 rounded-lg z-10 absolute top-full ${tooltipPositionClass} ${tooltipClassName}`}
        data-testid="tooltip-content"
        id={tooltipId}
        role="tooltip">
        {content}
      </div>
    </button>
  );
};

export default Tooltip;
