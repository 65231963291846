import { TRootState } from '@/redux/rootReducer';

export const getYearVehicles = (state: TRootState) => state.search.vehiclesYear || [];
export const getMakeVehicles = (state: TRootState) => state.search.vehiclesMake || [];
export const getMakeFilterVehicles = (state: TRootState) =>
  state.search.vehiclesMakeFilter || undefined;
export const getMakeFilterModels = (state: TRootState) =>
  state.search.vehiclesMakeFilterModels || {};

export const getModelVehicles = (state: TRootState) => state.search.vehiclesModel || [];
export const getInfoVehicle = (state: TRootState) => state.search.vehicleInfo;
