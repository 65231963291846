import { NotificationListener, OptimizelyDecision } from '@optimizely/optimizely-sdk';

import { isImpersonated } from '@/redux/modules/auth/userMini';
import { getODAnonymousId, getSegment } from '@/services/segment';
import { getCookie } from '@/utility/cookie';
import { isSSR } from '@/utility/isSSR';

import { getODNEventProperties } from './campground/util';
import { ECheckoutEventName } from './checkout/types';
import { EListingEventName } from './listings/types';
import { ESearchEventName } from './search/types';
import { getFBPixelProps } from './utils';

export enum EProjectSection {
  OWNER = 'owner',
  MARKETPLACE = 'marketplace',
  MARKETPLACE_DASHBOARD = 'marketplace_dashboard',
  WHEELBASE_DASHBOARD = 'wheelbase_dashboard',
  WHEELBASE_API = 'wheelbase_api',
  WHELLBASE_EMBED = 'wheelbase_embed',
  ROAMLY = 'roamly',
}

export async function trackEventV2<
  E extends string,
  P extends Record<string, unknown>,
  S = EProjectSection,
>(eventName: E, projectSection: S, extraInfo: P): Promise<void> {
  if (isSSR()) return;
  const impersonated = isImpersonated() ? { isImpersonated: true } : {};
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const universalFields = {
    platform: 'web',
    pageUrl: window.location.pathname,
    property: projectSection,
    legacyDeviceID: getCookie('odc-d'),
    cam: urlParams.get('cam'),
    subcam: urlParams.get('subcam'),
    ...impersonated,
  };
  // Movable ink requires certain fields to be strings, and segment can't convert data types
  const moveableInkEvents = [
    'Search Results Viewed',
    'Listing Viewed',
    'Booking Request Contact Started',
    'Booking Request Payment Completed',
  ];
  const stringFields = [
    'rentalID',
    'listingID',
    'rentalIDs',
    'listingIDs',
    'bookingID',
    'bookingIDs',
  ];
  const extendedExtraInfo = moveableInkEvents.includes(eventName)
    ? stringFields.reduce<Record<string, unknown>>(
        (acc, field) => {
          if (field in acc && !('string_' + field in acc)) {
            const value = acc[field];
            if (Array.isArray(value)) {
              acc['string_' + field] = value.map(f => `${f}`);
            } else {
              acc['string_' + field] = `${value}`;
            }
          }
          return acc;
        },
        { ...extraInfo },
      )
    : extraInfo;

  const infoWithFBPixelProps = attachFBPixelProps(eventName, extendedExtraInfo);
  const odnInfo = getODNEventProperties(extendedExtraInfo);
  const analytics = getSegment();
  if (!analytics) return;
  const anonId = await getODAnonymousId();
  const opts = { Optimizely: { userId: anonId } } as SegmentAnalytics.SegmentOpts;

  await analytics.track(
    eventName,
    { ...universalFields, ...infoWithFBPixelProps, ...odnInfo },
    opts,
  );
}

const EVENTS_TO_ATTACH_FB_PIXEL_PROPS: string[] = [
  ECheckoutEventName.CHECKOUT_BOOKING_REQUEST_CONTACT_STARTED,
  ECheckoutEventName.CHECKOUT_BOOKING_REQUEST_PAYMENT_COMPLETED,
  ESearchEventName.SEARCH_RESULT_VIEWED,
  EListingEventName.LISTING_VIEWED,
];

const EVENTS_TO_ATTACH_FB_PIXEL_PROPS_CONTENT_TYPE: string[] = [
  ECheckoutEventName.CHECKOUT_BOOKING_REQUEST_CONTACT_STARTED,
  ECheckoutEventName.CHECKOUT_BOOKING_REQUEST_PAYMENT_COMPLETED,
  EListingEventName.LISTING_VIEWED,
];

function attachFBPixelProps<E extends string, P extends Record<string, unknown>>(
  eventName: E,
  extraInfo: P,
): P {
  if (!extraInfo) {
    return extraInfo;
  }
  if (!EVENTS_TO_ATTACH_FB_PIXEL_PROPS.includes(eventName)) {
    return extraInfo;
  }
  const fbPixelFields = getFBPixelProps();
  if (EVENTS_TO_ATTACH_FB_PIXEL_PROPS_CONTENT_TYPE.includes(eventName)) {
    fbPixelFields.contentType = 'product';
  }

  return {
    ...extraInfo,
    ...fbPixelFields,
    content_type: 'product',
    content_ids: extraInfo.rentalID,
  };
}

// Optimizely does not implement this type.
type DecisionOption = {
  decisionInfo: OptimizelyDecision;
  attributes: Record<string, never>;
  userId: string;
  // Add more types as we need them.
  type: 'flag';
};

const getDecisionCacheKey = (decisionOption: DecisionOption): string => {
  const { decisionInfo: decision, userId } = decisionOption;
  if (!decision) return '';
  const { flagKey, variationKey, ruleKey } = decision;
  return `${userId}-${flagKey}-${ruleKey}-${variationKey}`;
};

// Keep a cache for if we've seen a certain decision.
let decisionCache: Record<string, boolean> = {};
export const _clearDecisionCache = () => {
  decisionCache = {};
};
// Did the current user see a decision during this page load?
export const didSeeDecision = (decisionOption: DecisionOption): boolean => {
  const cacheKey = getDecisionCacheKey(decisionOption);
  const containsCacheKey = decisionCache[cacheKey];
  if (containsCacheKey) return true;
  decisionCache[cacheKey] = true;
  return false;
};

// This handler is used to integrate segment Experiment Viewed events with optimizely FF decisions
// It tracks Optimizely hooks as trackEventV2 calls.
export const segmentDecision: NotificationListener<DecisionOption> = async decisionOption => {
  const { type } = decisionOption;
  if (type !== 'flag') return;
  const { decisionInfo: decision } = decisionOption;

  // If feature flag is not enabled or we already sent the event for this decision, do not send an event
  if (!decision.enabled || didSeeDecision(decisionOption)) return;

  // Send experiment if variationKey is present (for example, it's not a rollout) and ruleKey is present (experiment is found)
  // https://docs.developers.optimizely.com/full-stack/v4.0/docs/optimizelydecision-react
  if (decision.ruleKey && decision.variationKey) {
    trackEventV2('Experiment Viewed', EProjectSection.MARKETPLACE, {
      messageId: getDecisionCacheKey(decisionOption) || undefined,
      experimentKey: decision.ruleKey,
      variationKey: decision.variationKey,
      attributes: decisionOption.attributes,
      info: decision,
    });
  }
};
