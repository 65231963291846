import React, { useState } from 'react';

import { CHECKMARK } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import Text from '@/components/switchback/Text/Text';

import css from './Checkbox.module.css';

type ICheckbox = React.InputHTMLAttributes<HTMLInputElement>;

export enum CheckboxAlignment {
  top = 'top',
  center = 'center',
}

export enum CheckboxSizes {
  normal = 'normal',
  large = 'large',
}

interface IProps {
  testId?: string;
  contentClassName?: string;
  error?: string;
  hasError?: boolean;
  align?: CheckboxAlignment;
  checkBoxSize?: CheckboxSizes;
}

const Checkbox = React.forwardRef<HTMLInputElement, ICheckbox & IProps>(
  (
    {
      checked,
      defaultChecked,
      className = '',
      contentClassName = '',
      disabled,
      children,
      error,
      testId = 'checkbox',
      hasError,
      align = CheckboxAlignment.center,
      checkBoxSize = CheckboxSizes.normal,
      onChange,
      ...props
    },
    ref,
  ) => {
    const [internalValue, setInternalValue] = useState(defaultChecked);
    const hasChildren = React.Children.count(children);
    const Tag = hasChildren ? 'label' : 'span';

    return (
      <>
        <Tag
          className={`flex relative ${css.wrapper} ${className}`}
          data-align={align}
          data-disabled={String(Boolean(disabled))}
          data-error={String(hasError || !!error)}
          data-size={checkBoxSize}
          data-testid={testId}>
          <input
            checked={checked !== undefined ? checked : internalValue}
            className={`cursor-pointer disabled:cursor-default opacity-0 mr-3 ${css.input}`}
            data-selected={checked ? 'true' : 'false'}
            data-testid="checkbox-input"
            disabled={disabled}
            type="checkbox"
            ref={ref}
            onChange={e => {
              onChange?.(e);
              setInternalValue(e.currentTarget.checked);
            }}
            {...props}
          />
          <span
            className={`pointer-events-none absolute border-2 border-solid border-gray-310 text-green-500 duration-150 ease-in-out transition-colors flex justify-items-center items-center ${css.control}`}
            aria-hidden>
            <Icon
              className={`opacity-0 transition-opacity duration-150 ease-in-out w-full h-full ${css.icon}`}
              name={CHECKMARK}
            />
          </span>
          <div className={`flex flex-col ${contentClassName}`}>{children}</div>
        </Tag>
        {error && (
          <Text
            data-testid={`${props.name}-checkbox-error`}
            type="inline"
            className={`mt-1 text-red-800 text autoType200 ${css.error}`}>
            {error}
          </Text>
        )}
      </>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
