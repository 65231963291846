import { TAccountType } from '../../types/auth/ILoginForm';

export enum EAccountEventName {
  LOGGED_IN = 'Logged In',
  SIGNED_UP = 'Signed Up',
  PASSWORD_RESET = 'Password Reset',
  LOGGED_OUT = 'Logged Out',
  LOGIN_CREDENTIALS_ENTERED = 'Login Credentials Entered',
  VERIFICATION_CODE_REQUESTED = 'Verification Code Requested',
  VERIFICATION_CODE_ENTERED = 'Verification Code Entered',
}

export interface IAccountEvent {
  accountMethod: TAccountType;
}

export interface IAccountVerificationEvent {
  verificationMethod: string;
  accountMethod?: TAccountType;
}
