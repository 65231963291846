import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

const CREATE_MESSAGE_PENDING = 'messages/CREATE_MESSAGE_PENDING';
const CREATE_MESSAGE_RESPONSE = 'messages/CREATE_MESSAGE_RESPONSE';
const CREATE_MESSAGE_FAILURE = 'messages/CREATE_MESSAGE_FAILURE';

interface IMessage {
  senderId: number;
  bookingId: number;
  text: string;
}

interface ICreateMessagePendingAction extends IAction {
  type: typeof CREATE_MESSAGE_PENDING;
}

interface ICreateMessageResponseAction extends IAction {
  type: typeof CREATE_MESSAGE_RESPONSE;
  payload: IMessage;
}

interface ICreateMessageFailAction extends IAction {
  type: typeof CREATE_MESSAGE_FAILURE;
  payload: string;
  error: true;
}

type TCreateMessageAction =
  | ICreateMessagePendingAction
  | ICreateMessageResponseAction
  | ICreateMessageFailAction;

type TCreateMessageFunction = (
  senderId: number,
  bookingId: number,
  text: string,
) => ThunkAction<Promise<IMessage>, TRootState, void, TCreateMessageAction>;

export const createMessage: TCreateMessageFunction =
  (senderId, bookingId, text) => async dispatch => {
    dispatch<ICreateMessagePendingAction>({ type: CREATE_MESSAGE_PENDING });

    return new Promise((resolve, reject) =>
      apiRequest<IMessage>(
        {
          url: `${getCoreApi()}/messages`,
          method: 'POST',
          data: {
            sender_id: senderId,
            ref_booking_id: bookingId,
            text,
          },
        },
        true,
      )
        .then(response => {
          if (!response) {
            throw new Error('404');
          }
          return response;
        })
        .then(message => {
          dispatch<ICreateMessageResponseAction>({
            type: CREATE_MESSAGE_RESPONSE,
            payload: message,
          });
          return resolve(message);
        })
        .catch(response => {
          dispatch<ICreateMessageFailAction>({
            type: CREATE_MESSAGE_FAILURE,
            payload: response.error,
            error: true,
          });
          return reject(response.error);
        }),
    );
  };

interface IState {
  messages: IMessage[] | null;
  error?: string;
  isLoading: boolean;
}

export const initialState: IState = {
  messages: null,
  isLoading: false,
};

export default function reducer(state = initialState, action: TCreateMessageAction) {
  switch (action.type) {
    case CREATE_MESSAGE_PENDING:
      return {
        ...state,
        error: undefined,
        isLoading: true,
      };

    case CREATE_MESSAGE_RESPONSE:
      return {
        ...state,
        error: undefined,
        isLoading: false,
        messages: state.messages ? [...state.messages, action.payload] : [action.payload],
      };

    case CREATE_MESSAGE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
}
