import { ThunkAction } from 'redux-thunk';

import { ServiceIntegration } from '@/graphql/types-and-hooks';
import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

import { getBookingId } from '../selectors/checkout/booking';
import { getListingId } from '../selectors/listing/page';
import { getQuoteId } from '../selectors/quote';

const AVAILABLE_SERVICES_REQUEST = 'availableServices/AVAILABLE_SERVICES_REQUEST';
const AVAILABLE_SERVICES_RESPONSE = 'availableServices/AVAILABLE_SERVICES_RESPONSE';
const AVAILABLE_SERVICES_FAILURE = 'availableServices/AVAILABLE_SERVICES_FAILURE';

interface IAvailableServicesRequestAction extends IAction {
  type: typeof AVAILABLE_SERVICES_REQUEST;
}

interface IAvailableServicesResponseAction extends IAction {
  type: typeof AVAILABLE_SERVICES_RESPONSE;
  payload: ServiceIntegration[];
}

interface IAvailableServicesFailureAction extends IAction {
  type: typeof AVAILABLE_SERVICES_FAILURE;
  payload: Error;
}

type TGetAvailableServicesFunctionArg = { bookingId?: number; rentalId?: number; quoteId?: string };
type TGetAvailableServicesFunction = (
  ids?: TGetAvailableServicesFunctionArg,
) => ThunkAction<Promise<void>, TRootState, void, IAction>;

type TAction =
  | IAvailableServicesRequestAction
  | IAvailableServicesResponseAction
  | IAvailableServicesFailureAction;

const getAvailableBookingServices = (bookingId: number, query: Record<string, string>) => {
  return apiRequest<ServiceIntegration[]>(
    {
      url: `${getCoreApi()}/bookings/${bookingId}/services?${new URLSearchParams(query)}`,
      method: 'GET',
    },
    true,
  );
};

const getAvailableQuoteServices = (quoteId: string, query: Record<string, string>) => {
  return apiRequest<ServiceIntegration[]>(
    {
      url: `${getCoreApi()}/quotes/${quoteId}/services?${new URLSearchParams(query)}`,
      method: 'GET',
    },
    true,
  );
};

const getAvailableRentalServices = (rentalId: number) => {
  return apiRequest<ServiceIntegration[]>(
    {
      url: `${getCoreApi()}/rentals/${rentalId}/services`,
      method: 'GET',
    },
    true,
  );
};

export const getAvailableServices: TGetAvailableServicesFunction =
  (ids?: TGetAvailableServicesFunctionArg) => async (dispatch, getState) => {
    dispatch<IAvailableServicesRequestAction>({ type: AVAILABLE_SERVICES_REQUEST });

    const state = getState();
    const bookingId = ids?.bookingId || getBookingId(state);
    const quoteId = ids?.quoteId || getQuoteId(state);
    const rentalId = ids?.rentalId || getListingId(state);

    let promise: Promise<ServiceIntegration[]> | undefined;
    if (bookingId) {
      const canGenerateWeatherQuote = state?.checkout?.booking?.can_generate_weather_quote || false;
      promise = getAvailableBookingServices(bookingId, {
        weather: canGenerateWeatherQuote.toString(),
      });
    } else if (quoteId) {
      const canGenerateWeatherQuote = state?.quote?.data?.can_generate_weather_quote || false;
      promise = getAvailableQuoteServices(quoteId, { weather: canGenerateWeatherQuote.toString() });
    } else if (rentalId) {
      promise = getAvailableRentalServices(rentalId);
    }

    if (promise) {
      return promise
        .then(services => {
          dispatch<IAvailableServicesResponseAction>({
            type: AVAILABLE_SERVICES_RESPONSE,
            payload: services,
          });
        })
        .catch((error: Error) => {
          dispatch<IAvailableServicesFailureAction>({
            type: AVAILABLE_SERVICES_FAILURE,
            payload: error,
          });
        });
    } else {
      dispatch<IAvailableServicesResponseAction>({
        type: AVAILABLE_SERVICES_RESPONSE,
        payload: [],
      });

      return Promise.resolve();
    }
  };

interface IState {
  services: ServiceIntegration[];
  loading: boolean;
  errorMessage?: string | undefined;
}

export const initialState: IState = {
  services: [],
  loading: false,
};

export default function reducer(state = initialState, action: TAction): IState {
  switch (action.type) {
    case AVAILABLE_SERVICES_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: undefined,
      };
    case AVAILABLE_SERVICES_RESPONSE:
      return {
        ...state,
        services: action.payload,
        loading: false,
        errorMessage: undefined,
      };
    case AVAILABLE_SERVICES_FAILURE:
      return {
        ...state,
        services: [],
        loading: false,
        errorMessage: action.payload.message,
      };
    default:
      return state;
  }
}
