import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import { IMeta, IVehicleType } from '@/services/types/search/rentals/id';
import { keysToCamel } from '@/utility/objects';

type TSearchMeta = TRootState['search']['meta'];

export interface IMetaData {
  radius: number;
  lat: number;
  lng: number;
  city: string;
  county: string;
  state: string;
  country: string;
  countryCode: string;
  countryName: string;
  total: number;
  totalUnavailable: number;
  distance: number;
  startPosition: number;
  stopPosition: number;
  priceMax: number;
  priceMin: number;
  priceAverage: number;
  priceHistogram: {
    data: number[];
    maxValue: number;
  };
  vehicleTypes: IVehicleType[];
  locale: string;
  experiment: string;
  experiments: Record<string, string>;
  experimentsData: Record<string, Record<string, unknown>>;
  isBlended: boolean;
  drivableDistanceChecked: boolean;
}

const emptyMeta: IMetaData = {
  radius: 0,
  lat: 0,
  lng: 0,
  state: '',
  city: '',
  county: '',
  country: '',
  countryCode: '',
  countryName: '',
  total: 0,
  totalUnavailable: 0,
  distance: 0,
  startPosition: 0,
  stopPosition: 0,
  priceMax: 0,
  priceMin: 0,
  priceAverage: 0,
  priceHistogram: {
    data: [],
    maxValue: 0,
  },
  vehicleTypes: [],
  locale: '',
  experiment: '',
  experiments: {},
  experimentsData: {},
  isBlended: false,
  drivableDistanceChecked: false,
};

export const getSearchResultsMeta = createSelector<TRootState, TSearchMeta, IMetaData>(
  state => state.search.meta,
  meta => {
    if (!meta) {
      return emptyMeta;
    }

    return keysToCamel(meta);
  },
);

export const getNearbyResultsMeta = createSelector<TRootState, IMeta | undefined, IMetaData>(
  state => state.search.nearby?.meta,
  meta => {
    if (!meta) return emptyMeta;
    return keysToCamel(meta);
  },
);
