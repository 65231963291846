import { IItem, IUserNoticeEvent } from '@/services/types/booking/details';

import { IBookingService } from '../booking/services';
import { IOccupancy } from '../core/quotes';

export interface IDailyChanges {
  date: string;
  price: number;
  rules?: IRules[] | null;
}
interface IRules {
  description: string;
  price: number;
  rule_description: string;
  rule_id: number;
}
export interface IFutureBooking {
  from: string;
  items?: IItem[] | null;
  service_fee: number;
  services: IBookingService[];
  to: string;
  total: number;
}

export enum ProposalTypes {
  ChangeDates = 'change_dates',
  AddItem = 'add_item',
  RemoveItem = 'remove_item',
  BatchUpdateItems = 'batch_update_items',
  AddService = 'add_service',
  CancelRequest = 'cancel_request',
  AddDelivery = 'add_delivery',
  RemoveDelivery = 'remove_delivery',
  ChangeOccupancy = 'change_occupancy',
}

export type IProposal =
  | IAddItemProposal
  | IChangeDateProposal
  | ICancelRequestProposal
  | IBatchUpdateItemsProposal
  | IAddonProposal
  | IDeliveryProposal;

type IProposalDetail =
  | IChangeDateProposalDetails
  | ICancelProposalDetails
  | IAddItemProposalDetails
  | undefined;

type TProposalStatus = 'open' | 'approved' | 'declined' | 'accepted';
interface IAddItemProposal {
  action: ProposalTypes.AddItem | ProposalTypes.AddService;
  booking_id: number;
  created: string;
  daily_changes?: IDailyChanges[] | null;
  details?: IAddItemProposalDetails;
  id: number;
  object_id: number;
  object_type: string;
  owner_id: number;
  status: TProposalStatus;
  updated: string;
  dry_create: boolean;
  future_booking: IFutureBooking | null;
  renter_dismissed: string | null;
  renter_acknowledged: string | null;
  owner_acknowledged: string | null;
  user_notice_events?: IUserNoticeEvent[];
}
export interface IChangeDateProposal {
  action: ProposalTypes.ChangeDates;
  booking_id: number;
  created: string;
  daily_changes?: IDailyChanges[] | null;
  details?: IChangeDateProposalDetails;
  id: number;
  object_id: number;
  object_type: string;
  owner_id: number;
  status: TProposalStatus;
  updated: string;
  dry_create: boolean;
  future_booking: IFutureBooking | null;
  renter_dismissed: string | null;
  renter_acknowledged: string | null;
  owner_acknowledged: string | null;
  user_notice_events?: IUserNoticeEvent[];
}

export interface IChangeOccupancyProposal {
  action: ProposalTypes.ChangeOccupancy;
  booking_id: number;
  created: string;
  daily_changes?: IDailyChanges[] | null;
  details?: IOccupancy;
  id: number;
  object_id: number;
  object_type: string;
  owner_id: number;
  status: TProposalStatus;
  updated: string;
  dry_create: boolean;
  future_booking: IFutureBooking | null;
  renter_dismissed: string | null;
  renter_acknowledged: string | null;
  owner_acknowledged: string | null;
  user_notice_events?: IUserNoticeEvent[];
}
export interface ICancelRequestProposal {
  action: ProposalTypes.CancelRequest;
  booking_id: number;
  created: string;
  daily_changes?: null;
  id: number;
  details: ICancelProposalDetails;
  object_id: number;
  object_type: string;
  owner_id: number;
  status: TProposalStatus;
  updated: string;
  dry_create: boolean;
  future_booking: null;
  renter_dismissed: string | null;
  renter_acknowledged: string | null;
  owner_acknowledged: string | null;
  user_notice_events?: IUserNoticeEvent[];
}

export interface IChangeDateProposalDetails {
  from: string;
  from_time?: number;
  to: string;
  to_time?: number;
  owner_payout: number;
  rental_amount: number;
  total: number;
  prices?: IDailyChanges[] | null;
}

interface IAddItemProposalDetails {
  id: number;
  name: string;
  description: string;
  price: number;
  total: number;
  quantity: number;
  daily: string;
  created_at: string;
}

interface ICancelProposalDetails {
  status_change_request: Record<string, unknown>;
}

export interface IPricingRules {
  booking_id: number;
  created: string;
  current: boolean;
  daily_changes?: IDailyChanges[] | null;
  final_price: number;
  id: number;
  max_price: number;
  minimum_booking_days: number;
  num_days: number;
  updated: string;
}

export const isDateProposal = (pd: IProposalDetail): pd is IChangeDateProposalDetails => {
  return !!pd && 'from' in pd && 'to' in pd;
};

interface IBatchUpdateItemsProposal {
  action: ProposalTypes.BatchUpdateItems;
  booking_id: number;
  created: string;
  details: {
    batchTotal: number;
    items: IBatchUpdateItem[] | null;
  };
  dry_create: false;
  future_booking: null;
  id: number;
  object_id: number;
  object_type: string;
  owner_id: number;
  status: string;
  updated: string;
  daily_changes?: IDailyChanges[] | null;
  renter_dismissed: string | null;
  renter_acknowledged: string | null;
  owner_acknowledged: string | null;
  user_notice_events?: IUserNoticeEvent[];
}
interface IBatchUpdateItem {
  object_id: number;
  object_type: string;
  details: {
    id: number;
    name: string;
    description: string;
    price: number;
    total: number;
    quantity: number;
    daily: string;
    created_at: string;
  };
  action: string;
}

export interface IAddonProposal {
  action: ProposalTypes.AddItem | ProposalTypes.RemoveItem;
  booking_id: number;
  created: string;
  daily_changes?: IDailyChanges[] | null;
  details?: IAddItemProposalDetails;
  id: number;
  object_id: number;
  object_type: string;
  owner_id: number;
  status: TProposalStatus;
  updated: string;
  dry_create: boolean;
  future_booking: IFutureBooking | null;
  renter_dismissed: string | null;
  renter_acknowledged: string | null;
  owner_acknowledged: string | null;
  user_notice_events?: IUserNoticeEvent[];
}

export interface IDeliveryProposal {
  action: ProposalTypes.AddDelivery | ProposalTypes.RemoveDelivery;
  booking_id: number;
  created: string;
  daily_changes?: IDailyChanges[] | null;
  details?: IDeliveryProposalDetails;
  id: number;
  object_id: number;
  object_type: string;
  owner_id: number;
  status: TProposalStatus;
  updated: string;
  dry_create: boolean;
  future_booking: IFutureBooking | null;
  renter_dismissed: string | null;
  renter_acknowledged: string | null;
  owner_acknowledged: string | null;
  user_notice_events?: IUserNoticeEvent[];
}

interface IDeliveryProposalDetails {
  total: number;
  estimated_distance?: number;
  delivery_amount?: number;
  stationary?: boolean;
  rental_home?: IDeliveryProposalDetailsRentalHome;
  location?: IDeliveryProposalDetailsLocation;
  insurance_price_difference?: number;
  old_booking_delivery_location?: IOldBookingDeliveryLocation;
}

interface IDeliveryProposalDetailsRentalHome {
  country: string;
  state: string;
  city: string;
  street: string;
  zip: string;
  lat: number;
  lng: number;
}

interface IDeliveryProposalDetailsLocation extends IDeliveryProposalDetailsRentalHome {
  county: string;
  street1: string;
  short_city: string;
  short_country: string;
  short_county: string;
  short_state: string;
  place_id: string;
  formattedAddress: string;
}

interface IOldBookingDeliveryLocation {
  country: string;
  state: string;
  city: string;
  lat: number;
  lng: number;
  delivery: boolean;
}
