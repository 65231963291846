import React from 'react';

import Icon from '../Icon/Icon';
import { ICONS, IconSize } from '../Icon/Icon.constants';
import Tooltip from '../Tooltip/Tooltip';

interface IHelpProps {
  /**
   * The content displayed within the Tooltip
   */
  content: string;
  /**
   * An accessible label to describe the trigger
   */
  label: string;
  className?: string;
  tooltipClassName?: string;
}

const Help: React.FC<IHelpProps> = ({ content, label, className, tooltipClassName }) => {
  return (
    <Tooltip
      content={content}
      label={label}
      className={className}
      tooltipClassName={tooltipClassName}>
      <div className="inline-flex items-center justify-center w-5 h-5 border-none rounded-full text-gray-850 bg-canvas-200 transition-all duration-500 highlight hover:bg-gray-850 hover:text-white group-focus:bg-primary-900 group-focus:text-primary-contrast">
        <Icon name={ICONS.HELP} size={IconSize.normal} />
      </div>
    </Tooltip>
  );
};

export default Help;
