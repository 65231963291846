import { isCI } from '@/utility/isCI';
import { isJest } from '@/utility/isJest';

const reCaptchaToken = process.env.reCaptchaToken;

if (typeof reCaptchaToken !== 'string') {
  throw 'Recaptcha token is not set';
}

export const getReCaptchaToken = (): string => {
  if (isCI() || isJest()) return '';
  return reCaptchaToken;
};
