import { AxiosError } from 'axios';
import { ThunkAction } from 'redux-thunk';

import { updateUser } from '@/redux/modules/auth/userProfile';
import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';
import { getAuthToken } from '@/utility/session';

import { fetchUserFailure, IUserAction } from './userMini';

const VERIFICATION_TEXT_SERVICE_URL = `${getCoreApi()}/verify/phone`;
const VERIFICATION_CODE_SERVICE_URL = `${getCoreApi()}/verify/phone/finalize`;

//#region TEXT VERIFICATION
export const VERIFICATION_SET_ERROR = 'auth/VERIFICATION_SET_ERROR';
export const VERIFICATION_TEXT_PENDING = 'auth/VERIFICATION_TEXT_PENDING';
export const VERIFICATION_TEXT_FAILURE = 'auth/VERIFICATION_TEXT_FAILURE';
export const VERIFICATION_TEXT_SUCCESS = 'auth/VERIFICATION_TEXT_SUCCESS';
export const VERIFICATION_TEXT_RESET = 'auth/VERIFICATION_TEXT_RESET';
export const VERIFICATION_TEXT_RESENDING = 'auth/VERIFICATION_TEXT_RESENDING';

interface ITextVerificationResendingAction extends IAction {
  type: typeof VERIFICATION_TEXT_RESENDING;
}
interface ITextVerificationPendingAction extends IAction {
  type: typeof VERIFICATION_TEXT_PENDING;
}
interface ITextVerificationSetErrorAction extends IAction {
  type: typeof VERIFICATION_SET_ERROR;
  payload?: string;
}
interface ITextVerificationResetAction extends IAction {
  type: typeof VERIFICATION_TEXT_RESET;
}
interface ITextVerificationSuccessAction extends IAction {
  type: typeof VERIFICATION_TEXT_SUCCESS;
}
interface ITextVerificationFailureAction extends IAction {
  type: typeof VERIFICATION_TEXT_FAILURE;
  payload: AxiosError | undefined;
  error: boolean;
}
export type ITextVerificationAction =
  | ITextVerificationSuccessAction
  | ITextVerificationFailureAction
  | ITextVerificationPendingAction
  | ITextVerificationResetAction
  | ITextVerificationSetErrorAction
  | ITextVerificationResendingAction
  | ITogglePhoneVerificationModal;

export const textVerificationReset = (): ITextVerificationResetAction => ({
  type: VERIFICATION_TEXT_RESET,
});
const textVerificationResending = (): ITextVerificationResendingAction => ({
  type: VERIFICATION_TEXT_RESENDING,
});
const textVerificationPending = (): ITextVerificationPendingAction => ({
  type: VERIFICATION_TEXT_PENDING,
});

const textVerificationFailure = (error?: AxiosError): ITextVerificationFailureAction => ({
  type: VERIFICATION_TEXT_FAILURE,
  payload: error,
  error: true,
});

export const textVerificationSetError = (payload: string): ITextVerificationSetErrorAction => ({
  type: VERIFICATION_SET_ERROR,
  payload,
});

const textVerificationSuccess = (): ITextVerificationSuccessAction => {
  return {
    type: VERIFICATION_TEXT_SUCCESS,
  };
};
// async SEND TEXT THUNK (mini_me)
export const postTextVerification =
  (
    phone: string,
    isResending?: boolean,
  ): ThunkAction<void, TRootState, void, ITextVerificationAction | IUserAction> =>
  async dispatch => {
    if (isResending) dispatch(textVerificationResending());
    else dispatch(textVerificationPending());
    //not using cookie values on redux here because it cannot be trusted as SSOT
    const authToken = getAuthToken();

    if (!authToken) {
      dispatch(fetchUserFailure({ code: 401 } as unknown as AxiosError));
      dispatch(textVerificationFailure());
    }
    try {
      await apiRequest(
        {
          data: { phone },
          url: VERIFICATION_TEXT_SERVICE_URL,
          method: 'POST',
        },
        true,
      );

      dispatch(textVerificationSuccess());
    } catch (error) {
      dispatch(textVerificationFailure(error));
    }
  };

//#endregion

//#region CODE VERIFICATION
export const VERIFICATION_CODE_PENDING = 'auth/VERIFICATION_CODE_PENDING';
export const VERIFICATION_CODE_FAILURE = 'auth/VERIFICATION_CODE_FAILURE';
export const VERIFICATION_CODE_SUCCESS = 'auth/VERIFICATION_CODE_SUCCESS';

interface ICodeVerificationPendingAction extends IAction {
  type: typeof VERIFICATION_CODE_PENDING;
}
interface ICodeVerificationSuccessAction extends IAction {
  type: typeof VERIFICATION_CODE_SUCCESS;
}

interface ICodeVerificationFailureAction extends IAction {
  type: typeof VERIFICATION_CODE_FAILURE;
  payload: AxiosError | undefined;
  error: boolean;
}
export type ICodeVerificationAction =
  | ICodeVerificationSuccessAction
  | ICodeVerificationFailureAction
  | ICodeVerificationPendingAction;

const codeVerificationSuccess = (): ICodeVerificationSuccessAction => {
  return {
    type: VERIFICATION_CODE_SUCCESS,
  };
};

const codeVerificationPending = (): ICodeVerificationPendingAction => ({
  type: VERIFICATION_CODE_PENDING,
});

const codeVerificationFailure = (error?: AxiosError): ICodeVerificationFailureAction => ({
  type: VERIFICATION_CODE_FAILURE,
  payload: error,
  error: true,
});

export const postCodeVerification =
  (
    code: string,
    phone: string,
  ): ThunkAction<void, TRootState, void, ICodeVerificationAction | IUserAction> =>
  async dispatch => {
    dispatch(codeVerificationPending());
    //not using cookie values on redux here because it cannot be trusted as SSOT
    const authToken = getAuthToken();

    if (!authToken) {
      dispatch(fetchUserFailure({ code: 401 } as unknown as AxiosError));
      dispatch(codeVerificationFailure());
    }

    try {
      await apiRequest(
        {
          data: { code },
          url: VERIFICATION_CODE_SERVICE_URL + '?code=' + code,
          method: 'POST',
        },
        true,
      );

      dispatch(codeVerificationSuccess());
      dispatch(updateUser({ phone }));
    } catch (error) {
      dispatch(codeVerificationFailure(error));
    }
  };
//#endregion

//#region VERIFY MODAL
interface ITogglePhoneVerificationModal extends IAction {
  type: typeof TOGGLE_PHONE_VERIFY_MODAL;
  payload: boolean;
}

export const TOGGLE_PHONE_VERIFY_MODAL = 'auth/TOGGLE_PHONE_VERIFY_MODAL';

export const openPhoneVerificationModal = (): ITogglePhoneVerificationModal => ({
  type: TOGGLE_PHONE_VERIFY_MODAL,
  payload: true,
});

export const closePhoneVerificationModal = (): ITogglePhoneVerificationModal => ({
  type: TOGGLE_PHONE_VERIFY_MODAL,
  payload: false,
});
//#endregion
