import useSWR from 'swr';

import { getCoreApi } from '@/utility/getCoreApi';

import apiRequest from './apiRequest';

interface IRecentSearchOption {
  created: string;
  id: number;
  label: string;
  search_url: string;
  updated: string;
  user_id: number;
  value: string;
  timestamp: number;
}

const RECENT_SEARCHES_URL = `${getCoreApi()}/user-recent-searches`;

export const useRecentSearches = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  return useSWR<IRecentSearchOption[]>(
    isAuthenticated ? RECENT_SEARCHES_URL : null,
    (url: string) => apiRequest<IRecentSearchOption[]>({ url, method: 'GET' }),
  );
};

export const saveRecentSearch = async (search_url: string): Promise<IRecentSearchOption[]> => {
  const response = await apiRequest<IRecentSearchOption[]>({
    url: RECENT_SEARCHES_URL,
    data: { search_url },
    method: 'POST',
  });
  return response;
};
