import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

export function useCacheEviction(fieldNames: string[]) {
  const { cache } = useApolloClient();
  const evict = useCallback(() => {
    fieldNames.forEach(fieldName => {
      cache.evict({ id: 'ROOT_QUERY', fieldName });
    });
    cache.gc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cache]);

  return { evict };
}
