import React from 'react';

import Divider from '@/components/switchback/Divider/Divider';
import { calculateOptionIndex } from '@/utility/autoCompleteGroupedOptions';

import { IGroupOption, IOption } from './LocationSearchInput';

interface ILocationSearchContentProps {
  open: boolean;
  groupOptions: IGroupOption[];
  allGroupOptions: IGroupOption[];
  getListboxProps: () => Record<string, unknown>;
  getOptionProps: ({
    option,
    index,
  }: {
    option: IOption;
    index: number;
  }) => Record<string, unknown>;
}

const LocationSearchContent: React.FC<ILocationSearchContentProps> = ({
  open,
  groupOptions,
  allGroupOptions,
  getListboxProps,
  getOptionProps,
}) => {
  return (
    <div
      className={`pb-6 bg-white rounded-bl-lg rounded-br-lg ${open ? 'block' : 'hidden'}`}
      {...getListboxProps()}>
      {groupOptions.map((groupOption, groupIndex) => (
        <div key={groupIndex}>
          {groupIndex > 0 && <Divider className="mx-4 my-6" />}
          <div className="px-4 pt-6 pb-4 text-xs font-bold text-gray-500 uppercase semiHighlight">
            {groupOption.group}
          </div>
          <ul>
            {groupOption.options.map((option, optionIndex) => (
              <li
                key={option.label}
                className="px-4 text-base font-normal cursor-pointer py-1.5 hover:bg-gray-100 text-primary-base semiHighlight"
                {...getOptionProps({
                  option,
                  index: calculateOptionIndex(option.groupName, optionIndex, allGroupOptions),
                })}>
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default LocationSearchContent;
