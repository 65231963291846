import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { IPricingRules } from '@/services/types/proposals/proposals';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';
import { getAuthToken } from '@/utility/session';

const getPricingRulesUrl = (bookingId: number): string =>
  `${getCoreApi()}/bookings/${bookingId}/pricing-rules`;

const PRICING_RULE_LOADING = 'pricing-rule/PRICING_RULE_LOADING';
const PRICING_RULE_ERROR = 'pricing-rule/PRICING_RULE_ERROR';
const PRICING_RULE_SUCCESS = 'pricing-rule/PRICING_RULE_SUCCESS';

interface IPricingRulesLoading extends IAction {
  type: typeof PRICING_RULE_LOADING;
}
interface IPricingRulesError extends IAction {
  type: typeof PRICING_RULE_ERROR;
}
interface IPricingRulesSuccess extends IAction {
  type: typeof PRICING_RULE_SUCCESS;
  payload: IPricingRules;
}

type TAction = IPricingRulesLoading | IPricingRulesError | IPricingRulesSuccess;

const pricingRuleLoading = (): IPricingRulesLoading => ({
  type: PRICING_RULE_LOADING,
});
const pricingRuleError = (): IPricingRulesError => ({
  type: PRICING_RULE_ERROR,
});
const pricingRuleSuccess = (payload: IPricingRules): IPricingRulesSuccess => ({
  type: PRICING_RULE_SUCCESS,
  payload,
});

export const getPricingRules =
  (): ThunkAction<
    Promise<null | void>,
    TRootState,
    void,
    IPricingRulesLoading | IPricingRulesSuccess | IPricingRulesError
  > =>
  async (dispatch, getState) => {
    const state = getState();
    const bookingId = state.booking.details.data?.id;
    if (!bookingId) return;
    const url = getPricingRulesUrl(bookingId);
    dispatch(pricingRuleLoading());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(pricingRuleError());
      return;
    }
    try {
      const pricingRule = await apiRequest<IPricingRules>({ url }, true);
      if (pricingRule) {
        dispatch(pricingRuleSuccess(pricingRule));
      } else {
        dispatch(pricingRuleError());
      }
    } catch {
      dispatch(pricingRuleError());
    }
  };

// Selectors prefixed with "select"
export const selectPricingRules = (state: TRootState): IPricingRulesState => state.pricingRules;

interface IPricingRulesState {
  data: IPricingRules | null;
  error: boolean;
  isLoading: boolean;
}
export const initialState: IPricingRulesState = {
  data: null,
  error: false,
  isLoading: false,
};
export default function pricingRulesReducer(state = initialState, action: TAction) {
  switch (action.type) {
    case PRICING_RULE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case PRICING_RULE_ERROR:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    case PRICING_RULE_SUCCESS:
      return {
        error: false,
        isLoading: false,
        data: action.payload,
      };
    default:
      return { ...state };
  }
}
