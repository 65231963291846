import { AxiosError } from 'axios';

import {
  ILoginAction,
  RESET_USER,
  SIGNIN_USER_FAILURE,
  SIGNIN_USER_PENDING,
  SIGNIN_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  SIGNUP_USER_PENDING,
  SIGNUP_USER_SUCCESS,
} from '@/redux/modules/auth/login';
import {
  ICodeVerificationAction,
  ITextVerificationAction,
  TOGGLE_PHONE_VERIFY_MODAL,
  VERIFICATION_CODE_FAILURE,
  VERIFICATION_CODE_PENDING,
  VERIFICATION_CODE_SUCCESS,
  VERIFICATION_SET_ERROR,
  VERIFICATION_TEXT_FAILURE,
  VERIFICATION_TEXT_PENDING,
  VERIFICATION_TEXT_RESENDING,
  VERIFICATION_TEXT_RESET,
  VERIFICATION_TEXT_SUCCESS,
} from '@/redux/modules/auth/phoneVerification';
import {
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_SUCCESS,
  ResetPasswordAction,
} from '@/redux/modules/auth/resetPassword';
import {
  FETCH_USER_FAILURE,
  FETCH_USER_PENDING,
  FETCH_USER_SKIPPED,
  FETCH_USER_SUCCESS,
  IUserAction,
  IUserResponse,
} from '@/redux/modules/auth/userMini';
import { TRootState } from '@/redux/rootReducer';

import { IUpdateUserAction, UPDATE_USER_FAILURE, UPDATE_USER_SUCCESS } from './userProfile';

//exports
export { loginUser, resetUser, signupUser } from '@/redux/modules/auth/login';
export {
  closePhoneVerificationModal,
  openPhoneVerificationModal,
  postCodeVerification,
  postTextVerification,
  textVerificationReset,
} from '@/redux/modules/auth/phoneVerification';
export { resetPassword } from '@/redux/modules/auth/resetPassword';
export { fetchUser } from '@/redux/modules/auth/userMini';
export { updateUser } from '@/redux/modules/auth/userProfile';

export type TAuthError =
  | {
      code: number | string;
      error?: string;
    }
  | string;

interface IState {
  hasTriedFetch: boolean;
  isFetching: boolean;
  isLoggingIn: boolean;
  user: null | IUserResponse;
  isAuthenticated: boolean;
  phone: {
    number: string;
    isModalOpen: boolean | null;
    isResending: boolean;
    isVerifyingCode: boolean;
    isVerifiedCode: boolean;
    isTexting: boolean;
    isTexted: boolean;
    error?: null | AxiosError | string;
  };
  error: null | TAuthError;
  resetPassword: {
    error: null | TAuthError;
    loading: boolean;
  };
}
export const selectAuth = (state: TRootState) => state.auth;
export const selectAuthResetPasswordError = (state: TRootState) => state.auth.resetPassword.error;
export const selectAuthResetPasswordLoading = (state: TRootState) =>
  state.auth.resetPassword.loading;

export const initialAuthState: IState = {
  hasTriedFetch: false,
  isLoggingIn: false,
  isFetching: false,
  user: null,
  isAuthenticated: false,
  error: null,
  phone: {
    number: '',
    isModalOpen: false,
    isTexting: false,
    isResending: false,
    isTexted: true,
    isVerifyingCode: false,
    isVerifiedCode: false,
    error: null,
  },
  resetPassword: {
    error: null,
    loading: false,
  },
};

export default function authReducer(
  state = initialAuthState,
  action:
    | ILoginAction
    | IUserAction
    | ITextVerificationAction
    | ICodeVerificationAction
    | IUpdateUserAction
    | ResetPasswordAction,
): IState {
  switch (action.type) {
    case VERIFICATION_SET_ERROR:
      return {
        ...state,
        phone: {
          ...state.phone,
          error: action.payload,
        },
      };
    case VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        phone: {
          ...state.phone,
          isVerifyingCode: false,
          isVerifiedCode: false,
          error: action.payload,
        },
      };
    case VERIFICATION_CODE_PENDING:
      return {
        ...state,
        phone: {
          ...state.phone,
          isVerifyingCode: true,
          isVerifiedCode: false,
        },
      };
    case VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        phone: {
          ...state.phone,
          isVerifyingCode: false,
          isVerifiedCode: true,
        },
      };

    case VERIFICATION_TEXT_RESET:
      return {
        ...state,
        phone: {
          ...state.phone,
          isTexting: false,
          isTexted: false,
          isVerifyingCode: false,
          isVerifiedCode: false,
          error: null,
        },
      };
    case VERIFICATION_TEXT_RESENDING:
      return {
        ...state,
        phone: {
          ...state.phone,
          isResending: true,
          isTexted: false,
        },
      };
    case VERIFICATION_TEXT_PENDING:
      return {
        ...state,
        phone: {
          ...state.phone,
          isTexting: true,
          isTexted: false,
        },
      };
    case VERIFICATION_TEXT_SUCCESS:
      return {
        ...state,
        phone: {
          ...state.phone,
          isResending: false,
          isTexting: false,
          isTexted: true,
        },
      };
    case VERIFICATION_TEXT_FAILURE:
      return {
        ...state,
        phone: {
          ...state.phone,
          error: action.payload,
          isTexting: false,
          isTexted: false,
        },
      };
    case TOGGLE_PHONE_VERIFY_MODAL:
      return {
        ...state,
        phone: {
          ...state.phone,
          isModalOpen: action.payload,
          isTexting: false,
          isTexted: false,
          isVerifyingCode: false, //for matching code
          isVerifiedCode: false, //for matched code
        },
      };

    case FETCH_USER_PENDING:
      return { ...state, isFetching: true, hasTriedFetch: true };
    case FETCH_USER_SKIPPED:
      return { ...state, hasTriedFetch: true };
    case SIGNIN_USER_PENDING:
      return { ...state, isLoggingIn: true };
    case SIGNUP_USER_PENDING:
      return { ...state, isLoggingIn: true };
    case RESET_USER:
    case SIGNIN_USER_FAILURE:
    case SIGNUP_USER_FAILURE:
    case FETCH_USER_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isFetching: false,
        user: null,
        error: action.payload,
        isAuthenticated: false,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload,
        error: null,
        isAuthenticated: true,
      };
    case SIGNIN_USER_SUCCESS:
    case SIGNUP_USER_SUCCESS:
    case UPDATE_USER_SUCCESS:
      //TODO:Do we need login response on store for anything?
      return {
        ...state,
        isLoggingIn: false,
        error: null,
        isAuthenticated: true,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        error: {
          code: action.payload?.code ? action.payload.code : '500',
          error: action.payload?.message ? action.payload.message : 'Internal Server Error',
        },
      };
    case RESET_PASSWORD_PENDING:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: true,
        },
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          error: null,
          loading: false,
        },
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          error: action.payload,
          loading: false,
        },
      };
    default:
      return state;
  }
}
