import { createSelector } from 'reselect';

import { defaultLocale, TCurrency } from '@/config/locales';
import { TRootState } from '@/redux/rootReducer';
import { getLocale } from '@/redux/selectors/locale';
import { isCurrency, parseCurrencyCookie } from '@/utility/currency';
import { getCurrencyCookie } from '@/utility/session';

import { getQuoteCurrency } from './quote';

export const getUserCurrency = createSelector<
  TRootState,
  TCurrency | undefined,
  TCurrency | undefined,
  ReturnType<typeof getLocale>,
  TCurrency
>(
  // We might be in a situation where state was not updated yet, so use the cookie as a fallback
  state => parseCurrencyCookie(state.cookies.userLocaleCurrency) || getCurrencyCookie(),
  state => {
    const currency = state.auth.user?.currency;
    if (isCurrency(currency)) return currency;
  },
  getLocale,
  (cookiesCurrency, userCurrency, locale) => {
    const localeCurrency = locale.base_currency;
    const defaultCurrency = defaultLocale.base_currency;

    return userCurrency || cookiesCurrency || localeCurrency || defaultCurrency;
  },
);

export const getCurrency = createSelector<TRootState, TCurrency | undefined, TCurrency, TCurrency>(
  getQuoteCurrency,
  getUserCurrency,
  (quoteCurrency, userCurrency) => quoteCurrency || userCurrency,
);
