import { getODAnonymousId } from '@/services/segment';

import { isSSR } from './isSSR';
import { logger } from './logger';

interface Window {
  Outdoorsy?: {
    metrics?: { getIdentifier?: () => { did: string; sid: string } };
  };
}

export const getHeaders = async () => {
  const headers: Record<string, unknown> = {};

  const partnerId = process.env.partnerId;
  if (partnerId) {
    headers['Partner-ID'] = partnerId;
  }
  const getIdentifier = !isSSR() && (window as Window)?.Outdoorsy?.metrics?.getIdentifier;
  if (getIdentifier) {
    const { sid, did } = getIdentifier();
    headers['X-App-Session'] = sid;
    headers['X-Device-ID'] = did;
    headers['riskified-session-id'] = window?.sessionStorage?.getItem('riskified-session-id');
  }
  if (!isSSR()) {
    try {
      const anonId = await getODAnonymousId();
      if (anonId) {
        headers['X-Anonymous-ID'] = anonId;
      }
    } catch (err) {
      logger.error(err);
    }
  }

  return headers;
};
