import React from 'react';

import OutdoorsyLogo from '@/assets/images/outdoorsy-logo.svg';
import Modal from '@/components/switchback/Modal';
import { ModalSize } from '@/components/switchback/Modal/Modal';
import { SystemDowntimeState, useSystemDowntime } from '@/services/experiments/systemDowntime';

const SystemDowntimeNotice = () => {
  const systemDowntime = useSystemDowntime();

  if (!systemDowntime || systemDowntime.state === SystemDowntimeState.Off) return null;

  if (systemDowntime.state === SystemDowntimeState.Active) {
    return (
      <Modal size={ModalSize.medium} open>
        <OutdoorsyLogo className="mb-6" />
        <h4 className="mb-2 text-xl font-medium">{systemDowntime.title}</h4>
        <p>{systemDowntime.description}</p>
      </Modal>
    );
  }

  return (
    <div className="py-2 text-center bg-bark-100">
      <p className="text-sm font-medium leading-6">{systemDowntime.title}</p>
      <p className="text-xs text-gray-50">{systemDowntime.description}</p>
    </div>
  );
};

export default SystemDowntimeNotice;
