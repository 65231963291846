import React from 'react';

import OutdoorsyLogo from '@/assets/images/outdoorsy-logo.svg';
import Link from '@/components/switchback/Link/Link';

interface IProps {
  onClickLogo: () => void;
}

const GlobalHeaderLogoOnly: React.FC<IProps> = ({ onClickLogo }) => {
  return (
    <header
      id="global-header"
      data-testid="global-header"
      className="relative z-40 border-b border-gray-200 bg-canvas-100">
      <div className="grid grid-cols-main container-main gap-x-default">
        <div className="flex items-center justify-between py-3 col-content lg:py-7 sm:py-4 h-18 ">
          <Link href="/" className="">
            <OutdoorsyLogo
              className="block text-gray-900 translate-y-1 transform"
              onClick={onClickLogo}
            />
          </Link>
        </div>
      </div>
    </header>
  );
};

export default GlobalHeaderLogoOnly;
