import Cookies from 'js-cookie';

import { ERentalType } from './types';

type TFBFixelProps = {
  actionSource: string;
  contentType?: string;
  fbp?: string;
  fbc?: string;
};

export function getFBPixelProps(): TFBFixelProps {
  const fbp = Cookies.get('_fbp');
  const fbc = Cookies.get('_fbc');
  const props: TFBFixelProps = { actionSource: 'website' };
  if (fbp) props.fbp = fbp;
  if (fbc) props.fbc = fbc;
  return props;
}

export const parseRentalType = (isStay: boolean, isCampground: boolean): ERentalType => {
  let rentalType: ERentalType;
  if (isStay) {
    rentalType = ERentalType.STAY;
  } else if (isCampground) {
    rentalType = ERentalType.CAMPGROUND;
  } else {
    rentalType = ERentalType.RV;
  }
  return rentalType;
};

export const parseEventDate = (date: Date | string) =>
  parseInt((new Date(date).getTime() / 1000).toFixed(0));
