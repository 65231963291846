/**
 * This file implements some Ember utils (https://github.com/emberjs/ember.js/tree/master/packages/%40ember/-internals/metal/lib)
 * We should only be using these if we need exact parity with the Ember app's logic.
 */

// Ember.isEmpty uses Ember.get(obj, 'size') and Ember.get(obj, 'length')
// We can probably get away with obj.length and obj.size which takes care
// of Arrays, Maps, Sets.
function isEmpty(obj: any): boolean {
  const none = obj === null || obj === undefined;
  if (none) {
    return none;
  }

  if (typeof obj.size === 'number') {
    return !obj.size;
  }

  const objectType = typeof obj;

  if (objectType === 'object') {
    // was: let size = get(obj, 'size')
    const size = obj.size;
    if (typeof size === 'number') {
      return !size;
    }
  }

  if (typeof obj.length === 'number' && objectType !== 'function') {
    return !obj.length;
  }

  if (objectType === 'object') {
    // was: let length = get(obj, 'length')
    const length = obj.length;
    if (typeof length === 'number') {
      return !length;
    }
  }

  return false;
}
function isBlank(obj: unknown): boolean {
  return isEmpty(obj) || (typeof obj === 'string' && /\S/.test(obj) === false);
}
function isPresent(obj: unknown): boolean {
  return !isBlank(obj);
}

export function compact(objectInstance: { [key: string]: any }) {
  const compactedObject: { [key: string]: any } = {};

  for (const key in objectInstance) {
    const value = objectInstance[key];

    if (isPresent(value)) {
      compactedObject[key] = value;
    }
  }

  return compactedObject;
}

// This regexp is used by Ember
const CAPITALIZE_REGEXP = /(^|\/)([a-z\u00C0-\u024F])/g;
const CAPITALIZE_CACHE: { [key: string]: string } = {};
export function capitalize(str: string) {
  if (CAPITALIZE_CACHE[str]) return CAPITALIZE_CACHE[str];
  const capStr = str.replace(CAPITALIZE_REGEXP, match => match.toUpperCase());
  CAPITALIZE_CACHE[str] = capStr;
  return capStr;
}
