import { fetchUser } from '@/redux/modules/auth';
import { setCookie } from '@/redux/modules/cookies';
import { TAppDispatch } from '@/redux/rootReducer';
import { ILoginResponse } from '@/services/types/auth/ILoginResponse';

const COOKIE_SESSION_NAME = 'outdoorsy-session';
const COOKIE_SESSION_EXPIRATION = 18262; // 50 years floored (in days, 365.25 * 50)

export const setSessionCookie = async (data: ILoginResponse, dispatch: TAppDispatch) => {
  const cookieData = {
    actorId: null,
    authenticated: {
      ...data,
      authenticator: 'authenticator:outdoorsy',
    },
  };

  dispatch(
    setCookie({
      name: COOKIE_SESSION_NAME,
      value: cookieData,
      options: {
        path: '/',
        expires: COOKIE_SESSION_EXPIRATION,
      },
    }),
  );

  await dispatch(fetchUser(false, true));
};
