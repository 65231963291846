import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { IInsuranceBundle } from '@/services/types/booking/insuranceBundle';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

import { getBookingData } from '../selectors/checkout/booking';
import { getCurrency } from '../selectors/currency';
import { getQuoteFromAndTo } from '../selectors/listing/bill';
import { getFromAndTo } from '../selectors/queryParams';
import { getQuoteDelivery } from '../selectors/quote';

// TODO: Add async actions (REQUEST/FAIL/SUCCESS)
const SET_INSURANCE_BUNDLES = 'insurance/SET_BUNDLES';

interface ISetInsuranceBundlesAction extends IAction {
  type: typeof SET_INSURANCE_BUNDLES;
  payload: IInsuranceBundle[];
}

type TAction = ISetInsuranceBundlesAction;

const setInsuranceBundles = (payload: IInsuranceBundle[]): ISetInsuranceBundlesAction => ({
  type: SET_INSURANCE_BUNDLES,
  payload,
});

export const getInsuranceBundles =
  (
    rentalId: string,
    rawFrom?: string,
    rawTo?: string,
    rawStationaryDelivery?: boolean,
  ): ThunkAction<
    Promise<ISetInsuranceBundlesAction>,
    TRootState,
    void,
    ISetInsuranceBundlesAction
  > =>
  async (dispatch, getState) => {
    const state = getState();
    const presentment_currency = getCurrency(state);
    const { from: queryFrom, to: queryTo } = getFromAndTo(state);
    const { from: quoteFrom, to: quoteTo } = getQuoteFromAndTo(state);
    const stationaryDelivery = Boolean(
      rawStationaryDelivery ||
        getQuoteDelivery(state)?.stationary ||
        getBookingData(state)?.delivery?.stationary,
    );
    const from = rawFrom || queryFrom || quoteFrom;
    const to = rawTo || queryTo || quoteTo;
    const url = `${getCoreApi()}/rentals/${rentalId}/bundles`;
    const params = { presentment_currency, from, to, delivery: stationaryDelivery };

    const data = await apiRequest<IInsuranceBundle[]>({ url, params, method: 'GET' });
    return dispatch(setInsuranceBundles(data ?? []));
  };

export const initialState: IInsuranceBundle[] = [];

// TODO: Add async actions (REQUEST/FAIL/SUCCESS)
export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case SET_INSURANCE_BUNDLES:
      return action.payload;
    default:
      return state;
  }
}
