import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useLocaleSettings } from '@/hooks/useLocaleSettings';
import { useRouter } from '@/hooks/useRouter';
import { useUser } from '@/hooks/useUser';
import { selectAuthenticated } from '@/redux/modules/auth/selectors';
import { removeCookie } from '@/redux/modules/cookies';
import { getUser } from '@/redux/selectors/auth/user';
import { getUserCurrency } from '@/redux/selectors/currency';
import { trackNavClickedEvent } from '@/services/analytics/header';
import { formatCurrency } from '@/utility/currency';
import { COOKIE_SESSION_NAME, openSessionModal } from '@/utility/session';

import GlobalHeaderNavDestinations from './GlobalHeaderNavDestinations';
import GlobalHeaderNavMenuItem from './GlobalHeaderNavMenuItem/GlobalHeaderNavMenuItem';
import GlobalHeaderNavTravelJournal from './GlobalHeaderNavTravelJournal';

enum ESubmenus {
  DESTINATIONS = 'destinations',
  TRAVEL_JOURNAL = 'travel_journal',
}

interface IGlobalHeaderNavMobileMenuProps {
  userName?: string;
  isOwner?: boolean;
}

const GlobalHeaderNavMobileMenu: React.FC<
  React.PropsWithChildren<IGlobalHeaderNavMobileMenuProps>
> = ({ userName, isOwner = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { locale } = useLocaleSettings();
  const { logout } = useUser();

  const isAuthenticated = useSelector(selectAuthenticated);
  const { credits } = useSelector(getUser) || { credits: 0 };
  const currency = useSelector(getUserCurrency);

  const [openSubmenu, setOpenSubmenu] = useState<ESubmenus | null>(null);

  const { pathname } = useRouter();
  const newBecomeAHostPage = pathname.includes('/become-a-host/');
  const isBAHLandingPage = pathname === '/become-a-host';

  const handleOpenSubmenu = (submenu: ESubmenus) => () => {
    setOpenSubmenu(submenu);
  };

  const handleCloseSubmenu = () => {
    setOpenSubmenu(null);
  };

  const handleClickLogin = () => {
    openSessionModal('login');
  };

  const handleClickSignup = () => {
    openSessionModal('signup');
  };

  const trackClickedEvent = (link: string) => {
    trackNavClickedEvent({
      clickedLink: link,
      isInDropdown: true,
      dropdownHeader: 'My Account',
    });
  };

  const profileLabel = intl.formatMessage({
    defaultMessage: 'Profile',
    id: 'jJpROM',
    description: 'Menu constants > My account',
  });

  const settingsLabel = intl.formatMessage({
    defaultMessage: 'Settings',
    id: 'YnaTO3',
    description: 'Menu constants > My account',
  });

  const creditsLabelNoCredits = intl.formatMessage({
    defaultMessage: 'Refer friends, earn credits ',
    id: 'fTlXD1',
    description: 'Menu constants > My account',
  });
  const creditsLabelWithCredits = intl.formatMessage(
    {
      defaultMessage: '{availableCredits} in credits available',
      id: '6lhS+M',
      description: 'Menu constants > My account',
    },
    {
      availableCredits: formatCurrency({
        priceInCents: credits,
        currency,
        digits: 2,
      }),
    },
  );

  const destinationsMenuItem = ['en-us', 'en-ca', 'fr-ca'].includes(locale) && (
    <GlobalHeaderNavMenuItem
      data-testid="destinations-btn"
      label={intl.formatMessage({
        defaultMessage: 'Destinations',
        id: 'nssesN',
        description: 'Global Header Nav: Destinations link',
      })}
      submenu={<GlobalHeaderNavDestinations />}
      onClick={handleOpenSubmenu(ESubmenus.DESTINATIONS)}
      onCloseSubmenu={handleCloseSubmenu}
      submenuOpen={openSubmenu === ESubmenus.DESTINATIONS}
      newNavMenuItem
    />
  );

  const travelJournalMenuItem = (
    <GlobalHeaderNavMenuItem
      data-testid="travel-journal-btn"
      label={intl.formatMessage({
        defaultMessage: 'Travel Journal',
        id: 'G/wCJJ',
        description: 'Global Header Nav: Travel Journal link',
      })}
      submenu={<GlobalHeaderNavTravelJournal />}
      onClick={handleOpenSubmenu(ESubmenus.TRAVEL_JOURNAL)}
      onCloseSubmenu={handleCloseSubmenu}
      submenuOpen={openSubmenu === ESubmenus.TRAVEL_JOURNAL}
      newNavMenuItem
    />
  );

  return (
    <nav className="h-full">
      <ul className="flex flex-col h-full" role="menubar">
        {(isAuthenticated && (
          <>
            <li className="mt-4 mb-6 font-bold text-gray-900 autoType600">
              {userName ? (
                <FormattedMessage
                  defaultMessage="Hi, {name}"
                  id="IjcYm5"
                  description="Global Header > My Account"
                  values={{ name: userName }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Hi"
                  id="Sd5efi"
                  description="Global Header > My Account"
                />
              )}
            </li>

            <li className="border-b border-gray-310" />

            {credits > 0 ? (
              <GlobalHeaderNavMenuItem
                href="/referral/renter"
                label={creditsLabelWithCredits}
                onClick={() => trackClickedEvent('referral credits')}
                newNavMenuItem
              />
            ) : (
              <GlobalHeaderNavMenuItem
                href="/referral/renter"
                label={creditsLabelNoCredits}
                onClick={() => trackClickedEvent('referral credits')}
                newNavMenuItem
              />
            )}

            <GlobalHeaderNavMenuItem
              href="/dashboard/profile"
              label={profileLabel}
              onClick={() => trackClickedEvent(profileLabel)}
              newNavMenuItem
            />

            <GlobalHeaderNavMenuItem
              href="/dashboard/account/notifications"
              label={settingsLabel}
              onClick={() => trackClickedEvent(settingsLabel)}
              newNavMenuItem
            />

            {!isBAHLandingPage && destinationsMenuItem}
            {!isBAHLandingPage && travelJournalMenuItem}

            <li className="border-b border-gray-310" />

            {isOwner ? (
              <GlobalHeaderNavMenuItem
                href="/dashboard"
                label={intl.formatMessage({
                  defaultMessage: 'Switch to hosting',
                  id: '2dLHvx',
                  description: 'Global Header > My Account',
                })}
                onClick={() => trackClickedEvent('Dashboard')}
                newNavMenuItem
              />
            ) : (
              !isBAHLandingPage &&
              (newBecomeAHostPage ? (
                <GlobalHeaderNavMenuItem
                  label={intl.formatMessage({
                    defaultMessage: 'Try hosting today',
                    id: '73Bvs2',
                    description: 'Global Header > My Account',
                  })}
                  newNavMenuItem
                  newBecomeAHostPage={newBecomeAHostPage}
                  linkButton
                />
              ) : (
                <GlobalHeaderNavMenuItem
                  href="/become-a-host"
                  label={intl.formatMessage({
                    defaultMessage: 'Become a host',
                    id: '1Cch+Q',
                    description: 'Global Header > My Account',
                  })}
                  onClick={() => trackClickedEvent('List my RV')}
                  newNavMenuItem
                />
              ))
            )}

            <GlobalHeaderNavMenuItem
              label={intl.formatMessage({
                defaultMessage: 'Log out',
                id: 'Xne/lE',
                description: 'Global Header > My Account',
              })}
              onClick={() => {
                trackClickedEvent('Log out');

                logout();
                dispatch(removeCookie(COOKIE_SESSION_NAME));
              }}
              newNavMenuItem
            />
          </>
        )) || (
          <>
            {destinationsMenuItem && !isBAHLandingPage && (
              <>
                {destinationsMenuItem}
                <li className="border-b border-gray-310" />
              </>
            )}
            {travelJournalMenuItem && !isBAHLandingPage && (
              <>
                {travelJournalMenuItem}
                <li className="border-b border-gray-310" />
              </>
            )}
            <GlobalHeaderNavMenuItem
              data-testid="login-btn"
              onClick={handleClickLogin}
              label={intl.formatMessage({
                defaultMessage: 'Log in',
                id: '5+5g80',
                description: 'Global Header Nav: Log in link',
              })}
              newNavMenuItem
            />

            {!isBAHLandingPage && (
              <>
                <GlobalHeaderNavMenuItem
                  data-testid="signup-btn"
                  onClick={handleClickSignup}
                  label={intl.formatMessage({
                    defaultMessage: 'Sign up',
                    id: 'kZjbOt',
                    description: 'Global Header Nav: Sign up link',
                  })}
                  newNavMenuItem
                />

                <GlobalHeaderNavMenuItem
                  data-testid="list-my-rv-btn"
                  href="/become-a-host"
                  label={
                    newBecomeAHostPage
                      ? intl.formatMessage({
                          defaultMessage: 'Try hosting today',
                          id: '0Q7HS7',
                          description: 'Global Header Nav: Hosting flow link',
                        })
                      : intl.formatMessage({
                          defaultMessage: 'Become a host',
                          id: '+ViN7X',
                          description: 'Global Header Nav: List my RV link',
                        })
                  }
                  asDesktopButton
                  newNavMenuItem
                  newBecomeAHostPage={newBecomeAHostPage}
                />
              </>
            )}
          </>
        )}
      </ul>
    </nav>
  );
};

export default GlobalHeaderNavMobileMenu;
