import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { FETCH_USER_SERVICE_URL } from '@/redux/modules/auth/userMini';
import { getHeaders } from '@/utility/getHeaders';
import { isDev } from '@/utility/isSSR';
import { logger } from '@/utility/logger';
import {
  get2FASessionCookie,
  getAuthToken,
  getSessionCookie,
  isSessionValid,
  reset2FASessionCookie,
  resetSessionCookie,
} from '@/utility/session';

const DEFAULT_CONFIG: AxiosRequestConfig = {
  timeout: isDev() ? 5000_000 : 30_000,
  method: 'GET',
};

export const GENERIC_ERROR_MESSAGE = 'Oops, something went wrong. Try again later.';

// TODO: refactor to always return full response
// Overloads for returning the right type depending on `returnFullResponse`
async function apiRequest<ResponseData = unknown>(
  config: AxiosRequestConfig,
  rejectOnError?: boolean,
  returnFullResponse?: false,
  token?: string,
): Promise<ResponseData>;
async function apiRequest<ResponseData = unknown>(
  config: AxiosRequestConfig,
  rejectOnError?: boolean,
  returnFullResponse?: true,
  token?: string,
): Promise<AxiosResponse<ResponseData>>;
async function apiRequest<ResponseData = unknown>(
  config: AxiosRequestConfig,
  rejectOnError?: boolean,
  returnFullResponse?: boolean,
  token?: string,
): Promise<ResponseData | AxiosResponse<ResponseData> | undefined>;
async function apiRequest<ResponseData = unknown>(
  config: AxiosRequestConfig,
  rejectOnError?: boolean,
  returnFullResponse?: boolean,
  token?: string,
): Promise<ResponseData | AxiosResponse<ResponseData> | undefined> {
  if (isDev()) logger.info('apiRequest', config.url);
  const defaultHeaders = await getHeaders();
  const headers = { ...config.headers, ...defaultHeaders };
  const authToken = token ?? getAuthToken();
  if (authToken) {
    headers.authorization = `Bearer ${authToken}`;
  }

  try {
    const response = await axios.request<ResponseData>({ ...DEFAULT_CONFIG, ...config, headers });
    return returnFullResponse ? response : response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      const parsedUserSession = getSessionCookie();
      const parsed2faSession = get2FASessionCookie();

      if (parsedUserSession) {
        const isValidUserSession = isSessionValid(parsedUserSession);
        const isFailedLogin = (config.url || '').startsWith(FETCH_USER_SERVICE_URL);
        if (isFailedLogin || !isValidUserSession) {
          resetSessionCookie();
        }
      }

      if (parsed2faSession) {
        reset2FASessionCookie();
      }
    }

    if (isDev()) {
      let err: any = null;
      if (error.response) {
        // Request made and server responded
        err = {
          url: config.url,
          status: error.response.status,
          //headers: error.response.headers, //till needed
          ...error.toJSON(),
        };
      } else if (error.request) {
        // The request was made but no response was received
        err = {
          url: config.url,
          ...error.toJSON(),
        };
      } else {
        // Something happened in setting up the request that triggered an Error
        err = {
          url: config.url,
          message: error.message,
        };
      }
      logger.log('ERR IN apiRequest.js:', err);
    } else {
      const res = error && error.response;
      logger.captureExceptionWithDatadog(error, {
        url: config.url,
        req_params: config.data || {},
        res,
      });
    }

    if (rejectOnError) {
      return Promise.reject(
        returnFullResponse ? error?.response : error?.response?.data || GENERIC_ERROR_MESSAGE,
      );
    }
  }
}

export default apiRequest;
