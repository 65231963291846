import { IGeocodeFeature } from '@/services/types/mapbox/mapboxApi';
import { IAction } from '@/utility/redux/action';

const SET_MAPBOX_PLACE = 'mapboxPlace/SET_MAPBOX_PLACE';

type IMapboxPlace = IGeocodeFeature | null;

interface ISetMapboxPlaceAction extends IAction {
  type: typeof SET_MAPBOX_PLACE;
  payload: IGeocodeFeature | null;
}

type TAction = ISetMapboxPlaceAction;

export const setMapboxPlace = (payload: IMapboxPlace): ISetMapboxPlaceAction => ({
  type: SET_MAPBOX_PLACE,
  payload,
});

interface IState {
  data: IMapboxPlace;
}

export const initialState: IState = {
  data: null,
};

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case SET_MAPBOX_PLACE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return { ...state };
  }
}
