import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Divider from '@/components/switchback/Divider/Divider';
import Dropdown from '@/components/switchback/Dropdown/Dropdown';
import {
  IVehicleCampgrounds,
  prettyPrintVehicleCampgrounds,
} from '@/services/types/search/vehicleCampgrounds';

import VehicleCampgroundsContent from '../DesktopUniversalSearch/VehicleCampgroundsContent/VehicleCampgroundsContent';
import classes from './MobileUniversalSearchVehiclesCampgrounds.module.css';

const CustomHeader: React.FC = () => (
  <>
    <span className="inline-flex items-center items-bottom md:hidden">
      <h2 className="pr-6 text-xl text-gray-900 semiHighlight">
        <FormattedMessage defaultMessage="Add details" id="GykZiH" />
      </h2>
    </span>
    <Divider className="mt-8 mb-4" />
  </>
);

interface IMobileUniversalSearchVehiclesCampgroundsProps {
  onChangeVehicle?: (vehicle?: IVehicleCampgrounds) => void;
  vehicle?: IVehicleCampgrounds;
}

const MobileUniversalSearchVehiclesCampgrounds: React.FC<
  React.PropsWithChildren<IMobileUniversalSearchVehiclesCampgroundsProps>
> = ({ onChangeVehicle, vehicle }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalButtonRef = useRef<HTMLButtonElement>(null);

  const handleOpenGuests = () => {
    setIsModalOpen(true);
  };

  const dismiss = () => {
    setIsModalOpen(false);
  };

  const text = prettyPrintVehicleCampgrounds(vehicle);

  return (
    <div>
      <label
        htmlFor="vehicle-button"
        className="block text-xs font-bold text-gray-500 uppercase semiHighlight">
        <FormattedMessage defaultMessage="Vehicle filters" id="/m6wcW" />
      </label>
      <button
        id="vehicle-button"
        className={classes.vehicle_tab}
        ref={modalButtonRef}
        onClick={handleOpenGuests}
        type="button"
        aria-expanded={isModalOpen}>
        {text ? text : <FormattedMessage defaultMessage="Length & hookups" id="MT69EK" />}
      </button>
      <div id="header-guests-dialog" role="region">
        <Dropdown
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          customHeader={<CustomHeader />}
          show={isModalOpen}
          relativeTo={modalButtonRef.current}
          onDismiss={dismiss}>
          <VehicleCampgroundsContent
            onChangeVehicle={onChangeVehicle}
            open
            vehicle={vehicle}
            onConfirm={dismiss}
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default MobileUniversalSearchVehiclesCampgrounds;
