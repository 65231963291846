import { createSelector } from 'reselect';

import { bookingDetailsSelector } from '@/redux/modules/booking/selectors';
import { TRootState } from '@/redux/rootReducer';
import { IBooking } from '@/services/types/booking/details';
import { IItem } from '@/services/types/core/quotes';
import { IAddOnItem } from '@/services/types/quote/IAddons';
import { getIntl } from '@/utility/i18n';

interface IAddOnRowItem {
  id?: number;
  description?: string;
  name: string;
  price: string;
  priceInfo?: string;
  priceRules?: string;
  rawPrice?: number;
  quantityAvailable?: number;
  currency?: string;
  quantitySelected?: number;
  daily?: boolean;
  imageUrl?: string;
  position?: number;
  deferred?: boolean;
}

const mapAddonsToItems = (addons?: IItem[]) =>
  addons?.map(item => ({
    count: item.count,
    id: item.parent_item_id,
    itemId: item.id,
    daily: item.daily,
  }));

const getCheckoutBookingAddons = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  IAddOnItem[] | undefined
>(
  state => state.checkout.booking,
  data => mapAddonsToItems(data?.addons),
);

const getQuoteAddons = createSelector<
  TRootState,
  TRootState['quote']['data'],
  IAddOnItem[] | undefined
>(
  state => state.quote.data,
  data => mapAddonsToItems(data?.addons),
);

export const getBookingOrQuoteAddons = createSelector<
  TRootState,
  ReturnType<typeof getCheckoutBookingAddons>,
  ReturnType<typeof getQuoteAddons>,
  IAddOnItem[] | undefined
>(
  getCheckoutBookingAddons,
  getQuoteAddons,
  (checkoutBookingAddons, quoteAddons) => checkoutBookingAddons || quoteAddons,
);

export const getDetailsBookingAddons = createSelector<
  TRootState,
  IItem[] | undefined,
  IAddOnItem[] | undefined
>(state => state.booking.details.data?.addons, mapAddonsToItems);

const mapDataToIncrementValue = (item: IItem) => {
  const intl = getIntl();

  if (item.daily) {
    return intl.formatMessage({
      defaultMessage: 'day',
      id: 'aVqg31',
    });
  }

  if (item.count > 0) {
    return intl.formatMessage({
      defaultMessage: 'each',
      id: 'hcdFiU',
    });
  }

  return '';
};

const mapItemToDisplayFormat = (item: IItem, booking: IBooking) => {
  const intl = getIntl();

  const price = item.price
    ? intl.formatNumber(item.price / 100, {
        style: 'currency',
        currency: booking.presentment_currency || 'USD',
      })
    : intl.formatMessage({ defaultMessage: 'Free', id: 'tf1lIh' });

  const increment = mapDataToIncrementValue(item);

  const priceInfo =
    item.price && increment
      ? intl.formatMessage(
          {
            id: 'e63t1v',
            defaultMessage: `{increment, select,
        each {each}
        day {per day}
        other {}
      }`,
          },
          { increment },
        )
      : '';

  return {
    id: item.id,
    name: item.name,
    description: item.description,
    priceInfo,
    price,
    rawPrice: item.price,
    quantityAvailable: item.count,
    currency: booking.presentment_currency || 'USD',
    imageUrl: item.image_url,
    position: item.position,
    daily: item.daily,
    deferred: item.deferred,
  };
};

const mapItems = (
  booking: ReturnType<typeof bookingDetailsSelector> | TRootState['checkout']['booking'],
) => {
  if (!booking) {
    return;
  }
  // one-time booking items created from edit quota
  // do not have a parent_item_id because they do not exist on the listing
  // also item can not be required, rental_amount or delivery
  const data = booking.addons
    ?.filter(
      item => !item.required && !item.rental_amount && !item.delivery && !item.parent_item_id,
    )
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(item => mapItemToDisplayFormat(item, booking));

  return data;
};

export const getOneTimeItems = createSelector<
  TRootState,
  ReturnType<typeof bookingDetailsSelector>,
  IAddOnRowItem[] | undefined
>(bookingDetailsSelector, mapItems);

export const getOneTimeCheckoutBookingItems = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  IAddOnRowItem[] | undefined
>(state => state.checkout.booking, mapItems);
