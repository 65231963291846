export const UI_TOGGLE_MODAL = 'UI_TOGGLE_MODAL';

interface ToggleModalActionPayload {
  id: string;
  props?: any;
}

export interface ToggleModalAction {
  type: string;
  payload: ToggleModalActionPayload;
}

export const toggleModalAction = (params: ToggleModalActionPayload): ToggleModalAction => ({
  type: UI_TOGGLE_MODAL,
  payload: {
    id: params.id,
    props: params.props,
  },
});
