import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getIsAuthenticated } from '@/redux/selectors/auth/user';
import { navigateTo } from '@/utility/navigateTo';
import { triggerLoginModal } from '@/utility/triggerLoginModal';

export const useHostingStayFlow = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);

  const redirectToHostingStayFlow = useCallback(() => {
    if (isAuthenticated) {
      navigateTo(`${process.env.hostApp}/list-your-stay`);
    } else {
      triggerLoginModal();
    }
  }, [isAuthenticated]);

  return { redirectToHostingStayFlow };
};
