import { useEffect, useState } from 'react';

const useScript = (url: string | undefined, innerHtml?: string) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const handleLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    if (!url && !innerHtml) return;

    const script = document.createElement('script');
    if (url) script.src = url;
    else if (innerHtml) script.text = innerHtml;
    script.async = true;
    script.onload = handleLoad;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [url, innerHtml]);

  return isLoaded;
};

export default useScript;
