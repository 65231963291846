import { EDriverVerificationSection } from '@/constants/driverVerification';

import { ERentalType } from '../types';

export enum RenterDashboardEventName {
  ID_VERIFY_SELECTED = 'ID Verify Selected',
  ID_VERIFY_STARTED = 'ID Verify Started',
  ID_VERIFY_COMPLETED = 'ID Verify Completed',
  ID_VERIFY_FAILED = 'ID Verify Failed',
}

export type TDriverVerificationEvent = {
  bookingID: number;
  rentalType: ERentalType;
  source: EDriverVerificationSection;
  failReason?: string;
};
