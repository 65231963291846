import useSWR from 'swr';

import apiRequest from '@/services/apiRequest';
import { getSearchApi } from '@/utility/getCoreApi';

type TGeoDataMyIpResponse = {
  lat: number;
  lng: number;
  city: string;
  state: string;
  country_code: string;
  country_name: string;
};

export const useGeoDataMyIp = (skip?: boolean) => {
  return useSWR(skip ? null : `${getSearchApi()}/geodata/my-ip`, (url: string) =>
    apiRequest<TGeoDataMyIpResponse>({ url }),
  );
};
