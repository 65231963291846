import {
  FETCH_TAGS_FAILURE,
  FETCH_TAGS_PENDING,
  FETCH_TAGS_SUCCESS,
  ITagsAction,
} from '@/redux/modules/tags/actions';
import { IRentalTag } from '@/services/types/core/reviews/id';

export const initialState: ITagsData = {
  data: [],
  isFetching: false,
  error: false,
};

interface ITagsData {
  data?: IRentalTag[];
  isFetching: boolean;
  error: boolean;
}

export default function reducer(state: ITagsData = initialState, action: ITagsAction) {
  switch (action.type) {
    case FETCH_TAGS_SUCCESS:
      return {
        data: action.payload,
        isFetching: false,
        error: false,
      };
    case FETCH_TAGS_PENDING:
      return {
        isFetching: true,
        error: false,
      };
    case FETCH_TAGS_FAILURE:
      return {
        isFetching: false,
        error: true,
      };
    default:
      return state;
  }
}
