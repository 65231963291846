import { UNITS } from '@/constants/units';

const units: Record<string, UNITS> = {
  feet: 'foot',
  meters: 'meter',
  kilometers: 'kilometer',
  gallons: 'gallon',
  liters: 'liter',
  kg: 'kilogram',
  lbs: 'pound',
  miles: 'mile',

  // plural/repeating units of occurrence
  daily: 'day',
  weekly: 'week',
  monthly: 'month',
};

export const mapPluralUnitToSingular = (unit: string): UNITS => {
  // @ts-expect-error fixable: unchecked index access
  return units[unit] || unit;
};

export const pluralizeUnit = (val: number, unit: string, plural = unit + 's') => {
  return [1, -1].includes(val) ? unit : plural;
};
