import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { IRentalItem, TGetRentalItems } from '@/services/types/core/rentalItems';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

import { getBookingOrQuoteCurrency } from '../selectors/bill/data';

const SET_RENTAL_ITEMS = 'rentalItem/SET_RENTAL_ITEMS';

interface ISetRentalItemsAction extends IAction {
  type: typeof SET_RENTAL_ITEMS;
  payload: IRentalItem[];
}

type TAction = ISetRentalItemsAction;

const setRentalItems = (payload: IRentalItem[]): ISetRentalItemsAction => ({
  type: SET_RENTAL_ITEMS,
  payload,
});

export const getRentalItems =
  (rentalId: string): ThunkAction<Promise<void>, TRootState, void, ISetRentalItemsAction> =>
  async (dispatch, getState) => {
    const currency = getBookingOrQuoteCurrency(getState());
    const params: Record<string, string> = {
      available_for_rental_id: rentalId,
      presentment_currency: currency,
    };
    const url = `${getCoreApi()}/items`;
    const data = await apiRequest<IRentalItem[]>({ url, params, method: 'GET' });
    dispatch(setRentalItems(data || []));
  };

interface IState {
  data: TGetRentalItems;
}

export const initialState: IState = {
  data: [],
};

// TODO: Add async actions (REQUEST/FAIL/SUCCESS)
export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case SET_RENTAL_ITEMS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return { ...state };
  }
}
