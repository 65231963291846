import { EProjectSection, trackEventV2 } from '../trackEventV2';
import { EAccountEventName, IAccountEvent, IAccountVerificationEvent } from './types';

export const trackLoggedInEvent = (event: IAccountEvent) => {
  trackEventV2(EAccountEventName.LOGGED_IN, EProjectSection.MARKETPLACE, { ...event });
};

export const trackLoggedOutEvent = () => {
  trackEventV2(EAccountEventName.LOGGED_OUT, EProjectSection.MARKETPLACE, {});
};

export const trackSignedUpEvent = (event: IAccountEvent) => {
  trackEventV2(EAccountEventName.SIGNED_UP, EProjectSection.MARKETPLACE, { ...event });
};

export const trackPasswordResetEvent = () => {
  trackEventV2(EAccountEventName.PASSWORD_RESET, EProjectSection.MARKETPLACE, {});
};

export const trackLoginCredentialsEntered = (event: IAccountEvent) => {
  trackEventV2(EAccountEventName.LOGIN_CREDENTIALS_ENTERED, EProjectSection.MARKETPLACE, {
    ...event,
  });
};

export const trackVerificationCodeRequested = (event: IAccountVerificationEvent) => {
  trackEventV2(EAccountEventName.VERIFICATION_CODE_REQUESTED, EProjectSection.MARKETPLACE, {
    ...event,
  });
};

export const trackVerificationCodeEntered = (event: IAccountVerificationEvent) => {
  trackEventV2(EAccountEventName.VERIFICATION_CODE_ENTERED, EProjectSection.MARKETPLACE, {
    ...event,
  });
};

export const track2FALoggedInEvent = (event: IAccountVerificationEvent) => {
  trackEventV2(EAccountEventName.LOGGED_IN, EProjectSection.MARKETPLACE, {
    ...event,
  });
};
